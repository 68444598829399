import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

const UserInfoHeader = (props) => {

  const navigate = useNavigate()
  
  const onClickBack = () => {

    navigate(-1)
  }
  
  const onClickHome = () => {

    navigate("/dashboard")
  }

  return (
    <header>
      <div  className="project_head suvay_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>
            <h1>
              <a onClick={onClickHome}><i className="icon-logo"></i></a>
            </h1>
            <h4> <i className="icon-arrowleft" onClick={onClickBack}></i> 계정 정보</h4>
            </div>
            <div>
            <button className={`primary large survey_send_btn ${props.isLock ? "disabled" : ""}`} disabled={props.isLock} onClick={props.onClickSave}>변경사항 저장</button>
            </div>
          </div>
        </div>
      </div>
    </header>  
  );
}
export default UserInfoHeader 