import { Link } from 'react-router-dom';
import ProjectHeader from '../inc/ProjectHeader';
import NewProjectPopup from '../popup/NewProjectPopup';
import ProjectHomePopup from '../popup/ProjectHomePopup';
import DashBoard from '../project/project_01';
// import DashBoard from '../dashboard/DashBoard';
const Design = props => {
  return (      
    <div className="body design_guide">
      <div className="inwrap">
        <div>
        <section>
          <h2>TYPOGRAPHY - English/Korean - Noto Sans</h2>
          <div>
          <h1>H1. Ant Design</h1> 
          <h2>H2. Ant Design</h2>
          <h3>H3. Ant Design</h3>
          <h4>H4. Ant Design</h4>
          <h5>H5. Ant Design</h5>
          
          <dl>
            <dt>
              <p>Body - 400 - 14pt</p>
            </dt>
            <dd>
              <p>p. Ant Design</p>
              <p className="text_secondaty">text_secondaty - Ant Design</p>
            </dd>
          </dl>
          
          <dl>
            <dt>
              <p>Body - 500 - 14pt</p>
            </dt>
            <dd>
              <p className="text_danger">text_danger - Ant Design</p>
              <p className="text_disabled">text_disabled - Ant Design</p>
              <p className="text_warning">text_warning - Ant Design</p>
              <p className="text_mark">text_mark - Ant Design</p>
              <p className='c_primary_6'>c_primary_6</p>
              <p className='font_16'>font_16</p>
              <p className='c_neutral_6'>c_neutral_6</p>
            </dd>
          </dl>

          <dl>
            <dt>
              <p>Body - 700 - 14pt</p>
              <p>Body - underline - 14pt</p>
              <p>Body - strikethrough - 14pt</p>
              <p>Body - italic - 14pt</p>
              <p>Body - text_code - 14pt</p>
            </dt>
            <dd>
              <p className="fontw_700">Body - 700 - 14pt</p>
              <p className="underline">underline - 14pt</p>
              <p className="strikethrough">strikethrough - 14pt</p>
              <p className="italic">italic - 14pt</p>
              <p className="text_code">text_code - 14pt</p>
              <p className="text_footnote_description">text_footnote_description - 12pt</p>
            </dd>
          </dl>
        </div>
        </section>
        {/* <!-- Text button --> */}
        <section>
          <h2>Text button</h2>
          <div>
            <ul>
              <li></li>
              <li>Primary</li>
              <li>Primary (with icon)</li>
              <li>Secondary</li>
              <li>Text Only</li>
              <li><b>small</b></li>
              <li><button className="primary small"> small Button</button></li>
              <li><button className="secondary small bg">small Button</button></li>
              <li><button className="secondary small ">small Button</button></li>
              <li><button className="text_only small">small Button </button></li>
              <li><b>Medium</b></li>
              <li><button className="primary ">Button Title</button></li>
              <li></li>
              <li><button className="secondary gray">Button Title</button></li>
              <li><button className="text_only">Button Title</button></li>

              <li><b>Error</b></li>
              <li><button className="error">Button Title</button></li>
              <li></li>
              <li><button className="secondary">Button Title</button></li>
              <li><button className="text_only text_danger">Button Title</button></li>

              <li><b>Warning</b></li>
              <li><button className="warning">Button Title</button></li>
              <li></li>
              <li><button className="secondary">Button Title</button></li>
              <li><button className="text_only text_warning">Button Title</button></li>

              <li><b>Success</b></li>
              <li><button className="success">Button Title</button></li>
              <li></li>
              <li><button className="secondary">Button Title</button></li>
              <li><button className="text_only text_success">Button Title</button></li>


              <li>Disabled</li>
              <li><button className="disabled">Button Title</button></li>
              <li></li>
              <li><button className="disabled">Button Title</button></li>
              <li><button className="text_only disabled">Button Title</button></li>

              <li><b>Large</b></li>
              <li><button className="primary large">Button Title</button></li>
              <li><button className="primary large"> <i>+</i> Button Title</button></li>
              <li><button className="secondary large">Button Title</button></li>
              <li><button className="text_only large">Button Title</button></li>

              <li>Disabled</li>
              <li><button className="disabled large">Button Title</button></li>
              <li></li>
              <li><button className="disabled large">Button Title</button></li>
              <li><button className="text_only disabled large">Button Title</button></li>
            </ul>
          </div>
        </section>
        {/* <!-- Dropdown trigger --> */}
        <section>
          <h2>Dropdown trigger</h2>
          <div>
            <dl>
              <dt>
                Medium 
              </dt>
              <dd>
                <div className="selectBoxList">
                  {/* <!-- selectBox1 --> */}
                  <div className="box">
                      <div className="selectBox">
                      <span className="label">Dropdown Trigger</span>
                      <ul className="optionList">
                          <li className="optionItem">apple</li>
                          <li className="optionItem">orange</li>
                          <li className="optionItem">grape</li>
                          <li className="optionItem">melon</li>
                      </ul>
                      </div>
                  </div>
                  {/* <!-- selectBox2 --> */}
                  <div classname="selectBox">
                    <span classname="label">설문 상태 : 전체</span>
                    <ul classname="optionList">
                      <li classname="optionItem" >설문 상태 : 전체</li>
                      <li classname="optionItem" >설문 진행 중</li>
                      <li classname="optionItem" >설문 대기</li>
                      <li classname="optionItem" >설문 종료</li>
                    </ul>                 
                  </div>
                  <div className="box">
                      <div className="selectBox  ">
                      <span className="label">Dropdown Trigger</span>
                      <ul className="optionList">
                          <li className="optionItem">Red</li>
                          <li className="optionItem">orange</li>
                          <li className="optionItem">purple</li>
                          <li className="optionItem">green</li>
                      </ul>
                      </div>
                  </div>
                  <div>
                    <input type="text" placeholder="이메일"/>
                    <p className="c_dust_red_5">이메일 형식에 맞지 않습니다. @ 포함하여 정확하게 입력해주세요.</p>

                    <input type="text" classname='big' placeholder="이메일"/>
                   
                  </div>
                  
               </div>

              </dd>
            </dl>
            <dl>
              <dt>
                Large 
              </dt>
              <dd>
                <div className="selectBoxList">
                  {/* <!-- selectBox1 --> */}
                  <div className="box">
                      <div className="selectBox large">
                      <span className="label">Dropdown Trigger</span>
                      <ul className="optionList">
                          <li className="optionItem">apple</li>
                          <li className="optionItem">orange</li>
                          <li className="optionItem">grape</li>
                          <li className="optionItem">melon</li>
                      </ul>
                      </div>
                  </div>
                  {/* <!-- selectBox2 --> */}
                  <div className="box">
                      <div className="selectBox  large ">
                      <span className="label">Dropdown Trigger</span>
                      <ul className="optionList">
                          <li className="optionItem">Red</li>
                          <li className="optionItem">orange</li>
                          <li className="optionItem">purple</li>
                          <li className="optionItem">green</li>
                      </ul>
                      </div>
                  </div>
                  {/* <!-- selectBox3 --> */}
                  <div className="vertical_form">
                    <div>
                    <i className="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div>
                      <div className="selectBox select_inline">
                        <span className="label">참여자</span>
                        <ul className="optionList">
                            <li className="optionItem">참여자</li>
                            <li className="optionItem">소유자</li>
                            <li className="optionItem">삭제</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  
                  
               </div>
              </dd>
            </dl>
          </div>
        </section>


        {/* <!-- form --> */}
        <section>
          <h2>form</h2>
          <div>
            <label className="switch">
              <input type="checkbox"/>
              <span className="slider round"></span>
            </label>
            
            <label className="switch">
              <input type="checkbox" checked/>
              <span className="slider round"></span>
            </label>

            <label className="switch">
              <input type="checkbox" disabled/>
              <span className="slider round"></span>
            </label>


            <br/><br/>
            <div className="filebox  bs3-primary preview-image">
              <label for="input_file"><i className="icon-upload"></i>사진 첨부</label> 
              <input type="file" id="input_file" className="upload-hidden"/> 
              <input className="upload-name" value="파일선택" disabled="disabled"/>
            </div>
            <div className="filebox  bs3-primary preview-image">
              <label for="input_file" className='box'><i className="icon-upload"></i>사진 첨부</label> 
              <input type="file" id="input_file" className="upload-hidden"/> 
              <input className="upload-name" value="파일선택" disabled="disabled"/>
            </div>
            <br/><br/>

            <div className="search_box">
              <input type="text" placeholder="프로젝트 이름으로 검색"/>
              <button><i className="icon-search"></i></button>
            </div>
            <br/><br/>

            <div className="vertical_form">
              <div>
                <div className="selectBox select_inline">
                  <span className="label">참여자</span>
                  <ul className="optionList">
                    <li className="optionItem">참여자</li>
                    <li className="optionItem">소유자</li>
                  </ul>
                </div>
                <input type="text" id="join_mail" placeholder="예) af@atflow.co.kr" className="full big"/>
              </div>
              <div>
                <button className="full">추가</button>
              </div>
            </div>
            <br/>

            <div className="vertical_form">
              <div>
                <input type="text" id="join_mail" placeholder="예) af@atflow.co.kr" className="full big"/>
              </div>
              <div>
                <button className="full">추가</button>
              </div>
            </div>
            <br/>

            <div className="tooltip">Hover Me (LEFT)
                <span className="tooltiptext tooltip-left">왼쪽 툴팁</span>
              </div>

              <br/><br/>
              
              <div className="tooltip">Hover Me (RIGHT)
                <span className="tooltiptext tooltip-right">오른쪽 툴팁</span>
              </div>

              <br/><br/>
            
              <button className="tooltip">Hover Me (TOP)
                <span className="tooltiptext tooltip-top">위쪽 툴팁</span>
              </button>

              <br/><br/>

              <button className="tooltip">Hover Me (BOTTOM)
                <span className="tooltiptext tooltip-bottom">아래쪽 툴팁</span>
              </button>
              <span classname='tooltip'><i classname='icon-infocircle '></i> <span className="tooltiptext tooltip-top">문항마다 섹션 공통 질문이 함께 노출되어 보다 쉽고 정확한 설문조사 응답이 가능합니다.</span></span> 
              <br/><br/>


            <textarea name="" id="" cols="30" rows="10" classname='full' placeholder='섹션명과 설명은 설문하는데 필요한  개념, 유의사항 등을 전달하는 수단으로 활용하세요.
                  개별 설문에는 최소 1개의 섹션이 필요하며, 필요에 따라서 추가하시면 됩니다.
                  섹션 화면은 사용자에게 설문 표지처럼 보여진다는 점을 명심하세요.
                  '>
                
            </textarea>
            <br/>
            <textarea name="" id="" cols="30" rows="10" classname='full big' placeholder='섹션명과 설명은 설문하는데 필요한  개념, 유의사항 등을 전달하는 수단으로 활용하세요.
                  개별 설문에는 최소 1개의 섹션이 필요하며, 필요에 따라서 추가하시면 됩니다.
                  섹션 화면은 사용자에게 설문 표지처럼 보여진다는 점을 명심하세요.
                  '>
                
            </textarea>

            
            
            <br/>
            <div>
              <ul className="steps">
                <li className="ok"><span></span></li>
                <li  className="on"><span>2</span></li>
              </ul>
            </div>
          </div>
        </section>
        {/* <!-- Tab/Radio button (text) --> */}
        <section>
          <h2>Tab/Radio button (text)</h2>
          <div>
            <div>
              <ul className="tab">
                <li className="active">Tab1</li>
                <li>Tab2</li>
                <li>Tab1</li>
              </ul>
              <br/><br/>
                <div className="radio">
                  <label>
                    <input type="radio" name="contact" value="email" checked />
                    <span>이메일</span>
                  </label>
                
                  <label>
                    <input type="radio" name="contact" value="phone" />
                    <span>전화</span>
                  </label>
                
                  <label>
                    <input type="radio" name="contact" value="fax" disabled />
                    <span>팩스</span>
                  </label>
                
                  <label>
                    <input type="radio" name="contact" value="mail" />
                    <span>우편</span>
                  </label>
                </div>

                <div className="checkbox">
                  <label>
                    <input type="checkbox" name="checkbox" value="email" checked />
                    <span>이메일</span>
                  </label>
                
                  <label>
                    <input type="checkbox" name="checkbox" value="phone" />
                    <span>전화</span>
                  </label>
                
                  <label>
                    <input type="checkbox" name="checkbox" value="fax" disabled />
                    <span>팩스</span>
                  </label>
                
                  <label>
                    <input type="checkbox" name="checkbox" value="mail" />
                    <span>우편</span>
                  </label>
                </div>

            </div>
          </div>
        </section>


        {/* <style>

          .icon-wrap i {font-size:30px}
          .icon-wrap .span3{width: 25%;float: left; text-align: center; margin-bottom:3em}
          .icon-wrap .span3 span {display: block;font-size:14px;}

        </style> */}

        {/* <!-- icon --> */}
        <section className="icon-wrap">
          <h2>icon</h2>
          <div>
            <div className="container" id="icons">
              <div className="row">
                <div className="span3" title="Code: \e800">
                  <i className="demo-icon icon-arrowleft"></i> <span className="i-name">icon-arrowleft</span><span className="i-code">\e800</span>
                </div>
                <div className="span3" title="Code: \e801">
                  <i className="demo-icon icon-upload"></i> <span className="i-name">icon-upload</span><span className="i-code">\e801</span>
                </div>
                <div className="span3" title="Code: \e802">
                  <i className="demo-icon icon-calendar"></i> <span className="i-name">icon-calendar</span><span className="i-code">\e802</span>
                </div>
                <div className="span3" title="Code: \e803">
                  <i className="demo-icon icon-caretdown"></i> <span className="i-name">icon-caretdown</span><span className="i-code">\e803</span>
                </div>
              </div>
              <div className="row">

                <div className="span3" title="Code: \e805">
                  <i className="demo-icon icon-mail"></i> <span className="i-name">icon-mail</span><span className="i-code">\e805</span>
                </div>
                <div className="span3" title="Code: \e806">
                  <i className="demo-icon icon-close"></i> <span className="i-name">icon-close</span><span className="i-code">\e806</span>
                </div>
                <div className="span3" title="Code: \e807">
                  <i className="demo-icon icon-dot-menu"></i> <span className="i-name">icon-dot-menu</span><span className="i-code">\e807</span>
                </div>
              </div>
              <div className="row">
                <div className="span3" title="Code: \e808">
                  <i className="demo-icon icon-headset"></i> <span className="i-name">icon-headset</span><span className="i-code">\e808</span>
                </div>
                <div className="span3" title="Code: \e809">
                  <i className="demo-icon icon-delete"></i> <span className="i-name">icon-delete</span><span className="i-code">\e809</span>
                </div>
                <div className="span3" title="Code: \e80a">
                  <i className="demo-icon icon-down"></i> <span className="i-name">icon-down</span><span className="i-code">\e80a</span>
                </div>
                <div className="span3" title="Code: \e80b">
                  <i className="demo-icon icon-answer"></i> <span className="i-name">icon-answer</span><span className="i-code">\e80b</span>
                </div>
              </div>
              <div className="row">
                <div className="span3" title="Code: \e80c">
                  <i className="demo-icon icon-fileimage"></i> <span className="i-name">icon-fileimage</span><span className="i-code">\e80c</span>
                </div>
                <div className="span3" title="Code: \e80d">
                  <i className="demo-icon icon-attachment"></i> <span className="i-name">icon-attachment</span><span className="i-code">\e80d</span>
                </div>
                <div className="span3" title="Code: \e80e">
                  <i className="demo-icon icon-infocircle"></i> <span className="i-name">icon-infocircle</span><span className="i-code">\e80e</span>
                </div>
                <div className="span3" title="Code: \e80f">
                  <i className="demo-icon icon-left"></i> <span className="i-name">icon-left</span><span className="i-code">\e80f</span>
                </div>
              </div>
              <div className="row">
                <div className="span3" title="Code: \e810">
                  <i className="demo-icon icon-arrow-down"></i> <span className="i-name">icon-arrow-down</span><span className="i-code">\e810</span>
                </div>
                <div className="span3" title="Code: \e811">
                  <i className="demo-icon icon-arrow-up"></i> <span className="i-name">icon-arrow-up</span><span className="i-code">\e811</span>
                </div>
                <div className="span3" title="Code: \e812">
                  <i className="demo-icon icon-menu"></i> <span className="i-name">icon-menu</span><span className="i-code">\e812</span>
                </div>
                <div className="span3" title="Code: \e813">
                  <i className="demo-icon icon-edit"></i> <span className="i-name">icon-edit</span><span className="i-code">\e813</span>
                </div>
              </div>
              <div className="row">
                <div className="span3" title="Code: \e81c">
                  <i className="demo-icon icon-profile"></i> <span className="i-name">icon-profile</span><span className="i-code">\e81c</span>
                </div>
                <div className="span3" title="Code: \e821">
                  <i className="demo-icon icon-right"></i> <span className="i-name">icon-right</span><span className="i-code">\e821</span>
                </div>
                <div className="span3" title="Code: \e822">
                  <i className="demo-icon icon-search"></i> <span className="i-name">icon-search</span><span className="i-code">\e822</span>
                </div>
                <div className="span3" title="Code: \e823">
                  <i className="demo-icon icon-setting"></i> <span className="i-name">icon-setting</span><span className="i-code">\e823</span>
                </div>
              </div>
              <div className="row">
                <div className="span3" title="Code: \e825">
                  <i className="demo-icon icon-team"></i> <span className="i-name">icon-team</span><span className="i-code">\e825</span>
                </div>
                <div className="span3" title="Code: \e826">
                  <i className="demo-icon icon-up"></i> <span className="i-name">icon-up</span><span className="i-code">\e826</span>
                </div>
                <div className="span3" title="Code: \e827">
                  <i className="demo-icon icon-user"></i> <span className="i-name">icon-user</span><span className="i-code">\e827</span>
                </div>
                <div className="span3" title="Code: \e82a">
                  <i className="demo-icon icon-check"></i> <span className="i-name">icon-check</span><span className="i-code">\e82a</span>
                </div>
              </div>
              <div className="row">
                <div className="span3" title="Code: \e82b">
                  <i className="demo-icon icon-steps-check"></i> <span className="i-name">icon-steps-check</span><span className="i-code">\e82b</span>
                </div>
                <div className="span3" title="Code: \e82c">
                  <i className="demo-icon icon-radio-on"></i> <span className="i-name">icon-radio-on</span><span className="i-code">\e82c</span>
                </div>
                <div className="span3" title="Code: \e82d">
                  <i className="demo-icon icon-radio"></i> <span className="i-name">icon-radio</span><span className="i-code">\e82d</span>
                </div>
                <div className="span3" title="Code: \e82e">
                  <i className="demo-icon icon-checkbox-on"></i> <span className="i-name">icon-checkbox-on</span><span className="i-code">\e82e</span>
                </div>
              </div>
              <div className="row">
                <div className="span3" title="Code: \e82f">
                  <i className="demo-icon icon-checkbox"></i> <span className="i-name">icon-checkbox</span><span className="i-code">\e82f</span>
                </div>
                <div className="span3" title="Code: \e830">
                  <i className="demo-icon icon-project"></i> <span className="i-name">icon-project</span><span className="i-code">\e830</span>
                </div>
                <div className="span3" title="Code: \e831">
                  <i className="demo-icon icon-plus"></i> <span className="i-name">icon-plus</span><span className="i-code">\e831</span>
                </div>
                <div className="span3" title="Code: \e832">
                  <i className="demo-icon icon-logo"></i> <span className="i-name">icon-logo</span><span className="i-code">\e832</span>
                </div>
                <div className="span3" title="Code: \e824">
                  <i className="demo-icon icon-useradd"></i> <span className="i-name">icon-useradd</span><span className="i-code">\e824</span>
                </div>
                <div className="span3" title="Code: \e814">
                  <i className="demo-icon icon-copy"></i> <span className="i-name">icon-copy</span><span className="i-code">\e814</span>
                </div>
                <div className="span3" title="Code: \e815">
                  <i className="demo-icon icon-open"></i> <span className="i-name">icon-open</span><span className="i-code">\e815</span>
                </div>
                <div className="span3" title="Code: \e816">
                  <i className="icon-minuscircle"></i> <span className="i-name">icon-minuscircle</span><span className="i-code">\e816</span>
                </div>
              </div>
            </div>
            <div>
              <br/>
              {/* <br style="clear: both;"/> */}
              <img src="../../../assets/images/Bandade_b.svg" alt=""/>  
              <img src="../../../assets/images/Bandade_g.svg" alt=""/>  
              <img src="../../../assets/images/Bandade_r.svg" alt=""/>  
              <img src="../../../assets/images/Bandade_y.svg" alt=""/>  
              <br/>
              <img src="../../../assets/images/Family_b.svg"  alt=""/>  
              <img src="../../../assets/images/Family_g.svg"  alt=""/>  
              <img src="../../../assets/images/Family_r.svg"  alt=""/>  
              <img src="../../../assets/images/Family_y.svg"  alt=""/>  
              <br/>
              <img src="../../../assets/images/Heart_b.svg" alt=""/>  
              <img src="../../../assets/images/Heart_g.svg" alt=""/>  
              <img src="../../../assets/images/Heart_r.svg" alt=""/>  
              <img src="../../../assets/images/Heart_y.svg" alt=""/>  
              <br/>
              <img src="../../../assets/images/HeartOnHand_b.svg"  alt=""/>  
              <img src="../../../assets/images/HeartOnHand_g.svg"  alt=""/>  
              <img src="../../../assets/images/HeartOnHand_r.svg"  alt=""/>  
              <img src="../../../assets/images/HeartOnHand_y.svg"  alt=""/>  
              <br/>
              <img src="../../../assets/images/Keyboard_g.svg"  alt=""/>  
              <img src="../../../assets/images/Keyboard_b.svg"  alt=""/>  
              <img src="../../../assets/images/Keyboard_r.svg"  alt=""/>  
              <img src="../../../assets/images/Keyboard_y.svg"  alt=""/>  
              <br/>
              <img src="../../../assets/images/Laptop_b.svg"  alt=""/>  
              <img src="../../../assets/images/Laptop_g.svg"  alt=""/>  
              <img src="../../../assets/images/Laptop_r.svg"  alt=""/>  
              <img src="../../../assets/images/Laptop_y.svg"  alt=""/>  
              <br/>
              <img src="../../../assets/images/Tag_b.svg"  alt=""/>  
              <img src="../../../assets/images/Tag_g.svg"  alt=""/>  
              <img src="../../../assets/images/Tag_r.svg"  alt=""/>  
              <img src="../../../assets/images/Tag_y.svg"  alt=""/>  
              <br/>
              <img src="../../../assets/images/Brain_b.svg"  alt=""/>  
              <img src="../../../assets/images/Brain_g.svg"  alt=""/>  
              <img src="../../../assets/images/Brain_r.svg"  alt=""/>  
              <img src="../../../assets/images/Brain_y.svg"  alt=""/>  
              <br/>
              <img src="../../../assets/images/interview_1.png"  alt=""/>  
              <img src="../../../assets/images/interview_2.png"  alt=""/>  
              <img src="../../../assets/images/signin_1.png"  alt=""/>  
              <img src="../../../assets/images/no_items.png"  alt=""/>  
            </div>
          </div>
        </section>
      </div>
      </div>
    </div>
  );
}
  
export default Design;