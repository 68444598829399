import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import MemoPopSingle from '../../../markup/popup/memoPopSingle';
import MemoThreadPopup from '../../survey/popup/MemoThreadPopup';
import { initPopupMenu } from '../../../common/javascriptHelper';

const MemoPopup = props => {

  const {projectId, surveyData, participantId, affiliation, title, memothreads, showAnswer, onClosed} = props
  const scrollRef = useRef();
  const [memoThreads, setMemoThreads] = useState(memothreads)

  useEffect(()=> {

    $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').fadeIn();

    initPopupMenu()

    setTimeout(() => {
      scrollRef.current?.scroll({
        top: 0,
      });
    }, 200)

    return ()=>{ 
      $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').hide();
    }

  }, []);

  const onClickClose = () => {

    $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').fadeOut().promise().done(function() {
      if (!!onClosed) onClosed()
    });
  }


  const onClosedMemo = (memo) => {

    var list = memoThreads.filter(e => e !== memo)
    setMemoThreads(list)
    if (list.length === 0) {
      onClickClose()
    }
  }

  return (
    <>
    <div className="popup MemoPopup" >
        <div className="popup_wrap">

        {/* 메모 팝업 */}
        <div className="popup_inwrap">
          <div  className="section_tit flex_between">           
            <h5>메모 {memothreads?.length > 0 ? `(${memothreads?.length})` : ""} - {title}  </h5>
            <span className="close_btn" onClick={onClickClose}></span>
          </div>
          
          <form action="" onSubmit={(e) => {e.preventDefault()}}>
            <div className="pop_content" ref={scrollRef}>
              { memoThreads?.map((memo, index) => 
                  <MemoThreadPopup
                    key={`memo-${index}`}
                    projectId={projectId}
                    surveyId={surveyData?.idsurvey}
                    participantId={participantId}
                    affiliation={affiliation}
                    memothread={memo}
                    showAnswer={showAnswer}
                    onClosedMemo={onClosedMemo}
                    isPopup={false}
                  />
                )
              }
            </div>
          </form>
        </div>
        </div>
      </div>
      <div className="dim_bg" style={{width:"100%", height:"100%"}} onClick={onClickClose}></div>
    </>
  );  
}
 export default MemoPopup;