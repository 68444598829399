import React from 'react';
import { useApi } from '../../../context/ApiContext';
import { useProject } from '../../../context/ProjectContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import ProjectTargetGroupsPopup from '../popup/ProjectTargetGroupsPopup';
import ProjectTargetGroupsCompletePopup from '../popup/ProjectTargetGroupsCompletePopup';

const TargetGroupsButton = () => {
  const api = useApi();
  const project = useProject();
  const { popup, alert } = useCommonPopup();

  const onClickTargetGroups = async () => {
    const targetGroups = await popup(
      <ProjectTargetGroupsPopup project={project.projectData} />,
      {
        container: "project",
        name: "ProjectTargetGroupsPopup",
        style: "edit",
        useContainer: false,
      }
    );

    if (!!targetGroups) {
      const names = targetGroups.map(g => g.name);
      const result = await api.request(`/projects/${project.projectId}/update_target_groups/`, {
        method: 'post',
        params: { names }
      });

      if (result?.status === 200) {
        project.getProject();
        popup(
          <ProjectTargetGroupsCompletePopup
            targetgroups={result.data.targetgroups}
          />,
          {
            container: "project",
            name: "ProjectTargetGroupsCompletePopup",
            style: "edit",
            useContainer: false,
          }
        );
      } else {
        api.networkError(result, alert);
      }
    }
  };

  return (
    <button className="secondary gray" style={{ marginLeft: "auto" }} onClick={onClickTargetGroups}>
      <i className="icon-setting"></i> 참여자 그룹수 설정
    </button>
  );
};

export default TargetGroupsButton;