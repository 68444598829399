import { useEffect, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { handleSelect } from '../../../common/javascriptHelper';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const ChangeGroupPopup = props => {

  const api = useApi()
  const {alert} = useCommonPopup()

  const {popupData, onCancel, onConfirm} = props
  const { projectData, participantList } = props

  const [name, setName] = useState("")
  const [error, setError] = useState("")
  const [groupId, setGroupId] = useState(null)

  useEffect(() => {
    setName(participantList.map((participant) => (participant.identifier))?.join(", "))
    setGroupId(projectData.targetgroups?.[0]?.id)
  }, [])

  const onClickSelect = (e) => {
    handleSelect(e)
  }

  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickConfirm = async () => {
    const result = await api.request(`/participants/bulk_update/`, {
      method: "post",
      params: {
        project: projectData.idproject,
        targetgroup: groupId,
        participants: participantList.map((participant) => (participant.idparticipant))
      }
    })

    if (result?.status !== 204) {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">참여자 그룹 변경  <span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content height_auto ">
            <h4>{name}님의 그룹을 선택해주세요.</h4>
            <div className='selectBox_wrap'>
              <div className="selectBox large full">
                <span className="label" onClick={onClickSelect}>
                  {projectData.targetgroups?.find(g => g.id === groupId)?.name || "그룹을 선택해주세요"}
                </span>
                <ul className="optionList">
                  {projectData.targetgroups?.map((targetGroup) => (
                    <li
                      key={targetGroup.id}
                      className="optionItem"
                      onClick={() => setGroupId(targetGroup.id)}
                    >
                      {targetGroup.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <p className="tip">{error}</p>
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className=" primary large " onClick={onClickConfirm}>확인</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangeGroupPopup;