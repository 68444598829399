const ProjectHeader = () => {
  return (

    <header>
      <div  className="project_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>
            <i className="icon-menu"></i>
            <h1>
                <a href="../dashboard/"><i className="icon-logo"></i></a>
            </h1>
            <h4> 
            <i class="icon-arrowleft"></i>
              참여자 관리 
              <i className="icon-edit"></i>
              </h4>
            </div>
            <div>
            <button className="secondary large gray"><i className="icon-useradd"></i>공동 작업자 초대</button>
            <div className="info_wrap popmenu">
                <p className="dash_profile"><span>김</span>김선아</p>
                <ul className="menu_pop">
                <li>한국대학교</li>
                <li><a href="">계정정보 수정</a></li>
                <li><a href="">로그아웃</a></li> 
                </ul>
            </div>
            <div className="dot_menu popmenu">
                <i className="icon-dot-menu"></i>
                <ul className="menu_pop">
                <li className="pj_del_btn">프로젝트 삭제</li>
                </ul>
            </div>
            </div>
          </div>
        </div>
      
      <div className="nav">
      <nav>
        <div><i className="icon-close"></i></div>
        <ul>
          <li><a href="project" className="active"><i className="icon-home"></i>프로젝트 홈</a></li>
          <li><a href="Participant"><i className="icon-team"></i>참여자 관리</a></li>
        </ul>
        <div className="copy">Copyrights © 2023, atflow. <br/>
All rights reserved.</div>
      </nav>
      
      <div className="dim_bg"></div>
      </div>
   
      </div>
    </header>  
    
    );
}  
export default ProjectHeader;