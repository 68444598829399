import $ from 'jquery';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminHeader = (props) => {

  const navigate = useNavigate()

  useEffect(_ => {

  }, [])

  const onClickHome = () => {

    navigate("/dashboard")
  }
      
  return (
    <header>       
      <div  className="project_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>
            <h1>
                <a onClick={onClickHome}><i className="icon-logo"></i></a>
            </h1>
            <h4>관리자 센터</h4>
            </div>
          </div>
        </div>
      </div>
    </header>  
  );
}  
export default AdminHeader;