import cookie from 'react-cookies';


export const setCookie = (key, value, expires) => {
    if (!expires) {
        expires = new Date()
        expires.setFullYear(expires.getFullYear() + 1);
    }
    const options = {}
    options.expires = expires;
    options.path = '/';

    cookie.save(key, value, options);
}

export const getCookie = key => {
    
    let value = cookie.load(key)
    return value
}

export const removeCookie = key => {
    return cookie.remove(key, { path: '/' })
}