import { useNavigate } from "react-router-dom";
import { useProject } from "../../../context/ProjectContext";

const ProjectNav = ({ onClickMenu, activeMenu }) => {
  const navigate = useNavigate();
  const project = useProject();

  const onClickProjectHome = () => {
    if (!project.projectData?.idproject) return;
    onClickMenu();
    navigate(`../${project.projectId}`);
  };

  const onClickParticipant = () => {
    if (!project.projectData?.idproject) return;
    onClickMenu();
    navigate(`../${project.projectId}/participant`);
  };

  const onClickSendLinkStatus = () => {
    if (!project.projectData?.idproject) return;
    onClickMenu();
    navigate(`../${project.projectId}/send-link-status`);
  };

  return (
    <>
      {project.projectData?.eCRF === 1 && (
        <nav>
          <div>
            <i className="icon-close" onClick={onClickMenu}></i>
          </div>
          <ul>
            <li>
              <a
                className={activeMenu === "home" ? "active" : ""}
                onClick={onClickProjectHome}
              >
                <i className="icon-home"></i>프로젝트 홈
              </a>
            </li>
            <li>
              <a
                className={activeMenu === "participant" ? "active" : ""}
                onClick={onClickParticipant}
              >
                <i className="icon-team"></i>참여자 관리
              </a>
            </li>
            <li>
              <a
                className={activeMenu === "send-link-status" ? "active" : ""}
                onClick={onClickSendLinkStatus}
              >
                <i className="icon-phone"></i>설문 링크 발송 현황
              </a>
            </li>
          </ul>
        </nav>
      )}
      <div className="dim_bg" onClick={onClickMenu}></div>
    </>
  );
};

export default ProjectNav;