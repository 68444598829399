import $ from 'jquery';
import { useEffect } from "react";

const CommonPopup = props => {

  useEffect(()=> {


  }, []);

  return (
 
    <div className="popup common_pop" >
        <div className="popup_wrap">

        {/* 공용 */}
        <div className="popup_inwrap">
          <h5 className="section_tit">공용 팝업  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">
                  <h4>
                  설문 링크를 만들어서 응답자에게 공유하면 링크를 통해 누구나  설문에 참여할 수 있습니다.
                  </h4>
                  <h5>
                  설문 링크를 만든 후에는 주요 설문 편집 기능인 문항 추가, 삭제,  옵션 편집, 순서 변경 등이 제한됩니다.
                  </h5>
                  <h4>
                  만약 이러한 변경이 필요한 경우, 해당 설문을 종료하고 새로운  복사본을 생성하여 다시 시작해야 합니다. 기존 참여자들도 필요에  따라 다시 참여해야 한다는 점을 유의해 주세요.
                  </h4>
                  <h4>
                  설문 링크를 만들어서 설문을 보내시겠습니까?
                  </h4>
              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">확인</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
        </div>
      </div>

  );  
}
 export default CommonPopup;