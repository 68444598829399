import $ from 'jquery';
import React, { createContext, useEffect, useState, useMemo, useContext, useRef, forwardRef, useImperativeHandle } from 'react'
import CommonPopup from '../pages/popup/CommonPopup';
import ComponentPopup from '../pages/popup/ComponentPopup';
import { useNavigate } from 'react-router-dom';

var CommonPopupContext = createContext();

export function CommonPopupContextProvider({children}) {
   
  const popupHandler = useRef()
  const navigate = useNavigate()

  const confirm = async (message, option) => {

    if (!!popupHandler.current) {
      return await popupHandler.current.confirm(message, option)
    }    
  }

  const alert = async (message, option) => {

    if (!!popupHandler.current) {
      return await popupHandler.current.alert(message, option)
    }    
  }

  const error = async (message, option) => {

    if (!!popupHandler.current) {
      return await popupHandler.current.error(message, option)
    }    
  }

  const networkError = async (result, option) => {

    if (!!popupHandler.current) {
      if (result?.data?.code === 50) {
        await error("세션이 종료되었습니다.\n다시 로그인 해주세요.", option)      
        navigate("/signin")
      } else if (result?.data?.code === 60) {
        await error(result?.data?.msg?? "접근 권한이 없습니다.\n다시 로그인 해주세요.", option)            
        navigate("/signin")
      } else {
        await error(result?.data?.msg ?? "error", option)           
      }
    }

    // if (!!popupHandler.current) {
    //   return await popupHandler.current.error(message, {option})
    // }    
  }

  const popup = async (message, option) => {

    if (!!popupHandler.current) {
      return await popupHandler.current.popup(message, option)
    }    
  }

  const PopupContainer = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
      
      confirm (message, option) {
        return new Promise((res, rej) => {
          setPopupList((prev)=>([
            ...prev, 
            {
              type: "confirm",
              message: message, 
              option: {
                ...option,
                immediate: option?.immediate?? true,
                style: option?.style?? "small",
                confirm: option?.confirm?? "확인",
                cancel: option?.cancel?? "취소",
              },
              callback: res
            }
          ]))
        });
      },

      alert (message, option) {
  
        return new Promise((res, rej) => {
          setPopupList((prev)=>([
            ...prev, 
            {
              type: "alert",
              message: message, 
              option: {
                ...option,                
                immediate: option?.immediate?? true,
                style: option?.style?? "small",
                confirm: option?.confirm?? "확인",
              },
              callback: res
            }
          ]))
        });
      },

      error (message, option) {
  
        return new Promise((res, rej) => {
          setPopupList((prev)=>([
            ...prev, 
            {
              type: "error",
              message: message, 
              option: {
                ...option,                
                immediate: option?.immediate?? true,
                style: option?.style?? "small",
                confirm: option?.confirm?? "확인",
              },
              callback: res
            }
          ]))
        });
      },

      popup (children, option) {
  
        return new Promise((res, rej) => {
          setPopupList((prev)=>([
            ...prev, 
            {
              type: "popup",
              option: {
                ...option,
                name: option?.name?? "common",
                confirm: option?.confirm?? "확인",
                cancel: option?.cancel?? "취소",            
              },
              children: children,
              callback: res
            }
          ]))
        });
      }
    }));

    const [popupList, setPopupList] = useState([])

    const onClickCancel = async (popup, result) => {
  
      if (!!popup.option?.onCancel) {
        if (await popup.option?.onCancel() == false) {
          return false
        }
      }
      closePopup(popup, result?? false)
      return true
    }
  
    const onClickConfirm = async (popup, result) => {
      if (!!popup.option?.onConfirm) {
        if (await popup.option?.onConfirm() == false) {
          return false
        }
      } 
      closePopup(popup, result?? true) 
      return true
    }  
  
    const closePopup = async (popup, result) => {
  
      if (popup.option?.immediate) {
        $(`.${popup.option?.name}_pop, .popup_inwrap ${popup.option?.name}`).hide().promise().done(function() {
          onClosed(popup, result)
        });
      } else {
        $(`.${popup.option?.name}_pop, .popup_inwrap ${popup.option?.name}`).fadeOut().promise().done(function() {
          onClosed(popup, result)
        });
      }
    }
  
    const onClosed = async (popup, result) => {
        
      setPopupList((prev)=>(prev.filter(element => element !== popup)))

      setTimeout(() => { 
        if (!!popup.callback) popup.callback(result)
      }, 100)
    }
  
    return (
      <div className="popup_container">
      { popupList.map((popup, index) => 
          popup.type === "popup" ? (
            <ComponentPopup 
              key={`${popup.type}-${index}-${popup.name}`} 
              name={`${popup.type}-${index}`}
              popupData={popup}
              children={popup.children} 
              onCancel={onClickCancel}
              onConfirm={onClickConfirm}
            /> 
          ) : ( 
            <CommonPopup 
              key={`${popup.type}-${index}-${popup.name}`}               
              name={`${popup.type}-${index}`}
              popupData={popup} 
              message={popup.message}
              onCancel={onClickCancel}
              onConfirm={onClickConfirm}
            />
          )
        )
      }
      </div>
    )
  });
  
  return (
    <CommonPopupContext.Provider value={{confirm, alert, popup, networkError}}>   
      <div>
        {children}
        <PopupContainer ref={popupHandler}/>        
      </div>  
    </CommonPopupContext.Provider>
  );
}

export function useCommonPopup() {
  return useContext(CommonPopupContext)
}