import $ from 'jquery';
import { useEffect } from "react";

const ParticipantPopup6 = props => {

  useEffect(()=> {

    $('.agreeView-btn').click(function() {
      $('.Participant_pop5, .Participant_pop5 .popup_inwrap ').fadeIn();
    });
    $('.agreeDel-btn').click(function() {
      $('.Participant_pop5, .Participant_pop5 .popup_inwrap ').hide();
      $('.Participant_pop6, .Participant_pop6 .popup_inwrap ').fadeIn();
    });
    $('.agreeUp-btn').click(function() {
      $('.Participant_pop5, .Participant_pop5 .popup_inwrap ').hide();
      $('.Participant_pop4, .Participant_pop4 .popup_inwrap ').fadeIn();
    });

  }, []);

  return (
 
    <div className="popup Participant_pop6" >
        <div className="popup_wrap">

        {/* 서면 동의서 삭제 */}
        <div className="popup_inwrap">
          <div  className="section_tit flex_between">
            <h5>서면 동의서 삭제  </h5>
            <span className="close_btn"></span>
          </div>
          
          <form action="">
            <div className="pop_content  height_auto">
              <h4><br/>
              12yAb님의 서면 동의서를 삭제하시겠습니까? <br/><br/>

              동의하지 않으면 더 이상 설문을 진행할 수 없습니다.<br/><br/>
              </h4>

              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">삭제</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
        </div>
      </div>

  );  
}
 export default ParticipantPopup6;