import DashBoardHeader from "../inc/DashBoardHeader";
import NewProjectPopup from '../popup/NewProjectPopup';
import ProjectHomePopup from '../popup/ProjectHomePopup';
import $ from 'jquery';
import { useEffect } from "react";
import 'react-circular-progressbar/dist/styles.css';
import { useScript } from 'usehooks-ts'
import OwlCarousel from 'react-owl-carousel';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const DashBoard = props => {
  return (
    <div className="body">
      <DashBoardHeader/>
      <NewProjectPopup/>
      <ProjectHomePopup/>
      <script src="../js/progresscircle.js"></script>
      <div className="container">
        <div className="inwrap">
          <div className="left_wrap">
            <section className="project_ing">
              <h5 className="section_tit">진행 중 설문</h5>
              <div className="project_rolling">
              <OwlCarousel className='owl-carousel owl-theme' items={1} loop margin={10} nav dots>
                  <div className="item">
                    <div>
                      <p>당뇨환자들의 불안장애에 관한 연구</p>
                      <h5>임상 사전 문진</h5>
                      <ul>
                        <li>응답 <span>3(새 응답 1)</span></li>
                        <li>미응답 <span>7</span></li>
                        <li>등록 참여자 <span>10</span></li>
                      </ul>
                    </div>
                    <div  className="CircularProgress">
                      <CircularProgressbarWithChildren value={5}>
                        <div className="sub_line">
                          <div >응답률</div>
                          <h3 >5%</h3>
                        </div>
                        
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                  <div className="item">
                    <div>
                      <p>당뇨환자들의 불안장애에 관한 연구</p>
                      <h5>임상 사전 문진</h5>
                      <ul>
                        <li>응답 <span>3(새 응답 1)</span></li>
                        <li>미응답 <span>7</span></li>
                        <li>등록 참여자 <span>10</span></li>
                      </ul>
                    </div>
                    <div  className="CircularProgress">
                      <CircularProgressbarWithChildren value={80}>
                        <div className="sub_line">
                          <div >응답률</div>
                          <h3 >80%</h3>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                  <div className="item">
                    <div>
                      <p>당뇨환자들의 불안장애에 관한 연구</p>
                      <h5>임상 사전 문진</h5>
                      <ul>
                        <li>응답 <span>3(새 응답 1)</span></li>
                        <li>미응답 <span>7</span></li>
                        <li>등록 참여자 <span>10</span></li>
                      </ul>
                    </div>
                    <div  className="CircularProgress">
                      <CircularProgressbarWithChildren value={20}>
                        <div className="sub_line">
                          <div >응답률</div>
                          <h3 >20%</h3>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                  <div className="item">
                    <div>
                      <p>당뇨환자들의 불안장애에 관한 연구</p>
                      <h5>임상 사전 문진</h5>
                      <ul>
                        <li>응답 <span>3(새 응답 1)</span></li>
                        <li>미응답 <span>7</span></li>
                        <li>등록 참여자 <span>10</span></li>
                      </ul>
                    </div>
                    <div className="CircularProgress">
                      <CircularProgressbarWithChildren valueStart={0} value={50}>
                        <div className="sub_line">
                          <div >응답률</div>
                          <h3 >50%</h3>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                {/* </div> */}
                </OwlCarousel>
              </div>
            </section>            
            <section className="project">
              <h5 className="section_tit">프로젝트</h5>   
              <div className="search_wrap">
                <div className="search_box">
                  <input type="text" placeholder="프로젝트 이름으로 검색"/>
                  <button><i className="icon-search"></i></button>
                </div>
                <button className="primary large new_project_but"> <i>+</i> 새 프로젝트</button>
              </div>  
              
              <ul className="card_list">
                <li>
                  <div className="card_head">
                    <img src="../../../../assets/images/Tag_b.svg" alt=""/>
                    <div className="card_menu popmenu">
                      <i className="icon-dot-menu"></i>
                      <ul className="menu_pop">
                        <li>공동 작업자 초대</li>
                        <li>이름 바꾸기</li>
                        <li>삭제</li>
                      </ul>
                    </div>
                    <h5>당뇨 환자들의 불안장애에 관한 연구</h5>
                  </div>
                  
                  <ul>
                    <li><i className="icon-edit"></i> 설문 4개 <span className="hit new">새 응답</span></li>
                    <li><i className="icon-user"></i>김선아, 박지현, 김성민, 김민지 외1</li>
                    <li><i className="icon-calendar "></i>2022.12.31. 개설</li>
                  </ul>
  
  
                </li>
                <li>
                  <div className="card_head">
                    <img src="../../../../assets/images/HeartOnHand_y.svg" alt=""/>
                    <div className="card_menu popmenu">
                      <i className="icon-dot-menu"></i>
                      <ul className="menu_pop">
                        <li>공동 작업자 초대</li>
                        <li>이름 바꾸기</li>
                        <li>삭제</li>
                      </ul>
                    </div>
                    <h5>중노년 성인에서 우울증상과 사회적 결정요인 및 만성질환의 관계에 관한 연구</h5>
                  </div>
                  <ul>
                    <li><i className="icon-edit"></i> 설문 4개</li>
                    <li><i className="icon-user"></i>김선아, 박지현, 김성민, 김민지 외1</li>
                    <li><i className="icon-calendar "></i>2022.12.31. 개설</li>
                  </ul>
                </li>
                <li>
                  <div className="card_head">
                    <img src="../../../../assets/images/Heart_r.svg" alt=""/>
                    <div className="card_menu popmenu">
                      <i className="icon-dot-menu"></i>
                      <ul className="menu_pop">
                        <li>공동 작업자 초대</li>
                        <li>이름 바꾸기</li>
                        <li>삭제</li>
                      </ul>
                    </div>
                    <h5>소셜 미디어 사용과 우울증</h5>                
                  </div>
  
                  <ul>
                    <li><i className="icon-edit"></i> 설문 4개</li>
                    <li><i className="icon-user"></i>김선아, 박지현, 김성민, 김민지 외1</li>
                    <li><i className="icon-calendar "></i>2022.12.31. 개설</li>
                  </ul>
                </li>
  
                <li>
                  <div className="card_head">
                    <img src="../../../../assets/images/Keyboard_g.svg" alt=""/>
                    <div className="card_menu popmenu">
                      <i className="icon-dot-menu"></i>
                      <ul className="menu_pop">
                        <li>공동 작업자 초대</li>
                        <li>이름 바꾸기</li>
                        <li>삭제</li>
                      </ul>
                    </div>
                    <h5>당뇨 환자들의 불안장애에 관한 연구</h5>
                  </div>
                  
                  <ul>
                    <li><i className="icon-edit"></i> 설문 4개</li>
                    <li><i className="icon-user"></i>김선아, 박지현, 김성민, 김민지 외1</li>
                    <li><i className="icon-calendar "></i>2022.12.31. 개설</li>
                  </ul>
                </li>
              </ul>
            </section>
          </div>
          <div className="right_wrap">
            <section className="recent">
              <h5 className="section_tit">최근 방문 설문</h5>
              <ul>
                <li>
                  <div>
                    <p>소설 미어 사용과 우울증</p>
                    <h5>임상 사전 문진</h5>
                    {/* <span className="tag ing">설문 진행 중</span> */}

                  </div>
                  <div>
                    {/* <!-- <span className="hit">3</span>
                    <h2>5</h2>
                    <p>응답</p> --> */}
                    
                  </div>
                </li>
                <li>
                  <div>
                    <p>당뇨환자들의 불안장애에 관한 연구</p>
                    <h5>임상 사전 문진</h5>
                    <span className="tag ing">설문 진행 중</span>

                  </div>
                  <div>
                    <span className="hit">3</span>
                    <h2>5</h2>
                    <p>응답</p>
                    
                  </div>
                </li>
                <li>
                  <div>
                    <p>프로젝트명은 길어져도 최대 한 줄만 표시표시표시 합니다.</p>
                    <h5>최근 방문 설문의 설문명이 길어지면 최대 2줄까지 표시하고 더 길어지면 말줄임합니다다다다다.</h5>
                    <span className="tag ing">설문 진행 중</span>

                  </div>
                  <div>
                    <span className="hit">13</span>
                    <h2>0</h2>
                    <p>응답</p>
                    
                  </div>
                </li>
                <li>
                  <div>
                    <p>당뇨환자들의 불안장애에 관한 연구</p>
                    <h5>임상 본문진</h5>
                    <span className="tag hold">설문 대기</span>
                    

                  </div>
                  <div>
                    <h2>5</h2>
                    <p>응답</p>
                    
                  </div>
                </li>
                <li>
                  <div>
                    <p>당뇨환자들의 불안장애에 관한 연구</p>
                    <h5>알콜 환자들의 셀프진단</h5>
                    <span className="tag end">설문 종료</span>
                    

                  </div>
                  <div>
                    <h2>85</h2>
                    <p>응답</p>
                    
                  </div>
                </li>

              </ul>
      
            </section>
          </div>
        </div>
      </div>
    </div>
  ); 
}
export default DashBoard;