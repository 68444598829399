import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useApi } from '../../context/ApiContext'
import { useCommonPopup } from '../../context/CommonPopupContext'
import { validatePhoneNumber } from '../../common/stringHelper'

const FindPassword2 = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const api = useApi()
  const {alert} = useCommonPopup()

  const [inputs, setInputs] = useState({
    phoneno: "",        
  })

  const [errors, setErrors] = useState({
    phonenoError: "",
    findError: "",
  })
  
  const {phoneno} = inputs
  const {phonenoError, findError} = errors

  const inputPhoneno = useRef()
    
  const [isLoading, setIsLoading] = useState(false) 

  useEffect(_ => {

    if (location.state?.email == null) {
      alert('잘못된 접근입니다.')
      navigate(-1)
    }
    
  }, []);

  const setError = (name, error) => {
    setErrors({
      ...errors, 
      [`${name}Error`]: error
    })
  }
  
  const onChangeInput = (e) => {

    var { value, name } = e.target
    value = value.replace(/ /g, "") 
    setInputs({
      ...inputs, 
      [name]: value
    })

    if (name == "phoneno") {     
      if (value.length > 0 && !validatePhoneNumber(value)) {
        setError("phoneno", "휴대폰 번호는 숫자만 입력해주세요.")
      } else {
        setError("phoneno", "")
      }     
    } 
  }

  const onKeyEvent = (e) => {

    var { name } = e.target
    if (e.key === 'Enter') {
      if (name == "phoneno") {
        onClickFind()
      }       
    }
  }

  const checkValidation = _ => {

    if (phoneno.length == 0) {
      inputPhoneno.current.focus()
      setError("phoneno", "휴대폰번호를 입력해 주세요.")
      return false
    }
    if (!validatePhoneNumber(phoneno)) {
      setError("phoneno", "휴대폰 번호는 숫자만 입력해주세요.")
      return false
    }
    return true
  }

  const onClickFind = async (e) => {

    if (isLoading) return
    if (!checkValidation()) return
        
    setIsLoading(true)
    setError("find", "")      
    
    const result = await api.post("findPassword", {
      email: location.state.email,
      phoneno: phoneno
    })
    
    if (result?.data?.result != "ok") {
      if (result?.data?.msg?.length > 0) {
        setError("find", result.data.msg)
      } else {
        setError("find", "error")
      }
      setIsLoading(false)      
    } else {
      setError("find", "")      
      // setIsLoading(false)
      await alert('입력한 이메일로 새로운 비밀번호가 전송되었습니다.', {title: "비밀번호 찾기"})
      navigate("/signin")
    }
  }

  return (
    <div className="body">
      <div  className="login find_pw">
        <div className="inwrap">
          <div>
            <i className="icon-logo"></i>
            <h4>비밀번호 찾기</h4>
            <p>
              <span>Step 2.</span> 가입 시 등록한 휴대폰 번호를 확인해주세요. 
            </p>
            <form action="" className="inner_box" onSubmit={(e) => {e.preventDefault()}}>
              <dl>
                <dt>휴대폰 번호 </dt>
                <dd>
                  <input 
                    name="phoneno"
                    type="text" 
                    placeholder="휴대폰 번호"
                    ref={inputPhoneno}
                    className={phonenoError.length > 0 ? "error" : ""}
                    value={phoneno}
                    onChange={onChangeInput}
                    onKeyUp={onKeyEvent}
                  />
                  <p className="tip">{phonenoError}</p>
                </dd>
              </dl>
              <div className="but_wrap">                
                <button type="button"  className={`primary large ${isLoading && "disabled"}`} onClick={onClickFind}>확인</button>              
                <p className="tip">{findError}</p>
                <Link className="primary" to="/">로그인하기</Link>              
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FindPassword2;