import { QUESTION_VALUE_TYPES } from './codeHelper';

export const getAnswer = (datas, sectionorderno, questionorderno) => {
    return datas.filter(data => data.sectionorderno === sectionorderno && data.questionorderno === questionorderno)?.[0];
}

export const getScore = (question, content) => {
    if (`${content}`.length === 0) return null;

    if (QUESTION_VALUE_TYPES.includes(question.type)) {
        return "-";
    } else if (question.scoring !== 1) {
        return "-";
    } else {
        var score = 0;
        var ordernos = content?.split(",") ?? [];
        ordernos.forEach(orderno => {
            const item = question.items.filter(item => Number(item.orderno) === Number(orderno))?.[0];
            if (!!item) score += item.score;
        });
        return score;
    }
}

export const getValue = (question, content, reason) => {
    if (`${content}`.length === 0) return null;

    if (QUESTION_VALUE_TYPES.includes(question.type)) {
        return content;
    } else {
        var values = [];
        var ordernos = content?.split(",") ?? [];
        var reasonObj = {};

        // reason이 null이 아니고 문자열인 경우 JSON으로 파싱 시도
        if (reason !== null && typeof reason === 'string') {
            try {
                reasonObj = JSON.parse(reason);
            } catch (e) {
                console.warn(`Failed to parse reason JSON, using original string: ${reason}`);
                reasonObj = { [ordernos[0]]: reason }; // 단일 선택 질문의 경우를 위해
            }
        }

        ordernos.forEach(orderno => {
            const item = question.items.filter(item => Number(item.orderno) === Number(orderno))?.[0];
            if (!!item) {
                if (reasonObj[orderno] && (item.isetc === 1 || question.inputreason === 1)) {
                    values.push(`${item.content}:${reasonObj[orderno]}`);
                } else {
                    values.push(item.content);
                }
            }
        });
        return values.join(", ");
    }
}
