import $ from 'jquery';
import { useEffect } from "react";

const ParticipantPopup3 = props => {

  useEffect(()=> {

    $('.ParticipantAdd-btn').click(function() {
      $('.Participant_pop3, .Participant_pop3 .popup_inwrap ').fadeIn();
    });

  }, []);

  return (
 
    <div className="popup Participant_pop3" >
        <div className="popup_wrap">

        {/* 참여자 등록 */}
        <div className="popup_inwrap">
          <h5 className="section_tit">참여자 등록<span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content height_auto ">
              <div>
                <label>소속기관</label>

                <div class="selectBox large full">
                  <span class="label">기관을 선택해 주세요.</span>
                  <ul class="optionList">
                    <li class="optionItem">세브란스병원</li>
                    <li class="optionItem">강남세브란스병원</li>
                    <li class="optionItem">울산세브란스병원</li>
                    <li class="optionItem">세브란스병원</li>
                  </ul>
                </div>
              </div>
              <div>
                <label>시험대상자 식별번호</label>
                <input type="text" id="survey-link" placeholder="자동으로 생성됩니다." value="" class="full big disabled "/>
              </div>
              <div>
                <label>이니셜</label>
                <input type="text" id="survey-link" placeholder="이니셜을 입력해주세요." value="" class="full big "/>
              </div>
              <div>
                <label>참여자 그룹</label>
                <div className='selectBox_wrap'>
                  <div class="selectBox large full">
                    <span class="label">그룹을 선택해 주세요.</span>
                    <ul class="optionList">
                      <li class="optionItem">그룹 전체</li>
                      <li class="optionItem">그룹 A (시험군)</li>
                      <li class="optionItem">그룹 B (대조군)</li>
                      
                    </ul>
                  </div>

                </div>
                

              </div>


  
                  
              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">확인</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
        </div>
      </div>

  );  
}
 export default ParticipantPopup3;