import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";

const SurveyLinkPopup = props => {

  const navigate = useNavigate()
  const {popupData, onCancel, onConfirm} = props
  const {projectData, surveyData, link} = props

  const api = useApi()  
  const {confirm, alert} = useCommonPopup()
  const [url, setUrl] = useState()
  const linkRef = useRef()

  useEffect(()=> {
    
    setUrl(`${process.env.REACT_APP_HOST}answer/${link}`)

    return () => {
    }
  }, []);


  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickCloseSurvey = async () => {

    if (!!onConfirm) {
      onConfirm(popupData, true)
    }
  }

  const onCopy = () => {
    toast.success("링크가 복사되었습니다.")
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap common_pop medium">
        <h5 className="section_tit">설문 보내기 <span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content height_auto">
            <div>
              <div className="input_wrap flex_c">
                { projectData?.eCRF === 1 ? (
                    <>
                      <h5>설문 응답 가능한 상태입니다.<br/></h5>
                      <div className="text_disabled">
                        <h5 className="vertical_form">중복 응답 허용 
                          <label className="switch">
                            <input type="checkbox" disabled checked={surveyData?.multipleresponse === 1}/>
                            <span className="slider round"></span>
                          </label>
                        </h5>
                        <div>
                          <h6>동일한 사용자가 여러 번 반복하여 같은 설문에 응답하는 것을 허용합니다. 이 옵션은 설문 시작 전에 설정해야 하며, 설문을 보낸 후에는 변경할 수 없습니다.</h6>
                        </div>
                      </div>
                    </>                    
                  ) : (
                    <>
                    <p className="text_secondaty">설문 링크를 복사하여 응답자에게 공유하면 링크를 통해 누구나 설문에 답변할 수 있습니다.</p>
                    <div className="vertical_form">                       
                      <div>
                        <input type="text" id="survey-link" placeholder="" value={url} className="full big " disabled  onChange={()=>{}} />
                      </div>
                      <div>
                        <CopyToClipboard text={url} onCopy={onCopy}>
                          <button type="button" className="full">복사</button>
                        </CopyToClipboard>
                      </div>
                    </div> 
                    </>
                  )
                }
                <div>
                  <button type="button" className=" error large " onClick={onClickCloseSurvey}>설문 종료하기</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );  
}
 export default SurveyLinkPopup;