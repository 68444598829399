import $ from 'jquery';
import { useEffect } from "react";

const MemoPopSingle = props => {

  useEffect(()=> {

    $('.memo-btn').click(function() {
      $('.MemoPopup, .MemoPopup .popup_inwrap ').fadeIn();
    });


  }, []);

  return (
 
    <div className="popup MemoPopup" >

      <ul>
        <li  className="tit popmenu">
          <h5>메모 (2)</h5>
          {/* <i className="icon-dot-menu"></i> */}
  
            <i className="icon-dot-menu"></i>
            <ul className="menu_pop" >
              <li>삭제</li>
              <li>해결</li>
  
            </ul>
       
        </li>
        <li>
          <span className='profile'><img src="../../../assets/images/Avatar.jpg"/></span>
          <div className='text'>
            <p className='name'>이우리 <span>1  day ago</span></p>
            <p>1번 답변 재확인 필요dfs1번 답변 재확인 필요dfs1번 답변 재확인 필요dfs1번 답변 재확인 필요dfs</p>
          </div>
        </li>
        <li>
          <span className='profile'>U</span>
          <div className='text'>
            <p className='name'>이우리 <span>1  day ago</span></p>
            <div className='flex  '>
              <p>
              확인했습니다. 문제 없습니다.
              </p>
              <div> 
              <i className="icon-edit"></i>
                <span className='popmenu'>
                  <i className="icon-dot-menu"></i>
                  <ul className="menu_pop" >
                    <li>삭제</li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          

        </li>
        <li>
          <span className='profile'>U</span>
          <div className='text'>
            <p className='name'>이우리 <span>1  day ago</span></p>
            <div className='flex  '>
              <p>
              확인했습니다. 문제 없습니다.확인했습니다. 문제 없습니다.확인했습니다. 문제 없습니다.확인했습니다. 문제 없습니다.확인했습니다. 문제 없습니다.
              </p>
              <div> 
               <i className="icon-edit"></i>
               <span className='popmenu'>
                <i className="icon-dot-menu"></i>
                <ul className="menu_pop" >
                  <li>삭제</li>
                </ul>
                </span>
              </div>
            </div>
          </div>
          

        </li>
        <li>
          <span className='profile'>U</span>
          <div className="search_box full">
          <textarea className="full solo" id="textarea_auto" placeholder="문항" rows="1"></textarea>
          <button><i className="icon-memo_send"></i></button>
        </div>

        </li>
      </ul>

    </div>
  );  
}
 export default MemoPopSingle;