import { SURVEY_QUESTION_TYPE_ESSAY, SURVEY_QUESTION_TYPE_DATE, SURVEY_QUESTION_TYPE_MULTIPLECHOICE, SURVEY_QUESTION_TYPE_YESORNO, SURVEY_QUESTION_TYPE_SHORTANSWER } from "../common/codeHelper";
import { useState, useEffect, useRef } from "react";
import { validatePhoneNumberWithHiphen, validateRealNumber } from "../common/stringHelper";
import { useApi } from "../context/ApiContext";

export const useQuestionLogic = (props, isPreview = false) => {
  const { currentData, accessToken, onPrev, onNext } = props;
  const api = useApi();
  const [answer, setAnswer] = useState("");
  const [reasons, setReasons] = useState({});
  const [checkOK, setCheckOK] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const timer = useRef(null);
  const [shouldCheck, setShouldCheck] = useState(false);

  useEffect(() => {
    if (currentData) {
      const isLast = currentData.section.orderno === currentData.totalsection &&
                     currentData.question.orderno === currentData.totalquestion;
      setIsLastQuestion(isLast);
      setAnswer(currentData.question.answercontent ?? "");
      setReasons(currentData.question.answerreason ? JSON.parse(currentData.question.answerreason) : {});
      setCheckOK(currentData.question.mandatory !== 1);
    }
  }, [currentData]);

  useEffect(() => {
    setCheckOK(checkValidation());
  }, [answer, reasons]);

  const checkValidation = () => {
    if (!currentData?.question) return false;
    if (currentData.question.mandatory !== 1) return true;

    const items = currentData.question.items.filter((element) => isSelected(element));
    const etcs = items.filter((element) => element.isetc === 1);

    if (etcs.length > 0) {
      return Object.values(reasons).some(value => value?.length > 0);
    }
    if (currentData.question.isconditional === 1 && currentData.question.conditiontype === 'phoneno') {
      return validatePhoneNumberWithHiphen(answer);
    }
    if (currentData.question.multipleselect === 1) {
      const selectedItems = currentData.question.items.filter((element) => isSelected(element));
      return selectedItems.every(item => !item.isetc || reasons[item.orderno]?.length > 0);
    }
    if (currentData.question.type === SURVEY_QUESTION_TYPE_SHORTANSWER) {
      if (currentData.question.items[0].numberonly && !validateRealNumber(answer)) {
        return false;
      }
      if (answer.length > 50) {
        return false;
      }
    }
    return !!answer;
  };

  const updateAnswer = (data, timeout = 0) => {
    setAnswer(data);
    setShouldCheck(true);  // 답변이 업데이트될 때 체크 플래그를 설정
    if (!isPreview) {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        answerQuestion(data, JSON.stringify(reasons));
      }, timeout);
    }
  };

  const updateReason = (itemOrderno, data, timeout = 0) => {
    setReasons(prevReasons => ({
      ...prevReasons,
      [itemOrderno]: data
    }));
    if (!isPreview) {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        answerQuestion(answer, JSON.stringify({...reasons, [itemOrderno]: data}));
      }, timeout);
    }
  };

  const answerQuestion = async (answercontent, answerreason) => {
    if (currentData.question.isconditional === 1 && currentData.question.conditiontype === 'phoneno') {
      answercontent = answercontent.replace(/-/g, '');
    }

    const params = {
      accesstoken: accessToken,
      sectionorderno: currentData.section.orderno,
      questionorderno: currentData.question.orderno,
      answercontent: answercontent,
      answerreason: answerreason,
    };

    const result = await api.request("/surveys/answer_question/", {method: "post", params});
    return result?.status === 204;
  };

  useEffect(() => {
    if (shouldCheck && answer !== "") {
      const isLastPage = currentData.currentpage === currentData.totalpage;
      const shouldAutoAdvance =
        !isLastPage &&
        currentData.question.items.some(isSelected) &&
        currentData.question.multipleselect !== 1 &&
        ![SURVEY_QUESTION_TYPE_ESSAY, SURVEY_QUESTION_TYPE_DATE, SURVEY_QUESTION_TYPE_SHORTANSWER].includes(currentData.question.type) &&
        !currentData.question.items.find(item => item.orderno === Number(answer))?.isetc &&
        !(currentData.question.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE &&
          currentData.question.inputreason === 1 &&
          currentData.question.items.find(item => item.orderno === Number(answer))?.reasonguide !== null) &&
        !(currentData.question.type === SURVEY_QUESTION_TYPE_YESORNO &&
          currentData.question.inputreason === 1);

      if (shouldAutoAdvance) {
        onNext(answer);
      }

      setShouldCheck(false);  // 체크 완료 후 플래그 리셋
    }
  }, [answer, shouldCheck, currentData, isLastQuestion, onNext]);

  const onSelectAnswer = (item) => {
    let data;
    if (currentData.question.multipleselect === 1) {
      const list = !!answer ? answer.split(",") : [];
      const ret = list.filter((element) => Number(element) === Number(item.orderno));
      if (ret?.length === 0) list.push(Number(item.orderno));
      data = list.join(",");
    } else {
      data = `${item.orderno}`;
    }

    updateAnswer(data, 0);
  };

  const isSelected = (item) => {
    if (currentData.question.multipleselect === 1) {
      const list = !!answer ? answer.split(",") : [];
      return list.some((element) => Number(element) === Number(item.orderno));
    } else {
      return answer === `${item.orderno}`;
    }
  };

  return {
    answer,
    reasons,
    checkOK,
    isLastQuestion,
    updateAnswer,
    updateReason,
    onSelectAnswer,
    isSelected,
    onClickPrev: onPrev,
    onClickNext: onNext,
  };
};
