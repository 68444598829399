import { useEffect } from "react"
import { Navigate, useRoutes } from "react-router-dom"
import './App.css'

import PublicPage from "./pages/PublicPage"
import Signin from "./pages/auth/Signin"
import Signup from "./pages/auth/Signup"
import SignupComplete from "./pages/auth/SignupComplete"
import SignupInfo from "./pages/auth/SignupInfo"
import FindID from "./pages/auth/FindID"
import FindPassword1 from "./pages/auth/FindPassword1"
import FindPassword2 from "./pages/auth/FindPassword2"
import ErrorPage from "./pages/ErrorPage"
import ProtectedPage from "./pages/ProtectedPage"
import markupRouter from "./markup"
import DashBoard from "./pages/dashboard/DashBoard"
import Project from "./pages/project/Project"
import Survey from "./pages/survey/Survey"
import HomePage from "./pages/HomePage"
import Agreement from "./pages/auth/Agreement"
import ProjectHome from "./pages/project/home/ProjectHome"
import ParticipantManagement from "./pages/project/participant/ParticipantManagement"
import ParticipantDetail from "./pages/project/participant/ParticipantDetail"
import Answer from "./pages/answer/Answer"
import AnswerContent from "./pages/answer/AnswerContent"
import Preview from "./pages/prevew/Preview"
import PreviewContent from "./pages/prevew/PreviewContent"
import UserInfo from "./pages/user/UserInfo"
import Admin from "./pages/admin/Admin"
import AdminNotice from "./pages/admin/AdminNotice"
import AdminCustomer from "./pages/admin/AdminCustomer"
import CS from "./pages/cs/CS"
import CSNotice from "./pages/cs/CSNotice"
import CSFAQ from "./pages/cs/CSFAQ"
import CSInquiry from "./pages/cs/CSInquiry"
import AdminInquiry from "./pages/admin/AdminInquiry"
import Terms from "./pages/auth/Terms"
import Privacy from "./pages/auth/Privacy"
import AdminCustomerDetail from "./pages/admin/AdminCustomerDetail"
import SendLinkStatus from "./pages/project/send-status/SendLinkStatus"

function App() {

  useEffect(_ => {
    
  }, [])

  let element = useRoutes([
    { path: "/",
      element: <PublicPage />,
      children : [
        { index: true, element: <HomePage/> },
        { path: "/signin", element: <Signin/> },  
        { path: "/signup", element: <Signup/> },         
        { path: "/signup/1", element: <Signup/> },        
        { path: "/signup/2", element: <SignupInfo/> },
        { path: "/signup/3", element: <SignupComplete/> },
        { path: "/agreement", element: <Agreement/> },
        { path: "/agreement/:agreementId", element: <Agreement/> },
        { path: "/terms", element: <Terms/> },
        { path: "/privacy", element: <Privacy/> },
        { path: "/find-id", element: <FindID /> },
        { path: "/find-password/1", element: <FindPassword1/> },
        { path: "/find-password/2", element: <FindPassword2/> },
      ]
    },
    { path: "/dashboard",
      element: <ProtectedPage />,
      children : [
        { index: true, element: <DashBoard/> },
      ]
    },
    { path: "/userinfo",
      element: <ProtectedPage />,
      children : [
        { index: true, element: <UserInfo/> },
      ]
    },
    { path: "/admin",
      element: <Admin />,
      children : [
        { index: true, element: <Admin/> },
        { path: "notice", element: <AdminNotice/> },
        { path: "inquiry", element: <AdminInquiry/> },
        { path: "customer", element: <AdminCustomer/> },
        { path: "customer/:adminId", element: <AdminCustomerDetail/> },
      ]
    },
    { path: "/cs",
      element: <ProtectedPage />,
      children : [
        { index: true, element: <CS/> },
        { path: "notice", element: <CSNotice/> },
        { path: "faq", element: <CSFAQ/> },
        { path: "inquiry", element: <CSInquiry/> },
      ]
    },
    { path: "/project",
      element: <Project/>,
      children : [
        { index: true, element: <Project/> },        
        { path: ":projectId", element: <ProjectHome/> },
        { path: ":projectId/participant", element: <ParticipantManagement/> },
        { path: ":projectId/participant/:participantId", element: <ParticipantDetail/> },
        { path: ":projectId/survey", element: <Survey/> },
        { path: ":projectId/survey/:surveyId", element: <Survey/> },
        { path: ":projectId/survey/:surveyId/:tab", element: <Survey/> },
        { path: ":projectId/survey/:surveyId/:tab/:subTab", element: <Survey/> },
        { path: ":projectId/send-link-status", element: <SendLinkStatus/> },
      ]
    },
    { path: "/answer",
      element: <Answer/>,
      children : [
        { index: true, element: <Answer/> },
        { path: ":link", element: <AnswerContent/> }, 
        { path: "content", element: <AnswerContent/> },              
      ]
    },
    { path: "/preview",
      element: <Preview/>,
      children : [
        { index: true, element: <Preview/> },
        { path: ":surveyId", element: <PreviewContent/> },              
        { path: "ecrf/:surveyId", element: <PreviewContent/> },              
      ]
    },
    markupRouter,
      { path: "*", element: <ErrorPage /> }
  ])
	
  return (
      element
  )
}
export default App
