import { useEffect, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { validateInput, validateMobilePhoneNumber } from '../../../common/stringHelper';
import { formatMobilePhone } from "../../../common/stringHelper";

const ChangePhonePopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const { alert } = useCommonPopup()
  const api = useApi()
  const { projectData, participant } = props

  const [phone, setPhone] = useState("")
  const [error, setError] = useState("")
  const isChanging = participant?.phone?.length > 0


  useEffect(()=> {

    return () => {
    }
  }, []);


  const onChangePhone = (e) => {

    const value = e.target.value
    setPhone(value)

    if (value.length > 0 && !validateInput(value, "number")) {
      setError("휴대전화 번호는 숫자만 입력해주세요.")
    } else {
      setError("")
    }
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }


  const onClickConfirm = async (e) => {

    e.preventDefault()

    if (phone.length === 0 || !validateMobilePhoneNumber(phone)) {
      alert("올바른 휴대전화 번호를 입력해 주세요.")
      return
    }

    const result = await api.request(`/participants/${participant.idparticipant}/`, {
      method: "patch",
      params: { phone }
    })

    if (result?.status !== 204) {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">휴대전화 번호 {isChanging ? "변경" : "등록"}<span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e)=>e.preventDefault()}>
          <div className="pop_content height_auto " >
            <h4>{isChanging ? "변경" : "등록"}할 휴대전화 번호를 입력해주세요.</h4>
            <div>
              <input type="text" id="survey-link" placeholder="휴대전화 번호를 입력해주세요." value={phone}
                className={`full big ${error?.length > 0 ? "error" : ""}`} onChange={onChangePhone}/>
              <p className="tip">{error}</p>
              {isChanging ? (
                <p>현재 휴대전화 번호 : {formatMobilePhone(participant.phone)}</p>
              ) : ""}
            </div>
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className=" primary large "  onClick={onClickConfirm}>확인</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
 export default ChangePhonePopup;