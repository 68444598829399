import $ from 'jquery';
import { useEffect } from "react";

const NewProjectPopup = props => {

  useEffect(()=> {
    // 프로젝트 이름 바꾸기
    $('.project_head .icon-edit').click(function() {
      $('.project_home_pop, .project_home_pop .edit').fadeIn();
    });
    // 설문 이름 바꾸기
    $('.project table.line tbody td .icon-answer').click(function() {
      $('.project_home_pop, .project_home_pop .survey_edit').fadeIn();
    });



    // 프로젝트 삭제
    $('.pj_del_btn').click(function() {
      $('.project_home_pop, .project_home_pop .del').fadeIn();
    });
    
    // 설문 삭제
    $('.survey_del_btn').click(function() {
      $('.project_home_pop, .project_home_pop .survey_del').fadeIn();
    });
    // 설문 종료
    $('.survey_end_btn').click(function() {
      $('.project_home_pop, .project_home_pop .survey_end').fadeIn();
    });

    // 출처보기
    $('.project .view_btn').click(function() {
      $('.project_home_pop, .project_home_pop .view_source').fadeIn();
    });
    // 설문 삭제
    $('.survey .survey-del-btn').click(function() {
      $(this).next().fadeIn();
    });

  
    // 팝업 닫기
    $('.close_btn, .dim_bg, .pop_close').click(function() {
       $('.popup, .popup_inwrap').fadeOut();
    });

    // $('.question_foot .popmenu').click(function() {
    //   $(this).find('ul').stop().slideDown(100);
    //  },
    //  function() {
    //   $(this).find('ul').stop().slideUp(100);
    // }
    // );
    
  }, []);

  return (
    <div className="popup project_home_pop" >
      <div className="popup_wrap">
        {/* 프로젝트 이름 바꾸기 */}
        <div className="popup_inwrap edit">
          <h5 className="section_tit">프로젝트 이름 바꾸기  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">                      
              <h4>
              변경할 프로젝트 이름을 입력해주세요. (최대 30자)
              </h4>
              <div><
                label for="project_name">프로젝트 이름</label>
                <input type="text" id="project_name" placeholder="예) 당뇨환자의 수면장애" className="full big"/>
                <p className="tip">이름은 최대 30자를 넘을 수 없습니다.</p>
              </div>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close">취소</button>
                </div>
                <div>
                  
                  <button type="button" className=" primary large " >확인</button>
                  {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
                </div>
              </div>
            </div>
        </form>
        </div>
        {/* 설문 이름 바꾸기 */}
        <div className="popup_inwrap survey_edit">
          <h5 className="section_tit">설문 이름 바꾸기  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">                      
              <h4>
              변경할 설문 이름을 입력해주세요. (최대 30자)
              </h4>
              <div><label for="project_name">설문 이름</label>
                <input type="text" id="project_name" placeholder="예) 당뇨환자의 수면장애" className="full big"/>
                <p className="tip">이름은 최대 30자를 넘을 수 없습니다.</p>
              </div>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close">취소</button>
                </div>
                <div>
                  
                  <button type="button" className=" primary large " >확인</button>
                  {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
                </div>
              </div>
            </div>
        </form>
        </div>

        {/* 프로젝트 삭제 */}
        <div className="popup_inwrap del">
          <h5 className="section_tit">프로젝트 삭제  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">                      
              <h4>
              이 프로젝트를 삭제하시겠습니까? <br/> <br/>
              프로젝트를 삭제하면 모든 공동 작업자가 더 이상 해당 프로젝트를 볼 수 없게 되며, 프로젝트가 완전히 삭제됩니다.
             <br/><br/>
              </h4>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close">취소</button>
                </div>
                <div>
                  
                  <button type="button" className=" primary large " >삭제</button>
                  {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
                </div>
              </div>
            </div>
        </form>
        </div>
        {/* 설문 삭제  */}
        <div className="popup_inwrap survey_del">
          <h5 className="section_tit">설문 삭제  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">                      
              <h4>
              이 설문을 삭제하시겠습니까?<br/> <br/>
              설문을 삭제하면 모든 공동 작업자가 더 이상 해당 설문을 볼 수 없게 되며, 설문이 완전히 삭제됩니다.
             <br/><br/>
              </h4>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close">취소</button>
                </div>
                <div>
                  
                  <button type="button" className=" primary large " >삭제</button>
                  {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
                </div>
              </div>
            </div>
        </form>
        </div>
        {/* 설문 종료 */}
        <div className="popup_inwrap survey_end">
          <h5 className="section_tit">설문 종료  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">                      
              <h4>
              설문이 종료된 이후에는 더 이상 응답을 받을 수 없습니다.<br/> <br/>
              진행을 원하시면 확인 버튼을 눌러주세요.
             <br/><br/>
              </h4>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close">취소</button>
                </div>
                <div>
                  
                  <button type="button" className=" primary large " >확인</button>
                  {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
                </div>
              </div>
            </div>
        </form>
        </div>

        {/* 출처보기 */}
        <div className="popup_inwrap view_source">
          <h5 className="section_tit">출처보기  <span className="close_btn"></span></h5>
          <div className="pop_content">  
            <p>한국어판 정신건강 선별도구(K10)</p>
            <table className='line'>
              <colgroup><col className="date"/><col  /></colgroup>
              <thead>
                <tr>
                  <th colSpan={2}>APA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1차 저작권</td>
                  <td>
                  Kessler, R. C., Barker, P. R., Colpe, L. J., Epstein, J. F., Gfroerer, J. C., Hiripi, E., ... & Zaslavsky, A. M. (2003). Screening for serious mental illness in the general population. Archives of general psychiatry, 60(2), 184-189.
                  </td>
                </tr>
                <tr>
                  <td>2차 저작권</td>
                  <td>김용석. (2011). 한국어판 정신건강 선별도구 (K10, K6) 의 타당화 연구: 재가복지서비스 이용자를 대상으로. 정신건강과 사회복지, 37, 65-88.</td>
                </tr>
                <tr>
                  <th colSpan={2}>MLA</th>
                </tr>

                <tr>
                  <td>1차 저작권</td>
                  <td>Kessler, Ronald C., et al. "Screening for serious mental illness in the general population." Archives of general psychiatry 60.2 (2003): 184-189.</td>
                </tr>
                <tr>
                  <td>2차 저작권</td>
                  <td>김용석. "한국어판 정신건강 선별도구 (K10, K6) 의 타당화 연구: 재가복지서비스 이용자를 대상으로." 정신건강과 사회복지 37 (2011): 65-88.</td>
                </tr>
                <tr>
                  <th colSpan={2}>ISO690</th>
                </tr>
                <tr>
                  <td>1차 저작권</td>
                  <td>KESSLER, Ronald C., et al. Screening for serious mental illness in the general population. Archives of general psychiatry, 2003, 60.2: 184-189.</td>
                </tr>
                <tr>
                  <td>2차 저작권</td>
                  <td>김용석. 한국어판 정신건강 선별도구 (K10, K6) 의 타당화 연구: 재가복지서비스 이용자를 대상으로. 정신건강과 사회복지, 2011, 37: 65-88.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="dim_bg"></div> */}
      </div>
    </div>
  );  
}
 export default NewProjectPopup;