import { Link } from 'react-router-dom';
import ProjectHeader from '../inc/ProjectHeader';
import NewProjectPopup from '../popup/NewProjectPopup';
import ProjectHomePopup from '../popup/ProjectHomePopup';
import SurveySharePopup from '../popup/SurveySharePopup';
const Project01 = props => {
  return (      
    <div className="body project">
      <ProjectHeader/>
      <NewProjectPopup/>
      <ProjectHomePopup/>
      <SurveySharePopup/>
      <div className="container">
        <div className="inwrap">
          <div className="full gap">
          <section className="">
            <div className="search_wrap">
              <div>
                <div className="search_box">
                  <input type="text" placeholder="설문이름으로 검색"/>
                  <button><i className="icon-search"></i></button>
                </div>

                <div className="selectBox">
                  <span className="label">설문 상태 : 전체</span>
                  <ul className="optionList">
                    <li className="optionItem">전체</li>
                    <li className="optionItem">설문 진행 중</li>
                    <li className="optionItem">설문 대기</li>
                    <li className="optionItem">설문 종료</li>
                  </ul>
                  </div>

              </div>
              {/* <button className="primary large new_project_but"> <i>+</i> 새 프로젝트</button> */}
            </div>  
          </section>

          <section className="">
            <div className="search_wrap">
              <div>
                <p>전체 (117개)</p>

              </div>
              <button className="primary large new_project_but"> <i>+</i> 설문 만들기</button>
            </div>  
            <div className="section_in">
              <table className="line">
                <colgroup>
                  <col className="no"/>
                  <col />
                  <col className="date"/>
                  <col className="date"/>
                  <col className="date"/>
                  <col className="no"/>
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>설문이름</th>
                    <th>상태</th>
                    <th>응답</th>
                    <th>최종 수정일</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><i className="icon-answer" ></i></td>
                    <td>2023 프로젝트 사전설문</td>
                    <td><span className="tag ing">설문 진행 중</span></td>
                    <td>8(3)<span className="dot"></span></td>
                    <td>2022.12.01.</td>
                    <td className='popmenu'>
                      <i className="icon-dot-menu"></i>
                      <ul className="menu_pop">
                        <li className='survey_end_btn'>설문 종료</li>
                        <li className='survey_copy_btn'>설문 복사</li>
                        <li className='view_btn'>출처보기</li> 
                      </ul>
                      
                    </td>
                  </tr>
                  <tr>
                    <td><i className="icon-answer" ></i></td>
                    <td>2023 프로젝트 사전설문</td>
                    <td><span className="tag ing">설문 진행 중</span></td>
                    <td>8(3)<span className="dot"></span></td>
                    <td>2022.12.01.</td>
                    <td className='popmenu'>
                      <i className="icon-dot-menu"></i>
                      <ul className="menu_pop">
                        <li className='survey_end_btn'>설문 종료</li>
                        <li className='survey_copy_btn'>설문 복사</li>
                        <li className='view_btn'>출처보기</li> 
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td><i className="icon-answer" ></i></td>
                    <td>2023 프로젝트 사전설문</td>
                    <td><span className="tag hold">설문 대기</span></td>
                    <td>8(3)</td>
                    <td>2022.12.01.</td>
                    <td className='popmenu'>
                      <i className="icon-dot-menu"></i>
                      <ul className="menu_pop">
                       <li className='survey_send_btn'>설문 보내기</li>
                        <li className='survey_copy_btn'>설문 복사</li>
                        <li className='survey_del_btn'>설문 삭제</li> 
                        <li className='view_btn'>출처보기</li> 
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td><i className="icon-answer" ></i></td>
                    <td>2023 프로젝트 사전설문</td>
                    <td><span className="tag end">설문 종료</span></td>
                    <td>8(3)</td>
                    <td>2022.12.01.</td>
                    <td className='popmenu'>
                      <i className="icon-dot-menu"></i>
                      <ul className="menu_pop">
                       <li className='survey_copy_btn'>설문 복사</li>
                        <li className='survey_del_btn'>설문 삭제</li> 
                        <li className='view_btn'>출처보기</li> 
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="pagination">
                1-10 of 100 items
                <ul>
                  <li><a href=""><i className="icon-left"></i></a></li>
                  <li><a href="">1</a></li>
                  <li><i className="icon-dot-menu"></i></li>
                  <li><a href="">4</a></li>
                  <li><a href="">5</a></li>
                  <li><a className="active" href="">6</a></li>
                  <li><a href="">7</a></li>
                  <li><a href="">8</a></li>
                  <li><i className="icon-dot-menu"></i></li>
                  <li><a href="">50</a></li>
                  <li><a href=""><i className="icon-right"></i></a></li>
                  <li><a href="">10/page </a></li>
                </ul>
              </div>
            </div>
          </section>
         </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default Project01;