const SurveyHeader = () => {
  return (

    <header>
      <div  className="project_head suvay_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>
            <h1>
                <a href="/dashboard/"><i className="icon-logo"></i></a>
            </h1>
            <h4> <i className="icon-arrowleft"></i> 제목을 입력해주세요</h4>
            </div>
            <div>
            {/* <button className="disabled large">설문 보내기</button> */}
            <button className="primary large survey_send_btn ">설문 보내기</button>
            <button className="primary large"><i className="icon-ing_clock"></i>설문 진행중</button>
            <button className="disabled large "><i className="icon-union"></i>설문 종료됨</button>
            <div className="info_wrap popmenu">
                <p className="dash_profile"><span>김</span>김선아</p>
                <ul className="menu_pop">
                <li>한국대학교</li>
                <li><a href="">계정정보 수정</a></li>
                <li><a href="">로그아웃</a></li> 
                </ul>
            </div>
            <div className="dot_menu popmenu">
                <i className="icon-dot-menu"></i>
                <ul className="menu_pop">
                <li className="survey_re_btn">반복 설정</li>
                  <li className="survey_del_btn">설문 삭제</li>
                </ul>
            </div>
            </div>
          </div>
        </div>
        <div className="head_info"> 
          <div className="inwrap">
            <ul>
              <li className="active"><a href="survey">설문</a></li>
              <li className="active"><a href="SurveyAnswer" className="c_disabled">응답<span className="hit_gray">9</span></a></li>
            </ul>  
            <ul>
                <li>
                <button class="text_only disabled">모바일 미리보기</button>
                </li>
              <li>
              <button class="text_only view_btn">출처보기</button>
              </li>
            </ul>
          </div>
        </div>        
      
      {/* <nav className="nav">
        <div><i className="icon-close"></i></div>
        <ul>
          <li><a href=" " className="active"><i className="icon-home"></i>프로젝트 홈</a></li>
          <li ><a href=""><i className="icon-team c_disabled"></i>참여자 관리</a></li>
        </ul>
      </nav> */}
      </div>
      
    </header>  
    
    );
}  
export default SurveyHeader;