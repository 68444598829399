import $ from 'jquery';
import { useEffect } from "react";

const NewProjectPopup = props => {

  useEffect(()=> {
  // 표준척도
  $('.survey .scale_view').click(function() {
    $('.SurveyScalePopup, .SurveyScalePopup .survey_scale, .dim_bg').fadeIn();
  });
  $('.survey .ScalePopup2_view, .dim_bg').click(function() {
    $('.SurveyScaleStep2, .SurveyScaleStep2 .survey_scaleStep2').fadeIn();
  });

  }, []);

  return (



    <><div className="popup SurveyScaleStep2 popPlus">
      <div className="popup_wrap">
        <div className="popup_inwrap survey_scaleStep2">
          <h5 className="section_tit">척도 미리보기<span className="close_btn"></span></h5>

          <div className="pop_content">
            <div>
              정신건강 선별도구(K10)
              <p className='text_secondaty'>
                김용석. (2011). 한국어판 정신건강 선별도구 (K10, K6)의 타당성 연구 : 재가복지서비스 이용자를 대상으로. 정신건강과 사회복지, 37, 65-88.
              </p>
            </div>
            {/* <img src="../../../assets/images/surveyScale_img.jpg"/> */}
            <table className='view_table'>
                <thead>
                  <tr>
                    <th colspan="2">
                        지난 1년 동안 귀하가 가장 우울 또는 불안했거나 스트레스가 가장 많았던 한달을 떠올려 보십시오. 만약 그런 시기가 없었다면 지난 12개월 중 대표적인 달을 떠올려 주십시오.
                    </th>
                    <th>
                      한 달 내내
                    </th>
                    <th>
                      한 달 중 대부분
                    </th>
                    <th>
                      한 달 중 절반 정도
                    </th>
                    <th>
                      한 달 중 며칠
                    </th>
                    <th>
                      전혀 없었다
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td class="text_left">아무런 이유 없이 기운이 없던 적이 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td class="text_left">신경이 예민했던 날은 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td class="text_left">신경이 너무 예민해서 귀하를 안정시킬 수 있는 것이 아무 것도 없다고 느끼신 적이 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td class="text_left">희망이 없다고 느끼신 적이 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td class="text_left">안절부절 못하거나 조바심을 냈던 적은 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td class="text_left">안절부절 못하거나 조바심을 내서 가만히 앉아있을 수 없다고 느끼신 적이 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td class="text_left">우울하여 기운을 북돋울 수 있는 것이 아무것도 없다고 느끼신 적이 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td class="text_left">매사가 힘들다고 느끼신 적은 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td class="text_left">자신을 가치없는 사람처럼 느끼신 적은 얼마나 됩니까?</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>

                </tbody>
              </table>
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close">취소</button>
              </div>
              <div>

                <button type="button" className=" primary large ">확인</button>
                {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><div className="popup SurveyScalePopup">
        <div className="popup_wrap">

          <div className="popup_inwrap survey_scale">
            <h5 className="section_tit">표준 척도 <span className="close_btn"></span></h5>
            <div className="pop_content">
              <p className="text_secondaty">자주 사용되는 표준 척도들을 불러와  설문조사를 쉽고 빠르게 만들어보세요.</p>
              <div>
                <label htmlFor="">척도 검색</label>
                <div className="vertical_form">
                  <div>
                    <input type="text" id="" placeholder="척도 이름" className="full" />
                  </div>
                  <div>
                    <button className="full"><i className="icon-search"></i></button>
                  </div>
                </div>
              </div>
              <div>
                <h6>추천 /  검색 결과 (2)</h6>
                <div className='divder_up'>
                  <span className='c_primary_6'>한국어판</span> 정신건강 선별도구(K10)
                  <p className='text_secondaty'>
                    김용석. (2011). 한국어판 정신건강 선별도구 (K10, K6)의 타당성 연구 : 재가복지서비스 이용자를 대상으로. 정신건강과 사회복지, 37, 65-88.
                  </p>
                </div>
                <div className='divder_up'>
                  역학연구를 위한 우울척도 (CES-D)
                  <p className='text_secondaty'>
                    전겸구, 최상진, 양병창(2001). 통합적 한국판 CES-개발. 한국심리학회지 : <br />건강, 6(1), 59-76
                  </p>
                </div>
                <h6>우울증</h6>
                <div className='divder_up'>
                  <span className='c_primary_6'>한국어판</span> 역학연구를 위한 우울척도-단축형 (CES-10-D)
                  <p className='text_secondaty'>
                    전겸구, 최상진, 양병창(2001). 통합적 한국판 CES-개발. 한국심리학회지 :  <br />건강, 6(1), 59-76
                  </p>
                </div>

                <h6>검색 결과 (0)</h6>
                <div className='divder_up'>
                  <p className='center'>
                    <br></br>
                    검색 결과가 없습니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close">취소</button>
                </div>
                <div>

                  <button type="button" className=" primary large ScalePopup2_view ">확인</button>
                  {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className='dim_bg'></div>
      </>



  );  
}
 export default NewProjectPopup;