import React, { useEffect, useState } from 'react'
import { Outlet, Navigate, useNavigate, Link } from "react-router-dom"
import { useAuth, AuthContextProvider } from '../context/AuthContext'
import { useApi, ApiContextProvider } from '../context/ApiContext'

const HomePage = () => {

  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(_ => {
    
    const init = async () => {
      if (auth.isAutoSignin() && await auth.isAuth()) {        
        navigate("/dashboard")
      } else {
        navigate("/signin")
      }   
    }
    init()
    
  }, [])
  
  return (
    <>
    </>
  )
}
export default HomePage