import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useApi } from '../../context/ApiContext';
import { decimalFormat } from '../../common/stringHelper';
import { useNavigate } from 'react-router-dom';

const OpenSurvey = props => {

  const navigate = useNavigate()
  const api = useApi()

  const [surveyList, setSurveyList] = useState()

  useEffect(_ => {
    
    getOpenSurveyList()

    return ()=>{ 
    }
  }, [])

  useEffect(_ => {
    
    getOpenSurveyList()

  }, [props.isRefresh])

  /*=========================================================
   *  Data 
   =========================================================*/
   const getOpenSurveyList = async () => {

    const result = await api.post("getOpenSurveyList", {
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      result.data.data.surveys.forEach((survey, index1) => {                
        survey["rate"] = survey.participantcount > 0 ? Math.min(100, survey.completedcount / survey.participantcount * 100) : 0    
      });
      setSurveyList(result.data.data.surveys)
    }
  }

  const onClickSurvey = (survey) => {

    navigate(`/project/${survey.project}/survey/${survey.idsurvey}`)
  }

  return (
    <>
    { surveyList?.length > 0 ? (
        <section className="project_ing">
          <h5 className="section_tit">진행 중 설문</h5>
          <div className="project_rolling">
            <OwlCarousel className='owl-carousel owl-theme' items={1} loop margin={10} nav dots autoplay={false} center={false}>          
              { surveyList.map((survey, index) => 
                  <div className="item" key={`open-survey-${index}`} onClick={() => { onClickSurvey(survey) }}>
                    <div>
                      <p>{survey.projectname}</p>
                      <h5>{survey.surveyname.length > 0 ? survey.surveyname : "제목없음"}</h5>
                      <ul>
                        <li>응답 <span>{survey.completedcount}</span></li>
                        <li>미응답 <span>{survey.notansweredcount}</span></li>
                        <li>등록 참여자 <span>{survey.participantcount}</span></li>
                        {survey.eCRF === 1 && survey.multipleresponse === 1 && <li>총 응답건수 <span>{survey.totalresponsecount}</span></li>}
                      </ul>
                    </div>
                    <div  className="CircularProgress">
                      <CircularProgressbarWithChildren value={survey.rate}>
                        <div className="sub_line">
                          <div >응답률</div>
                          <h3 >{decimalFormat(survey.rate, 2)}%</h3>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                )
              }
            </OwlCarousel>
          </div>
          </section> 
      ) : (null)
    }
    </>
  )
}

export default OpenSurvey 