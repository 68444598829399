import $ from 'jquery';
import { useEffect } from "react";

const ParticipantPopup4 = props => {

  useEffect(()=> {

    $('.agreeUp-btn').click(function() {
      $('.Participant_pop4, .Participant_pop4 .popup_inwrap ').fadeIn();
    });

  }, []);

  return (
 
    <div className="popup Participant_pop4" >
        <div className="popup_wrap">

        {/* 서면 동의서 업로드 */}
        <div className="popup_inwrap">
          <h5 className="section_tit">서면 동의서 업로드 <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content height_auto ">
              <h4>카메라</h4>
              <h4>갤러리</h4>



  
                  
              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">확인</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
        </div>
      </div>

  );  
}
 export default ParticipantPopup4;