import React from 'react';

import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import { AuthContextProvider } from './context/AuthContext';
import { ApiContextProvider } from './context/ApiContext';

import App from './App';
import './index.css';
// import { ToastContainer } from 'react-toastify';
import { CommonPopupContextProvider } from './context/CommonPopupContext';
import { Toaster } from 'react-hot-toast';
import { LoadingIndicatorContextProvider } from './context/LoadingIndicatorContext';

if (process.env.REACT_APP_ENV === "prod") {
  console.log = () => { }
  console.debug = () => { }
  console.info = () => { }
  console.warn = () => { }
  console.error = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router>
      <LoadingIndicatorContextProvider>
        <ApiContextProvider>
          <AuthContextProvider>
            <CommonPopupContextProvider>
              <App/>
              <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                  // Define default options
                  className: '',
                  duration: 3000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },
                  success: {
                    duration: 3000,
                    theme: {
                      primary: 'green',
                      secondary: 'black',
                    },
                  },
                }}
              />
            </CommonPopupContextProvider>
          </AuthContextProvider>
        </ApiContextProvider>
      </LoadingIndicatorContextProvider>
    </Router>
  // </React.StrictMode>
);

// loadExternalJS();
reportWebVitals();

