import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { PARTICIPANT_STATUS } from '../../../common/codeHelper';
import { handleSelect } from '../../../common/javascriptHelper';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const ChangeGradePopup = props => {

  const api = useApi()
  const {alert} = useCommonPopup()  

  const {popupData, onCancel, onConfirm} = props  
  const { customerList } = props

  const [error, setError] = useState("")  
  const [status, setStatus] = useState()
  const [message, setMessage] = useState("")
  
  useEffect(_ => {
    
    if (customerList.length == 1) {
      setStatus(customerList?.[0]?.premium === 1 ? 0 : 1)
      setMessage(`${customerList?.[0]?.name}님의 등급을 ${customerList?.[0]?.premium === 1 ? '프리미엄' : '일반'}에서 ${customerList?.[0]?.premium === 1 ? '일반' : '프리미엄'}으로 변경합니다.`)
    } else {
      var name = customerList.map((customer) => (customer.name))?.join(", ")
      setStatus(customerList?.[0]?.premium)
      setMessage(`${name}님의 등급을 변경해 주세요.`)
    }
  }, [])

  const onClickSelect = (e) => {
    handleSelect(e)
  }

  const onChangeStatus = (status) => {

    setStatus(status)
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickConfirm = async () => {

    if (status !== 0 && status !== 1) {
      setError("변경할 등급을 선택해 주세요.")
      return
    }

    const result = await api.post("updateCustomerGrade", {
      premium: status,
      ids: customerList.map((customer) => (customer.idadmin))
    })
    
    if (result?.data?.result != "ok") {
      if (result?.data?.msg) {
        setError(result?.data?.msg)
      } else {
        api.networkError(result, alert)
      }
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }   
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">고객 등급 변경 <span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content height_auto  ">
              <h4>{message}</h4>
              { customerList?.length > 1 && <div className="selectBox large">
                <span className="label" onClick={onClickSelect}>{status === 0 ? '일반' : status === 1 ? '프리미엄' : '변경할 등급을 선택해 주세요.'}</span>
                <ul className="optionList">
                  <li className="optionItem" onClick={() => onChangeStatus(0)}>일반</li>
                  <li className="optionItem" onClick={() => onChangeStatus(1)}>프리미엄</li>
                </ul> 
              </div>
              }
              <p className="tip" style={{whiteSpace:"pre-line"}}>{error}</p>
              
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
                </div>
                <div>
                  <button type="button" className=" primary large " onClick={onClickConfirm}>확인</button>
                </div>
              </div>
            </div>
        </form>
      </div>
    </div>
  );  
}
 export default ChangeGradePopup;