import { Link } from 'react-router-dom';
import MyInfoHeader from '../inc/MyInfoHeader';
import PwChangePopup from '../popup/PwChangePopup';

const MyInfo = props => {
  return (      
    <div className="body project survey myinfo ">
      <PwChangePopup/>
      <MyInfoHeader/>

 
      <div className="container">
        <div className="inwrap">

            <div className="card my_info">
              <dl>
                <dt>이메일</dt>
                <dd>abcd@gangnam.go.kr</dd> 
              </dl>
              <dl>
                <dt>이름</dt>
                <dd>김강남</dd>
              </dl>
              <dl>
                <dt>성별</dt>
                <dd>남자</dd>
              </dl>
              <dl>
                <dt>휴대폰 번호</dt>
                <dd>010 - 1111- 2222</dd>
              </dl>

            </div>
            <div className="card myinfo_change">
              <div>
                <img src="../../../assets/images/myinfo.jpg" alt="" />
                <div className="popmenu">
                  <i className="icon-photo"></i>
                  <ul className="menu_pop">
                    <li className="survey_re_btn">프로필 사진 바꾸기</li>
                    <li className="survey_del_btn">삭제하기</li>
                  </ul>
                </div>
              </div>

              <ul>
                <li>
                <label for="">비밀번호</label>
                <button className='secondary gray pwChangeBtn'>비밀번호 변경</button>
                </li>
                <li>
                  <label htmlFor="">소속기관</label>
                  <ul>
                    {/* <li>
                    <input name="affiliation" type="text" placeholder="소속기관" className="full " value=""/>
                    </li> */}
                    <li>
                    <div className="selectBox  full">
                      <span className="label">한국대학교</span>
                      <ul className="optionList">
                        <li className="optionItem">한국대학교</li>
                        <li className="optionItem">한국대학교</li>
                        <li className="optionItem">한국대학교</li>
                        <li className="optionItem">한국대학교</li>
                      </ul>
                    </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <label htmlFor="">직업</label>
                  <div className="selectBox  full">
                    <span className="label">대학원생(석사)</span>
                    <ul className="optionList">
                      <li className="optionItem">의료진</li>
                      <li className="optionItem">간호사</li>
                    </ul>
                  </div>
                </li>
                <li>
                <button className="error">회원 탈퇴</button>
                </li>
                

                </ul>

            </div>
         </div>

      </div>
    </div>
    );
  }
  
  export default MyInfo;