import { useEffect, useRef, useState } from "react";
import { useAuth } from '../../context/AuthContext'
import { useApi } from '../../context/ApiContext'
import { initPopupMenu } from "../../common/javascriptHelper";
import { useNavigate } from 'react-router-dom';
import NewProjectPopup from '../project/popup/NewProjectPopup';
import DeleteProjectPopup from '../project/popup/DeleteProjectPopup';
import ProjectNamePopup from '../project/popup/ProjectNamePopup';
import InviteCollaboratorPopup from '../project/popup/InviteCollaboratorPopup';
import { useCommonPopup } from '../../context/CommonPopupContext';
import Pagination from '../../component/Pagination';
import { markKeyword } from "../../common/stringHelper";

const ProjectList = props => {

  const navigate = useNavigate()
  const auth = useAuth()
  const api = useApi()
  const {refreshData} = props
  const { popup, alert } = useCommonPopup()

  const [projectList, setProjectList] = useState()
  
  const [pageLimit, setPageLimit] = useState(9)
  const [totalCount, setTotalCount] = useState(Number(0))
  const [currentPage, setCurrentPage] = useState(0)
  const [originalTotalCount, setOriginalTotalCount] = useState(0)
  
  const [keyword, setKeyword] = useState("")
  const searchPage = useRef()
  const searchKeyword = useRef("")
  
  /*=========================================================
   *  useEffect 
   =========================================================*/
  useEffect(_ => {
    
  }, [])

  useEffect(_ => {
    
    if (searchPage.current === currentPage) return
    getProjectList(keyword, currentPage)
    
  }, [currentPage])

  useEffect(_ => {
    
    if (searchKeyword.current !== keyword) {
      getProjectList(keyword, 0)
    }
  }, [keyword])

  /*=========================================================
   *  Data 
   =========================================================*/
  const getProjectList = async (search, page) => {

    searchKeyword.current = search

    const result = await api.post("getProjectList", {
      search: search,
      page: page?? 0,
      limit: pageLimit
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      searchPage.current = page
      setCurrentPage(page)
      setOriginalTotalCount(result.data.data.originalTotalCount)
      setTotalCount(result.data.data.totalCount)
      
      var list = result.data.data.projects
      list.forEach((data, index) => {
        data["display_name"] = markKeyword(data.name, search)
      });
      setProjectList(list)
    }
  }

  /*=========================================================
   *  Handler
   =========================================================*/

  const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onClickNewProject = async () => {

    const result = await popup(<NewProjectPopup/>,
      {
        container: "project",
        name:"new_project_pop",
        style:"new_project_pop",      
        hasFooter:false,
        useContainer:false,
        title: "출처 보기"
      }, 
    )
    if (!!result) {
      navigate(`/project/${result}`)
    } else {
      getProjectList(keyword, currentPage)
    }
  }

  const onChangeInput = (e) => {

    var value = e.target.value   
    if (keyword === value) return

    setKeyword(value)

    // if (!!timer) clearTimeout(timer)
    // timer = setTimeout(() => {
    //   getProjectList(value, 0)
    // }, 200)
  }

  const onKeyEvent = (e) => {

    if (e.key === 'Enter') {
      onClickSearch()
    }
  }

  const onClickSearch = async () => {

    await getProjectList(keyword, 0)
  }

  const ProjectCard = props => {

    const { item, index } = props

    useEffect(_ => {
    
      initPopupMenu()
  
    }, [])

    const onClickCard = () => {
                  
      navigate(`/project/${item.idproject}`)
    }

    const onClickInvite = async () => {
      const result = await popup(<InviteCollaboratorPopup projectData={item}/>,
        {
          container: "project",
          name: "SurveySharePopup",
          style: "edit",      
          hasFooter: false,
          useContainer: false,
        }, 
      )
      if (result === true) {
        getProjectList(keyword, currentPage)
      }
    }

    const onClickRename = async () => {

      const result = await popup(<ProjectNamePopup projectData={item}/>,
        {
          container: "project",
          name: "project_home_pop",
          style: "edit",      
          hasFooter: false,
          useContainer: false,
        }, 
      )
      if (result === true) {
        getProjectList(keyword, currentPage)
      }
    }

    const onClickDelete = async () => {
          
      const result = await popup(<DeleteProjectPopup projectData={item}/>,
        {
          container: "project",
          name:"project_home_pop",
          style:"del",      
          hasFooter: false,
          useContainer: false,
        }, 
      )
      if (result === true) {        
        await getProjectList(keyword, currentPage)
        refreshData()
      }
    }

    return (
      <li>        
        <div className="card_head" onClick={onClickCard}>
        {index % 4 == 0 && <img src="../../../../assets/images/Tag_b.svg" alt=""/>}
          {index % 4 == 1 && <img src="../../../../assets/images/HeartOnHand_y.svg" alt=""/>}
          {index % 4 == 2 && <img src="../../../../assets/images/Heart_r.svg" alt=""/>}
          {index % 4 == 3 && <img src="../../../../assets/images/Keyboard_g.svg" alt=""/>}
          <div className="card_menu popmenu">
            <i className="icon-dot-menu" onClick={(e) => { e.stopPropagation();}}></i>
            <ul className="menu_pop" onClick={(e) => { e.stopPropagation();}}>
              <li onClick={onClickInvite}>공동 작업자 초대</li>
              <li onClick={onClickRename}>이름 바꾸기</li>
              <li onClick={onClickDelete}>삭제</li>
            </ul>
          </div>
          <h5><div dangerouslySetInnerHTML={ {__html: item.display_name} }></div></h5>
        </div>
        <ul onClick={onClickCard}>
          <li><i className="icon-filetext"></i> 설문 {item.surveyinfo.surveycount}개
          { item.surveyinfo.newanswer === 1 && <span className="hit new">새 응답</span> }
          </li>
          <li><i className="icon-user"></i>{item.collaborators.map(obj => obj.name).join(",")}</li>
          <li><i className="icon-calendar "></i>{item.createdate} 개설</li>
        </ul>
      </li>
    )
  }

  return (
    <>
      { !!projectList ? (  
        originalTotalCount > 0 ? (
          <section className="project">
            <h5 className="section_tit">프로젝트</h5>   
            <div className="search_wrap">
              <div className="search_box">
                <input type="text" placeholder="프로젝트 이름으로 검색" value={keyword} onChange={onChangeInput} onKeyUp={onKeyEvent}/>
                <button onClick={onClickSearch}><i className="icon-search"></i></button>
              </div>
              <button className="primary large new_project_but" onClick={onClickNewProject}> <i>+</i> 새 프로젝트</button>
            </div>  
            { keyword?.length > 0 && 
              (
                <div className="search_wrap">
                  <div>
                    <p>검색 결과 ({totalCount}개)</p>
                  </div>
                </div>  
              )
            }
            { projectList.length > 0 ? (
              <>                
                <ul className="card_list">
                  { projectList.map((item, index) => <ProjectCard key={`project-card-${index}`} item={item} index={index}/>)}
                </ul>
                <div>
                  <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageSize={pageLimit}
                    changeCurrentPage={onChangePage}
                  />
                </div>
              </>  
              ) : (
                <p className='center'>
                  <br/><br/><br/><br/>
                  검색어와 일치하는 프로젝트가 없습니다.
                  <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </p>
              )
            }            
          </section>
        ) : (
          <div className="full no_item">
            <section className="project">
              <div>
                <img src="../../../assets/images/no_items.png" alt=""/>
              </div>
              <div>
                <p>
                  아직 참여한 프로젝트가 없습니다. <br/>
                  새 프로젝트 버튼을 눌러서 시작해보세요.
                </p>
              </div>
              <div>
                <button className="primary large new_project_but" onClick={onClickNewProject}> <i>+</i> 새 프로젝트</button>
              </div>
            </section>
          </div>
        )
      ) : null
    }
  </>
  )
}

export default ProjectList 