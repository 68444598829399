import $ from 'jquery';

export const initPopupMenu = (immidiate) => {
   
  $('.popmenu').hover(function() {
    if (immidiate === true) $(this).find('ul').stop().show();
    else $(this).find('ul').stop().slideDown(100);
   },
   function() {
    if (immidiate === true) $(this).find('ul').stop().hide();
    else $(this).find('ul').stop().slideUp(100);
  }
  );
}

export const initSelect = () => {

  // 셀렉트박스
  const label = document.querySelectorAll('.label');
  label.forEach(function(lb){
    lb.addEventListener('click', e => {
        let optionList = lb.nextElementSibling;
        let optionItems = optionList.querySelectorAll('.optionItem');
        clickLabel(lb, optionItems);
    })
  });
  const clickLabel = (lb, optionItems) => {
    if(lb.parentNode.classList.contains('active')) {
        lb.parentNode.classList.remove('active');
        optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
                handleSelect(lb, opt)
            })
        })
    } else {
        lb.parentNode.classList.add('active');
        optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
                handleSelect(lb, opt)
            })
        })
    }
  }
  const handleSelect = (label, item) => {
    label.innerHTML = item.textContent;
    label.parentNode.classList.remove('active');
  }
}

export const initFileInput = () => {

  var fileTarget = $('.filebox .upload-hidden');

  fileTarget.on('change', function(){
      if(window.FileReader){
          // 파일명 추출
          var filename = $(this)[0].files[0].name;
      } 

      else {
          // Old IE 파일명 추출
          var filename = $(this).val().split('/').pop().split('\\').pop();
      };

      $(this).siblings('.upload-name').val(filename);
  });

  //preview image 
  var imgTarget = $('.preview-image .upload-hidden');

  imgTarget.on('change', function(){
      var parent = $(this).parent();
      parent.children('.upload-display').remove();

      if(window.FileReader){
          //image 파일만
          if (!$(this)[0].files[0].type.match(/image\//))  
          
          var reader = new FileReader();
          reader.onload = function(e){
              var src = e.target.result;
              parent.append('<div class="upload-display"><div class="upload-thumb-wrap"><img src="'+src+'" class="upload-thumb"></div></div>');
          }
          reader.readAsDataURL($(this)[0].files[0]);
      }

      else {
          $(this)[0].select();
          $(this)[0].blur();
          var imgSrc = document.selection.createRange().text;
          parent.append('<div class="upload-display"><div class="upload-thumb-wrap"><img class="upload-thumb"></div></div>');

          var img = $(this).siblings('.upload-display').find('img');
          img[0].style.filter = "progid:DXImageTransform.Microsoft.AlphaImageLoader(enable='true',sizingMethod='scale',src=\""+imgSrc+"\")";        
      }
  });


  // fileUpload
  var fileTarget = $('.filebox .upload-hidden');

  fileTarget.on('change', function(){
  if(window.FileReader){
    // 파일명 추출
    var filename = $(this)[0].files[0].name;
  } 

  else {
    // Old IE 파일명 추출
    var filename = $(this).val().split('/').pop().split('\\').pop();
  };

  $(this).siblings('.upload-name').val(filename);
  });

  //preview image 
  var imgTarget = $('.preview-image .upload-hidden');

  imgTarget.on('change', function(){
    var parent = $(this).parent();
    parent.children('.upload-display').remove();

    if(window.FileReader){
      //image 파일만
      if (!$(this)[0].files[0].type.match(/image\//))  
      
      var reader = new FileReader();
      reader.onload = function(e){
          var src = e.target.result;
          parent.append('<div class="upload-display"><div class="upload-thumb-wrap"><img src="'+src+'" class="upload-thumb"></div></div>');
      }
      reader.readAsDataURL($(this)[0].files[0]);
    } else {
        $(this)[0].select();
        $(this)[0].blur();
        var imgSrc = document.selection.createRange().text;
        parent.append('<div class="upload-display"><div class="upload-thumb-wrap"><img class="upload-thumb"></div></div>');

        var img = $(this).siblings('.upload-display').find('img');
        img[0].style.filter = "progid:DXImageTransform.Microsoft.AlphaImageLoader(enable='true',sizingMethod='scale',src=\""+imgSrc+"\")";        
    }
  });
}

export const handleSelect = (e) => {

  let lb = e.target
  let optionList = lb.nextElementSibling;
  
  if (optionList == null) return

  let optionItems = optionList.querySelectorAll('.optionItem');

  const clickListener = (e) => {    
    if (e.target.classList.contains('skipLabelModify')) return;

    lb.innerHTML = e.target.textContent;
    lb.parentNode.classList.remove('active');
  }

  const outsideClickListener = (event) => {
    
    if (event.target === lb) return
    if (lb.parentNode.classList.contains('active')) {
      lb.parentNode.classList.remove('active');
    }
  }

  if(lb.parentNode.classList.contains('active')) {
    lb.parentNode.classList.remove('active');
    optionItems.forEach((opt) => {
      opt.removeEventListener('click', clickListener)
    })
    document.removeEventListener('click', outsideClickListener);
  } else {
    lb.parentNode.classList.add('active');
    optionItems.forEach((opt) => {
      opt.addEventListener('click', clickListener)
    })
    document.addEventListener('click', outsideClickListener);
  }
}



export const handlePopmenu = (e) => {

  $('.popmenu').hover(function() {
    $(this).find('ul').stop().slideDown(100);
   },
   function() {
    $(this).find('ul').stop().slideUp(100);
  }
  );

  $(".project_head i.icon-menu").click(function() {
    // alert('aa');
    $('.project_head .nav').addClass('active');
  });
  $(".project_head .nav .icon-close").click(function() {
    // alert('aa');
    $(' .project_head .nav').removeClass('active');
  });
}

export default function setScreenHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}