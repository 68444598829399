import React from 'react';

const YesOrNoContent = ({
  currentData,
  isSelected,
  onSelectAnswer,
  reasons,
  updateReason
}) => {
  return (
    <ul className='radio checkbox'>
      { currentData.question.items.map((item, index) =>
          <li key={`question-${index}`}>
            <label>
              <input
                type="radio"
                name="question"
                checked={isSelected(item)}
                onChange={(e) => { onSelectAnswer(item) }}
              /><span>{item.content}</span>
            </label>
            { currentData.question.inputreason === 1 && isSelected(item) &&
              <>
                <textarea
                 className={`full solo ${reasons[item.orderno]?.length === 300 ? 'danger' : ''}`}
                  id={`textarea_auto_${item.orderno}`}
                  placeholder="답변 입력"
                  rows="1"
                  value={reasons[item.orderno] || ""}
                  onChange={(e) => updateReason(item.orderno, e.target.value)}>
                </textarea>
                <p className='text_length'>{reasons[item.orderno]?.length || 0}/300</p>
              </>
            }
          </li>
        )
      }
    </ul>
  )
}

export default YesOrNoContent;
