import $ from 'jquery';
import { useEffect } from "react";

const CommonPopup = props => {

  useEffect(()=> {
    $('.pwChangeBtn').click(function() {
      $('.common_pop, .common_pop .popup_inwrap ').fadeIn();
    });


  }, []);

  return (
 
    <div className="popup common_pop" >
        <div className="popup_wrap">
        {/* 공용 */}
        <div className="popup_inwrap">
          <h5 className="section_tit">비밀번호 변경 <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">
              <div>
                <label for="password">비밀번호</label>      
                <input name="password" type="text" className='full'   placeholder="비밀번호"value=""/>
              </div>
              <div>
                <label for="pwCheck">비밀번호 확인</label>      
                <input name="pwCheck" type="text" className='full'   placeholder="비밀번호"value=""/>
              </div>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close">취소</button>
                </div>
                <div>
                  <button type="button" className=" primary large ">확인</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        </div>
      </div>

  );  
}
 export default CommonPopup;