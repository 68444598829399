import { useEffect, useState } from "react";
import { useApi } from "../../../context/ApiContext";
import { handleSelect } from "../../../common/javascriptHelper";
import { useCommonPopup } from "../../../context/CommonPopupContext";

const SendSurveyLinkPopup = ({ projectId, participants, onCancel, onConfirm, popupData }) => {
  const api = useApi();
  const {alert} = useCommonPopup();
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isReservation, setIsReservation] = useState(false);
  const [reservationDate, setReservationDate] = useState("");

  useEffect(() => {
    getSurveyList();
  }, []);

  const getSurveyList = async () => {
    const result = await api.request(`/projects/${projectId}/open_surveys/`);
    if (result?.status === 200) {
      setSurveys(result.data);
    }
  };

  const onClickSelect = (e) => {
    handleSelect(e);
  };

  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onChangeSurvey = (survey) => {
    setSelectedSurvey(survey);
  };

  const onClickSend = async () => {
    if (!selectedSurvey) {
      alert("설문을 선택해주세요.");
      return;
    }

    if (isReservation && !reservationDate) {
      alert("예약 발송 날짜를 선택해주세요.");
      return;
    }

    const result = await api.request(`/projects/${projectId}/send_link/`, {
      method: "post",
      params: {
        survey: selectedSurvey.idsurvey,
        participants: participants.map((p) => p.idparticipant),
        reservation_date: reservationDate
      },
    });

    if (result?.status === 204) {
      alert("설문 링크 발송 요청이 완료되었습니다.\n좌측 메뉴의 '설문 링크 발송 현황'에서 결과를 확인해주세요.", {
        title: "설문 링크 발송",
      });
      if (!!onConfirm) {
        onConfirm(popupData, true);
      }
    } else {
      api.networkError(result, alert);
    }
  };

  const getRecipientString = () => {
    return participants.map(p => p.initial).join(';');
  };

  const handleReservationToggle = () => {
    setIsReservation(!isReservation);
    if (!isReservation) {
      setReservationDate("");
    }
  };

  const handleDateChange = (e) => {
    setReservationDate(e.target.value);
  };

  const calculateMinDate = () => {
    // 한국 시간 기준으로 8시30분 이전이면 오늘, 이후면 내일을 min 으로 설정
    const nowInKorea = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Seoul" }));
    const koreaHours = nowInKorea.getHours();
    const koreaMinutes = nowInKorea.getMinutes();

    // 오전 8시 30분 이후면 내일 날짜로 설정
    if (koreaHours * 100 + koreaMinutes >= 830) {
      nowInKorea.setDate(nowInKorea.getDate() + 1);
    }

    // YYYY-MM-DD 형식으로 반환
    return nowInKorea.toISOString().split('T')[0];
  };

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">설문 링크 발송 <span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content height_auto">
            <div>
              <p>선택한 {participants.length}명의 참여자에게 설문 링크를 발송합니다.</p>
              <p>설문지에 지정된 참여 그룹에 속해있지 않은 사용자에게는</p>
              <p>링크가 발송되지 않습니다.</p>
              <p>설문 링크를 발송할 설문지를 선택해주세요.</p>
            </div>
            <div className="font_14">
              <p className="fontw_700">[수신대상]</p>
              <p>{getRecipientString()}</p>
            </div>
            <div className="selectBox_wrap">
              <div className="selectBox large full">
                <span className="label" onClick={onClickSelect}>
                  {selectedSurvey?.name || "설문지를 선택해주세요"}
                </span>
                <ul className="optionList">
                  {surveys.map((survey) => (
                    <li
                      key={survey.id}
                      className="optionItem"
                      onClick={() => onChangeSurvey(survey)}
                    >
                      {survey.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="switch_wrap">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isReservation}
                  onChange={handleReservationToggle}
                />
                <span className="slider round"></span>
              </label>
              <span style={{marginLeft: "8px"}}>예약 발송을 요청합니다.</span>
            </div>
            {isReservation && (
              <div>
                <p className="font_16" style={{marginTop: "-8px", marginBottom: "8px"}}>
                  날짜 선택 - 시간은 오전 9시로 고정되어 있습니다.
                </p>
                <div className="reservation_date">
                  <input
                    type="date"
                    value={reservationDate}
                    onChange={handleDateChange}
                    min={calculateMinDate()}
                  />
                  {reservationDate && (
                    <p className="font_14" style={{marginTop: "8px"}}>
                      선택한 날짜: {reservationDate}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="primary large" onClick={onClickSend}>
                  발송 요청
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendSurveyLinkPopup;