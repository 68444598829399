import React, { useEffect } from 'react'
import { useApi } from '../../context/ApiContext'
import { useNavigate, useLocation } from 'react-router'

function SignupComplete() {

  let navigate = useNavigate();
  const location = useLocation()

  useEffect(_ => {

    if (!location.pathname.includes("markup") && location.state?.email == null || location.state?.accesstoken == null) {
      alert('잘못된 접근입니다.')
      navigate(-1)
    }
    
  }, []);

  const onClickStart = async _ => {
    navigate('/dashboard')
  }

  return (
    <div id="join_step2" className="body">
      <div  className="login join join_ok">
        <div className="inwrap">
          <div>
            <ul>
              <li>
                <i className="icon-logo"></i>
                <h4>🎉 리서치플로우에 가입해 주셔서 감사합니다!</h4>
                <p>
                  여러분의 다양한 프로젝트를 유연하게 진행할 수 있도록 <br/>
                  최선을 다하는 파트너, 리서치플로우가 되겠습니다.
                </p>                
                <img src="../../../assets/images/signin_1.png" alt=""/>
                <div className="but_wrap">
                  <button type="button" className="primary large" onClick={onClickStart}>시작하기</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SignupComplete