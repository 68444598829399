import { Link } from 'react-router-dom';
import ProjectHeader from '../inc/ProjectHeader';
import NewProjectPopup from '../popup/NewProjectPopup';
import ProjectHomePopup from '../popup/ProjectHomePopup';
import SurveySharePopup from '../popup/SurveySharePopup';
import ParticipantPopup from '../popup/participantPopup';
import ParticipantPopup2 from '../popup/participantPopup2';
import ParticipantPopup3 from '../popup/participantPopup3';
import ParticipantPopup4  from '../popup/participantPopup4';
import ParticipantPopup5  from '../popup/participantPopup5';
import ParticipantPopup6  from '../popup/participantPopup6';
const Project01 = props => {
  return (      
    <div className="body project participant">
      <ProjectHeader/>
      <NewProjectPopup/>
      <ProjectHomePopup/>
      <SurveySharePopup/>
      <ParticipantPopup/>
      <ParticipantPopup2/>
      <ParticipantPopup3/>
      <ParticipantPopup4/>
      <ParticipantPopup5/>
      <ParticipantPopup6/>
      <div className="container">
        <div className="inwrap">
          <div className="full gap">
          <section className="">
            <div className="search_wrap">
              <div>
                <div className="search_box">
                  <input type="text" placeholder="식별번호, 이니셜로 검색"/>
                  <button><i className="icon-search"></i></button>
                </div>

                <div className="selectBox">
                  <span className="label">참여자 그룹 : 전체   </span>
                  <ul className="optionList">
                    <li className="optionItem">전체</li>
                    <li className="optionItem">그룹 A (시험군)</li>
                    <li className="optionItem">그룹 B (대조군)</li>
                  </ul>
                  </div>

              </div>
              {/* <button className="primary large new_project_but"> <i>+</i> 새 프로젝트</button> */}
            </div>  
          </section>

          <section className="">
            <div className="search_wrap">
              <div>
                <ul className="tab">
                  <li className="active">전체</li>
                  <li>내 기관</li>
                </ul>

              </div>
              <button className="primary large ParticipantAdd-btn"> <i>+</i> 참여자 등록</button>
            </div>  
            <div className="section_in">
              <table className="line">
                <colgroup>
                  <col className="no"/>
                  <col />
                  <col className=" "/>
                  <col className="date"/>
                  <col className="date"/>
                  <col className="date"/>
                  <col className="col100"/>
                </colgroup>
                <thead>
                  <tr>
                    <th className="checkbox">
                      <label>
                        <input type="checkbox" name="" value=""  /><span></span>
                      </label>
                    </th>
                    <th>소속기관</th>
                    <th>시험대상자 식별번호</th>
                    <th>이니셜</th>
                    <th>참여자 그룹</th>
                    <th>서면 동의일</th>
                    <th>상태</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="checkbox">
                      <label>
                        <input type="checkbox" name="" value="" /><span></span>
                      </label>
                    </td>
                    <td><a href="participantView">세브란스병원 </a></td>
                    <td className='c_primary_6'>mY123</td>
                    <td>KSY</td>
                    <td>그룹 A (시험군)</td>
                    <td><button className="text_only agreeUp-btn" >업로드</button></td>
                    <td>
                      <div className="selectBox select_inline"><span className="label">대기</span>
                        <ul className="optionList">
                          <li className="optionItem">대기</li>
                          <li className="optionItem">진행</li>
                          <li className="optionItem">중단</li>
                          <li className="optionItem">탈퇴</li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="checkbox">
                      <label>
                        <input type="checkbox" name="" value="email" /><span></span>
                      </label>
                    </td>
                    <td><a href="participantView">강남세브란스병원 </a></td>
                    <td className='c_primary_6'>mY123</td>
                    <td>KSY</td>
                    <td>그룹 A (시험군)</td>
                    <td ><button className="text_only agreeView-btn" >2023.04.03.</button></td>
                    <td>
                      <div className="selectBox select_inline"><span className="label">대기</span>
                        <ul className="optionList">
                          <li className="optionItem">대기</li>
                          <li className="optionItem">진행</li>
                          <li className="optionItem">중단</li>
                          <li className="optionItem">탈퇴</li>
                        </ul>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>

              <div className='flex_between'>
                <div>
                  <button className="secondary gray Participant-btn">참여자 그룹 변경</button>
                  <button className="secondary gray m_l_8 Participant-btn2">상태 변경</button>
                </div>
                <div>
                  <div className="pagination">
                    1-10 of 100 items
                    <ul>
                      <li><a href=""><i className="icon-left"></i></a></li>
                      <li><a href="">1</a></li>
                      <li><i className="icon-dot-menu"></i></li>
                      <li><a href="">4</a></li>
                      <li><a href="">5</a></li>
                      <li><a className="active" href="">6</a></li>
                      <li><a href="">7</a></li>
                      <li><a href="">8</a></li>
                      <li><i className="icon-dot-menu"></i></li>
                      <li><a href="">50</a></li>
                      <li><a href=""><i className="icon-right"></i></a></li>
                      <li><a href="">10/page </a></li>
                    </ul>
                  </div>
                </div>

              </div>
              
            </div>
          </section>
         </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default Project01;