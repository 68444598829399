import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const SaveMyScalePopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const {projectData, section, scaleName} = props

  const api = useApi()  
  const {alert} = useCommonPopup()

  const [name, setName] = useState(scaleName)
  const [error, setError] = useState("")
  const nameInputRef = useRef()

  useEffect(()=> {
    
    setTimeout(() => { 
      nameInputRef.current?.focus()
    }, 100)

    return () => {
    }
  }, []);


  const onChangeName = (e) => {

    const value = e.target.value
    // if (value.length > 30) {
    //   setError("이름은 최대 30자를 넘을 수 없습니다.")
    // } else {
      setName(value)
      // setError("")
    // }
  }

  const onKeyEventName = (e) => {
  
    if (e.key === 'Enter') {
      if (name.length <= 30) {
        onClickOK()
      }
    }
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickOK = async () => {

    const result = await api.request(`/sections/${section.idsection}/save_custom_scale/`, {
      method: "post",
      params: { name }
    })
    if (result?.status !== 201) {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap common_pop medium">
        <h5 className="section_tit">내 척도에 저장<span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content">    
            <h5>선택한 섹션의 설문 목록을 내 척도에 저장합니다. 왼쪽 하단의 [척도 불러오기] 옵션을 통해 언제든지 불러올 수 있습니다.</h5>
            <div>
              <label htmlFor="project_name">척도 이름</label>
              <input type="text" 
                className="full big"
                id="project_name" 
                ref={nameInputRef}
                placeholder="이름을 입력해 주세요" 
                value={name}
                onChange={onChangeName}
                onKeyUp={onKeyEventName}   
              />
              <p className="tip">{error}</p>
            </div>                
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className=" primary large " onClick={onClickOK}>저장</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );  
}
 export default SaveMyScalePopup;