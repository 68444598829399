import { useNavigate } from "react-router-dom"
import { useApi } from "../../context/ApiContext"
import { useAuth } from "../../context/AuthContext"
import { useCommonPopup } from "../../context/CommonPopupContext"
import { useEffect } from "react"
import { initPopupMenu } from "../../common/javascriptHelper"
import { profileImageUrl } from "../../common/imageHelper"
import PasswordConfirmPopup from "../user/popup/PasswordConfirmPopup"

const UserName = () => {

  const navigate = useNavigate()
  const auth = useAuth()
  const api = useApi()
  const {popup, confirm} = useCommonPopup()

  useEffect(_ => {

    initPopupMenu()

  }, [auth.adminInfo])

  const onClickUserInfo = async() => {

    if (await popup(
      <PasswordConfirmPopup/>, {
        // container: "project survey",
        name: "PasswordConfirmPopup",
        // style: "survey_send",
        useContainer: false,
      }) !== true ) return

    navigate("/userinfo")
  }

  const onClickCS= async () => {

    navigate("/cs")
  }

  const onClickLogout = async () => {

    if (await confirm("정말 로그아웃 하시겠습니까?<br/>자동 로그인을 설정한 경우 해제됩니다.", {title: "로그아웃"})) {
      auth.logout()
      navigate("/signin")
    }
  }


  return (
    <div className="info_wrap popmenu">
        <p className="dash_profile">
        {!!auth.adminInfo?.profileimage ? 
          <img src={profileImageUrl(auth.adminInfo?.profileimage, "small")} style={{width:"24px", height: "24px", borderRadius:"100%"}}/> 
          : <span>{auth.adminInfo?.name[0]}</span>}
        {auth.adminInfo?.name}        
        </p>
        <ul className="menu_pop">
        <li className="disabled">{auth.adminInfo?.affiliation}</li>
        <li><a onClick={onClickCS}>고객센터</a></li>
        <li><a onClick={onClickUserInfo}>계정정보 수정</a></li>
            <li><a onClick={onClickLogout}>로그아웃</a></li> 
            {/* <li><a onClick={onClickDeleteAdmin}>회원탈퇴</a></li>  */}
        </ul>
    </div>
  )
}  
export default UserName