import { useEffect, useRef, useState } from 'react';
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { markKeyword } from '../../../common/stringHelper';
import toast from 'react-hot-toast';

const MyScalePopup = props => {

  const {popupData, onCancel, onConfirm} = props

  const api = useApi()
  const { popup, alert } = useCommonPopup()

  const [customScaleList, setCustomScaleList] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [enableDelete, setEnableDelete] = useState(false)

  useEffect(_ => {

    getCustomScaleList()

  }, [])

  useEffect(_ => {
    
    setSelectAll(customScaleList?.filter(element => element.selected === false)?.length === 0)     
    setEnableDelete(checkDelete())

  }, [customScaleList])

  /*=========================================================
  *  Data 
  =========================================================*/
  
  const getCustomScaleList = async () => {

    const result = await api.post("customScaleList", {})
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else { 
      var list = result.data.data.scales 
      list.forEach((data, index) => {
        data["selected"] = false
        data["display_name"] = data.name
      }); 
      setCustomScaleList(list)   
      if (list.length == 0) {
        setEditMode(false)
      }
    }
  }

  /*=========================================================
   *  Handler 
   =========================================================*/

  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }
  
  const onClickEditMode = (editMode) => {
    setEditMode(editMode)
  }

  const onSelectAllScale = (value) => {

    setSelectAll(value)
    setCustomScaleList(customScaleList.map((element) => 
      ({
        ...element,
        ["selected"] : value,
      })
    ))
  }

  const onSelectScale = (item, value) => {

    setCustomScaleList(customScaleList.map((element) => 
      item === element ? {
        ...element,
        ["selected"] : value,
      } : element)
    )
  }

  const checkDelete = () => {
    const list = customScaleList?.filter((element) => element.selected === true)
    return list?.length > 0
  }

  const onClickDelete = async () => {
    
    const list = customScaleList.filter((element) => element.selected === true)
    if (list?.length == 0) {
      alert("삭제할 척도를 선택해 주세요.")
      return
    }

    const result = await api.post("deleteCustomScale", {
      customscales: list.map((element) => (element.idcustomscale))
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else { 
      toast.success('삭제되었습니다.')
      getCustomScaleList()
    }
  }
  return (
    <div>
      <div className={`popup MyScalePopup_pop MyScalePopup`}>
        <div className="popup_wrap">
          <div className={`popup_inwrap survey_scale`}>            
            <div>
              <h5 className="section_tit">내 척도 보기&nbsp;<span className="close_btn" onClick={onClickClose}></span></h5>
              <form action="" onSubmit={(e) => {e.preventDefault()}}>          
                <div className="pop_content scroll">                  
                  { !!customScaleList && (
                      <>
                        { customScaleList.length > 0 ? (
                          <>
                            <h6 className="flex_between">
                              <span>내 척도({customScaleList?.length})</span>
                              {!editMode && <button className="text_only" onClick={() => onClickEditMode(true)}>편집</button>}
                            </h6>
                            { editMode &&  <div className="divder_up">
                                <input type="checkbox" name="" value="all" id="all" 
                                  checked={selectAll} 
                                  onChange={(e) => onSelectAllScale(e.target.checked)}/>
                                <label  htmlFor="all">전체 선택</label>
                              </div>
                            }
                            { customScaleList?.map((item, index) =>
                                <div className="divder_up" key={`scale-${index}`}>
                                  { editMode && <input type="checkbox" name="" value="all" id="check_02" 
                                    checked={item.selected} 
                                    onChange={(e)=>onSelectScale(item, e.target.checked)}/>
                                  }
                                  <label  htmlFor="check_02">
                                    {item.name}
                                    <p className="text_secondaty">
                                      <div>
                                      프로젝트 : {item.projectname} <br/>
                                      {item.savedate}
                                      </div>
                                    </p>
                                  </label>
                                </div>
                              )
                            }
                          </>

                        ) : (
                          <>
                            <div className='center'>저장된 척도가 없습니다.</div>
                          </>
                        )                          
                        }
                      </>
                    )

                  }
                </div>
                { editMode && <div className="pop_foot">
                    <div className="but_wrap">
                      <div>
                        <button type="button" className="secondary gray  pop_close" onClick={() => onClickEditMode(false)}>취소</button>
                      </div>
                      <div>
                        <button type="button" className={`primary  ${enableDelete ? '' : 'disabled'}`} 
                          disabled={!enableDelete}
                          onClick={onClickDelete}>삭제</button>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>      
  )
}
export default MyScalePopup;