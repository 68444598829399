import React, { useState, useEffect } from 'react';
import { handleSelect } from '../common/javascriptHelper';

// 공통 페이지네이션 component
const Pagination = props => {

    const {totalCount, currentPage, pageSize, changeCurrentPage, changePageLimit} = props;
    const pageGroupSize = 5
    const [pagingData, setPagingData] = useState(null);

    useEffect(_ => {
        if (totalCount && currentPage != null) {
            const totalPage = Math.ceil(totalCount / pageSize);
            const halfSize = Math.floor(pageGroupSize / 2)
            var startPage = 1, endPage = 1

            if (currentPage <= halfSize ) {
              startPage = 1;
              endPage =  Math.min(currentPage + (pageGroupSize - (currentPage - startPage)), totalPage)
            } else if (currentPage > totalPage - halfSize) {
              endPage =  totalPage
              startPage = Math.max(1, currentPage - (pageGroupSize - (totalPage - currentPage + 1)))
            } else {
              startPage = Math.max(1, currentPage - halfSize + 1);
              endPage =  Math.min(totalPage, currentPage + halfSize)
            }
            const startIndex = (currentPage - 1) * pageSize + 1
            const endIndex = Math.min(currentPage * pageSize, totalCount)

            setPagingData({ totalPage, startPage, endPage, startIndex, endIndex })
        }
    }, [totalCount, currentPage, pageSize])

    const onClickPrev = (e) => {
      if (currentPage > 1) {
        changeCurrentPage(currentPage - 1)
      }
    }

    const onClickNext = (e) => {
      if (currentPage < pagingData.totalPage) {
        changeCurrentPage(currentPage + 1)
      }
    }

    const onClickPage = (page) => {

      changeCurrentPage(page);
    }

    const onClickSelect = (e) => {
      handleSelect(e)
    }

    const onSelectCount = (limit) => {

      changePageLimit(limit)
    }

    return (
      pagingData && currentPage !== null && pagingData.totalPage > 1 ? (
        <div className="pagination">
          {pagingData.startIndex}-{pagingData.endIndex} of {totalCount} items
          <ul>
            <li><a onClick={onClickPrev}><i className="icon-left"></i></a></li>
            { pagingData.startPage > 1 && <li><a
              className={currentPage == 1 ? "active" : ""} onClick={()=>onClickPage(1)}>1</a></li>
            }
            { pagingData.startPage > 2 && <li><i className="icon-dot-menu"></i></li>}
            {
              [...Array(pagingData.endPage - pagingData.startPage + 1)].map((x, i) =>
                <li key={`pagination-${i}`}><a className={currentPage == pagingData.startPage + i ? "active" : ""}
                onClick={()=>onClickPage(pagingData.startPage + i)}>{pagingData.startPage + i}</a></li>
              )
            }
            { pagingData.endPage < pagingData.totalPage - 1 && <li><i className="icon-dot-menu"></i></li>}
            { pagingData.endPage < pagingData.totalPage &&
              <li><a className={currentPage == pagingData.totalPage ? "active" : ""}
              onClick={()=>onClickPage(pagingData.totalPage)}>{pagingData.totalPage}</a></li>
            }
            <li><a onClick={onClickNext}><i className="icon-right"></i></a></li>
            { !!changePageLimit &&
              <li>
                <div className="selectBox">
                  <span className="label" onClick={onClickSelect}>{pageSize}/page</span>
                  <ul className="optionList">
                    <li className="optionItem" onClick={()=>onSelectCount(10)}>10/page</li>
                    <li className="optionItem" onClick={()=>onSelectCount(20)}>20/page</li>
                    <li className="optionItem" onClick={()=>onSelectCount(50)}>50/page</li>
                    <li className="optionItem" onClick={()=>onSelectCount(100)}>100/page</li>
                  </ul>
                </div>
              </li>
            }
          </ul>
        </div>
    ) : (
      <div className="pagination"></div>
    ))
};

export default Pagination;
