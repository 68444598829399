import $ from 'jquery';
import { useEffect, useRef } from "react";

const CommonPopup = props => {

  const {type, name, message, popupData, onConfirm, onCancel} = props
  const {immediate, title, style, confirm, cancel, hasFooter} = props.popupData.option

  const focusRef = useRef()

  useEffect(()=> {

    if (immediate) $(`.${name}_pop, .${name}_pop ${name}`).show();
    else $(`.${name}_pop, .${name}_pop ${name}`).fadeIn();

    document.body.classList.add("pop")

    setTimeout(() => { 
      focusRef.current?.focus()
    }, 100)

    return () => {
      document.body.classList.remove("pop")
    }
  }, []);


  const onClickClose = async() => {
      
    if (!!onCancel) {
      if (await onCancel(popupData, false) == false) {
        return
      }
    }
  }

  const onClickConfirm = async () => {
    
    if (!!onConfirm) {
      if (await onConfirm(popupData, true) == false) {
        return
      }
    }
  }  

  const onSubmit = async (e) => {
    e.preventDefault()
    if (popupData.type === "alert" && !!onConfirm) {
      if (await onConfirm(popupData, true) == false) {
        return
      }
    }
  }

  return (
    <div className={`popup ${name}_pop ${name} `} >
      <form onSubmit={onSubmit}>    
        <input type="check" style={{position:"absolute", width: "0", height:"0", overflow: "hidden"}} ref={focusRef}/>
      </form>
      <div className="popup_wrap">
        <div className={`popup_inwrap common_pop ${style?? ""}`}>            
          <div>
            {title?.length > 0 && <h5 className="section_tit">{title}&nbsp;<span className="close_btn" onClick={onClickClose}></span></h5>}
            <form action="" onSubmit={(e) => {e.preventDefault()}}>          
              <div className="pop_content">
                <h5 style={{whiteSpace:"pre-line"}} dangerouslySetInnerHTML={ {__html: message} }></h5>
              </div>
              { hasFooter !== false && <div className="pop_foot">
                  <div className="but_wrap">
                    <div>
                      {!!cancel && <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>{cancel?? "취소"}</button>}
                    </div>
                    <div>
                      {!!confirm && <button type="button" className=" primary large " onClick={onClickConfirm}>{confirm?? "확인"}</button>}
                    </div>
                  </div>
                </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  );  
}
export default CommonPopup;