import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { initPopupMenu } from '../../../common/javascriptHelper';
import { profileImageUrl } from '../../../common/imageHelper';
import { useAuth } from '../../../context/AuthContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const MemoThreadPopup = props => {
  
  const {projectId, surveyId, sectionId, questionId, answerId, participantId, affiliation, memothread, showAnswer, isPopup, onClosed, onClosedMemo} = props
  const api = useApi()
  const auth = useAuth()
  const {confirm, alert} = useCommonPopup()
  const [memoInput, setMemoInput] = useState("")
  
  const [memoThread, setMemoThread] = useState()
  const [memoList, setMemoList] = useState()
  const [editMemo, setEditMemo] = useState()
  const memoInputRef = useRef()  
  const editInputRef = useRef()
  const memoThreadId = useRef(memothread)
  const isLock = useRef(false)
  const scrollRef = useRef();

  useEffect(()=> {

    if (isPopup) $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').fadeIn();

    initPopupMenu()

    if (!!memoThreadId.current) {
      getMemoThread()
    } else {
      if (isPopup === true) {
        setTimeout(() => {
          memoInputRef.current?.focus()
        }, 200)
      }
    }

    return ()=>{ 
      if (isPopup) $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').hide();
    }

  }, []);

  useEffect(() => {

    initPopupMenu()
  }, [memoList])

  useEffect(() => {

    setInputSize(memoInputRef.current)

  }, [memoInput])
  
  useEffect(() => {

    setInputSize(editInputRef.current)

  }, [editMemo])

  const onClickClose = () => {

    if (isPopup === false) return

    $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').fadeOut().promise().done(function() {
      if (!!onClosed) onClosed()
    });
  }

  const getMemoThread = async () => {

    const result = await api.post("getMemoThread", {
      memothread : memoThreadId.current
    })

    if (result?.data?.result !== "ok") {
      if (result?.data?.code === 383) {
        // await alert("메모가 종료되었습니다.")
      } else {
        // await api.networkError(result, alert)
      }
      if (!!onClosedMemo) {
        onClosedMemo(memothread)
      } else {
        onClickClose()
      }
    } else {       
      setMemoThread(result.data.data.memothread)    
      setMemoList(result.data.data.memothread.memos)
      memoInputRef.current?.focus()
      
      setTimeout(() => {
        if (!!scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      }, 100)
    }
  }

  const createMemoThread = async () => {

    if (!surveyId) return

    const result = await api.post("createMemoThread", {
      survey: surveyId,
      answer : answerId,
      section : sectionId,
      question : questionId,
      content : memoInput
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {   
      memoThreadId.current = result.data.data.memothread
      getMemoThread()      
    }
  } 

  const updateMemoStatus = async (fixed) => {
    
    const result = await api.post("updateMemoStatus", {
      memothread : memoThreadId.current,
      fixed : fixed,
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {   
      getMemoThread()
    }
  }

  const createMemo = async () => {

    const result = await api.post("createMemo", {
      memothread : memoThreadId.current,
      content : memoInput
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {   
      getMemoThread()
    }
  }

  const updateMemo = async () => {

    const result = await api.post("updateMemo", {
      memo : editMemo.idmemo,
      content : editMemo.content
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {   
      getMemoThread()
    }
  }

  const deleteMemo = async (memo) => {

    const result = await api.post("deleteMemo", {
      memo : memo.idmemo,
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {   
      getMemoThread()
    }
  }

  const setInputSize = (e) => {
    
    if (!e) return
   
    e.style.height = 0;
    e.style.height = 2 + Math.min(22 * 5, Math.max(30, e.scrollHeight))  + 'px';
  }

  const onChangeInput = (e) => {

    var value = e.target.value  
    setMemoInput(value)  
  }

  const onKeyUp = (e) => {

    if (e.key === 'Enter') {      
      if (e.altKey === true) {
        setMemoInput(`${memoInput}\r\n`)
      } else {
        e.preventDefault()
        onClickSend()
      }
    } 
  }

  const onKeyDown = (e) => {

    if (e.key === 'Enter' && e.altKeyt !== true) {
      e.preventDefault()
    }   
  }

  const onChangeEdit = (e) => {

    var value = e.target.value   
    setEditMemo({
      ...editMemo,
      content: value
    })
  }

 const onKeyUpEdit = async (e) => {
    
    if (e.key === 'Enter') {
      if (e.altKey === true) {
        setEditMemo({
          ...editMemo,
          content: `${editMemo.content}\n`
        })
      } else {
        e.preventDefault()
        await updateMemo()
        setEditMemo()
      }
    }   
  }

  const onKeyDownEdit = (e) => {
    
    if (e.key === 'Enter' && e.altKeyt !== true) {
      e.preventDefault()
    }  
  }
 
  const onClickSend = async (e) => {
    
    if (memoInput?.length == 0) return
    if (isLock.current) return 
    isLock.current = true

    if (!!editMemo) {
      await updateMemo(editMemo)
      setEditMemo()
      setMemoInput("")
    } else if (!!memoThreadId.current) {
      await createMemo()
      setMemoInput("")
    } else {
      await createMemoThread()
      setMemoInput("")
    }
    isLock.current = false
  }

  const onClickEdit = async (memo) => {

    setEditMemo(memo)
    setTimeout(() => {
      editInputRef.current?.focus()
    }, 200)
  }

  const onClickDeleteMemo = async (memo) => {

    if (await confirm("이 메모를 삭제하시겠습니까?", {title: "메모 삭제"}) === false) return
    deleteMemo(memo)
  }


  const onClickFixed = async (e) => {

    await updateMemoStatus(1)
    onClickClose()
  }

  const onClickUnfixed = async (e) => {
    await updateMemoStatus(0)
    // onClickClose()
  }

  const onClickShowAnswer = async () => {

    if (!!participantId) {
      window.open(`/project/${projectId}/survey/${memoThread.survey}/answer/individual?participant=${participantId}&answer=${memoThread?.answer}&affiliation=${affiliation?? "MY"}`)  
    }  else {
      window.open(`/project/${projectId}/survey/${memoThread.survey}/answer/individual`)  
    }
  }

  return (
    <>
      <div className="popup MemoPopup" >
      <div  className="tit ">
            <h5>{!memoThreadId.current ? "새 메모" : memoThread?.fixed === 1 ? "해결됨" : memoThread?.memos?.length > 0 ? `메모 (${memoThread?.memos?.length})` : "메모"}</h5>
            { !!memoThread &&
              <div>
                {showAnswer && <span className='c_primary_6' onClick={() => {onClickShowAnswer()}}>응답 보기</span>} 
                <div className="dot_menu popmenu">
                  <i className="icon-dot-menu"></i>
                  <ul className="menu_pop" >
                  { memoThread.fixed === 1 ? <li onClick={onClickUnfixed}>복원</li>: <li onClick={onClickFixed}>해결</li> }
                  </ul>
                </div>
              </div>
            }              
          </div>
        <ul className={isPopup ? "scroll" : "warp"} ref={scrollRef}>
          { memoList?.map((memo, index) =>
            <li key={`memo-${index}`}>
              <span className='profile'>{!!memo.profileimage ? <img src={profileImageUrl(memo.profileimage, "small")}/> : `${memo.name?.[0]}`}</span>
              <div className='text'>
                <p className='name'>{memo.name} <span>{memo.createdate}</span></p>
                <div className='flex  '>
                  { editMemo?.idmemo === memo.idmemo ? 
                    <div className="search_box full">
                      <textarea className="full solo" 
                          id="textarea_auto" 
                          placeholder="" 
                          rows={1}
                          ref={editInputRef}
                          value={editMemo.content}
                          onChange={onChangeEdit}
                          onKeyUp={onKeyUpEdit}
                          onKeyDown={onKeyDownEdit}
                        />
                    </div> : <p style={{whiteSpace: "pre-line"}}>{memo.content}</p>
                  }
                  { memoThread?.fixed !== 1 && memo?.admin === auth.adminInfo?.idadmin &&
                    <div> 
                      <i className="icon-edit" onClick={() => {onClickEdit(memo)}}></i>
                      <span className='popmenu'>
                        <i className="icon-dot-menu"></i>
                        <ul className="menu_pop" >
                          <li onClick={() => { onClickDeleteMemo(memo) }}>삭제</li>
                        </ul>
                      </span>
                    </div>
                  }
                </div>
              </div>
            </li>
          )}      
          {/* <li className='footer'>
            <span className='profile'>{!!auth.adminInfo?.profileimage ? <img src={profileImageUrl(auth.adminInfo?.profileimage, "small")}/> : auth.adminInfo?.name[0]}</span>
            <div className="search_box full">
              <textarea className="full solo" 
                id="textarea_auto" 
                placeholder="회신" 
                rows={1}
                ref={memoInputRef}
                value={memoInput}
                onChange={onChangeInput}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                disabled={memoThread?.fixed === 1}
              />
              <button><i className="icon-memo_send" onClick={onClickSend}></i></button>
            </div>
          </li> */}
        </ul>
        <div className='footer'>
            <span className='profile'>{!!auth.adminInfo?.profileimage ? <img src={profileImageUrl(auth.adminInfo?.profileimage, "small")}/> : auth.adminInfo?.name[0]}</span>
            <div className="search_box full">
              <textarea className="full solo" 
                id="textarea_auto" 
                placeholder={!memoThreadId.current ? "새 메모 작성" : "회신"}
                rows={1}
                ref={memoInputRef}
                value={memoInput}
                onChange={onChangeInput}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                disabled={memoThread?.fixed === 1}
              />
              <button className={memoInput.length == 0 ? "disabled" : ""}>
                <i className={memoInput.length == 0 ? "icon-memo_send" : "icon-send-filled"} onClick={onClickSend}></i>
              </button>
            </div>
          </div>
      </div>
      {isPopup && <div className="dim_bg" style={{width:"100%", height:"100%"}} onClick={onClickClose}></div>}
    </>
  );  
}
 export default MemoThreadPopup;