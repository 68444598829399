import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';
import CSHeader from './CSHeader';

const CSFAQ = props => {
  
  const navigate = useNavigate()  
  const api = useApi()
  const {popup, confirm, alert} = useCommonPopup()
  const [faqList, setFaqList] = useState()

  /*=========================================================
   *  useEffect 
   =========================================================*/
  
  useEffect(_ => {
  
    getFaqList()

  }, [])

  /*=========================================================
   *  Data 
   =========================================================*/
   const getFaqList = async () => {

    const result = await api.post("getFaqList", {})    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      // setTotalCount(result.data.data.totalCount)
      setFaqList(result.data.data.faqcategory)
    }
  }

  /*=========================================================
   *  Handler 
   =========================================================*/
   const onClickNotice= async () => {
    navigate("/cs/notice")
  }

  const onClickInquiry= async () => {
    navigate("/cs/inquiry")
  }

  const onClickFAQ= async () => {
    navigate("/cs/faq")
  }

  /*=========================================================
   *  Render 
   =========================================================*/

  const FaqItem = (props) => {

    const {item, index} = props
    const [open, setOpen] = useState(false)
    const [faqInfo, setFaqInfo] = useState()

    const toggleItem = () => {

      if (!faqInfo) {
        getFaqInfo()
      } 
      setOpen(!open)
    }

    const getFaqInfo = async () => {

      const result = await api.post("getFaqInfo", {faq: item.idfaq})    
  
      if (result?.data?.result != "ok") {
        api.networkError(result, alert)
      } else {
        setFaqInfo(result.data.data.faq)
      }
    }

    const likeFaq = async () => {

      if (!faqInfo) return 
      
      const result = await api.post(faqInfo.mylike === 1 ? "unlikeFaq" : "likeFaq", {faq: item.idfaq})    
  
      if (result?.data?.result != "ok") {
        api.networkError(result, alert)
      } else {
        getFaqInfo()
      }
    }
    
    return (
      <>
        <dl className={`${open ? 'open' : ''}`}>
          <dt onClick={toggleItem}>{item.title}</dt>
          <dd >
            {faqInfo?.content?? ''}
            {!!faqInfo && <p className={`like ${faqInfo?.mylike === 1 ? 'likeon' : ''}`} onClick={likeFaq}>
                {faqInfo?.likecount?? 0} 
              </p>
            }
          </dd>
        </dl>
      </>
    )
  }

  const FaqCategory = (props) => {

    const {category, index} = props
    return (
      <>
        <h5 className='fontw_600'>
          {category.category}
        </h5>
        {
          category?.faqs?.length > 0 ? (
            category?.faqs?.map((item, itemIndex) => <FaqItem key={`faq-item-${index}-${itemIndex}`} item={item} index={itemIndex}/>)
          ) : (
            <>
              등록된 질문이 없습니다.
            </>
          )
        }
        {/* <dl className='open'>
          <dt>섹션과 문항의 차이는 무엇인가요?</dt>
          <dd >
            섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
            <p className='like'>
              999 
            </p>
          </dd>
        </dl>
        <dl className='open'>
          <dt>섹션과 문항의 차이는 무엇인가요?</dt>
          <dd>섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
            <p className='like likeon '>
              999 
            </p>
          </dd>
        </dl> */}
        <div className='paddingTB'>
        </div>
      </>
    )
  }
  
  return (   
    <div className="body project cs">
      <CSHeader/>
      <div className="container">
        <div className="inwrap">
          <div className={`full gap`}>         
            <div className="csboard_wrap">
              <div className='menu_list'>              
                <button onClick={onClickNotice}>공지사항</button>            
                <button onClick={onClickFAQ} className="active">자주 묻는 질문</button>
                <button onClick={onClickInquiry}>1:1문의</button>
              </div>
              <div className='board_con faq'>
                <div>
                  {
                    faqList?.length > 0 ? (
                      faqList?.map((category, index) => <FaqCategory key={`faq-category-${index}`} category={category} index={index}/>)
                    ) : (
                      <>
                        등록된 질문이 없습니다.
                      </>
                    )
                  }
                </div>
                {/* <div>                 
                  <h5 className='fontw_600'>
                    설문 작성하기
                  </h5>
                  <dl className='open'>
                    <dt>섹션과 문항의 차이는 무엇인가요?</dt>
                    <dd >
                      섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
                      <p className='like'>
                       999 
                      </p>
                    </dd>
                  </dl>
                  <dl className='open'>
                    <dt>섹션과 문항의 차이는 무엇인가요?</dt>
                    <dd>섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
                      <p className='like likeon '>
                        999 
                      </p>
                    </dd>
                  </dl>
                  <div className='paddingTB'>
                  </div>
                  <h5 className='fontw_600'>
                    응답 수집하기
                  </h5> 
                  <dl>
                    <dt>섹션과 문항의 차이는 무엇인가요?</dt>
                    <dd>
                      섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
                      <p className='like'>
                        999 
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>섹션과 문항의 차이는 무엇인가요?</dt>
                    <dd>섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
                      <p className='like'>
                        999 
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>섹션과 문항의 차이는 무엇인가요?</dt>
                    <dd>섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
                      <p className='like'>
                        999 
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>섹션과 문항의 차이는 무엇인가요?</dt>
                    <dd>섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
                      <p className='like'>
                        999 
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>섹션과 문항의 차이는 무엇인가요?</dt>
                    <dd>섹션은 설문을 하나의 단위로 묶어서 제공하는 것입니다. 여러 종류의 설문을 한 번에 실시하는 경우, 사용자에게 다음 설문이 무엇인지 설명하는 용도로 활용해보세요. 연구 목적으로 사용되는 설문 중 한 종류의 질문 척도를 섹션으로 묶어 제공하면, 사용자에게 질문의 의도를 공통적으로 전달하고 응답을 얻을 수 있습니다.
                      <p className='like'>
                        999 
                      </p>
                    </dd>
                  </dl>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default CSFAQ;