import React, { createContext, useEffect, useState, useMemo, useContext } from 'react'
import { useApi } from './ApiContext'

var AuthContext = createContext();

export function AuthContextProvider({ children }) {
   
  const api = useApi()  
  const [adminInfo, setAdminInfo] = useState(null)
  const [isCheckAuth, setIsCheckAuth] = useState(false)

  const isAutoSignin = () => {

    return api.getAutoSignin()
  }

  const isAuth = async () => {

    console.log(`isAuth isCheckAuth = ${isCheckAuth}`)
    if (isCheckAuth) return !!adminInfo
    
    let isSignin = false
    let accesstoken = api.getAccessToken();    
    
    console.log(`isAuth accesstoken = ${accesstoken}`)

    if (!!accesstoken) {
      console.log(`accesstoken = ${accesstoken}`)
      let result = await getAdminInfo()
      setAdminInfo(result)
      isSignin = !!result
    }     
    setIsCheckAuth(true)

    return isSignin
  }
  
  const getAdminInfo = async () => {

    const result = await api.request('/admins/me/')
    
    if (result?.status !== 200) {
      return null
    } else {
      return result.data
    }
  }

  const updateAdminInfo = async () => {

    let result = await getAdminInfo()
      setAdminInfo(result)
  }

  const logout = async () => {

    api.removeAuthToken()
    setIsCheckAuth(false)
  }

  const deleteAdmin = async () => {

    api.removeAuthToken()
    setIsCheckAuth(false)
  }

  return (
    <AuthContext.Provider
      value={{isAuth, isAutoSignin, adminInfo, setIsCheckAuth, logout, deleteAdmin, updateAdminInfo}}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext)
}