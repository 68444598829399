import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const InquiryPopup = props => {

  const {popupData, onCancel, onConfirm, inquiry} = props

  const api = useApi()  
  const {alert} = useCommonPopup()
  const [inquiryInfo, setInquiryInfo] = useState()
  const [inquiryContent, setInquiryContent] = useState()
  const [answer, setAnswer] = useState()

  // const [name, setName] = useState(projectData.name)
  // const [error, setError] = useState("")
  // const nameInputRef = useRef()

  useEffect(()=> {
    
    getInquiryInfo(inquiry.idinquiry)

    return () => {
    }
  }, []);


  const getInquiryInfo = async (idinquiry) => {
    
    const result = await api.post("getInquiryInfo", {
      inquiry: idinquiry,
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {                  
      setInquiryInfo(result.data.data.inquiry)      
      
      const list = result.data.data.inquiry.contents
      if (list.length > 0) {
        console.log(`getInquiryInfo ${list.length} ${JSON.stringify(list[list.length - 1])}`)  
        setInquiryContent(list[list.length - 1])
      }
    }
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickOK = async () => {

    const result = await api.post("answerForInquiry", {
      inquiry: inquiry.idinquiry,
      inquirycontent: inquiryContent.idinquirycontent,
      content: answer,
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }  

  const onChangeAnswer = (e) => {

    setAnswer(e.target.value)
    setInputSize(e.target)
  }

  const setInputSize = (e) => {
    
    if (!e) return
       
    e.style.height = 0;
    e.style.height = 2 + Math.min(30 * 5 + 8, Math.max(38, e.scrollHeight))  + 'px';    
  }

  const downloadFile = (inquirycontent, file) => {

    getPresignedUrlForInquiryFileDownload(inquirycontent, file.filename, file.filepath)
  }

  const getPresignedUrlForInquiryFileDownload = async (inquirycontent, filename, filepath) => {
    
    const result = await api.post("getPresignedUrlForInquiryFileDownload", {
      inquirycontent: inquirycontent,
      filename: filename,
      filepath: filepath
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {      
      console.log(`getPresignedUrlForDownload ${result.data.data.url}`)
      const link = document.createElement('a')
      link.href = result.data.data.url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap common_pop large">
        <h5 className="section_tit">문의<span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content">
            <table className='line'>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>소속 기관</th>
                  <th>문의 구분</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{inquiryInfo?.name}</td>
                  <td>{inquiryInfo?.affiliation}</td>
                  <td>{inquiryInfo?.category}</td>
                </tr>
              </tbody>
            </table> 
           <div>
              <label className='text_secondaty'>문의 제목</label>
              <p className='font_16'>{inquiryInfo?.title}</p>
            </div>  
            { inquiryInfo?.contents?.map((item, itemIndex) => 
                <div key={`inquiry-${itemIndex}`}>
                  { item.isanswer !== 1 ? ( 
                      <div >
                        <label  className='text_secondaty'>문의 내용 <span className='c_neutral_6'>{item.registerdate}</span></label>
                        <p className='font_16'>{item.content}</p>
                        <ul className='file_list'> 
                          { item.files?.map((file, fileIndex) => 
                              <li className='flex_between' key={`file-${fileIndex}`}>
                                <div>
                                  <i className='icon-attachment'></i>
                                  <span className='c_primary_6 cursor' onClick={() => downloadFile(item.idinquirycontent, file)}>{file.filename}</span>
                                </div>
                              </li>
                            )
                          }
                        </ul>
                      </div>
                    ) : (
                      <div >
                        <label  className='text_secondaty'>답변 <span className='c_neutral_6'>{item.registerdate} {item.name}</span></label>
                        <p className='font_16'>{item.content}</p>
                      </div>
                    )
                  }
                </div>
              )
            }
            { inquiryContent?.isanswer !== 1 ? (
                <div>
                  <label  className='text_secondaty'>새 답변 </label>
                  <textarea 
                    className={`full big solo`}
                    // ref={nameRef}
                    id="textarea_auto" 
                    placeholder="" 
                    rows={1} 
                    value={answer} 
                    onChange={onChangeAnswer}
                  />
                  {/* <textarea className='full' cols="30" rows="5" value={answer} onChange={onChangeAnswer}></textarea> */}
                </div>
              ) : (
                <div>
                  <label  className='text_secondaty'><span className='c_neutral_6'>답변 발송 후  조회만 가능</span></label>
                </div>
              )
            }
          </div>
          <div className="pop_foot">
            { inquiryContent?.isanswer !== 1 ? (
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
                  </div>
                  <div>
                    <button type="button" className={`primary large ${!answer || answer?.length == 0 ? 'disabled' : ''}`} disabled={!answer || answer?.length == 0} onClick={onClickOK}>답변 발송</button>
                  </div>
                </div>
              ) : (
                <div className="but_wrap">
                  <div>
                    <button type="button" className=" primary large " onClick={onClickClose}>확인</button>
                  </div>
                </div>
              )
            }
          </div>
        </form>
      </div>
    </div>
  );  
}
 export default InquiryPopup;