import { Outlet } from "react-router-dom";


function PublicPage() {

  return (
    <Outlet/>
  );
}
export default PublicPage;
