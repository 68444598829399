import $ from 'jquery';
import { useEffect } from "react";
import MemoPopSingle  from '../popup/memoPopSingle';
const MemoPopup = props => {

  useEffect(()=> {

    $('.memo-btn').click(function() {
      $('.MemoPopup, .MemoPopup .popup_inwrap ').fadeIn();
    });


  }, []);

  return (
 
    <div className="popup MemoPopup" >
        <div className="popup_wrap">

        {/* 메모 팝업 */}
        <div className="popup_inwrap">
          <div  className="section_tit flex_between">           
            <h5>메모 (3) - 1. 과거 병력 조사  </h5>
            <span className="close_btn"></span>
          </div>
          
          <form action="">
            <div className="pop_content">
            <MemoPopSingle/>
            <MemoPopSingle/>
            <MemoPopSingle/>
            </div>
              {/* <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">확인</button>
                  </div>
                </div>
              </div> */}
          </form>
        </div>
        </div>
      </div>

  );  
}
 export default MemoPopup;