import { Link } from 'react-router-dom';
import QuestionnairHeader from '../inc/QuestionnairHeader';

const Questionnair = props => {

  
  return (      

    <div className='question_preview mobile_view'>
      <div className="preview_header">

          <ul className="tab">
            <li className="mobileView_btn active">모바일</li>
            <li className='tabletView_btn '>태블릿</li>
          </ul>
          <div>
            <i className='icon-left'></i>
            <span>1/28</span>
            <i className='icon-right'></i>
          </div>
          <ul className="tab">
            <li className="active">그룹 A (시험군)</li>
            <li>그룹 B (대조군)</li>
          </ul>
      </div>


      <div className="question_list">
        <h4>목차 <span class="close_btn"></span></h4>
        <ul>
          <li className='active c_disabled'><h5>1. 연구 동의 및 사전 문항</h5></li>
          <li>
            <p>1-1. 이 연구는 자발적인 참여이며, 참가 여부와 무관하게 언제든지 연구에서 철수할 수 있습니다. 이에 동의하십니까?</p>
            <i className='icon-warning c_danger'></i>
            
          </li>
          <li>1-2. 우울증 진단을 받은 적이 있으신가요?

          </li>
          <li  className='active'>
            <h5>2. 한국어판 정신건강 선별도구</h5>
            <span>그룹 A (시험군) 만</span>

          </li>
          <li>2-1. 아무런 이유없이 기운이 없던 적이 얼마나 됩니까?</li>               
          <li>2-2. 신경이 예민했던 날은 얼마나 됩니까?</li>
          <li>2-3. 신경이 너무 예민해서 귀하를 안정시킬 수 있는 것이 아무 것도 없다고 느끼신 적이 얼마나 됩니까?</li>
          <li>2-4. 희망이 없다고 느끼신 적이 얼마나 됩니까?</li>
          <li>2-5. 안절부절 못하거나 조바심을 냈던 적은 얼마나 됩니까?</li>
          <li  className='active'>
            <h5>2. 한국어판 정신건강 선별도구</h5>
            <span>그룹 A (시험군) 만</span>
            
            <div>

          </div>
          </li>
          <li>2-1. 아무런 이유없이 기운이 없던 적이 얼마나 됩니까?</li>               
          <li>2-2. 신경이 예민했던 날은 얼마나 됩니까?</li>
          <li>2-3. 신경이 너무 예민해서 귀하를 안정시킬 수 있는 것이 아무 것도 없다고 느끼신 적이 얼마나 됩니까?</li>
          <li>2-4. 희망이 없다고 느끼신 적이 얼마나 됩니까?</li>
          <li>2-5. 안절부절 못하거나 조바심을 냈던 적은 얼마나 됩니까?</li>

          
        </ul>
        <div className="qlist_foot">
          <button className="text_only">문항 추가</button>
          <button className="text_only scale_view">표준척도 불러오기</button>
          <button className="text_only">섹션추가</button>
        </div>
      </div>

      


      
        <div className="body questionnair">
          <QuestionnairHeader/>

          <div className="container">

            {/* START PAGE */}
            {/* <div className="inwrap start">
              <div className='questionnair_body'>
              <div></div>
              <h3 className='c_primary_6'>1. 연구 동의 및 사전 문항</h3>
              <h4>
                본 연구는 30-40대를 대상으로 우울증 여부와 정도를 조사하는 목적으로 진행됩니다. <br/>
                총 3개의 섹션 14개의 문항으로 구성되어 있습니다.
                </h4>
                <p className='bg_gray'>
                  지난 1년동안 귀하가 가장 우울 또는 불안했거나 스트레스가 가장 많았던 한 달(月)을 떠올려 보십시오. 만약 그런 시기가 없었다면 지난 12개월 중 대표적인 달(月)을 떠올려주십시오.
                </p>
              </div>
              <div className="but_wrap">
                <button type="button" class="primary large full">시작</button>
              </div>
            </div> */}


            {/* 질문 */}
            <div className="inwrap">
              <div className='questionnair_body'>
                <p className='bg_gray'>
                  지난 1년동안 귀하가 가장 우울 또는 불안했거나 스트레스가 가장 많았던 한 달(月)을 떠올려 보십시오. 만약 그런 시기가 없었다면 지난 12개월 중 대표적인 달(月)을 떠올려주십시오.
                </p>
                <div className='bg_white'>
                <h3>2-1. 아무런 이유없이 기운이 없던 적이 얼마나 됩니까?</h3>
                <ul className='radio checkbox'>
                  <li>
                  <label>
                    <input type="radio" name="question" value="" /><span>한 달 내내</span></label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" name="question" value="" /><span>한 달 중 대부분</span>
                    </label>
                  </li>
                  <li>
                    <label><input type="checkbox" name="checkbox" value="email"/>
                    <span>이메일</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" name="question" value="" /><span>한 달 중 절반 정도</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" name="question" value="" /><span>한 달 중 며칠</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" name="question" value="" /><span>전혀 없었다</span>
                    </label>
                  </li>

                </ul>
                </div>

                <div className='bg_white '>
                  <h3>3-1. 지난 한 주 동안 자신이 얼마나 우울했는지 표시해주세요. </h3>
                  <img src="../../../assets/images/image 28.jpg" alt="" />

                  <ul className='score'>
                    <li className='tooltip active'>1 
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>3
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>4
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>2
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>5
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>6
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>7
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>8
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>9
                    <span class="tooltiptext tooltip-top">매우 아니다</span>
                    </li>
                    <li className='tooltip'>10
                    <span class="tooltiptext tooltip-top">매우 그렇다</span>
                    </li>

                  </ul>
                  <div> 
                    1점 : 매우 아니다. <br/>
                    10점: 매우 그렇다.
                  </div>
                  
                </div>

                <div className='bg_white '>
                  <h3>4.1. 스스로 우울한 원인이 무엇이라고 생각하시나요? </h3>

                  <ul className='radio'>
                  <li>
                    <label>
                      <input type="radio" name="question" value="" /><span>네</span>
                    </label>
                    <textarea className="full solo danger" id="textarea_auto" placeholder="사유" rows="1"> </textarea>
                    <p className='text_length'>0/300</p>
                  </li>
                  <li>
                    <label>
                      <input type="radio" name="question" value="" /><span>아니요 (설문을 종료합니다.)</span>
                    </label>
                  </li>
      

                </ul>

                  
                  
                </div>
                


                <div className='bg_white '>
                  <h3>4.1. 스스로 우울한 원인이 무엇이라고 생각하시나요? </h3>

                  <textarea className="full solo" id="textarea_auto" placeholder="문항" rows="1"></textarea>

                  
                  
                </div>



                <div className='bg_white '>
                  <h3>5.1. 최근 병원에 방문하셨던 날짜를 알려주세요. </h3>

                  <input  className='big' type="date" />

                  
                  
                </div>

                <div className="but_wrap">

                <button type="button" class="secondary gray large ">이전</button>
                <button type="button" class="primary large ">다음</button>

                </div>
              </div> 
            </div>

            {/* END PAGE */}
            {/* <div className="inwrap start">
              <div className='questionnair_body'>
              <div></div>
              <h3>
              👍👏🥰❤ <br/>
              설문을 완료했습니다.
              </h3>
              <h4 className=''>참여해주셔서 진심으로 감사드립니다.</h4>
              </div>
              <div className="but_wrap">
                <button type="button" class="primary large full">종료</button>
              </div>
            </div> */}




            {/* ERROR PAGE */}
            {/* <div className="inwrap start">
              <div className='questionnair_body'>
              <div></div>
              <h3>
              😵 <br/>
              설문지를 표시할 수 없습니다.
              </h3>
              <h4>이전 화면으로 돌아가 다시 시도해보세요. </h4>

              <h4>계속해서 문제가 발생한다면, contact@atflow.kr로 이메일을 보내주시면 신속하게 도움을 드리겠습니다.</h4>

              <h4>불편을 끼쳐드려 죄송합니다.</h4>
              </div>
              <div className="but_wrap">
                <button type="button" class="secondary gray large full">이전</button>
              </div>
            </div> */}






          </div>
        </div>

    </div>
    );
  }
  
  export default Questionnair;