import $ from 'jquery';
import { useEffect, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useNavigate } from 'react-router-dom';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const DeleteProjectPopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const { projectData } = props

  const api = useApi()
  const {alert} = useCommonPopup()

  const [error, setError] = useState("")
  
  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickDelete = async () => {

    const result = await api.request(`/projects/${projectData.idproject}/`, {
      method: "delete"
    })
    
    if (result?.status !== 204) {
      if (result?.data?.detail === '소유자만 삭제할 수 있습니다.') {
        setError("참여자는 프로젝트를 삭제할 수 없습니다. 진행을 원하시면 소유자에게 문의하세요.")
      } else if (result?.data?.detail) {
        setError(result?.data?.detail)
      } else {
        api.networkError(result, alert)
      }
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }   
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap common_pop medium">
        <h5 className="section_tit">프로젝트 삭제  <span className="close_btn" onClick={onClickClose}></span></h5>
         <form action="" onSubmit={(e) => {e.preventDefault()}}>
            <div className="pop_content">
              <h5>
              이 프로젝트를 삭제하시겠습니까? <br/> <br/>
              프로젝트를 삭제하면 모든 공동 작업자가 더 이상 해당 프로젝트를 볼 수 없게 되며, 프로젝트가 완전히 삭제됩니다.
              <br/><br/>
              </h5>
              <p className="tip">{error}</p>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
                </div>
                <div>                  
                  <button type="button" className=" primary large " onClick={onClickDelete}>삭제</button>                  
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
  );  
}
 export default DeleteProjectPopup;