import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../../context/ApiContext';

import { useSurvey } from '../../../context/SurveyContext';
import { useProject } from '../../../context/ProjectContext';
import MemoThreadPopup from '../popup/MemoThreadPopup';
import { QUESTION_VALUE_TYPES } from '../../../common/codeHelper';
import { handleSelect } from '../../../common/javascriptHelper';

const SurveyAnswerIndividual = props => {

  const navigate = useNavigate()

  const api = useApi()

  const project = useProject()
  const survey = useSurvey()

  const {affiliation, participant, answer} = props
  const [individualData, setIndividualData] = useState()
  const [selectedOrder, setSelectedOrder] = useState(0)
  const [selectedResponseOrder, setSelectedResponseOrder] = useState(0)
  const [answerCount, setAnswerCount] = useState(0)
  const [orderList, setOrderList] = useState([])

  useEffect(_ => {

  }, [])

  useEffect(_ => {

    if (!!affiliation) {
      getAnswerCount()
    }

  }, [affiliation])

  const getAnswerCount = async () => {

    const result = await api.request(`/surveys/${survey.surveyId}/answer_count/`, {
      params: {
        myaffiliation : affiliation === "MY"
      }
    })

    if (result?.status !== 200) {
      api.networkError(result, alert)
    } else {
      var list = []
      var count = result.data.answercount
      if (result.data.initials?.length === count) {
        for(var i=0; i<count; i++) {
          var order = count - i - 1
          var identifier = result.data.initials[order]
          var responses = result.data.responses[order][identifier]
          console.log(`${JSON.stringify(count)}`)
          list.push({
            order: order,
            identifier: identifier,
            count: responses
          });
        }
      } else {
        for(var i=0; i<count; i++) {
          var order = count - i - 1
          list.push({
            order: order,
            identifier: `${order + 1}`,
            count: 1
          });
        }
      }
      console.log(`orderList : ${JSON.stringify(list)}`)
      setOrderList(list)
      setAnswerCount(count)
      if (count > 0) {
        if(!!participant) {
          if (!!answer) showIndividualByAnswerId(participant, answer)
          else showIndividualById(participant, selectedResponseOrder)
        } else {
          setSelectedOrder(count - 1)
          showIndividualByOrder(count - 1, selectedResponseOrder)
        }
      } else {
        setIndividualData([])
      }
    }
  }

  const showIndividualByOrder = async (order, responseorder) => {

    const result = await api.request(`/surveys/${survey.surveyId}/individual/`, {
      params: {
        participantorder : order,
        myaffiliation : affiliation === "MY",
        responseorder: responseorder
      }
    })

    if (result?.status !== 200) {
      api.networkError(result, alert)
    } else {
      setSelectedOrder(result.data.participantorder)
      setSelectedResponseOrder(responseorder)
      setIndividualData(result.data)
    }
  }

  const showIndividualById = async (participant, responseorder) => {

    const result = await api.request(`/surveys/${survey.surveyId}/individual/`, {
      params: {
        participant: Number(participant),
        myaffiliation : affiliation === "MY",
        responseorder: responseorder
      }
    })

    if (result?.status !== 200) {
      api.networkError(result, alert)
    } else {
      setSelectedOrder(result.data.participantorder)
      setSelectedResponseOrder(responseorder)
      setIndividualData(result.data)
    }
  }

  const showIndividualByAnswerId = async (participant, answer) => {

    const result = await api.request(`/surveys/${survey.surveyId}/individual/`, {
      params: {
        survey: Number(survey.surveyId),
        participant: Number(participant),
        myaffiliation : affiliation === "MY",
        idanswer: answer
      }
    })

    if (result?.status !== 200) {
      api.networkError(result, alert)
    } else {
      setSelectedOrder(result.data.participantorder)
      setSelectedResponseOrder(result.data.responseorder)
      // setSelectedResponseOrder(responseorder)
      setIndividualData(result.data.data)
    }
  }

  const onClickOrder = async (order) => {

    setSelectedOrder(order)
    setSelectedResponseOrder(0)
    showIndividualByOrder(order, 0)
  }

  const onClickResponseOrder = async (order) => {

    setSelectedResponseOrder(order)
    showIndividualByOrder(selectedOrder, order)
  }

  const onClickSelect = (e) => {
    if (orderList[orderList.length - selectedOrder - 1]?.count <= 1) return
    handleSelect(e)
  }

  const QuestionItem = (props) => {

    const {projectData, individualData, question} = props
    const [showMemo, setShowMemo] = useState(false)

    const onClickMemo = () => {
      setShowMemo(!showMemo)
    }

    const onClosedMemo = () => {
      setShowMemo(false)
      showIndividualByOrder(selectedOrder, selectedResponseOrder)
    }

    return (
      <tr >
        <td className='center'>{question.sectionorderno}-{question.orderno}</td>
        <td className='group'>
          <div>
            <div>{question.content}</div>
          </div>
        </td>
        <td>
          <div className='twoLine'>
            {QUESTION_VALUE_TYPES.includes(question.type) ? question.answercontent : question.itemcontent}
          </div>
        </td>
        <td className='center'>{question.scoring === 1 && !QUESTION_VALUE_TYPES.includes(question.type) ? question.score : "-"}</td>
        { projectData?.eCRF === 1 && <td className='center'>
            {showMemo && <MemoThreadPopup
              projectId={project.projectId}
              surveyId={survey.surveyId}
              sectionId={question.section}
              questionId={question.idquestion}
              answerId={individualData.answer}
              memothread={question.memo.memothread}
              isPopup={true}
              onClosed={() => {onClosedMemo()}}/>
            }
            <i className={`${question.memo.fixed === 1 ? "icon-memo_end" : !!question.memo.memothread ? "icon-memo_ing" : "icon-memo_plus"} memo-btn`}
              onClick={onClickMemo}
            />
            {question.memo.newmemo && <span className="dot"></span>}
          </td>
        }
      </tr>
    )
  }

  return (
    <div  className='full gap' >
      <section className='nameList'>
        <ul >
          { orderList.map((order, index) =>
              <a key={`participantorder-${index}`} className={selectedOrder === order.order ? "active" : ""}
                onClick={() => onClickOrder(order.order)}>&nbsp;&nbsp;{order.identifier}{order.count > 1 ? `(${order.count})` : ``}&nbsp;&nbsp;
              </a>
          )}
        </ul>
      </section>
      <section className="">
      <div className="search_wrap">
        { project.projectData?.eCRF === 1 && orderList?.length > 0 && (
            survey.surveyData?.multipleresponse === 1 ? (
              <div>
                <div className='search_box'>
                  <h5>{orderList[orderList.length - selectedOrder - 1]?.identifier} : 응답 {orderList[orderList.length - selectedOrder - 1]?.count}회</h5>
                </div>
                <div className="selectBox">
                  <span className={`label ${orderList[orderList.length - selectedOrder - 1]?.count <= 1 ? "c_disabled" : ""}` }
                    onClick={onClickSelect}>{individualData?.completeddates?.[selectedResponseOrder]?? '-'}</span>
                  <ul className="optionList">
                    { individualData?.completeddates?.map((date, index) =>
                      <li key={`completeddates-${index}`} className="optionItem" onClick={() => {onClickResponseOrder(index)}}>{date}</li>
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              <div>
                <div className='search_box'>
                  <h5>{orderList[orderList.length - selectedOrder - 1]?.identifier}</h5>
                </div>
              </div>
            )
          )
        }
      </div>
      <div className='section_in'>
        <table className="line ">
          <colgroup>
            <col className='col80' />
            <col />
            <col className='col300' />
            <col className='col100' />
            {project.projectData?.eCRF === 1 && <col className='col100' />}
          </colgroup>
          <thead>
              <tr>
                <th className='center'>번호</th>
                <th>질문</th>
                <th>답변</th>
                <th className='center'>점수</th>
                {project.projectData?.eCRF === 1 && <th className='center'>메모</th>}
            </tr>
          </thead>
          <tbody>
            { individualData?.questions?.map((question, index) =>
              <QuestionItem key={`question-${question.sectionorderno}-${question.orderno}`}
                projectData={project.projectData}
                individualData={individualData}
                question={question}
              />
            )}
          </tbody>
        </table>
        </div>
      </section>
    </div>
  )
}
export default SurveyAnswerIndividual;