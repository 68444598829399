import UserInfoHeader from './UserInfoHeader';
import { useAuth } from '../../context/AuthContext';
import { useEffect, useRef, useState } from 'react';
import { useApi } from '../../context/ApiContext';
import { handleSelect, initPopupMenu } from '../../common/javascriptHelper';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useNavigate } from 'react-router-dom';
import { getFileData, profileImageUrl } from '../../common/imageHelper';
import ChangePasswordPopup from './popup/ChangePasswordPopup';
import toast from 'react-hot-toast';
import { useLoadingIndicator } from '../../context/LoadingIndicatorContext';
import MyScalePopup from './popup/MyScalePopup';
import ChangePhoneNumberPopup from './popup/ChangePhoneNumberPopup';

const UserInfo = () => {

  const navigate = useNavigate()
  const auth = useAuth()
  const api = useApi()  
  const {popup, confirm, alert} = useCommonPopup()

  const [file, setFile] = useState()
  const [selectedAffiliation, setSelectedAffiliation] = useState()
  const [profileUrl, setProfileUrl] = useState()
  const [imageData, setImageData] = useState()
  const {showLoading, stopLoading} = useLoadingIndicator()
  const [customScaleCount, setCustomScaleCount] = useState()

  const [inputs, setInputs] = useState({
    affiliation: "",
    occupation: "",
  })

  const [errors, setErrors] = useState({
    affiliationError: "",
  })

  const {affiliationError} = errors
  
  const {affiliation, occupation} = inputs
  
  const inputAffiliation = useRef()
  const [affiliationList, setAffiliationList] = useState()

  const [isLoading, setIsLoading] = useState(false) 

  useEffect(_ => {
   
    initPopupMenu()
    getAffiliationList()
    getCustomScaleCount()

  }, []);

  useEffect(_ => {

    if (!auth.adminInfo || !affiliationList) return

    if (auth.adminInfo.profileimage?.length > 0) {
      setProfileUrl(profileImageUrl(auth.adminInfo.profileimage, "medium"))
    }
    if (affiliationList.filter(item => item.name === auth.adminInfo?.affiliation)?.length > 0) {
      setSelectedAffiliation(auth.adminInfo?.affiliation)
      setInputs({
        ...inputs, 
        occupation: auth.adminInfo?.occupation
      })
    } else {
      setSelectedAffiliation("직접 입력")
      setInputs({
        ...inputs, 
        occupation: auth.adminInfo?.occupation,
        affiliation: auth.adminInfo?.affiliation
      })
    }

  }, [auth.adminInfo, affiliationList]);

  
  const getAffiliationList = async() => {

    const result = await api.post("getAffiliationList", {
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setAffiliationList(result.data.data.affiliations)
    }
  }

  const getCustomScaleCount = async() => {

    const result = await api.post("getCustomScaleCount", {
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setCustomScaleCount(result.data.data.scalecount)
    }
  }

  const deleteAdmin = async () => {

    showLoading()
    const result = await api.post("deleteAdmin", {})
    
    if (result?.data?.result != "ok") {
      stopLoading()
      if (result?.data?.code == 99) {
        alert("소유하고 있는 프로젝트가 있습니다. 프로젝트 홈에서 공동참여자 중 한명을 소유자로 지정한 후 다시 탈퇴를 진행해주세요. ",
        {title: "회원탈퇴 실패"})
      } else {
        api.networkError(result, alert)
      }
    } else {
      auth.deleteAdmin() 
      stopLoading()     
      navigate("/signin")
    }   
  }

  const setError = (name, error) => {
    setErrors({
      ...errors, 
      [`${name}Error`]: error
    })
  }


  const onChangeInput = (e) => {

    var { value, name } = e.target
    value = value.replace(/ /g, "") 
    setInputs({
      ...inputs, 
      [name]: value
    })
  }

  const onKeyEvent = (e) => {

    var { name } = e.target
    if (e.key === 'Enter') {
    }
  }

  const onClickSelect = (e) => {
    handleSelect(e)
  }

  const onSelectAffiliation = (value) => {

    setSelectedAffiliation(value)
  }

  const onSelectOccupation = (e) => {

    setInputs({
      ...inputs, 
      ["occupation"]: e.target.innerText
    })
    setError("occupation", "")
  }

  const onClickDeleteAdmin = async () => {

    if (await confirm(`정말 탈퇴하시겠습니까?<br/><br/>
    탈퇴한 후에는 참여했던 프로젝트에서 모두 제외되며, 추후 서비스 이용을 위해서는 재가입이 필요합니다.`,
    {title: "회원탈퇴"})) {
      deleteAdmin()
    }
  }

  const onSelectFile = async (e) => {
    
    let file = e.target.files[0]
    if (!file) return
    
    setFile(file)
    let data = await getFileData(file)
    setImageData(data)
  }

  const onClickSave = async() => {
    
    if (isLoading) return
    // if (!checkValidation()) return
    setIsLoading(true)
    showLoading()

    var filename = null;
    if (file != null) {
      filename = await uploadFile()
      if (!filename) {
        stopLoading()
        setIsLoading(false)  
        return
      }
    }

    var params = {
      affiliation : selectedAffiliation === "직접 입력" ? affiliation : selectedAffiliation,
      occupation : occupation,
    }
    if (filename?.length > 0) {
      params["profileimage"] = filename
    } else if (auth.adminInfo?.profileimage?.length > 0 && !profileUrl?.length) {
      params["profileimage"] = ""
    }

    const result = await api.post("updateAdminInfo", params)
    
    stopLoading()
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
      setIsLoading(false)     
    } else {           
      await auth.updateAdminInfo()      
      setFile(null)      
      setImageData(null)      
      toast.success("변경사항이 저장되었습니다.")
      setIsLoading(false)  
    }
  }

  const uploadFile = async _ => {

    if (file == null) return null

    let data = await getFileData(file)
    if (data == null) return null

    const result = await api.post("uploadProfileImage", {
      file: data,      
    })
    
    if (result?.data?.result != "ok") {
      stopLoading()
      setIsLoading(false)    
      await alert("이미지 업로드에 실패했습니다.<br/>이미지 사이즈를 확인하시고 다시 시도해 주세요.", {title: "이미지 업로드"})
      return null   
    } else {
      return result.data.data.filename
    }
  }

  const onClickDeleteProfile = () => {

    setFile(null)
    setProfileUrl(null)
    setImageData(null)
  }

  const onClickChangePassword = async () => {

    const result = await popup(
      <ChangePasswordPopup email={auth.adminInfo.email}/>, {
        name: "ChangePasswordPopup",
        useContainer: false,
      }
    )
    if (!result || result === false) return

    await changePassword(result)
    
  }

  const changePassword = async (password) => {

    if (isLoading) return
    // if (!checkValidation()) return
    setIsLoading(true)
    const result = await api.post("updateAdminInfo", {
      newpassword: password
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
      setIsLoading(false)     
    } else {     
      setIsLoading(false)
      await alert("비밀번호가 변경되었습니다.<br/><br/>다시 로그인해 주세요.", {
        title: "비밀번호 변경"
      })
      navigate("/signin")
    }
  }

  const onClickChangePhoneNumber = async () => {

    const result = await popup(
      <ChangePhoneNumberPopup phoneno={auth.adminInfo.phoneno}/>, {
        name: "ChangePhoneNumberPopup",
        useContainer: false,
      }
    )
    if (!result || result === false) return
      
    await auth.updateAdminInfo()      
  }
  
  const onClickMyScale = async () => {

    await popup(
      <MyScalePopup/>,
      {
        container: "",
        name: "MyScalePopup",
        style: "survey_scale",
        title: "내 척도 보기",    
        useContainer: false
      }, 
    )
  }

  return (    
    <div className="body project survey myinfo ">
      <UserInfoHeader onClickSave={onClickSave} isLock={isLoading}/>
      <div className="container">
        <div className="inwrap">
          <div className="card my_info">
            <dl>
              <dt>이메일</dt>
              <dd>{auth.adminInfo?.email?? "-"}</dd> 
            </dl>
            <dl>
              <dt>이름</dt>
              <dd>{auth.adminInfo?.name?? "-"}</dd>
            </dl>
            <dl>
              <dt>성별</dt>
              <dd>{!!auth.adminInfo?.gender ? auth.adminInfo?.gender === "f" ?  "여자" : "남자" : "-"}</dd>
            </dl>
            <dl>
              <dt>휴대폰 번호</dt>
              <dd className='flex'>
                {auth.adminInfo?.phoneno?? "-"}
                <button className="secondary gray" onClick={onClickChangePhoneNumber}>번호 변경</button>
              </dd>
            </dl>
            <dl>
              <dt>내척도</dt>
              <dd  className='flex'>
                {customScaleCount != null ? `총 ${customScaleCount}건` : '-'}
                <button className="secondary gray" onClick={onClickMyScale}>내 척도 보기</button>
              </dd>
            </dl>            
          </div>
          <div className="card myinfo_change">
            <div>
              {!!imageData ? <img src={imageData}/>
                : !!profileUrl ? <img src={profileUrl} alt=""/>                  
                : <span>{auth.adminInfo?.name[0]}</span>
              }
              <div className="popmenu">
                <i className="icon-photo"></i>
                <ul className="menu_pop">
                  <li className="survey_re_btn">
                    <div className="filebox">
                      <label htmlFor="files"  className="survey_re_btn">프로필 사진 바꾸기</label> 
                      <input type="file" id="files" name="files[]" accept="image/*" capture="environment" onChange={onSelectFile}/>
                    </div>
                  </li>
                  <li className="survey_del_btn" onClick={onClickDeleteProfile}>삭제하기</li>
                </ul>
              </div>
            </div>
            <ul>
              <li>
              <label htmlFor="">비밀번호</label>
              <button className='secondary gray' onClick={onClickChangePassword}>비밀번호 변경</button>
              </li>
              <li>
                <label htmlFor="">소속기관</label>
                <ul>
                  { selectedAffiliation === "직접 입력" && 
                    <li>
                      <input 
                        name="affiliation"
                        type="text" 
                        placeholder="소속기관"
                        ref={inputAffiliation}
                        className={`full ${affiliationError.length > 0 ? "error" : ""}`}
                        value={affiliation}
                        onChange={onChangeInput}
                      /> 
                    </li>
                  }
                  <li>
                    <div className="selectBox  full">
                      <span className="label" onClick={onClickSelect}>{selectedAffiliation?? "기관을 선택해 주세요."}</span>
                      <ul className="optionList">
                        { affiliationList?.map((affiliation, index) =>
                            <li className="optionItem" key={`affiliation-${index}`} onClick={() => onSelectAffiliation(affiliation.name)}>{affiliation.name}</li>
                          )                          
                        }
                        <li className="optionItem" onClick={() => onSelectAffiliation("직접 입력")}>직접 입력</li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <label htmlFor="">직업</label>
                <div className="selectBox  full">
                  <span className="label" onClick={onClickSelect}>{occupation.length > 0 ? occupation : "직업을 선택하세요."}</span>
                  <ul className="optionList active">
                      <li className="optionItem" onClick={onSelectOccupation}>대학생</li>
                      <li className="optionItem" onClick={onSelectOccupation}>대학원생(석사과정)</li>
                      <li className="optionItem" onClick={onSelectOccupation}>대학원생(박사과정)</li>
                      <li className="optionItem" onClick={onSelectOccupation}>보건</li>
                      <li className="optionItem" onClick={onSelectOccupation}>의료진</li>
                      <li className="optionItem" onClick={onSelectOccupation}>기타</li>
                  </ul>
                </div>
              </li>
              <li>
              <button className="error" onClick={onClickDeleteAdmin}>회원 탈퇴</button>
              </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserInfo 