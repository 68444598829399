import React, { useEffect, useState } from 'react'
import { Outlet, Navigate, useNavigate, Link } from "react-router-dom"
import { useAuth, AuthContextProvider } from '../context/AuthContext'
import { useApi, ApiContextProvider } from '../context/ApiContext'

const ProtectedPage = () => {

  const navigate = useNavigate()
  const auth = useAuth()
  const [isInit, setIsInit] = useState(false)

  useEffect(_ => {
    
    const init = async () => {
      if (!await auth.isAuth()) {
        alert('로그인이 필요합니다.')
        navigate("/")
      }   
    }
    init()
    
  }, [])
  
  useEffect(_ => {

    if (!auth.adminInfo) return
    setIsInit(true)       
    
  }, [auth.adminInfo])

  return (
    isInit ? (<Outlet />) : (null)
  )
}
export default ProtectedPage