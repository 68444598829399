import { Link } from 'react-router-dom';
import SurveyHeader from '../inc/SurveyHeader';
import NewProjectPopup from '../popup/NewProjectPopup';
import ProjectHomePopup from '../popup/ProjectHomePopup';
import SurveyScalePopup from '../popup/SurveyScalePopup';
import SurveySendPopup from '../popup/SurveySendPopup';
const Survey = props => {
  return (      
    <div className="body project SurveyAnswer">
      <SurveyHeader/>
      <NewProjectPopup/>
      <ProjectHomePopup/>
      <SurveyScalePopup/>
      <SurveySendPopup/>
      <div className="container">
        <div className="inwrap">
          <div className="full gap">
          {/* 상단 */}
          <section className="">
            <div className="top_wrap">
             <h5 className='bold'>응답 3개</h5>
             <div>
              <button className="secondary gray large">EXCEL 다운로드</button>
              <button className="secondary gray large">CVS 다운로드</button>
             </div>
            </div> 
            <div className='top_wrap menu_wrap'>
              <ul className='sub_menu'>
                <li className="active"><a href="SurveyAnswer">요약보기</a></li>
                <li className=""><a href="SurveyAnswer2" className="">개별보기</a></li>
              </ul>
              <ul className="tab">
                <li className="active">전체</li>
                <li>내 기관</li>
              </ul>            
            </div>
            <ul className='top_wrap'>
              <li>
                <p>응답</p>
                <h3>3</h3>
              </li>
              <li>
                <p>미응답</p>
                <h3>7</h3>
              </li>
              <li>
                <p>등록 참여자</p>
                <h3>10</h3>
              </li>
              <li>
                <p>응답률</p>
                <h3>30%</h3>
              </li>
            </ul>
             
            

          </section>
          네 아니요 / 객관식 (2개)
          <section>
            <div className="search_wrap">
             <h5 className='bold'>1. 우울증 진단을 받은 적이 있으신가요?</h5>
            </div>

            <div className='graph_wrap'>
              <div className='gray_bg'>
                <dl>
                  <dt>최근 1년 내 1번 이상 있음</dt>
                  <dd>
                    <div>
                      2(66.6%)
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>한 번도 없음</dt>
                  <dd>
                    <div  style={{width:'50%', }}>
                      1(33.3%)
                    </div>
                  </dd>
                </dl>
                
                  <div className='num'>
                   <span>0</span>
                    <span >1</span>
                    <span >2</span>
                  </div>
              </div>
              <table className='line'>
                 <colgroup>
                <col className='col200'/><col />
                </colgroup>
                <thead>
                  <tr>
                    <th>답변</th>
                    <th>응답수</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>최근 1년 내 1번 이상 있음</td>
                    <td>2(66.6%)</td>
                  </tr>
                  <tr>
                    <td>한 번도 없음</td>
                    <td>1(33.3%)</td>
                  </tr>
                
                </tbody>

              </table>
            </div>
          </section>
          네 아니요 / 객관식 (5개)
          <section>
            <div className="search_wrap">
             <h5 className='bold'>1. 아무런 이유 없이 기운이 없던 적이 얼마나 됩니까?</h5>
            </div>
            <div className='graph_wrap'>
              <div className='gray_bg'>
                <dl>
                  <dt>한 달 내내</dt>
                  <dd>
                    <div>
                      2(25.5%)
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>한 달 중 대부분</dt>
                  <dd>
                    <div>
                      2(25.5%)
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>한 달 중 절반 정도</dt>
                  <dd>
                    <div>
                      2(25.5%)
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>한 달 중 며칠</dt>
                  <dd>
                    <div style={{width:'50%', }}>
                      1(12.5%)
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>전혀 없었다</dt>
                  <dd>
                    <div style={{width:'50%', }}>
                      1(12.5%)
                    </div>
                  </dd>
                </dl>
                  <div className='num'>
                   <span>0</span>
                    <span >1</span>
                    <span >2</span>
                  </div>
              </div>
              <table className='line'>
               <colgroup>
                <col className='col200'/><col />
                </colgroup>
                <thead>
                  <tr>
                    <th>답변</th>
                    <th>응답수</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>한 달 내내</td>
                    <td>2(25.5%)</td>
                  </tr>
                  <tr>
                    <td>한 달 중 대부분</td>
                    <td>2(25.5%)</td>
                  </tr>
                  <tr>
                    <td>한 달 중 절반 정도</td>
                    <td>2(25.5%)</td>
                  </tr>
                  <tr>
                    <td>한 달 중 며칠</td>
                    <td>1(25.5%)</td>
                  </tr>
                  <tr>
                    <td>전혀 없었다</td>
                    <td>1(25.5%)</td>
                  </tr>
                </tbody>

              </table>
            </div>
          </section>

          점수형 ( 10개 예시 )
          <section>
            <div className="search_wrap">
             <h5 className='bold'>2-11.  오늘을 포함한 지난 한 주 동안 당신이 얼마나 우울했는지 1점부터 10점 사이로 표시해주세요.</h5>
            </div>
            <div className='graph_wrap vertical '>
              <div className='gray_bg'>
                <dl>
                  <dt>
                    <span>5</span>
                    <span>4</span>
                    <span>3</span>
                    <span>2</span>
                    <span>1</span>
                  </dt>
                  <dd>

                  </dd>
                  <dd>
                    <div style={{height:'20%'}}>
                     <span> 1(6.3%)</span>
                    </div>
                    <span>1</span>
                  </dd>
                  <dd>
                    <div className='zero'>
                      <span> 0</span>
                    </div>
                    <span>2</span>
                  </dd>
                  <dd>
                     <div className='zero'>
                     <span> 0</span>
                     </div>
                     <span>3</span>
                  </dd>
                  <dd>
                    <div  style={{height:'40%'}}>2(12.5%)</div>
                    <span>4</span>
                  </dd>
                  <dd>
                    <div style={{height:'60%'}}>3(18.8%)</div>
                    <span>5</span>
                  </dd>
                  <dd>
                    <div style={{height:'100%'}}> 5(31.3%)</div>
                    <span>6</span>
                  </dd>
                  <dd>
                    <div style={{height:'80%'}}>4(25%)</div>
                    <span>7</span>
                  </dd>
                  <dd>
                   <div  className='zero'>
                   <span> 0</span>
                   </div>
                   <span>8</span>
                  </dd>
                  <dd>
                   <div className='zero'>
                   <span> 0</span>
                   </div>
                   <span>9</span>
                  </dd>
                  <dd>
                    <div style={{height:'20%'}}>1(6.3%)</div>
                    <span>10</span>
                  </dd>
                  <dd></dd>
              
                </dl>

                
              </div>
              <table className='line'>
                <colgroup>
                <col className='col200'/><col />
                </colgroup>
                <thead>
                  <tr>
                    <th>답변</th>
                    <th>응답수</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>1 (6.3%)</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>2 (12.5%)</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>3 (18.8%)</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>3 (18.8%)</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>3 (18.8%)</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>3 (18.8%)</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>3 (18.8%)</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>3 (18.8%)</td>
                  </tr>
                </tbody>

              </table>
            </div>
          </section>


          주관식
          <section>
            <div className="search_wrap">
             <h5 className='bold'>2-12. 스스로 우울한 원인이 무엇이라고 생각하시나요?</h5>
            </div>
            <ul className='bg_gray_list'>
              <li>
              제가 우울한 이유는 대인관계에서의 문제 때문이라고 생각합니다. 최근에는 가족, 친구들과의 소통이 원활하지 않아서 외로움과 불안감이 많이 느껴지고 있습니다. 특히, 일부 가족 구성원과의 갈등이 계속되고 있어 상처받은 마음을 치유할 수 없는 것 같습니다. 이러한 상황이 저의 생활에 큰 부담을 주고, 일상에서 즐거움을 느끼지 못하게 만들고 있습니다.
              </li>
              <li>
              저는 지난 몇 년간 건강 문제로 인해 많은 시간을 침대에 누워 보냈기 때문에, 체력 저하와 자존감 하락으로 인해 우울해진 것 같습니다. 
              </li>
              <li>
              일상적으로 일이나 공부에서 실패를 하면 스스로 자책감을 느끼는 편인데, 이러한 부정적인 생각이 누적되어 우울해진 것 같습니다. 일상에서의 작은 실패도 큰 실패로 받아들이며, 자존감이 떨어져 계속적으로 자신에게 부정적인 생각을 하게 됩니다. 이러한 부정적인 생각이 쌓여 우울감이 더해지는 것 같습니다.
              </li>
              <li>
              최근 가족으로부터 이별을 경험한 이후로, 저는 매일매일 슬픔과 아픔을 느끼며 지내고 있습니다. 그리고 이러한 감정들이 상당한 스트레스를 유발하고 있습니다. 이전까지는 가족들과 함께 살면서 상처받은 일이 있어도, 가족들이 서로 지지해주면서 어려움을 함께 극복해왔습니다. 하지만 이번 이별에서는 그렇지 않았습니다. 가족들 간의 갈등과 불화가 이어져, 이별로 끝나게 되었습니다.
              이제는 가족들과 함께하지 않아서 더욱 외로움과 불안감이 커졌습니다. 또한 이전보다도 갑작스런 슬픔과 울음이 자주 찾아오고, 그로 인해 생활 패턴이 많이 어긋나게 되었습니다. 이러한 상황으로 인해 일상 생활에서 즐거움을 찾기가 어려워졌고, 앞으로의 미래에 대한 불안감이 더해지고 있습니다. 이별로 인한 감정적 충격과 아픔이 지속되고, 이를 극복하는 방법을 찾지 못하고 있어 스트레스와 우울감이 더해지고 있습니다.
              그리고 이러한 감정과 상황에서 벗어나기 위해서는 무엇을 해야할지 감이 잘 잡히지 않습니다. 더구나, 이번 이별로 인해 가족 관계와 인간관계에 대한 신뢰와 안정감이 흔들리고 있어, 이를 다시 회복하기 위해서는 많은 노력과 시간이 필요할 것으로 생각됩니다. 하지만 그런 노력과 시간이 필요할 때마다, 또한 어떻게 극복해 나갈지에 대한 충분한 지식과 방법론이 없기 때문에, 앞으로의 삶에 대한 불안감과 우울감이 커지는 것 같습니다.
              </li>
            </ul>
          
          </section>
         </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default Survey;