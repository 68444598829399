export const SURVEY_QUESTION_TYPE_YESORNO = "YESORNO"
export const SURVEY_QUESTION_TYPE_MULTIPLECHOICE = "MULTIPLECHOICE"
export const SURVEY_QUESTION_TYPE_SHORTANSWER = "SHORTANSWER"
export const SURVEY_QUESTION_TYPE_SCORE = "SCORE"
export const SURVEY_QUESTION_TYPE_ESSAY = "ESSAY"
export const SURVEY_QUESTION_TYPE_DATE = "DATE"
export const SURVEY_QUESTION_TYPE_YEAR = "YEAR"

export const QUESTION_VALUE_TYPES = [
  SURVEY_QUESTION_TYPE_ESSAY,
  SURVEY_QUESTION_TYPE_DATE,
  SURVEY_QUESTION_TYPE_YEAR,
  SURVEY_QUESTION_TYPE_SHORTANSWER
]

export const SURVEY_QUESTION_TYPE_CONDITION_AGE = "age"
export const SURVEY_QUESTION_TYPE_CONDITION_BIRTHDAY = "birthday"
export const SURVEY_QUESTION_TYPE_CONDITION_PHONENO = "phoneno"
export const SURVEY_QUESTION_TYPE_CONDITION_GENDER = "gender"
export const SURVEY_QUESTION_TYPE_CONDITION_REGION = "region"
export const SURVEY_QUESTION_TYPE_CONDITION_EDUCATION = "education"
export const SURVEY_QUESTION_TYPE_CONDITION_MARRIAGE = "marriage"

export const SURVEY_STATUS_WRITING = "WRITING"
export const SURVEY_STATUS_OPEN = "OPEN"
export const SURVEY_STATUS_CLOSED = "CLOSED"

export const NOTIFICATION_TYPE_MEMO = "MEMO"
export const NOTIFICATION_TYPE_FILE = "FILE"
export const NOTIFICATION_TYPE_NOTICE = "NOTICE"
export const NOTIFICATION_TYPE_AFFILIATION = "AFFILIATION"
export const NOTIFICATION_TYPE_INQUIRY = "INQUIRY"
export const NOTIFICATION_TYPE_ANSWER_INQUIRY = "ANSWER_INQUIRY"

export const SURVEY_QUESTION_TYPE = {

  YESORNO: "네/아니요",
  MULTIPLECHOICE: "객관식",
  SHORTANSWER: "단답형",
  SCORE: "점수형",
  ESSAY: "주관식",
  DATE: "날짜",
  YEAR: "연도",
  age: "조건_나이대",
  birthday: "조건_생일",
  phoneno: "조건_휴대폰번호",
  gender: "조건_성별",
  region: "조건_지역",
  education: "조건_학력",
  marriage: "조건_결혼"
}

export const SURVEY_QUESTION_OPTION = {
  SCORING: "점수 매기기",
  MOVE_AFTER_ANSWER: "답변을 기준으로 문항 보내기",
  INPUT_REASON: "사유입력",
  USE_ETC: "기타 항목",
}

export const SURVEY_STATUS = {
  WRITING: "설문 대기",
  OPEN: "설문 진행 중",
  CLOSED: "설문 종료"
}

export const PARTICIPANT_STATUS = {
  PROCEED: "진행",
  WITHDRAW: "탈퇴",
  HALT: "중단"
}

export const HISTORY_ACTION = {
  CHANGE_STATUS: "상태값변경",
  NEW_MEMO: "메모 추가",
  DELETE_MEMO: "메모 삭제",
  CHANGE_TARGET_GROUP: "그룹 변경"
}
