import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useProject } from '../../../context/ProjectContext';
import { handleSelect } from '../../../common/javascriptHelper';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { useAuth } from '../../../context/AuthContext';
import { validateInput } from '../../../common/stringHelper';

const ChangeInitialPopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const { alert } = useCommonPopup()
  const api = useApi()
  const auth = useAuth()  
  const { projectData, participant } = props

  const [name, setName] = useState("")
  const [error, setError] = useState("")


  useEffect(()=> {

    return () => {
    }
  }, []);


  const onChangeName = (e) => {

    const value = e.target.value
    setName(value)

    if (value.length > 0 && !validateInput(value)) {
      setError("대상자 번호는 영문, 숫자, 특수문자(-_) 조합으로 지정해주세요.")
    } else {
      setError("")
    }
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }


  const onClickConfirm = async (e) => {

    e.preventDefault()
   
    if ( name.length == 0) {
      alert("대상자 번호를 입력해 주세요.")
      return 
    }
    
    const result = await api.request(`/participants/${participant.idparticipant}/`, {
      method: "patch",
      params: { initial: name }
    })

    if (result?.status !== 204) {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }  

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">대상자 번호 변경<span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e)=>e.preventDefault()}>
          <div className="pop_content height_auto " >
            <h4>변경할 대상자 번호를 입력해주세요.</h4>
            <div>
              <input type="text" id="survey-link" placeholder="대상자 번호를 입력해주세요." value={name} 
                className={`full big ${error?.length > 0 ? "error" : ""}`} onChange={onChangeName}/>
              <p className="tip">{error}</p>              
              <p>현재 대상자 번호 : {participant.initial}</p>                 
            </div>                     
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className=" primary large "  onClick={onClickConfirm}>확인</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );  
}
 export default ChangeInitialPopup;