import { useEffect, useState } from 'react';
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const SurveyScaleSourcePopup = props => {

  const api = useApi()
  const {alert} = useCommonPopup()

  const [scaleSourceList, setScaleSourceList] = useState()

  useEffect(_ => {
    
    getScaleSource()
    return ()=>{ 
    }
  }, [])

  /*=========================================================
   *  Data 
   =========================================================*/

  const getScaleSource = async () => {

    if (!props.surveyId) return

    const result = await api.post("showScaleSource", {
      survey: props.surveyId
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {   
      result.data.data.scales.forEach(scale => {
        scale.sources.forEach(source => {
          source["APA"] = getAPA(source)
          source["MLA"] = getMLA(source)
          source["ISO690"] = getISO690(source)
        });
      });
      setScaleSourceList(result.data.data.scales)   
    }
  }

  const getAPA = (source) => {
    
    var author = source.author
    const array = source.author.split("^")
    if (array.length > 1) author = `${array.slice(0, -1).join(",")}, &${array.slice(-1)}`

    var result1 = ""
    var result2 = ""
    if (author.length > 0) result1 += author + ". "
    if (source.year.length > 0) result1 += "(" +source.year + "). "
    if (source.title.length > 0) result1 += source.title + ((source.type !== "website") ? ". " : " ")
    
    if (source.journal.length > 0) result2 += "<span class='italic'>" + source.journal + "</span>, "
    if (source.volume.length > 0) result2 += source.volume
    if (source.issue.length > 0) result2 += "(" + source.issue + ")"
    if (source.volume.length > 0 || source.issue.length > 0) result2 += ", "
    if (source.pages.length > 0) result2 += source.pages
    if (result2.length > 0) result2 += "."

    //1 안정숙, 전성균, 한준규, 노경선, Goodman, & R. (2003). 한국어판 강점· 난점 설문지 (Strengths and Difficulties Questionnaire) 개발. 신경정신의학, 42(1), 141-148.
    //2 안정숙, 전성균, 한준규, 노경선, & Goodman, R. (2003). 한국어판 강점· 난점 설문지 (Strengths and Difficulties Questionnaire) 개발. 신경정신의학, 42(1), 141-148.

    //1 김용석. (2011). 한국어판 정신건강 선별도구(K10,K6)의 타당화 연구; 재가복지서비스 이용자를 대상으로. 정신건강과 사회복지, 37, 65-88.
    //2 김용석. (2011). 한국어판 정신건강 선별도구 (K10, K6) 의 타당화 연구: 재가복지서비스 이용자를 대상으로. 정신건강과 사회복지, 37, 65-88.

    //1 허심양, 오주용, & 김지혜. (2012). 한국판 Barratt 충동성 검사-11 의 신뢰도 및 타당도 연구. 한국심리학회지 : 일반, 31(3), 769-782.
    //2 허심양, 오주용, & 김지혜. (2012). 한국판 Barratt 충동성 검사-11 의 신뢰도 및 타당도 연구. 한국심리학회지: 일반, 31(3), 769-782.
        
    return result1 + result2
  }

  const getMLA = (source) => {
    
    var author = source.author
    const array = source.author.split("^")
    if (array.length > 1) {
      if (array.length < 3) author = `${array.slice(0, -1).join(",")}, and ${array.slice(-1)}`
      else author = `${array[0]}, et al`
    }
    
    var result1 = ""
    var result2 = ""
    if (author.length > 0) result1 += author + ". "
    if (source.title.length > 0) result1 += ((source.type !== "website") ? "\" " : "") + source.title + ((source.type !== "website") ? ".\" " : " ")
    if (source.journal.length > 0) result1 += "<span class='bold'>" + source.journal + "</span> "
    if (source.volume.length > 0) result1 += source.volume
    if (source.issue.length > 0) result1 += "." + source.issue + " "
    if (source.year.length > 0) result1 += "(" + source.year + ")"
    if (source.pages.length > 0) result1 += ": " + source.pages
    if (source.type !== "website") result1 += "."

    //1 안정숙, et al. "한국어판 강점· 난점 설문지 (Strengths and Difficulties Questionnaire) 개발." 신경정신의학 42.1 (2003): 141-148.
    //2 안정숙, et al. "한국어판 강점· 난점 설문지 (Strengths and Difficulties Questionnaire) 개발." 신경정신의학 42.1 (2003): 141-148.

    //1 김용석. "한국어판 정신건강 선별도구(K10,K6)의 타당화 연구; 재가복지서비스 이용자를 대상으로." 정신건강과 사회복지 37(2011): 65-88.
    //2 김용석. "한국어판 정신건강 선별도구 (K10, K6) 의 타당화 연구: 재가복지서비스 이용자를 대상으로." 정신건강과 사회복지 37 (2011): 65-88.

    //1 허심양, et al. "한국판 Barratt 충동성 검사-11 의 신뢰도 및 타당도 연구." 한국심리학회지 : 일반 31.3 (2012): 769-782.
    //2 허심양, 오주용, and 김지혜. "한국판 Barratt 충동성 검사-11 의 신뢰도 및 타당도 연구." 한국심리학회지: 일반 31.3 (2012): 769-782.

    return result1 + result2
  }

  const getISO690 = (source) => {
    
    var author = source.author
    const array = source.author.split("^")
    if (array.length > 1) {
      if (array.length < 3) author = `${array.slice(0, -1).join(",")}, and ${array.slice(-1)}`
      else author = `${array[0]}, et al`
    }
    
    var result = ""
    if (author.length > 0) {
      if (source.type == "website") result += "<span class='italic'>" + author + "</span>" + ". "
      else result += author + ". "
    }
    if (source.title.length > 0) {
      if (source.type == "article") result += source.title + ((source.type !== "website") ? ". " : " ")
      else result += "<span class='italic'>" + source.title + "</span>" + ((source.type !== "website") ? ". " : " ")
    }         
    if (source.journal.length > 0) {
      if (source.type == "article") result += "<span class='italic'>" + source.journal + "</span>" + ", "
      else result += source.journal + ", "
    }
    if (source.year.length > 0) result += source.year + ", "

    if (source.volume.length > 0) result += source.volume
    if (source.issue.length > 0) result += "." + source.issue
    if (source.pages.length > 0) result += ": " + source.pages
    if (source.type !== "website") result += "."

    //1 안정숙, et al. 한국어판 강점· 난점 설문지 (Strengths and Difficulties Questionnaire) 개발. 신경정신의학, 2003, 42.1: 141-148.
    //2 안정숙, et al. 한국어판 강점· 난점 설문지 (Strengths and Difficulties Questionnaire) 개발. 신경정신의학, 2003, 42.1: 141-148.


    //1 김용석. 한국어판 정신건강 선별도구(K10,K6)의 타당화 연구; 재가복지서비스 이용자를 대상으로. 정신건강과 사회복지, 2011, 37: 65-88.
    //2 김용석. 한국어판 정신건강 선별도구 (K10, K6) 의 타당화 연구: 재가복지서비스 이용자를 대상으로. 정신건강과 사회복지, 2011, 37: 65-88.
    
    //1 허심양, et al. 한국판 Barratt 충동성 검사-11 의 신뢰도 및 타당도 연구. 한국심리학회지 : 일반, 2012, 31.3: 769-782.
    //2 허심양; 오주용; 김지혜. 한국판 Barratt 충동성 검사-11 의 신뢰도 및 타당도 연구. 한국심리학회지: 일반, 2012, 31.3: 769-782.

    return result
  }

  
  /*=========================================================
  *  Rendering 
  =========================================================*/

  return (
    <>
    { !!scaleSourceList ? (
      scaleSourceList.length > 0 ? (
        scaleSourceList.map((scale, index1) =>
          <div key={`scale-${index1}`}>
            <p>{scale.name}</p>
            <table className='line'>
              <colgroup><col className="date"/><col  /></colgroup>
              {/* <thead>
                <tr>
                  <th colSpan={2}>APA</th>
                </tr>
              </thead> */}
              <tbody>
              <tr>
                <th colSpan={2}>APA</th>
              </tr>
              {scale.sources.map((source, index2) =>
                <tr key={`scale-${index1}-apa-${index2}`}>
                  {/* <td>{index2 + 1}차 저작권</td> */}
                  <td><div dangerouslySetInnerHTML={ {__html: source.APA} }></div></td>
                </tr>
              )}
              <tr>
                  <th colSpan={2}>MLA</th>
              </tr>
              {scale.sources.map((source, index2) =>
                <tr key={`scale-${index1}-mla-${index2}`}>
                  {/* <td>{index2 + 1}차 저작권</td> */}
                  <td><div dangerouslySetInnerHTML={ {__html: source.MLA} }></div></td>
                </tr>
              )}
              <tr>
                <th colSpan={2}>ISO690</th>
              </tr>
              {scale.sources.map((source, index2) =>
                <tr key={`scale-${index1}-iso690-${index2}`}>
                  {/* <td>{index2 + 1}차 저작권</td> */}
                  <td><div dangerouslySetInnerHTML={ {__html: source.ISO690} }></div></td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        )) : (
          <div>
            <p className="center"><br/>출처가 없습니다.<br/><br/><br/><br/></p>
          </div>
        )
      ) : (null)
    }
    </>
  )
}
 export default SurveyScaleSourcePopup;