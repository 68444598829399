import $ from 'jquery';
import React, { createContext, useEffect, useState, useMemo, useContext, useRef, forwardRef, useImperativeHandle } from 'react'

var LoadingIndicatorContext = createContext();

export function LoadingIndicatorContextProvider({children}) {
   
  const loadingMessage = useRef("");
  const [show, setShow] = useState(false);

  const showLoading = async (message) => {
    
    loadingMessage.currnet = message
    setShow(true)      
  }

  const stopLoading = async () => {
    
    setShow(false)      
  }
  
  return (
    <LoadingIndicatorContext.Provider value={{showLoading, stopLoading}}>   
      <div>
        {children}
        { show && 
        <div className="body">
        <div className="loading-container">
          <div>
            <div className="loading"></div>
            <div id="loading-text">{loadingMessage.current?.length > 0 ? loadingMessage.current : "Loading..."}</div>
            </div>
        </div>
        </div>
        }

      </div>  
    </LoadingIndicatorContext.Provider>
  );
}

export function useLoadingIndicator() {
  return useContext(LoadingIndicatorContext)
}