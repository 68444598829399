import { useApi } from '../../../context/ApiContext';
import { getFileData } from '../../../common/imageHelper';
import toast, {Toaster} from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import RegisterAgreementPopup from './RegisterAgreementPopup';
import { isMobile } from 'react-device-detect';
import { useLoadingIndicator } from '../../../context/LoadingIndicatorContext';

const AgreementPreviewPopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const api = useApi()
  const {popup, alert} = useCommonPopup()
  const {showLoading, stopLoading} = useLoadingIndicator()
  const [imageData, setImageData] = useState()

  useEffect(_ => {

    downloadImage()   

  }, [])

  const downloadImage = async () => {

    const result = await api.post("downloadParticipantAgreement", {
      project: props.projectId,
      participant: props.participant.idparticipant,
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {   
      setImageData(result.data.data.image)
    }
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickChangeAgreement = async () => {

    const result = await popup(
      <RegisterAgreementPopup 
        projectId={props.projectId}        
        participant={props.participant}
      />,
      {
        container: "project",
        name: "Participant_pop4",
        style: "height_auto", 
        title: "서면 동의서 업로드",
        hasFooter: false,
      }, 
    )
    if (result === true) {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }

  const onClickImage = async (e) => {

    let file = e.target.files[0]
    if (file == null) return

    let data = await getFileData(file)
    if (data == null) return

    showLoading()

    const result = await api.post("uploadParticipantAgreement", {
      project: props.projectId,
      participant: props.participant.idparticipant,
      file: data
    })

    stopLoading()

    if (result?.data?.result != "ok") {
      // api.networkError(result, alert)
      await alert("이미지 업로드에 실패했습니다.<br/>이미지 사이즈를 확인하시고 다시 시도해 주세요.", {title: "이미지 업로드"})
    } else {
      toast.success("서면동의서가 업로드 되었습니다.")
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }

  return (
    <>
      <div className="popup_wrap">
        <div className="popup_inwrap">
          <div  className="section_tit flex_between">
            <h5>{popupData.option?.title?? ""}</h5>
            <div>
              { isMobile ? <button className="text_only agreeUp-btn" onClick={onClickChangeAgreement}>동의서 바꾸기</button>
              : (
                  <div className="filebox field" align="left" title='이미지 업로드'>
                    <label htmlFor="files" className="c_primary_6">동의서 바꾸기</label> 
                    <input type="file" id="files" name="files[]" accept="image/*" capture="environment" onChange={(e) => {onClickImage(e)}}/>
                  </div>
                )
              }
            </div>
            <span className="close_btn" onClick={onClickClose}></span>
          </div>
          <form action="" onSubmit={(e) => {e.preventDefault()}}>
            <div className="pop_content  ">
              { !!imageData ? (
                  <img src={!!imageData ? `data:image/jpeg;base64,${imageData}` : null} title="" filename=""/>
                ): (
                  <div className="loading-container">
                    <div>
                      <div className="loading"></div>
                      <div id="loading-text">loading</div>
                    </div>
                  </div>
                ) 
              } 
              </div>
          </form>
        </div>
      </div>
    </>
  );  
}
 export default AgreementPreviewPopup;