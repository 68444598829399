import { useApi } from '../../../context/ApiContext';
import { getFileData } from '../../../common/imageHelper';
import toast from 'react-hot-toast';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { useLoadingIndicator } from '../../../context/LoadingIndicatorContext';

const RegisterAgreementPopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const api = useApi()
  const {alert} = useCommonPopup()
  const {showLoading, stopLoading} = useLoadingIndicator()

  const onClickImage = async (e) => {

    let file = e.target.files[0]
    if (file == null) return

    let data = await getFileData(file)
    if (data == null) return
   
    showLoading()
    const result = await api.post("uploadParticipantAgreement", {
      project: props.projectId,
      participant: props.participant.idparticipant,
      file: data
    })
    stopLoading()
    
    if (result?.data?.result != "ok") {
      // api.networkError(result, alert)
      await alert("이미지 업로드에 실패했습니다.<br/>이미지 사이즈를 확인하시고 다시 시도해 주세요.", {title: "이미지 업로드"})
    } else {
      toast.success("서면동의서가 업로드 되었습니다.")
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }

  return (
    <>
      <div className="filebox field" align="left" title='이미지 업로드'>
        <label htmlFor="files1"><h4>카메라</h4></label> 
        <input type="file" id="files1" name="files[]" accept="image/*" capture="environment" onChange={onClickImage}/>
      </div>
      <div className="filebox field" align="left" title='이미지 업로드'>
        <label htmlFor="files2"><h4>갤러리</h4></label> 
        <input type="file" id="files2" name="files[]" accept="image/*" onChange={onClickImage}/>
      </div>
    </>
  );  
}
 export default RegisterAgreementPopup;