import $ from 'jquery';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProject } from "../../../context/ProjectContext";
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { initPopupMenu } from "../../../common/javascriptHelper";
import DeleteProjectPopup from "../popup/DeleteProjectPopup";
import ProjectNamePopup from "../popup/ProjectNamePopup";
import InviteCollaboratorPopup from "../popup/InviteCollaboratorPopup";
import UserName from '../../inc/UserName';
import ProjectNav from './ProjectNav';

const ProjectHeader = ({
  title,
  activeMenu,
  leftContent,
  rightContent,
  onBack
}) => {
  const navigate = useNavigate();
  const project = useProject();
  const { popup } = useCommonPopup();

  useEffect(() => {
    initPopupMenu();
  }, []);

  const onClickHome = () => {
    navigate("/dashboard");
  };

  const onClickDeleteProject = async () => {
    const result = await popup(
      <DeleteProjectPopup projectData={project.projectData} />,
      {
        container: "project",
        name: "project_home_pop",
        style: "del",
        hasFooter: false,
        useContainer: false,
      }
    );
    if (result === true) {
      closeNav();
      navigate("/dashboard");
    }
  };

  const onClickRename = async () => {
    const result = await popup(
      <ProjectNamePopup projectData={project.projectData} />,
      {
        container: "project",
        name: "project_home_pop",
        style: "edit",
        hasFooter: false,
        useContainer: false,
      }
    );
    if (result === true) {
      project.getProject();
    }
  };

  const onClickCollaborators = async () => {
    const result = await popup(
      <InviteCollaboratorPopup projectData={project.projectData} />,
      {
        container: "project",
        name: "SurveySharePopup",
        style: "edit",
        hasFooter: false,
        useContainer: false,
      }
    );
    if (result === true) {
      project.getProject();
    }
  };

  const openNav = () => {
    $(".project_head .nav").addClass("active");
    $(".project_head .nav .dim_bg").show();
    $("body").addClass("pop");
  };

  const closeNav = () => {
    $(".project_head .nav").removeClass("active");
    $(".project_head .nav .dim_bg").hide();
    $("body").removeClass("pop");
  };

  const renderTitle = () => {
    if (activeMenu === "home") {
      return (
        <h4>
          {title}
          <i className="icon-edit" onClick={onClickRename}></i>
        </h4>
      );
    }
    return (
      <h4>
        {onBack && <i className="icon-arrowleft" onClick={onBack}></i>}
        {title}
        {leftContent}
      </h4>
    );
  };

  const renderRightContent = () => {
    if (activeMenu === "home") {
      return (
        <>
          <button className="secondary large gray" onClick={onClickCollaborators}>
            <i className="icon-useradd"></i>공동 작업자 초대
          </button>
          {rightContent}
        </>
      );
    }
    return rightContent;
  };

  return (
    <header>
      <div className="project_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>
              {project.projectData?.eCRF === 1 && (
                <i className="icon-menu" onClick={openNav}></i>
              )}
              <h1>
                <a onClick={onClickHome}>
                  <i className="icon-logo"></i>
                </a>
              </h1>
              {renderTitle()}
            </div>
            <div>
              {renderRightContent()}
              <UserName />
              <div className="dot_menu popmenu">
                <i className="icon-dot-menu"></i>
                <ul className="menu_pop">
                  <li className="pj_del_btn" onClick={onClickDeleteProject}>
                    프로젝트 삭제
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="nav">
          <ProjectNav onClickMenu={closeNav} activeMenu={activeMenu} />
        </div>
      </div>
    </header>
  );
};

export default ProjectHeader;