import { Link } from 'react-router-dom';
import SurveyHeader from '../inc/SurveyHeader';
import NewProjectPopup from '../popup/NewProjectPopup';
import ProjectHomePopup from '../popup/ProjectHomePopup';
import SurveyScalePopup from '../popup/SurveyScalePopup';
import SurveySendPopup from '../popup/SurveySendPopup';
import MemoPopSingle  from '../popup/memoPopSingle';
const SurveyAnswer2 = props => {
  return (      
    <div className="body project SurveyAnswer SurveyAnswer2">
      <SurveyHeader/>
      <NewProjectPopup/>
      <ProjectHomePopup/>
      <SurveyScalePopup/>
      <SurveySendPopup/>

      <div className="container">
        <div className="inwrap">
          <div className="full gap">
          {/* 상단 */}
          <section className="">
            <div className="top_wrap">
             <h5 className='bold'>응답 3개</h5>
             <div>
              <button className="secondary gray large">EXCEL 다운로드</button>
              <button className="secondary gray large">CVS 다운로드</button>
             </div>
            </div> 
            <div className='top_wrap menu_wrap'>
              <ul className='sub_menu'>
                <li className=""><a href="SurveyAnswer">요약보기</a></li>
                <li className="active"><a href="SurveyAnswer2" className="">개별보기</a></li>
              </ul>
              <ul className="tab">
                <li className="active">전체</li>
                <li>내 기관</li>
              </ul>            
            </div>
            <ul className='nameList'>
              <a className='active' href="">WId21</a>
              <a href="">1dgla</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              <a href="">Lfi81</a>
              
            </ul>
          </section>

          <section className="">
            <table className="line">
              <colgroup>
                <col className='col80' />
                <col />
                <col className='col300' />
                <col className='col100' />
                <col className='col100' />
              </colgroup>
              <thead>
                  <tr>
                    <th className='center'>번호</th>
                    <th>질문</th>
                    <th>답변</th>
                    <th className='center'>점수</th>
                    <th className='center'>메모</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='center'>1</td>
                  <td>아무런 이유 없이 기운이 없던 적이 얼마나 됩니까?</td>
                  <td>한 달 내내</td>
                  <td className='center'>4</td>
                  <td className='center'>
                  <MemoPopSingle/>
                    <i className='icon-memo_ing memo-btn'></i><span class="dot"></span>
                  </td>
                </tr>
                <tr>
                  <td className='center'>2</td>
                  <td>신경이 예민했던 날은 얼마나 됩니까?</td>
                  <td>한 달 중 며칠</td>
                  <td className='center'>1</td>
                  <td className='center'>
                    <i className='icon-memo_plus memo-btn'></i>
                  </td>
                </tr>
                <tr>
                  <td className='center'>3</td>
                  <td>신경이 너무 예민해서 귀하를 안정시킬 수 있는 것이 아무것도 없다고 느끼신 적이 얼마나 됩니까? 문장이 길어지면 줄바꿈처리하고 모두 보여줍니다.</td>
                  <td>한 달 내내</td>
                  <td className='center'>4</td>
                  <td className='center'>
                    <i className='icon-memo_end memo-btn'></i>
                  </td>
                </tr>
                <tr>
                  <td className='center'>4</td>
                  <td>네/아니요 질문의 답변 예시입니다.</td>
                  <td>네</td>
                  <td className='center'>1</td>
                  <td className='center'>
                    <i className='icon-memo_plus memo-btn'></i>
                  </td>
                </tr>
                <tr>
                  <td className='center'>5</td>
                  <td>점수형 질문의 답변 예시입니다.</td>
                  <td>5</td>
                  <td className='center'>5</td>
                  <td className='center'>
                    <i className='icon-memo_ing memo-btn'></i>
                  </td>
                </tr>
                <tr>
                  <td className='center'>6</td>
                  <td>주관식 질문의 답변 예시입니다.</td>
                  <td>주관식 질문에는 주관식 답변을 합니다.</td>
                  <td className='center'>0</td>
                  <td className='center'>
                    <i className='icon-memo_plus memo-btn'></i>
                  </td>
                </tr>
                <tr>
                  <td className='center'>7</td>
                  <td>날짜형 질문의 답변 예시입니다.</td>
                  <td>2023.05.25.</td>
                  <td className='center'>0</td>
                  <td className='center'>
                    <i className='icon-memo_plus memo-btn'></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>


         </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default SurveyAnswer2;