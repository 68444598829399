import $ from 'jquery';
import { useEffect } from "react";

const SurveySendPopup = props => {

  useEffect(()=> {
    // 설문보내기
    $('.survey_send_btn').click(function() {
      $('.SurveySendPopup, .SurveySendPopup .survey_send, .dim_bg').fadeIn();
    });

    $('.make-link').click(function() {
      $(this).hide();
      $(this).next().show();
    });
    $('.link-del').click(function() {
      $('.make-link').show();
      $(this).parent().parent().parent().hide();
    });

    function copyFunction() {
      // Get the text field
      var copyText = document.getElementById("survey-link");
    
      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
    
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      
      // Alert the copied text
      alert("복사 완료");
    }
  }, []);

  return (



    <>
   
    <div className="popup SurveySendPopup">
        <div className="popup_wrap">

        {/* 설문보내기 */}
        <div className="popup_inwrap survey_send">
          <h5 className="section_tit">설문 보내기  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">
                  <h4>
                  설문 링크를 만들어서 응답자에게 공유하면 링크를 통해 누구나  설문에 참여할 수 있습니다.
                  </h4>
                  <h4>
                  설문 링크를 만든 후에는 주요 설문 편집 기능인 문항 추가, 삭제,  옵션 편집, 순서 변경 등이 제한됩니다.
                  </h4>
                  <h4>
                  만약 이러한 변경이 필요한 경우, 해당 설문을 종료하고 새로운  복사본을 생성하여 다시 시작해야 합니다. 기존 참여자들도 필요에  따라 다시 참여해야 한다는 점을 유의해 주세요.
                  </h4>
                  <h4>
                  설문 링크를 만들어서 설문을 보내시겠습니까?
                  </h4>
              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">링크만들기</button>
                  </div>
                </div>
              </div>
          </form>
        </div>

        {/* 설문보내기 */}
        <div className="popup_inwrap survey_send">
          <h5 className="section_tit">설문 보내기  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content height_auto">
              <div>
                <div className="input_wrap flex_c">
              

                      <div class="vertical_form">

                      <div>
                        <input type="text" id="survey-link" placeholder="" value="http://www.xxx.com/agbdeFG" class="full big "/>
                      </div>
                      <div>
                        <button type="button" class="full c_primary_6"   onclick="copyFunction()">복사</button>
                      </div>

                    </div>
                    <div>
                    <button type="button" className=" error large ">설문 종료하기</button>
                  </div>

                  </div>
                </div>
             
              </div>
             
          </form>
        </div>

        {/* 설문보내기 */}
        <div className="popup_inwrap survey_send">
          <h5 className="section_tit">설문 종료하기  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content height_auto">
              <h4>
              설문을 종료하면 설문에 대한 접근이 차단되며 참여자들은 설문을 더 이상 볼 수 없습니다. 한 번 종료된 설문을 다시 진행하려면 새로운 복사본을 생성하여  설문을 시작해야 합니다.
              </h4>
              <h4>
              설문을 종료하시겠습니까?
              </h4>
      
              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">종료</button>
                  </div>
                </div>
              </div>
          </form>
        </div>

        </div>
      </div>
      <div className='dim_bg'></div>
      
      </>



  );  
}
 export default SurveySendPopup;