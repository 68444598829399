import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { validatePhoneNumber } from '../../../common/stringHelper';

const ChangePhoneNumberPopup = props => {

  const {popupData, onCancel, onConfirm, phoneno} = props
  const api = useApi()  
  const {alert} = useCommonPopup()

  const [phoneNumber, setPhoneNumber] = useState()
  const [error, setError] = useState("")
  const phoneNumberInputRef = useRef()

  useEffect(()=> {
    
    setTimeout(() => { 
      phoneNumberInputRef.current?.focus()
    }, 200)

    return () => {
    }
  }, []);


  const onChangePhoneNumber = (e) => {

    const value = e.target.value

    setPhoneNumber(value)
    if (value.length > 0 && !validatePhoneNumber(value)) {
      setError("숫자만 입력해주세요.")
    } else {
      setError("")
    }       
  }

  const onKeyEventPhoneNumber = (e) => {
  
    if (e.key === 'Enter') {
      onClickConfirm()
    }
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickConfirm = async () => {

    if (phoneNumber?.length == 0 || !validatePhoneNumber(phoneNumber)) return

    const result = await api.post("updatePhoneNo", {
      newphoneno: phoneNumber,
    })
    
    if (result?.data?.result != "ok") {
      await api.networkError(result, alert)
    } else {     
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }  

  return (
    // <div className="popup project_home_pop" >
    <div className="popup_wrap">
    {/* 공용 */}
    <div className="popup_inwrap common_pop medium">
      <h5 className="section_tit">휴대폰 번호 변경<span className="close_btn" onClick={onClickClose}></span></h5>
      <form action="" onSubmit={(e) => {e.preventDefault()}}>
        <div className="pop_content">
          <label htmlFor="phoneno">변경할 휴대폰 번호를 입력해 주세요.<br/> </label>                 
          <div>
            <input name="phoneno" 
              type="text" 
              ref={phoneNumberInputRef}
              className={`full ${error.length > 0 ? "error" : ""}`}
              placeholder="비밀번호" 
              autoComplete="new-phoneno"
              value={phoneNumber}              
              onChange={onChangePhoneNumber}
              onKeyUp={onKeyEventPhoneNumber}/>
              <p className="tip">{error}</p>
          </div>          
        </div>
        <div className="pop_foot">
          <div className="but_wrap">
            <div>
              <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
            </div>
            <div>
              <button type="button" className={`primary large ${!phoneNumber || phoneNumber?.length === 0 || error?.length > 0 ? 'disabled' : ''}`} 
                disabled={!phoneNumber || phoneNumber?.length === 0 || error?.length > 0}
                onClick={onClickConfirm}>확인</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    </div>
    //  </div>
  );  
}
 export default ChangePhoneNumberPopup;