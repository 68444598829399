import $ from 'jquery';
import { useEffect } from "react";

const ParticipantPopup5 = props => {

  useEffect(()=> {

    $('.agreeView-btn').click(function() {
      $('.Participant_pop5, .Participant_pop5 .popup_inwrap ').fadeIn();
    });
    $('.agreeDel-btn').click(function() {
      $('.Participant_pop5, .Participant_pop5 .popup_inwrap ').hide();
      $('.Participant_pop6, .Participant_pop6 .popup_inwrap ').fadeIn();
    });
    $('.agreeUp-btn').click(function() {
      $('.Participant_pop5, .Participant_pop5 .popup_inwrap ').hide();
      $('.Participant_pop4, .Participant_pop4 .popup_inwrap ').fadeIn();
    });

  }, []);

  return (
 
    <div className="popup Participant_pop5" >
        <div className="popup_wrap">

        {/* 서면 동의서 미리보기 */}
        <div className="popup_inwrap">
          <div  className="section_tit flex_between">
            
            <h5>12yAB님의 서면 동의서  </h5>
            <div>
              <button class="text_only agreeDel-btn" >삭제</button>
              <button class="text_only agreeUp-btn" >동의서 바꾸기</button>
            </div>

            <span className="close_btn"></span>
          </div>
          
          <form action="">
            <div className="pop_content  ">
              <img src="../../../assets/images/participantPopup_img.jpg"/>

              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">확인</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
        </div>
      </div>

  );  
}
 export default ParticipantPopup5;