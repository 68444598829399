import { useEffect, useRef, useState } from 'react';
import { handleSelect } from '../../../common/javascriptHelper';
import { useApi } from '../../../context/ApiContext';
import { useSurvey } from '../../../context/SurveyContext';
import { SURVEY_QUESTION_TYPE_SCORE,
  SURVEY_QUESTION_TYPE_YESORNO,
  SURVEY_STATUS_CLOSED,
  SURVEY_STATUS_WRITING,
  SURVEY_QUESTION_TYPE_MULTIPLECHOICE,
  SURVEY_QUESTION_TYPE_SHORTANSWER,
  } from '../../../common/codeHelper';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const QuestionItem = props => {

  const api = useApi()
  const survey = useSurvey()
  const {confirm} = useCommonPopup()

  var { index, section, question, selectedItem, onChangeItem } = props
  var {item} = props
  // var [item, setItem] = useState(null)
  var [moveQuestionList, setMoveQuestionList] = useState()
  var [moveQuestion, setMoveQuestion] = useState()

  const oldData = useRef(props.item)
  const isLock = useRef(false)

  const timer = useRef({
    content: null,
    score: null,
    reasonguide: null,
    prefixguide: null,
    suffixguide: null,
    placeholder: null,
  })

  const handleCheckboxChange = (field, checked) => {
    updateItemData(field, checked ? "" : null, true);
  };

  /*=========================================================
   *  useEffect
   =========================================================*/

   useEffect(_ => {

    return ()=>{
      clearTimeout(timer.current.content)
      clearTimeout(timer.current.score)
      clearTimeout(timer.current.reasonguide)
      clearTimeout(timer.current.prefixguide)
      clearTimeout(timer.current.suffixguide)
      clearTimeout(timer.current.placeholder)
    }
  }, [])

  useEffect(_ => {

    oldData.current = {
      ...props.item
    }
  }, [props])

  useEffect(_ => {

    const list = []
    survey.surveyData?.sections.forEach(element1 => {
      if ( section?.orderno <= element1.orderno) {
        element1.questions.forEach(element2 => {
          if (section?.orderno < element1.orderno || question?.orderno < element2.orderno) {
            list.push(element2)
          }
        });
      }
    });

    setMoveQuestion(survey.surveyData?.sections.filter(
      element1 => element1.orderno === item?.movetosection
      )?.[0]?.questions.filter(
        element2 => element2.orderno === item?.movetoquestion
      )?.[0]
    )

    setMoveQuestionList(list)
  }, [survey.surveyData])

  /*=========================================================
   *  Data
   =========================================================*/

  const deleteItem = async () => {

    if (question == null) return

    if (isLock.current) return
    isLock.current = true
    const result = await api.post("deleteItem", {
      item: item.iditem,
    })

    setTimeout(() => {
      isLock.current = false
    }, 200)

    return result?.data?.result == "ok"
  }

  const updateItem = async (name, value, action, extra) => {

    if (question == null) return

    var params = {
      ...extra,
      item: item.iditem,
      [name]: value
    }

    const result = await api.post(action, params)
    return result?.data?.result == "ok"
  }

  const updateData = async (name, value, action, immidiate, extra) => {

    if (oldData.current[name] == value) return

    if (immidiate) {
      var result = await updateItem(name, value, action, extra)
      if (result) {
        // await getQuestion(question.idquestion)
      }
      return result
    } else {
      survey.updateItem(section, question, {
        ...item,
        [name]: value
      })
      if (!!timer.current[name]) clearTimeout(timer.current[name])
      timer.current[name] = setTimeout(() => {
        updateItem(name, value, action, extra)
      }, survey.saveTimeout)
      return true
    }
  }

  const invokeUpdateItem = async (name, value) => {
    return await api.request(`/items/${item.iditem}/`, {
      method: "patch",
      params: { [name]: value }
    });
  }

  const updateItemData = async (name, value, immediate = false) => {
    if (oldData.current[name] === value) return;

    if (immediate) {
      const result = await invokeUpdateItem(name, value)
      if (result.status === 200) {
        survey.updateItem(section, question, {
          ...result.data,
        })
      }
      return result.status === 200
    } else {
      survey.updateItem(section, question, {
        ...item,
        [name]: value
      })
      if (!!timer.current[name]) clearTimeout(timer.current[name])
      timer.current[name] = setTimeout(() => {
        invokeUpdateItem(name, value)
      }, survey.saveTimeout)
      return true
    }
  }

  const onChangeReasonGuide = (value, immediate = false) => {
    updateItemData("reasonguide", value, immediate);
  }

  /*=========================================================
   *  Handler
   =========================================================*/
  const onChangeContent = (e) => {

    var value = e.target.value
    updateItemData("content", value, false)
  }

  const onChangeScore = async (e) => {

    var value = Number(e.target.value)
    if (isNaN(value)) {
      return
    }

    if (e.target.value.length > 0 && question.fromscale === 1 && section.scoreupdated === 0) {
      if (await confirm(`해당 섹션의 모든 문항의 점수를 현재 문항의 점수와 같이 변경하시겠습니까?
      <br/><br/>
      진행을 원하시면 확인 버튼을 눌러주세요.
      `, {title : "점수 일괄 변경 적용"}) === false) return
      if (await updateData("score", value, "updateItemScore", true, {batch: 1})) {
        survey.getSurvey(section.idsection, question.idquestion)
      }
    } else {
      updateData("score", value, "updateItemScore", false, {batch: 0})
    }
  }

  const onClickSelect = (e) => {

    if (survey.surveyData?.status !== SURVEY_STATUS_WRITING) return
    handleSelect(e)
  }

  const onClickMoveEnd = () => {
    updateData("question", -1, "updateItemMoveTo", true, {section: -1})
  }

  const onClickMoveQuestion = (question) => {
    updateData("question", question.orderno, "updateItemMoveTo", true, {section: question.sectionIndex})
  }

  const onClickDelete = async () => {

    var result = await deleteItem()
    if (result) {
      onChangeItem()
    }
  }

  const disabled = survey.surveyData?.status !== SURVEY_STATUS_WRITING

  return (
    <div className="question-item">
      {question?.type === SURVEY_QUESTION_TYPE_SCORE ? (
        <div className="row">
          <div className="left_label">
            <label className={item === selectedItem ? "active" : ""}> {item?.score}점</label>
            <input
              type="text"
              className={`full ${survey.hasError(`question-${question?.idquestion}`, `item-${item?.iditem}`) ? "error" : ""}`}
              placeholder=""
              value={item?.content ?? ""}
              onChange={onChangeContent}
              disabled={disabled}
            />
          </div>
        </div>
      ) : question?.type === SURVEY_QUESTION_TYPE_SHORTANSWER ? (
        <div className='short-answer-question-item'>
          <div className="input-group">
            <input
              type="text"
              className="prefix"
              placeholder="앞 가이드"
              value={item.prefixguide || ""}
              onChange={(e) => updateItemData("prefixguide", e.target.value)}
              disabled={item.prefixguide === null || disabled}
            />
            <input
              type="text"
              className="full-width"
              placeholder="답변 입력란 안내문구"
              value={item.placeholder || ""}
              onChange={(e) => updateItemData("placeholder", e.target.value)}
              disabled={disabled}
            />
            <input
              type="text"
              className="suffix"
              placeholder="뒤 가이드"
              value={item.suffixguide || ""}
              onChange={(e) => updateItemData("suffixguide", e.target.value)}
              disabled={item.suffixguide === null || disabled}
            />
          </div>

          <div className="checkbox-group">
            <label className="checkbox-wrapper">
              <input
                type="checkbox"
                id="usePrefix"
                checked={item.prefixguide !== null}
                onChange={(e) => handleCheckboxChange("prefixguide", e.target.checked, true)}
                disabled={disabled}
              />
              <span>앞에 가이드</span>
            </label>

            <label className="checkbox-wrapper">
              <input
                type="checkbox"
                id="useSuffix"
                checked={item.suffixguide !== null}
                onChange={(e) => handleCheckboxChange("suffixguide", e.target.checked, true)}
                disabled={disabled}
              />
              <span>뒤에 가이드</span>
            </label>

            <label className="checkbox-wrapper number-only">
              <input
                type="checkbox"
                id="numberOnly"
                checked={item.numberonly === true}
                onChange={(e) => updateItemData("numberonly", e.target.checked, true)}
                disabled={disabled}
              />
              <span>숫자만</span>
            </label>
          </div>
        </div>
      ) : (
        <div className={`row vertical_form gap yesno
          ${question?.scoring !== 1 ? "no_score" : ""}
          ${question?.type === SURVEY_QUESTION_TYPE_YESORNO ? "no_icon" : ""}`}>
          <div>
            <div>
              <span className={`num ${(survey.surveyData?.status !== SURVEY_STATUS_WRITING || item?.isetc === 1) ? "no_hover" : ""}`}
                onMouseOver={() => {survey.surveyData?.status === SURVEY_STATUS_WRITING && !!props.setDraggable && props.setDraggable(true)}}
                onMouseLeave={() => {survey.surveyData?.status === SURVEY_STATUS_WRITING && !!props.setDraggable && props.setDraggable(false)}}
              >{(item?.index ?? 0) + 1}</span>
              <input
                type="text"
                className='full big solo'
                placeholder=""
                value={item?.content ?? ""}
                onChange={onChangeContent}
                disabled={survey.surveyData?.status === SURVEY_STATUS_CLOSED || item.isetc === 1}
              />
            </div>
            {question?.moveafteranswer === 1 && (
              <div className="left_label answer-move" data-position={index}>
                <label>답변이동</label>
                <div className="selectBox large" onClick={onClickSelect}>
                  <span className={`label ${survey.surveyData?.status !== SURVEY_STATUS_WRITING ? "c_disabled" : ""}`}>
                    {!!moveQuestion ? `${moveQuestion.sectionIndex}-${moveQuestion.orderno}. ${moveQuestion.content}`
                    : item?.movetosection === -1 || item?.movetoquestion === -1 ? "종료" : "이동할 문항을 선택해 주세요."}
                  </span>
                  <ul className="optionList">
                    {moveQuestionList?.map((question, index2) =>
                      <li className="optionItem" key={`move-option-${index2}`} onClick={() => onClickMoveQuestion(question)}>{question.sectionIndex}-{question.orderno}. {question.content}</li>
                    )}
                    <li className="optionItem" onClick={() => onClickMoveEnd()}>종료</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div>
            {question?.scoring === 1 && (
              <>
                <span>점수</span> <span>:</span>
                <input
                  type="text"
                  className='big solo score'
                  value={item?.score ?? ""}
                  onChange={onChangeScore}
                  disabled={survey.surveyData?.status !== SURVEY_STATUS_WRITING}
                />
              </>
            )}
            {question?.type !== SURVEY_QUESTION_TYPE_YESORNO && survey.surveyData?.status === SURVEY_STATUS_WRITING && (
              <i className={`icon-minuscircle ${question?.items?.length > 1 ? "" : "c_disabled"}`} onClick={onClickDelete}></i>
            )}
          </div>
        </div>
      )}
      {question?.inputreason === 1 && item.isetc !== 1 && question?.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE && (
        <div className="row reason-input-wrapper">
          <label>
            <input
              type="checkbox"
              checked={item.reasonguide !== null}
              onChange={(e) => onChangeReasonGuide(e.target.checked ? "" : null, true)}
              disabled={survey.surveyData?.status !== SURVEY_STATUS_WRITING}
            />
            {item.reasonguide !== null ? "가이드 문구 (플레이스 홀더)를 10자 내외로 적어주세요." : "이 항목은 추가 사유 입력이 노출되지 않습니다."}
          </label>
          {item.reasonguide !== null ? (
            <input
              type="text"
              value={item.reasonguide || ''}
              onChange={(e) => onChangeReasonGuide(e.target.value)}
              placeholder="답변 입력"
              disabled={survey.surveyData?.status !== SURVEY_STATUS_WRITING}
            />
          ) : (
            <input type="text" placeholder="답변 입력 없음" value="답변 입력" class="disabled"></input>
          )}
        </div>
      )}
    </div>
  )
}


export default QuestionItem;
