import { Link } from 'react-router-dom';
import SurveyHeader from '../inc/SurveyHeader';
import NewProjectPopup from '../popup/NewProjectPopup';
import ProjectHomePopup from '../popup/ProjectHomePopup';
import SurveyScalePopup from '../popup/SurveyScalePopup';
import SurveySendPopup from '../popup/SurveySendPopup';
import CommonPopup from '../popup/CommonPopup';
const Survey = props => {
  return (      
    <div className="body project survey">
      <SurveyHeader/>
      <NewProjectPopup/>
      <ProjectHomePopup/>
      <SurveyScalePopup/>
      <SurveySendPopup/>
      <CommonPopup/>
      <div className="container">
        <div className="inwrap">
          <div className="full gap">
          <section className="">
            <div className="search_wrap">
              <input type="text"  className='full big'placeholder="제목을 입력해주세요."/>
            </div>  
          </section>
          <div className='qustion_wrap'>
            <div className="question_list">
              <ul>
                <li className='active c_disabled'><h5>1. 연구 동의 및 사전 문항</h5></li>
                <li>
                  <p>1-1. 이 연구는 자발적인 참여이며, 참가 여부와 무관하게 언제든지 연구에서 철수할 수 있습니다. 이에 동의하십니까?</p>
                  <i className='icon-warning c_danger'></i>
                  <div>
                    <i className='icon-delete survey-del-btn'></i>
                    <div className='del_popup'>
                      <div className='pop_in'>
                      <p>삭제했습니다.</p>
                      <button type='button' className="text_only">실행취소</button>
                      </div>
                    </div>
                    <i className='icon-menu'></i>
                  </div>
                </li>
                <li>1-2. 우울증 진단을 받은 적이 있으신가요?
                <div>
                  <i className='icon-delete survey-del-btn'></i>
                  <div className='del_popup'>
                    <div className='pop_in'>
                    <p>삭제했습니다.</p>
                    <button type='button' className="text_only">실행취소</button>
                    </div>
                  </div>
                  <i className='icon-menu'></i>
                </div>
                </li>
                <li  className='active'>
                  <h5>2. 한국어판 정신건강 선별도구</h5>
                  <span>그룹 A (시험군) 만</span>
                  
                  <div>
                  <i className='icon-delete survey-del-btn'></i>
                  <div className='del_popup'>
                    <div className='pop_in'>
                    <p>삭제했습니다.</p>
                    <button type='button' className="text_only">실행취소</button>
                    </div>
                  </div>
                  <i className='icon-menu'></i>
                </div>
                </li>
                <li>2-1. 아무런 이유없이 기운이 없던 적이 얼마나 됩니까?</li>               
                <li>2-2. 신경이 예민했던 날은 얼마나 됩니까?</li>
                <li>2-3. 신경이 너무 예민해서 귀하를 안정시킬 수 있는 것이 아무 것도 없다고 느끼신 적이 얼마나 됩니까?</li>
                <li>2-4. 희망이 없다고 느끼신 적이 얼마나 됩니까?</li>
                <li>2-5. 안절부절 못하거나 조바심을 냈던 적은 얼마나 됩니까?</li>

                
              </ul>
              <div className="qlist_foot">
                <button className="text_only">문항 추가</button>
                <button className="text_only scale_view">표준척도 불러오기</button>
                <button className="text_only">섹션추가</button>
              </div>
            </div>
            <div className="question">
              {/* 섹션 생성 */}
              <section>
                <div className='in_padding flex_c question_body'>
                  <div>
                    <label htmlFor="">섹션명</label>
                    <input type="text"  className='full big'placeholder="섹션명을 입력해주세요."/>
                  </div>
                  <div>
                    <label htmlFor="">설명</label>
                    <textarea name="" id="" cols="30" rows="10" className='full' placeholder='섹션명과 설명은 설문하는데 필요한  개념, 유의사항 등을 전달하는 수단으로 활용하세요.
                    개별 설문에는 최소 1개의 섹션이 필요하며, 필요에 따라서 추가하시면 됩니다.
                    섹션 화면은 사용자에게 설문 표지처럼 보여진다는 점을 명심하세요.
                    '>
                    </textarea>
                    <p className='text_right c_disabled'> 0 / 100</p>
                  </div>
                  <div>
                    <label htmlFor="">섹션 공통 질문 
                    <span className='tooltip'><i className='icon-infocircle '></i> <span className="tooltiptext tooltip-top">문항마다 섹션 공통 질문이 함께 노출되어 보다 쉽고 정확한 설문조사 응답이 가능합니다.</span></span> 
                    </label>
                    <textarea name="" id="" cols="30" rows="10" className='full' placeholder='응답자가 문항에 답변할 때, 해당 문항이 속한 섹션의 공통 질문도 함께 제공됩니다. 
                    연속된 질문의 경우 공통 질문을 활용하면 응답자가 설문 조사를 더욱 이해하기 쉽게 만들어 줄 수 있습니다.
                    입력하지 않으면 섹션 공통 질문은 응답자에게 노출되지 않습니다.'>
                    </textarea>
                    <p className='text_right c_disabled'> 0 / 100</p>
                  </div>
                </div>
                <div className='question_foot'>
                   <div className="in_padding">
                    <div >
                    <div className="selectBox">
                    <span className="label">모든 그룹 응답</span>
                    <ul className="optionList">
                      <li className="optionItem">모든 그룹 응답</li>
                      <li className="optionItem">그룹 A (시험군) 만 응답</li>
                      <li className="optionItem">그룹 B (대조군) 만 응답</li>
                    </ul>
                   </div>
                   <span className='tooltip'>
                      <i className='icon-infocircle '></i> 
                      <span className="tooltiptext tooltip-top">그룹을 지정하면 해당 그룹만 섹션에 응답할 수 있습니다. 이 기능은 그룹별로 다른 질문을 하고자 할 때 사용됩니다.</span>
                    </span> 

                    </div>
                   
                   

                    <i className='icon-copy'></i>
                    <i className='icon-delete survey-del-btn'></i>
                    {/* <i className='icon-delete c_disabled '></i> */}
                    <div className='del_popup'>
                      <div className='pop_in'>
                      <p>삭제했습니다.</p>
                      {/* <button type='button' className="text_only">실행취소</button> */}
                      </div>
                    </div>
                   </div>
                </div>
              </section>
              
              {/* 네/아니요 질문 */}
              <section>
                <div className='in_padding flex_c question_head'>
                  <div className='vertical_form gap align_item_top'>
                    <div  className='Survey_num'>
                      <span>1-1.</span>
                      {/* <input type="text" className="full big solo" placeholder="문항"/> */}
                      <textarea className="full big solo" id="textarea_auto" placeholder="문항" rows={1} ></textarea>
                    </div>
                    <div>
                    <div className="box full">
                      <div className="selectBox large">
                        <span className="label">네/아니요 질문</span>
                          <ul className="optionList">
                            <li className="optionItem">네/아니요 질문</li>
                            <li className="optionItem">객관식</li>
                            <li className="optionItem">점수형</li>
                            <li className="optionItem">주관식</li>
                          </ul>
                        </div>  
                      </div>
                    </div>
                  </div>
                
                </div>
                {/* 점수 있을때 */}
                <div className='in_padding flex_c gap question_body'>
                  <div  className='vertical_form gap yesno  '>
                    <div>
                      <div>
                        <span className='num'>1</span>
                        <input type="text" className='full big solo' placeholder="" value='네'/>
                      </div>
                      <div className='left_label' >
                        <label>답변이동</label>
                        <div className="selectBox large full">
                          <span className="label">이동할 문항 선택</span>
                          <ul className="optionList">
                            <li className="optionItem">1번 문항으로</li>
                            <li className="optionItem">2번 문항으로</li>
                            <li className="optionItem">3번 문항으로</li>
                            <li className="optionItem">4번 문항으로</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span>점수</span> <span>:</span>
                        <input type="text" className='big solo score' value='10'/> 
                        {/* <i className='icon-minuscircle'></i> */}
                    </div>
                  </div>  


                  <div  className='vertical_form gap yesno  '>
                    <div>
                      <span className='text'>사유</span>
                      <input type="text" className='full disabled big solo' placeholder="사유" value=''/>
                    </div>
                 
                  </div>    


                  {/* 점수 없을때 */}
                  <div  className='vertical_form gap yesno no_score '>
                    <div>
                    <span className='num'>2</span>
                    <input type="text" className='full big solo' placeholder="" value='아니요'/>
                    </div>

                    <div className='left_label' >
                      <label>답변이동</label>
                      <div className="selectBox large full">
                        <span className="label">이동할 문항 선택</span>
                        <ul className="optionList">
                          <li className="optionItem">1번 문항으로</li>
                          <li className="optionItem">2번 문항으로</li>
                          <li className="optionItem">3번 문항으로</li>
                          <li className="optionItem">4번 문항으로</li>
                        </ul>
                      </div>
                  </div>

                 
                   
                   
                  </div>


                  <div  className=' vertical_form gap yesno no_score '>
                    <div>
                      <span className='text'>사유</span>
                      <input type="text" className='full disabled big solo' placeholder="사유" value=''/>
                    </div>
                    
                  </div>

                  


                </div>
                <div className='question_foot in_padding'>
                  <p>
                    <span>필수</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                  <p>
                  <span>다중선택</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                  
                  <div className="filebox field" align="left" title='이미지 업로드'>
                  <label for="files"><i className="icon-fileimage" ></i></label> 
                    <input type="file" id="files" name="files[]" multiple />
                  </div>
            
                  <i className='icon-copy' title='복사'></i>
                  <i className='icon-delete survey-del-btn' title='삭제'></i>
                  {/* <i className='icon-delete c_disabled '></i> */}
                  <div className='del_popup'>
                    <div className='pop_in'>
                    <p>삭제했습니다.</p>
                    <button type='button' className="text_only">실행취소</button>
                    </div>
                  </div>
                  <span className='popmenu'>
                    <i className="icon-dot-menu " ></i>
                    <ul className="menu_pop checkbox">
                      <li>
                        <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>점수 매기기</span>
                        </label>
                      </li>
                      <li>
                      <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>답변을 기준으로 문항 보내기</span>
                        </label>
                      </li>
                      <li>
                        <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>사유 입력</span>
                        </label>
                      </li>
                    </ul>
                  </span>

                  </div>
              </section>

              {/* 객관식 */}
              <section>
                <div className='in_padding flex_c question_head'>
                  <div className='vertical_form gap'>
                    <div  className='Survey_num'>
                      <span>1-1.</span>
                      <input type="text" className="full big solo" placeholder="문항"/>
                    </div>
                    <div>
                    <div className="box full">
                      <div className="selectBox large">
                        <span className="label">객관식</span>
                          <ul className="optionList">
                            <li className="optionItem">네/아니요 질문</li>
                            <li className="optionItem">객관식</li>
                            <li className="optionItem">점수형</li>
                            <li className="optionItem">주관식</li>
                          </ul>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
                {/* 점수 있을때 */}
                <div className='in_padding flex_c gap question_body'>
                  <div  className='vertical_form gap yesno'>
                    <div>
                      <div>
                        <span className='num'>1</span>
                        <input type="text" className='full big solo' placeholder="답변 입력" />
                      </div>

                      {/* 답변이동 */}
                      <div className='left_label' >
                        <label>답변이동</label>
                        <div className="selectBox large full">
                          <span className="label">이동할 문항 선택</span>
                          <ul className="optionList">
                            <li className="optionItem">1번 문항으로</li>
                            <li className="optionItem">2번 문항으로</li>
                            <li className="optionItem">3번 문항으로</li>
                            <li className="optionItem">4번 문항으로</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span>점수</span> <span>:</span>
                        <input type="text" className='big solo score' value='10'/> 
                        <i className='icon-minuscircle'></i>
                    </div>

                    

                  </div>
                  {/* 옵션추가 */}
                  <div  className='vertical_form gap yesno'>
                    <div>
                      <div>
                        <span className='num plus'></span>
                        <button className='full'>옵션 추가</button>
                        {/* <input type="text" className='full big solo' placeholder="옵션 추가" /> */}
                      </div>
                    </div>
                    <div>
                      
                    </div>
                  </div> 
                            
                  {/* 점수 없을때 */}
                  <div  className='vertical_form gap yesno no_score'>
                    <div>
                      <div>
                        <span className='num'>2</span>
                        <input type="text" className='full big solo' placeholder="답변 입력" value=''/>
                      </div>
                      {/* 답변이동 */}
                      <div className='left_label' >
                        <label>답변이동</label>
                        <div className="selectBox large full">
                          <span className="label">이동할 문항 선택</span>
                          <ul className="optionList">
                            <li className="optionItem">1번 문항으로</li>
                            <li className="optionItem">2번 문항으로</li>
                            <li className="optionItem">3번 문항으로</li>
                            <li className="optionItem">4번 문항으로</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <span>점수</span> <span>:</span>
                        <input type="text" className='big solo' value='10'/>  */}
                        <i className='icon-minuscircle'></i>
                    </div>
                  </div>
                  {/* 옵션추가 */}
                  <div  className='vertical_form gap yesno no_score' >
                    <div>
                      <div>
                        <span className='num plus'></span>
                        <button className='full'>옵션 추가</button>
                        {/* <input type="text" className='full big solo' placeholder="옵션 추가" /> */}
                      </div>
                    </div>
                    <div>
                     
                    </div>
                  </div> 


                </div>
                <div className='question_foot in_padding'>
                  <p>
                    <span>필수</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                  <p>
                  <span>다중선택</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                  <i className='icon-fileimage'></i>
                  <i className='icon-copy'></i>
                  <i className='icon-delete survey-del-btn'></i>
                  {/* <i className='icon-delete c_disabled '></i> */}
                  <div className='del_popup'>
                    <div className='pop_in'>
                    <p>삭제했습니다.</p>
                    <button type='button' className="text_only">실행취소</button>
                    </div>
                  </div>
                  <span className='popmenu'>
                    <i className="icon-dot-menu "></i>
                    <ul className="menu_pop checkbox">
                      <li>
                        <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>점수 매기기</span>
                        </label>
                      </li>
                      <li>
                      <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>답변을 기준으로 문항 보내기</span>
                        </label>
                      </li>
                    </ul>
                  </span>

                  </div>
              </section>

              {/* 점수형 */}
              <section>
                <div className='in_padding flex_c question_head'>
                  <div className='vertical_form gap'>
                    <div  className='Survey_num'>
                      <span>1-1.</span>
                      <input type="text" className="full big solo" placeholder="문항"/>
                    </div>
                    <div>
                    <div className="box full">
                      <div className="selectBox large">
                        <span className="label">점수형</span>
                          <ul className="optionList">
                            <li className="optionItem">네/아니요 질문</li>
                            <li className="optionItem">객관식</li>
                            <li className="optionItem">점수형</li>
                            <li className="optionItem">주관식</li>
                          </ul>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>

                <div className='in_padding flex_c gap question_body point_type'>
                  <div className='in_padding bg_gray point_preview'>
                    <label htmlFor="">미리보기</label>
                    <ul className=''>
                      <li>1</li>
                      <li className='active'>2</li>
                      <li>3</li>
                      <li>4</li>
                      <li>5</li>
                      <li>6</li>
                      <li>7</li>
                    </ul>
                  </div>
                  <div className='flex'>
                    <div >
                      <label htmlFor="">척도</label>
                      <div className="selectBox large">
                      <span className="label">7점</span>
                        <ul className="optionList">
                          <li className="optionItem">0점</li>
                          <li className="optionItem">1점</li>
                          <li className="optionItem">3점</li>
                          <li className="optionItem">4점</li>
                          <li className="optionItem">5점</li>
                          <li className="optionItem">6점</li>
                          <li className="optionItem">7점</li>
                          <li className="optionItem">8점</li>
                          <li className="optionItem">9점</li>
                          <li className="optionItem">10점</li>
                          <li className="optionItem">11점</li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="">시작점</label>
                      <div className="selectBox large">
                        <span className="label">0점</span>
                        <ul className="optionList">
                          <li className="optionItem">0점</li>
                          <li className="optionItem">1점</li>
                          <li className="optionItem">3점</li>
                          <li className="optionItem">4점</li>
                        </ul>
                      </div>
                    </div>


                  </div>
                  <div>
                    <label htmlFor="">채점 방식</label>
                    <div className='flex'>                    
                      <div className="radio">
                        <label>
                          <input type="radio" name="point" value="" />
                          <span>점수대로 채점</span>
                        </label>
                      </div>
                      <div className="radio">
                      <label>
                          <input type="radio" name="point" value=""/>
                          <span>점수의 역순으로 채점</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <label htmlFor="">점수 설명</label>
                    <div className='left_label' >
                      <label>1점</label>
                      <input type="text" className='full' />
                    </div>
                  </div>
                  <div>
                    <div className='left_label' >
                      <label className='active'>2점</label>
                      <input type="text" className='full' />
                    </div>
                  </div>
                  <div>
                    <div className='left_label' >
                      <label>3점</label>
                      <input type="text" className='full' />
                    </div>
                  </div>
                  <div>
                    <div className='left_label' >
                      <label>4점</label>
                      <input type="text" className='full' />
                    </div>
                  </div>
                  <div>
                    <div className='left_label' >
                      <label>5점</label>
                      <input type="text" className='full' />
                    </div>
                  </div>
                  <div>
                    <div className='left_label' >
                      <label>6점</label>
                      <input type="text" className='full' />
                    </div>
                  </div>
                  <div>
                    <div className='left_label' >
                      <label>7점</label>
                      <input type="text" className='full' />
                    </div>
                  </div>

                </div>
                <div className='question_foot in_padding'>
                  <p>
                    <span>필수</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                  <p>
                   <span>다중선택</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                  <i className='icon-fileimage'></i>
                  <i className='icon-copy'></i>
                  <i className='icon-delete survey-del-btn'></i>
                  {/* <i className='icon-delete c_disabled '></i> */}
                  <div className='del_popup'>
                    <div className='pop_in'>
                    <p>삭제했습니다.</p>
                    <button type='button' className="text_only">실행취소</button>
                    </div>
                  </div>
                  <span className='popmenu'>
                    <i className="icon-dot-menu "></i>
                    <ul className="menu_pop checkbox">
                      <li>
                        <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>점수 매기기</span>
                        </label>
                      </li>
                      <li>
                      <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>답변을 기준으로 문항 보내기</span>
                        </label>
                      </li>
                    </ul>
                  </span>

                </div>
              </section>

              {/* 주관식 */}
              <section>
                <div className='in_padding flex_c question_head'>
                  <div className='vertical_form gap'>
                    <div  className='Survey_num'>
                      <span>1-1.</span>
                      <input type="text" className="full big solo" placeholder="문항"/>
                    </div>
                    <div>
                    <div className="box full">
                      <div className="selectBox large">
                        <span className="label">주관식</span>
                          <ul className="optionList">
                            <li className="optionItem">네/아니요 질문</li>
                            <li className="optionItem">객관식</li>
                            <li className="optionItem">점수형</li>
                            <li className="optionItem">주관식</li>
                          </ul>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>

                <div className='in_padding   question_body '>
                
                  <textarea name="" id="" cols="30" rows="10" className='full font_16' placeholder='답변 입력 영역' ></textarea>
                  <p className="text_right c_disabled"> 0 / 100</p>



                </div>
                <div className='question_foot in_padding'>
                  <p>
                    <span>필수</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                 
                  <i className='icon-fileimage'></i>
                  <i className='icon-copy'></i>
                  <i className='icon-delete survey-del-btn'></i>
                  {/* <i className='icon-delete c_disabled '></i> */}
                  <div className='del_popup'>
                    <div className='pop_in'>
                    <p>삭제했습니다.</p>
                    <button type='button' className="text_only">실행취소</button>
                    </div>
                  </div>
                  {/* <span className='popmenu'>
                    <i className="icon-dot-menu "></i>
                    <ul className="menu_pop checkbox">
                      <li>
                        <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>점수 매기기</span>
                        </label>
                      </li>
                      <li>
                      <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>답변을 기준으로 문항 보내기</span>
                        </label>
                      </li>
                    </ul>
                  </span> */}

                </div>
              </section>
              {/* 날짜 */}
              <section>
                <div className='in_padding flex_c question_head'>
                  <div className='vertical_form gap'>
                    <div  className='Survey_num'>
                      <span>1-1.</span>
                      <input type="text" className="full big solo" placeholder="문항"/>
                    </div>
                    <div>
                    <div className="box full">
                      <div className="selectBox large">
                        <span className="label">날짜</span>
                          <ul className="optionList">
                            <li className="optionItem">네/아니요 질문</li>
                            <li className="optionItem">객관식</li>
                            <li className="optionItem">점수형</li>
                            <li className="optionItem">주관식</li>
                            <li className="optionItem">날짜</li>
                          </ul>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>

                <div className='in_padding   question_body '>
                  <input type="date" className='disabled'/>
                  <input type="date" className='active'/>
                </div>
                <div className='question_foot in_padding'>
                  <p>
                    <span>필수</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                 
                  <i className='icon-fileimage'></i>
                  <i className='icon-copy'></i>
                  <i className='icon-delete survey-del-btn'></i>
                  {/* <i className='icon-delete c_disabled '></i> */}
                  <div className='del_popup'>
                    <div className='pop_in'>
                    <p>삭제했습니다.</p>
                    <button type='button' className="text_only">실행취소</button>
                    </div>
                  </div>
                  {/* <span className='popmenu'>
                    <i className="icon-dot-menu "></i>
                    <ul className="menu_pop checkbox">
                      <li>
                        <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>점수 매기기</span>
                        </label>
                      </li>
                      <li>
                      <label>
                         <input type="checkbox" name="checkbox" value="phone"/>
                          <span>답변을 기준으로 문항 보내기</span>
                        </label>
                      </li>
                    </ul>
                  </span> */}

                </div>
              </section>
            </div>
            

          </div>
          


         </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default Survey;