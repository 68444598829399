import { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import AdminHeader from './AdminHeader';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';
import { useAuth } from '../../context/AuthContext';

const Admin = props => {
  
  const location = useLocation()
  const navigate = useNavigate()
  const auth = useAuth()
  const [isInit, setIsInit] = useState(false)

  /*=========================================================
   *  useEffect 
   =========================================================*/
   useEffect(_ => {
    
    const init = async () => {
      
      if (!await auth.isAuth()) {
        alert('로그인이 필요합니다.')
        navigate("/")
      } 
    }
    init()    
  }, [])

  useEffect(_ => {

    if (!auth.adminInfo) return
    if (auth.adminInfo?.level !== 1 && auth.adminInfo?.level !== 2)  {
      alert('접근권한이 없습니다.')
      navigate('/dashboard')
    }
    setIsInit(true)       
    if (location.pathname == '/admin') {
      navigate('notice')
    }
    
  }, [auth.adminInfo])

  return (
    isInit ? (<Outlet />) : (null)
  )
}
  
export default Admin;