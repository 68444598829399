const MyInfoHeader = () => {
  return (

    <header>
      <div  className="project_head suvay_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>
            <h1>
                <a href="/dashboard/"><i className="icon-logo"></i></a>
            </h1>
            <h4> <i className="icon-arrowleft"></i> 계정 정보</h4>
            </div>
            <div>
            {/* <button className="disabled large">설문 보내기</button> */}
            <button className="primary large survey_send_btn ">변경사항 저정</button>

             
            </div>
          </div>
        </div>
     
      
      </div>
      
    </header>  
    
    );
}  
export default MyInfoHeader;