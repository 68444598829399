import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useApi } from '../../context/ApiContext'
import { useAuth } from '../../context/AuthContext'
import { validateEamil } from '../../common/stringHelper'

const Signin = () => {

  const navigate = useNavigate()
  const auth = useAuth()
  const api = useApi()  

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    autoSingnin: false
  })

  const [errors, setErrors] = useState({
    emailError: "",
    passwordError: "",
    signinError: "",
  })
  
  const {email, password, autoSingnin} = inputs
  const {emailError, passwordError, signinError} = errors

  const inputEmail = useRef()
  const inputPassword = useRef()
  const [isLoading, setIsLoading] = useState(false) 
  const [tooltipState, setTooltipState] = useState("none") 
    
  useEffect(_ => {
    
    api.removeAuthToken()
    auth.setIsCheckAuth(false)
  }, [])

  const setError = (name, error) => {
    setErrors({
      ...errors, 
      [`${name}Error`]: error
    })
  }

  const checkEamil = (value) => {

    if (value.length == 0) {
      setError("email", "")
    } else if (!validateEamil(value)) {
      inputEmail.current.focus()
      setError("email", "이메일 형식에 맞지 않습니다. @를 포함하여 정확하게 입력해주세요.")
    } else {
      setError("email", "")
    }
  }

  const checkpassword = (value) => {

    if (value.length == 0) {
      setError("password", "")
    } else {
      setError("password", "")
    }
  }

  const onChangeInput = (e) => {

    var { value, name } = e.target
    value = value.replace(/ /g, "") 
    setInputs({
      ...inputs, 
      [name]: value
    })

    if (name == "email") {
      checkEamil(value)
    } else if (name == "password") {     
      checkpassword(value)
    } 
  }

  const onKeyEvent = (e) => {

    var { key, name } = e.target
    if (e.key === 'Enter') {
      if (name == "email") {
        inputPassword.current.focus()
      } else if (name == "password") {
        onClickSignin()
      }      
    }
  }

  const onChangeAutoSingnin = (e) => {

    setInputs({
      ...inputs, 
      ["autoSingnin"]: e.target.checked
    })
    if (tooltipState == "none" && e.target.checked) {
      setTooltipState("show")
      const timer = setInterval(() => {
        setTooltipState("hide")
      }, 2000)
    }    
  }

  const checkValidation = _ => {

    if (email.length == 0) {
      inputEmail.current.focus()
      setError("email", "이메일을 입력하세요.")
      return false
    }
    if (!validateEamil(email)) {
      inputEmail.current.focus()
      setError("email", "이메일 형식에 맞지 않습니다. @를 포함하여 정확하게 입력해주세요.")
      return false
    }
    if (password.length == 0) {
      inputPassword.current.focus()
      setError("password", "비밀번호를 입력하세요.")
      return false
    }
    return true
  }

  const onClickSignin = async (e) => {

    if (isLoading) return
    if (!checkValidation()) return
        
    setIsLoading(true)

    const result = await api.post("login", {
      email: email,
      password: password
    })
    
    if (result?.data?.result != "ok") {
      if (result?.data?.msg?.length > 0) {
        setError("signin", result.data.msg)
      } else {
        setError("signin", "error")
      }
      setIsLoading(false)      
    } else {

      api.setAutoSignin(autoSingnin)
      api.setAccessToken(result.data.data.accesstoken)

      setError("signin", "")      
      setIsLoading(false)
      
      navigate("/dashboard", { replace:true })
    }
  }

  const onClickAgreement = (e, agreement) => {
    
    e.preventDefault()

    window.open(`/${agreement}`)
  }

  return (
    <div className="body">
      <div  className="login">
        <div className="inwrap">
          <div>
            <i className="icon-logo"></i>
            <h4>로그인</h4>
            <p>
              리서치플로우의 관리자 화면입니다. <br/>
              회원가입 후 사용할 수 있습니다.
            </p>
            <form action="" onSubmit={(e) => {e.preventDefault()}}>
              <dl>
                <dt>이메일 </dt>
                <dd>
                  <input 
                    name="email"
                    type="text" 
                    ref={inputEmail}
                    className={emailError.length > 0 ? "error" : ""}
                    placeholder="이메일" 
                    value={email}
                    onChange={onChangeInput}
                    onKeyUp={onKeyEvent} 
                  />
                  <p className="tip">{emailError}</p>
                </dd>
                <dt>비밀번호</dt>
                <dd>
                  <input 
                    name="password"
                    type="password" 
                    ref={inputPassword}
                    className={passwordError.length > 0 ? "error" : ""}
                    placeholder="비밀번호"
                    value={password}                        
                    onChange={onChangeInput}    
                    onKeyUp={onKeyEvent}                         
                  />
                  <p className="tip">{passwordError}</p>
                </dd>
              </dl>
              <div className="checkbox">
                <label>
                  <input                     
                    type="checkbox" 
                    name="checkbox" 
                    defaultChecked={autoSingnin} 
                    onChange={onChangeAutoSingnin}
                  />
                  <span data-tooltip-id="my-tooltip">로그인 상태 유지                  
                  </span>
                  {tooltipState === "show" && <span className="tooltiptext">공용 pc를 사용할 경우 보안상의 심각한 문제가 발생할 수 있습니다. <br/> 개인 pc에서만 사용해주세요.</span>}
                </label>
              </div>
              <button type="button"  className={`primary large ${isLoading && "disabled"}`} onClick={onClickSignin}>로그인</button>
              <p className="tip">{signinError}</p>
            </form>
            <ul>
              <li><Link className="primary" to="/find-id">아이디 찾기</Link></li>
              <li><Link className="primary" to="/signup/1">회원가입</Link></li>
              <li><Link className="primary" to="/find-password/1">비밀번호 찾기</Link></li>
            </ul>
          </div>
          <div className='foot_wrap'>
            <p className='footer_top'>
              &copy; Atflow Corporation: <a href="https://atflow.kr">https://atflow.kr</a>.<br />
              사업자 등록번호: 385-88-01932 (대표: 이선영)<br />
              서울특별시 성동구 아차산로 38, 316호 앳플로우<br />
            </p>
            <p className='footer_bottom'>
              <a href="" onClick={(e) => onClickAgreement(e, "terms")}>서비스 약관</a>  • 
              <a href="" onClick={(e) => onClickAgreement(e, "privacy")}>개인정보 처리방침</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signin