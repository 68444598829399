import $ from 'jquery';
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { initPopupMenu } from '../../common/javascriptHelper';
import { useCommonPopup } from '../../context/CommonPopupContext';
import UserName from '../inc/UserName';

const AdminCustomerDetailHeader = (props) => {

  const navigate = useNavigate()
  const auth = useAuth()
  const { popup, confirm } = useCommonPopup()
  const { customer } = props

  useEffect(_ => {

    initPopupMenu()

  }, [])

  const onClickHome = () => {

    navigate("/dashboard")
  }
  
  return (
    <header>       
      <div  className="project_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>       
            <h1>
              <a onClick={onClickHome}><i className="icon-logo"></i></a>
            </h1>
            <h4> <i className="icon-arrowleft" onClick={() => navigate(-1)}></i> {customer?.name}님의 정보</h4>
            </div>
            <div>
            <UserName/>
            </div>
          </div>
        </div>
      </div>
    </header>  
  );
}  
export default AdminCustomerDetailHeader;