const AnswerHeader = (props) => {

  const {currentData} = props
  
  return (
    <header>
      <div  className="question_head">
        <div className="inwrap">
          <div>
            <h4>{currentData?.participantinitial?? ""}</h4>
            <span>{currentData?.name?? ""}</span>
          </div>
          { currentData?.completed !== 1 && 
            <h5>
              {currentData?.currentpage?? "-"}/{currentData?.totalpage?? "-"}
            </h5>
          }
          {/* <span className="close_btn"></span> */}
        </div>
        <span className="progress" style={{width: currentData?.completed === 1 ? "100%" : !!currentData?.totalpage ? `${currentData?.currentpage*100/currentData?.totalpage}%` : "0%"}}></span>
      </div>
    </header>  
  );
}  
export default AnswerHeader;