import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router';

import { useApi } from '../../context/ApiContext'
import { validateEamil, validatePassword } from '../../common/stringHelper'
import { useCommonPopup } from '../../context/CommonPopupContext';
import { Link } from 'react-router-dom';

const Agreement = (props) => {
  
  const { agreementId } = useParams()
  const api = useApi()
  const {alert} = useCommonPopup()
  const [agreementList, setAgreementList] = useState([])
  const [data, setData] = useState()

  useEffect(_ => {   
     
      getAgreementList(agreementId)
  }, [])

  const getAgreementList = async (agreementId) => {

    const result = await api.post("getAgreementInfo", {agreement:agreementId})
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)   
    } else {
      setData(result.data.data.agreement.content)
    }
  }


  return (
    <div className="body" style={{border:"unset"}}>
      <div  className="login agreement">
        <div className="inwrap">
          <div>
            <ul>
              <li>
                <i className="icon-logo"></i>
                <div  dangerouslySetInnerHTML={{__html: data} }></div>
                {/* { agreementList?.map((agreement, index) =>
                  <>
                    <a key={`link_${index}`} name={agreement.idagreement}/>
                    <div  key={`agreement_${index}`} dangerouslySetInnerHTML={{__html: agreement.content} }></div>
                  </>
                  )
                } */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agreement