import React, { useState } from 'react';

const SurveyTargetGroupPopup = ({ surveyData, targetgroups, onCancel, onConfirm, popupData }) => {
  const [selectedGroup, setSelectedGroup] = useState(surveyData.targetgroup?.id || 'all');
  const [isOpen, setIsOpen] = useState(false);

  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickConfirm = () => {
    if (!!onConfirm) {
      onConfirm(popupData, selectedGroup)
    }
  }

  const handleSelect = (value) => {
    setSelectedGroup(value);
    setIsOpen(false);
  }

  const getSelectedLabel = () => {
    if (selectedGroup === 'all') return '모든 그룹';
    const group = targetgroups?.find(g => g.id === selectedGroup);
    return group?.name || '모든 그룹';
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap common_pop medium">
        <h5 className="section_tit">참여자 그룹 변경 <span className="close_btn" onClick={onClickClose}></span></h5>
        <div className="pop_content">
          <p>{surveyData.display_name?.length > 0 ? <strong>{surveyData.display_name}</strong> : '제목없음'}<br/>
          위 설문에 참여할 참여자 그룹을 선택해주세요.<br/>
          설문 대기 상태에서만 변경할 수 있습니다.</p>

          <div className={`selectBox ${isOpen ? 'active' : ''}`}>
            <span className="label" onClick={() => setIsOpen(!isOpen)}>
              {getSelectedLabel()}
            </span>
            <ul className="optionList">
              <li className="optionItem" onClick={() => handleSelect('all')}>
                모든 그룹
              </li>
              {targetgroups?.map(group => (
                <li
                  key={group.id}
                  className="optionItem"
                  onClick={() => handleSelect(group.id)}
                >
                  {group.name}
                </li>
              ))}
            </ul>
          </div>

          <div className="pop_foot" style={{marginTop: '112px'}}>
            <div className="but_wrap">
              <button className="secondary" onClick={onClickClose}>취소</button>
              <button className="primary" onClick={onClickConfirm}>확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyTargetGroupPopup;
