export const validateEamil = (value) => {

  if (value.length === 0 || value === 'undefined') {
    return false
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return false
  }
  return true
}

export const validateInput = (value, type = "name") => {

  if (value.length === 0 || value === 'undefined') {
    return false
  }
  const pattern = type === "number" ?  /^[0-9]+$/ : /^[A-Za-z0-9_-]+$/i
  return pattern.test(value)
}

export const validateMobilePhoneNumber = (value) => {
  if (value.length === 0 || value === 'undefined') {
    return false
  }

  return /^01[0-9]{8,9}$/.test(value)
}

export const validateRealNumber = (value) => {
  if (value.length === 0 || value === 'undefined') {
    return false
  }
  return /^[0-9]+(\.[0-9]+)?$/.test(value)
}

export const validatePhoneNumber = (value) => {

  if (value.length === 0 || value === 'undefined') {
    return false
  }
   // /^(?:[+0]9)?[0-9]{10,12}$/i
      // /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
  return /^(?:[+0]9)?[0-9]{10,12}$/.test(value)
}

export const validatePhoneNumberWithHiphen = (value) => {

  if (value.length === 0 || value === 'undefined') {
    return false
  }
  if (!/^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{3,4}$/i.test(value)) {
    return false
  }
  return true
}

export const validatePassword = (email, value) => {
  if (/(\w)\1\1/g.test(value)) {
    return false
  }
  if (continuousNumber(value)) {
    return false
  }
  if (!checkEmailString(email, value)) {
    return false
  }
  return true
}

export const continuousNumber = (value) => {

  let old = -1;
  const limit = 2;
  let count = 0;

  const charList = Array.from(value);

  for (let item of charList) {
    const temp = item.charCodeAt(0);    
    if ((temp >= 48 && temp <= 57) && ((temp === old + 1) || (temp === 48 && old === 57))) {
      count = count + 1;
      if (limit <= count) {
        return true;
      }
    } else {
      count = 0;
    }
    old = temp;
  }
  return false;
}

export const checkEmailString = (email, password) => {
  const localPart = email.split('@')[0];
  const substrings = [];
  
  for (let i = 0; i < localPart.length - 2; i++) {
    substrings.push(localPart.slice(i, i + 3));
  }
  
  for (let substring of substrings) {
    if (password.toLowerCase().includes(substring.toLowerCase())) {
      return false;
    }
  }    
  return true;
}

export const decimalFormat = (value, fix) => {
    
  if (Math.round(value) === value) return value
  return value.toFixed(fix)
}

export const markKeyword = (data, keyword) => {

  if (keyword?.length === 0) return data
  
  var skeyword = keyword.replaceAll(' ', '')
  skeyword = skeyword.replaceAll(/\S+/g, match => match.split('').join('\\s*'));  
  skeyword = skeyword.replaceAll("(", "\\(")
  skeyword = skeyword.replaceAll(")", "\\)")

  var re = new RegExp(skeyword, 'i');
  var list = data.match(re)
  var result = data
  list?.forEach((match) => {
    result = result.replace(match, `<span class='c_primary_6 bold'>${match}</span>`)
  })
  return result
}

export const getYYYYMMDD = (value) => {

  var date = new Date(value)
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export const formatMobilePhone = (phone) => {
  // Validate and format the phone number if it starts with '01' and is 10 or 11 digits long
  const regex = /^01([0-9]{8,9})$/;
  if (regex.test(phone)) {
      // Insert hyphens after the third digit and then after 3 or 4 more digits, depending on the total length
      return phone.replace(/^(\d{3})(\d{3,4})(\d{4})$/, "$1-$2-$3");
  }
  return "-";
}
