import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ProjectContextProvider } from "../../context/ProjectContext";
import { useAuth } from "../../context/AuthContext";


const Project = props => {

  const { projectId } = useParams()

  const navigate = useNavigate()
  const auth = useAuth()
  const [isInit, setIsInit] = useState(false)

  useEffect(_ => {
    
    const init = async () => {
      if (!await auth.isAuth()) {
        alert('로그인이 필요합니다.')
        navigate("/")
        return
      }   
      if (projectId == null || projectId == 'undefined') {
        alert('잘못된 접근입니다.')
        navigate("/dashboard")
        return
      }
      setIsInit(true)       
    }
    init()
    
  }, [])
  

  return (
    isInit ? (
      <ProjectContextProvider projectId={projectId}>
        <Outlet />
      </ProjectContextProvider>
    ) : (null)
  )
}

export default Project;