import { useEffect, useState } from "react";
import { useApi } from "../../../context/ApiContext";
import { useProject } from "../../../context/ProjectContext";
import ProjectHeader from "../components/ProjectHeader";
import Pagination from "../../../component/Pagination";
import { handleSelect } from "../../../common/javascriptHelper";
import { useCommonPopup } from "../../../context/CommonPopupContext";
import { useNavigate } from "react-router-dom";

const SendLinkStatus = () => {
  const api = useApi();
  const project = useProject();
  const navigate = useNavigate();
  const {alert} = useCommonPopup();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [items, setItems] = useState();
  const [originalTotalCount, setOriginalTotalCount] = useState(0);

  const statusOptions = [
    { value: 'all', label: '발신 상태 : 전체' },
    { value: 'WAITING', label: '대기 중' },
    { value: 'IN_PROGRESS', label: '진행 중' },
    { value: 'COMPLETED', label: '완료' },
    { value: 'FAILED', label: '실패' },
  ];

  const getList = async () => {
    if (!project.projectId) return;

    const params = {
      page,
      limit,
      ...(search && { search }),
      ...(status !== "all" && { status })
    }

    const result = await api.request(
      `/projects/${project.projectId}/send_link_statuses/`,
      { params }
    );

    if (result?.status === 200) {
      setItems(result.data.results || []);
      setTotal(result.data.count || 0);
      setOriginalTotalCount(result.data.originalTotalCount || 0);
    }
  };

  useEffect(() => {
    getList();
  }, [project.projectId, page, limit, search, status]);

  const onSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const onClickSearch = () => {
    getList();
  };

  const onKeyEvent = (e) => {
    if (e.key === 'Enter') {
      onClickSearch();
    }
  };

  const onClickSelect = (e) => {
    handleSelect(e);
  };

  const selectStatus = (value) => {
    setStatus(value);
    setPage(1);
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangePageLimit = (newLimit) => {
    const index = (page - 1) * limit;
    const newPage = Math.floor(index / newLimit) + 1;
    setPage(newPage);
    setLimit(newLimit);
  };

  const onClickSurvey = (surveyId) => {
    navigate(`/project/${project.projectId}/survey/${surveyId}`);
  };

  const formatParticipants = (participants) => {
    if (!participants?.length) return '-';

    const initials = participants.map(p => p.initial);
    if (initials.length === 1) return initials[0];

    return {
      display: `${initials[0]} 등 ${initials.length}건`,
      full: initials.join(';')
    };
  };

  const getReservationDateTime = (item) => {
    if (item.reservation_date) {
      return `${item.reservation_date} 09:00:00`;
    }
    return item.created;
  };

  const getStatusText = (status) => {
    const statusMap = {
      'COMPLETED': '완료',
      'FAILED': '실패',
      'WAITING': '대기 중',
      'IN_PROGRESS': '진행 중'
    };
    return statusMap[status] || status;
  };

  const hasNoData = !!items && originalTotalCount === 0;

  return (
    <div className="body project">
      <ProjectHeader
        title="설문 링크 발송 현황"
        activeMenu="send-link-status"
      />
      <div className="container">
        <div className="inwrap">
          <div className={`full gap ${hasNoData ? "no_item" : ""}`}>
            {!hasNoData ? (
              <>
                <section className="">
                  <div className="search_wrap">
                    <div>
                      <div className="search_box">
                        <input
                          type="text"
                          placeholder="연구번호 혹은 발신자 이름 검색"
                          value={search}
                          onChange={onSearch}
                          onKeyUp={onKeyEvent}
                        />
                        <button onClick={onClickSearch}>
                          <i className="icon-search"></i>
                        </button>
                      </div>
                      <div className="selectBox">
                        <span className="label" onClick={onClickSelect}>
                          {statusOptions.find(opt => opt.value === status)?.label}
                        </span>
                        <ul className="optionList">
                          {statusOptions.map(option => (
                            <li
                              key={option.value}
                              className="optionItem"
                              onClick={() => selectStatus(option.value)}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="">
                  <div className="search_wrap">
                    <div>
                      <p>{search.length > 0 ? `검색 결과 (${total}개)` : `전체 (${total}개)`}</p>
                    </div>
                  </div>
                  <div className="section_in">
                    <table className="line">
                      <colgroup>
                        <col />
                        <col />
                        <col />
                        <col className="col150" />
                        <col />
                        <col />
                        <col />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>타입</th>
                          <th>연구번호</th>
                          <th>발신 요청(예정)일</th>
                          <th>발신 기관명</th>
                          <th>발신자</th>
                          <th>설문 제목</th>
                          <th>발신 결과</th>
                          <th>기타 로그</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items?.map((item) => {
                          const participants = formatParticipants(item.participants);
                          const singleParticipant = typeof participants === 'string';

                          return (
                            <tr key={item.id}>
                              <td>{item.reservation_date ? '예약' : '즉시'}</td>
                              <td className={singleParticipant ? '' : 'Participant-no c_primary_6'}>
                                {singleParticipant ? (
                                  participants
                                ) : (
                                  <div
                                    style={{cursor: 'pointer'}}
                                    onClick={() => alert(participants.full, {title: '수신 연구번호 목록'})}
                                  >
                                    {participants.display}
                                  </div>
                                )}
                              </td>
                              <td>{getReservationDateTime(item)}</td>
                              <td>{item.admin.affiliation}</td>
                              <td>{item.admin.name}</td>
                              <td className="Participant-no c_primary_6">
                                <div
                                  style={{cursor: 'pointer'}}
                                  onClick={() => onClickSurvey(item.survey.idsurvey)}
                                  dangerouslySetInnerHTML={{__html: `${item.survey.name} ${item.survey.targetgroup?.name || ''}`}}
                                />
                              </td>
                              <td>{getStatusText(item.status)}</td>
                              <td className="Participant-no c_primary_6">
                                {item.error_message ? (
                                  <div
                                    style={{cursor: 'pointer'}}
                                    onClick={() => alert(item.error_message.replace(/\n/g, '<br/>'), {title: '설문 링크 발송 에러 로그'})}
                                  >
                                    에러로그
                                  </div>
                                ) : '-'}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="flex_between">
                      <div></div>
                      <Pagination
                        totalCount={total}
                        currentPage={page}
                        pageSize={limit}
                        changePageLimit={onChangePageLimit}
                        changeCurrentPage={onChangePage}
                      />
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <section className="project">
                <div>
                  <img src="../../../assets/images/no_items.png" alt=""/>
                </div>
                <div>
                  <p>
                    설문 링크 전송 기록이 없습니다.<br/>
                    설문 링크 발송은 참여자 관리 테이블에서 가능합니다.
                  </p>
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendLinkStatus;