import { Link } from 'react-router-dom';
import DashBoard from './dashboard/DashBoard';
import Design from './test/design';
import Survey from './Survey/Survey';
import SurveyAnswer from './Survey/SurveyAnswer';
import SurveyAnswer2 from './Survey/SurveyAnswer2';
import Test from './test/test';
import Project from './project/project_01';
import Participant from './project/participant';
import ParticipantView from './project/participantView';
import Markup from './markup';
import JoinFail from './join/join_fail';
import Questionnair from './questionnair/questionnair';
import QuestionnairPreview from './questionnair/questionnairPreview';
import MyInfo from './join/MyInfo';
import Signup from '../pages/auth/Signup';
import SignupInfo from '../pages/auth/SignupInfo';
import SignupComplete from '../pages/auth/SignupComplete';
import Project01 from './project/project_01';
import Project02 from './project/project_02';


export const markupRouter = {
  path: "/markup",
  element: <Markup />,
  children: [
    { index: true, element: <Test /> },
    { path: "design", element: <Design /> },
    { path: "dashboard", element: <DashBoard /> },
    { path: "survey", element: <Survey /> },
    { path: "SurveyAnswer", element: <SurveyAnswer /> },
    { path: "SurveyAnswer2", element: <SurveyAnswer2 /> },
    { path: "project", element: <Project /> },
    { path: "project01", element: <Project01 /> },
    { path: "project02", element: <Project02 /> },
    { path: "Participant", element: <Participant /> },
    { path: "participantView", element: <ParticipantView /> },
    { path: "JoinFail", element: <JoinFail /> },
    { path: "Questionnair", element: <Questionnair /> },
    { path: "QuestionnairPreview", element: <QuestionnairPreview /> },
    { path: "MyInfo", element: <MyInfo/> },
    { path: "signup/1", element: <Signup/> },        
    { path: "signup/2", element: <SignupInfo/> },
    { path: "signup/3", element: <SignupComplete/> },


  ],
}



export default markupRouter


  