import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../context/ApiContext';
import { SURVEY_STATUS, SURVEY_STATUS_WRITING } from '../../common/codeHelper';
import { useNavigate } from 'react-router-dom';

const RecentVisitSurvey = props => {

  const navigate = useNavigate()
  const api = useApi()

  const [surveyList, setSurveyList] = useState()

  useEffect(_ => {
    
    getRecentVisitSurveyList()

    return ()=>{ 
    }
  }, [])


  useEffect(_ => {
    
    getRecentVisitSurveyList()

  }, [props.isRefresh])

  const onClickSurvey = (survey) => {

    navigate(`/project/${survey.project}/survey/${survey.idsurvey}`)
  }

  /*=========================================================
   *  Data 
   =========================================================*/
   const getRecentVisitSurveyList = async () => {

    const result = await api.post("getRecentVisitSurveyList", {
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setSurveyList(result.data.data.surveys)
      if (!!props.setHasRecent) props.setHasRecent(result.data.data.surveys.length > 0)
    }
  }

  return (
    <>
    { surveyList?.length > 0 ? (
        <section className="recent">
          <h5 className="section_tit">최근 방문 설문</h5>
          <ul>
            { surveyList.map((survey, index) => 
              <li key={`recent-survey-${index}`} onClick={() => { onClickSurvey(survey) }}>
                <div>
                  <p>{survey.projectname}</p>
                  <h5>{survey.surveyname.length > 0 ? survey.surveyname : "제목없음"}</h5>
                  <span className={`tag ${survey.status === SURVEY_STATUS_WRITING ? "hold" : survey.status === "CLOSED" ? "end" : ""}`}>{SURVEY_STATUS[survey.status]}</span>
                </div>
                { survey.status !== SURVEY_STATUS_WRITING && 
                  <div>
                    {survey.newanswercount > 0 && <span className="hit">{survey.newanswercount}</span>}
                    <h2>{survey.completedcount}</h2>
                    <p>응답</p>
                  </div>
                }
              </li>
            )}
          </ul>
        </section>
      ) : (null)
    }
    </>
  )
}
export default RecentVisitSurvey 