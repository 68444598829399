import $ from 'jquery';
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';

const AdminMenu = (props) => {

  const { tab } = props
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(_ => {

  }, [])
  
  const onClickNotice= async () => {
    if (tab === 'notice') return
    navigate("/admin/notice")
  }

  const onClickInquiry= async () => {
    if (tab === 'inquiry') return
    navigate("/admin/inquiry")
  }

  const onClickCustomer= async () => {
    if (tab === 'customer') return
    navigate("/admin/customer")
  }


  return (
    <div className="project_head">
      <div className="nav active">
        <nav> 
          <ul>
            <li>
              <a className={tab == 'notice' ? 'active' : ''} onClick={onClickNotice}>
                <i className="icon-notice" ></i>공지사항 관리
              </a>
            </li>
            <li>
              <a className={tab == 'inquiry' ? 'active' : ''} onClick={onClickInquiry}>
                <i className="icon-info"></i>고객 문의사항 관리
              </a>
            </li>
            { auth.adminInfo?.level === 2 && <li>
              <a className={tab == 'customer' ? 'active' : ''} onClick={onClickCustomer}>
                <i className="icon-team"></i>고객 관리
              </a>
            </li>}
          </ul>
        </nav>
      </div>
      <div className='copyrights'>
        Copyrights © 2023, atflow. <br/>
        All rights reserved.
      </div>
    </div>  
  );
}  
export default AdminMenu;