import React, { useState } from 'react';

const ProjectTargetGroupsPopup = ({ project, onCancel, onConfirm, popupData }) => {
  const [groupCount, setGroupCount] = useState(project.targetgroups.length);
  const groups = project.targetgroups;
  const [isOpen, setIsOpen] = useState(false);

  const getDefaultGroups = () => {
    const defaultGroups = Array(5).fill(null).map((_, index) => {
      if (index < groups.length) {
        return groups[index];
      }
      const groupName = `그룹 ${String.fromCharCode(65 + index)}`;
      return {
        default_name: groupName,
        name: groupName,
        order: index + 1
      };
    });
    return defaultGroups;
  };

  const [allGroups, setAllGroups] = useState(getDefaultGroups());

  const handleSelect = (count) => {
    setGroupCount(count);
    setIsOpen(false);
  };

  const handleNameChange = (index, newName) => {
    const updatedGroups = [...allGroups];
    updatedGroups[index].name = newName;
    setAllGroups(updatedGroups);
  };

  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  };

  const onClickConfirm = async () => {
    if (!!onConfirm) {
      onConfirm(popupData, allGroups.slice(0, groupCount))
    }
  };

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap common_pop medium">
        <h5 className="section_tit">참여자 그룹수 설정 <span className="close_btn" onClick={onClickClose}></span></h5>
        <div className="pop_content" style={{gap: '8px'}}>
          <p>프로젝트 참여자 그룹의 갯수 및 이름을 설정할 수 있습니다. (5개까지)</p>
          <div className={`selectBox ${isOpen ? 'active' : ''}`}>
            <span className="label" onClick={() => setIsOpen(!isOpen)}>
              {`${groupCount}개 그룹 ${groupCount === project.targetgroups.length ? '(기본)' : ''}`}
            </span>
            <ul className="optionList">
              {[2, 3, 4, 5].map(count => (
                <li key={count} className="optionItem" onClick={() => handleSelect(count)}>
                  {`${count}개 그룹`}{count === 2 ? ' (기본)' : ''}
                </li>
              ))}
            </ul>
          </div>
          <p className="text_danger" style={{ display: groupCount < project.targetgroupmaxorder ? 'block' : 'none', textAlign: 'right' }}>
            사용 중인 그룹보다 적게 설정할 수 없습니다.
          </p>
          <h6 style={{marginTop: '8px'}}>참여자 그룹 이름</h6>
          <table className="line">
            <colgroup>
              <col width="30%"/>
              <col width="70%"/>
            </colgroup>
            <thead>
              <tr>
                <th>기본 이름</th>
                <th>변경 이름</th>
              </tr>
            </thead>
            <tbody>
              {allGroups.map((group, index) => {
                const isDisabled = index >= groupCount;
                const tdStyle = {
                  padding: '8px 16px',
                  background: isDisabled ? 'var(--neutral_3)' : null,
                  color: isDisabled ? 'var(--color_disabled_25)' : null
                };
                return (
                  <tr key={index}>
                    <td style={tdStyle}>{group.default_name}</td>
                    <td style={tdStyle}>
                      {isDisabled ? (
                        <span>{group.name}</span>
                      ) : (
                        <input
                          type="text"
                          value={group.name}
                          onChange={(e) => handleNameChange(index, e.target.value)}
                          style={{width: '200px'}}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pop_foot" style={{ marginTop: '24px' }}>
            <div className="but_wrap">
              <button className="secondary" onClick={onClickClose}>취소</button>
              <button className="primary" onClick={onClickConfirm}>저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectTargetGroupsPopup;