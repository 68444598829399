import $ from 'jquery';
import { useEffect } from "react";

const SurveySharePopup = props => {

  useEffect(()=> {

        // 공동작업자 초대

  $('.project_head button.secondary').click(function() {
  
    $('.SurveySharePopup, .SurveySharePopup .survey_share').fadeIn();
  });
  $('.SurveySharePopup .invite_send').click(function() {
    $('.SurveyShareStep2, .SurveyShareStep2 .survey_ShareStep2').fadeIn();
  });

  }, []);

  return (

    <><div className="popup SurveyShareStep2 popPlus">
      <div className="popup_wrap">
        <div className="popup_inwrap survey_ShareStep2">
          <h5 className="section_tit">초대장 발송<span className="close_btn"></span></h5>

          <div className="pop_content height_auto">
            <div>
              ok@naver.com님은 아직 Research Flow에 가입되어 있지 않습니다.< br/>
              공동작업자로 추가하기 위해 초대장을 발송할 예정입니다. <br/>
              계속 진행을 원하시면 확인 버튼을 눌러주세요.
            </div>
            
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close">취소</button>
              </div>
              <div>

                <button type="button" className=" primary large ">확인</button>
                {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="popup SurveySharePopup">
        <div className="popup_wrap">

        {/* 공동작업자 초대 */}
        <div className="popup_inwrap survey_share">
          <h5 className="section_tit">‘중노년 성인에서 우울증상과 사회적 결정요인 및 만성질환의 관계에 관한 연구’ 공동 작업자  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content">                      
            <div>
                  <label for="join_mail" >이메일</label>
                  <div className="vertical_form">
                    <div>
                      {/* <!-- <div>
                        참여자
                        <ul>
                          <li>참여자</li>
                          <li>쇼유자</li>
                        </ul>
                      </div> --> */}
                    
                        <div className="selectBox select_inline">
                          <span className="label">참여자</span>
                          <ul className="optionList">
                              <li className="optionItem">참여자</li>
                              <li className="optionItem">소유자</li>
                          </ul>
                        </div>
                      <input type="text" id="join_mail" placeholder="예) af@atflow.co.kr" className="full big"/>
                    </div>
                    <div >
                      <button type='button' className="full  invite_send">추가</button>
                    </div>
                  </div>
                  
                </div>
                <div>
                  <div className="vertical_form">
                    <div>
                    <i className="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div>
                      <div className="selectBox select_inline">
                        <span className="label">참여자</span>
                        <ul className="optionList">
                            <li className="optionItem">참여자</li>
                            <li className="optionItem">소유자</li>
                            <li className="optionItem">삭제</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="vertical_form">
                    <div>
                    <i className="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div>
                      <div className="selectBox select_inline">
                        <span className="label">참여자</span>
                        <ul className="optionList">
                            <li className="optionItem">참여자</li>
                            <li className="optionItem">소유자</li>
                            <li className="optionItem">삭제</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="vertical_form">
                    <div>
                    <i className="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div>
                      <div className="selectBox select_inline">
                        <span className="label">참여자</span>
                        <ul className="optionList">
                            <li className="optionItem">참여자</li>
                            <li className="optionItem">소유자</li>
                            <li className="optionItem">삭제</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="vertical_form">
                    <div>
                    <i className="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div>
                      <div className="selectBox select_inline">
                        <span className="label">참여자</span>
                        <ul className="optionList">
                            <li className="optionItem">참여자</li>
                            <li className="optionItem">소유자</li>
                            <li className="optionItem">삭제</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>


                <p className="text_secondaty">프로젝트 공동작업자로 지정되면 프로젝트 내 모든 설문의 접근이 가능합니다. </p>
            </div>
            <div className="pop_foot">
              <div className="but_wrap">
                <div>
                  <button type="button" className="secondary gray large pop_close">취소</button>
                </div>
                <div>
                  
                  <button type="button" className=" primary large " >확인</button>
                  {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
                </div>
              </div>
            </div>
        </form>
        </div>

        </div>
      </div></>

  );  
}
 export default SurveySharePopup;