import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { getFileData } from '../../../common/imageHelper';
import axios from 'axios';

const CSInquiryPopup = props => {

  const {popupData, onCancel, onConfirm, inquiry} = props

  const api = useApi()  
  const {alert} = useCommonPopup()

  const [title, setTitle] = useState()
  const [titleError, setTitleError] = useState("")

  const [content, setContent] = useState()
  const [categoryList, setCategoryList] = useState([])
  const [category, setCategory] = useState()

  const [fileList, setFileList] = useState([])
  const [fileError, setFileError] = useState()

  const [isLoading, setIsLoading] = useState(false)
  const [isOK, setIsOK] = useState(false)

  useEffect(()=> {
    
    getInquiryCategory()
    if (!!inquiry) setTitle(`재문의 : ${inquiry.title}`)

    return () => {
    }
  }, []);

  useEffect(()=> {
    
    if (fileList.length >= 5) {
      setFileError('파일은 5개까지만 등록할 수 있습니다. 기존 파일을 삭제 후 등록해주세요.')
    } else {
      setFileError('')
    }
  }, [fileList]);

  useEffect(()=> {
    
    var result = checkValidation()
    console.log(`checkValidation ${result} ${title?.length}`)
    setIsOK(checkValidation())

  }, [category, title, content, isLoading]);

  const checkValidation = () => {
    if (isLoading) return false
    if (!category) return false
    if (!title || title?.length === 0) return false
    if (!content || content?.length === 0) return false

    return true
  }
  /*=========================================================
   *  Data 
   =========================================================*/
   const getInquiryCategory = async () => {

    const result = await api.post("getInquiryCategory", {})    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setCategoryList(result.data.data.inquirycategory)
      setCategory(result.data.data.inquirycategory?.[0])
    }
  }

  const registerInquiry = async () => {

    if (isLoading) return
    setIsLoading(true)

    var files = []
    for(let i=0; i<fileList.length; i++) {
      var fileInfo = fileList[i]
      var filepath = await updateFile(fileInfo.filename, fileInfo.file)
      if (!filepath) {
        setIsLoading(false)
        return
      }
      files.push({
        filename: fileInfo.filename,
        filepath: filepath
      })
    }
   
    var result = null

    if (!!inquiry) {
      result = await api.post("reregisterInquiry", {
        inquiry: inquiry.inquiry,
        content: content,
        files: files
      })  
    } else {
      result = await api.post("registerInquiry", {
        category: category.idinquirycategory,
        title: title,
        content: content,
        files: files
      })  
    }
      

    setIsLoading(false)

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }

  const onChangeTitle = (e) => {

    const value = e.target.value
    if (value.length > 50) {
      setTitleError("제목은 최대 50자 이내로 입력해 주세요.")
    } else {
      setTitle(value)
      setTitleError("")
    }
  }

  const onChangeContent = (e) => {

    const value = e.target.value
    setContent(value)
  }

  const onClickCategory = (data) => {

    setCategory(data)
  }

  const onClickClose = () => {

    if (isLoading) return
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickOK = async () => {

    registerInquiry()
  }  

  const onSelectFile = async (e) => {
    
    const file = e.target.files[0]
    if (!file) return
    
    var filename = file.name.normalize('NFC')
    setFileList([
      ...fileList,
      {
        [`filename`]: filename,
        [`file`]: file
      }
    ])
  }

  const onDeleteFile = async (fileInfo) => {
    
    var list = fileList.filter(e => e !== fileInfo)
    setFileList(list)
  }

  const getPresignedUrlForInquiryFileUpload = async (filename) => {
    
    const result = await api.post("getPresignedUrlForInquiryFileUpload", {
      filename : filename
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {      
      var url = result.data.data.url
      var filepath = result.data.data.filepath
      console.log(`getPresignedUrlForInquiryFileUpload : url = ${url}\nfilepath = ${filepath}`)
      return result.data.data
    }
    return null
  }

  const updateFile = async (filename, file) => {
    
    return new Promise(async (resolve, reject) => {
      
      var data = await getPresignedUrlForInquiryFileUpload(filename)
      if (data === null) resolve(null)
  
      var url = data.url
      var filepath = data.filepath
  
      try {
        await axios.put(url, file, {
            headers: {'Content-Type': file.type, charset: 'utf-8'},
        }).then(async ({ status, data }) => {
          console.log(`result : ${status} ${JSON.stringify(data)}`)
          // await registerInquiry(filename, filepath)
          resolve(filepath)
          // setIsLoading(false)
        })
        .catch(error => {
          console.log(`error : ${JSON.stringify(error)}`)
          // setIsLoading(false)
          api.networkError(error, alert)
          resolve(null)
        });
      } catch (err) { 
        // setIsLoading(false)
        api.networkError(err, alert)
        resolve(null)
      }
    });
  }

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap">
        <h5 className="section_tit">1:1 문의하기<span className="close_btn" disabled={isLoading} onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content">  
           <div>
              <label htmlFor="project_name">문의 종류</label>
              <div className="radio">
                { categoryList?.map((item, itemIndex) => 
                    <label>
                      <input type="radio" name="checkbox" 
                        value={item.idinquirycategory} 
                        disabled={isLoading || !!inquiry} 
                        checked={category === item} 
                        onChange={(e) => {onClickCategory(item)}}/>
                      <span>{item.category}</span>
                    </label>  
                  )
                }
              </div>
            </div>  
            <div>
              <label htmlFor="project_name">제목</label>
              <input type="text" className="full big" id="project_name" 
                placeholder="제목을 입력해 주세요." 
                value={title} 
                disabled={isLoading || !!inquiry} 
                onChange={onChangeTitle}/>
              <p className='tip'>{titleError}</p>
            </div>
            <div>
              <label htmlFor="project_name">내용</label>
              <textarea className='full' cols="30" rows="5" 
                value={content} 
                disabled={isLoading} 
                placeholder='내용을 입력해 주세요.'
                onChange={onChangeContent}></textarea>
            </div> 
            <div>
              <div className="filebox">
                <div>
                  <label htmlFor="input_file" className='box'>
                    <i className="icon-upload"></i>
                    파일 첨부
                  </label>
                  <input type="file" id="input_file" className="upload-hidden" disabled={isLoading} onChange={onSelectFile}/>
                </div>
              </div>
              <ul className='file_list'>
                {
                  fileList?.map((item, index) => 
                    <li className='flex_between'>
                      <div>
                        <i className='icon-attachment'></i>
                        <span className='c_primary_6'>{item.filename}</span>
                      </div>
                      <i className='icon-delete cursor' disabled={isLoading} onClick={() => onDeleteFile(item)}></i>
                    </li>
                  )
                }
              </ul>
              <p className='tip'>{fileError}</p>
            </div> 
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" disabled={isLoading} onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className={`primary large ${!isOK ? 'disabled' : ''}`} disabled={!isOK} onClick={onClickOK}>문의 접수하기</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );  
}
 export default CSInquiryPopup;