import { Link } from 'react-router-dom';
import QuestionnairHeader from '../inc/QuestionnairHeader';

const Questionnair = props => {
  return (      
    <div className="body questionnair">
      <QuestionnairHeader/>

      <div className="container">

        {/* START PAGE */}
        {/* <div className="inwrap start">
          <div className='questionnair_body'>
          <div></div>
          <h3 className='c_primary_6'>1. 연구 동의 및 사전 문항</h3>
          <h4>
            본 연구는 30-40대를 대상으로 우울증 여부와 정도를 조사하는 목적으로 진행됩니다. <br/>
            총 3개의 섹션 14개의 문항으로 구성되어 있습니다.
            </h4>
            <p className='bg_gray'>
              지난 1년동안 귀하가 가장 우울 또는 불안했거나 스트레스가 가장 많았던 한 달(月)을 떠올려 보십시오. 만약 그런 시기가 없었다면 지난 12개월 중 대표적인 달(月)을 떠올려주십시오.
            </p>
          </div>
          <div className="but_wrap">
            <button type="button" class="primary large full">시작</button>
          </div>
        </div> */}


        {/* 질문 */}
        <div className="inwrap">
          <div className='questionnair_body'>
            <p className='bg_gray'>
              지난 1년동안 귀하가 가장 우울 또는 불안했거나 스트레스가 가장 많았던 한 달(月)을 떠올려 보십시오. 만약 그런 시기가 없었다면 지난 12개월 중 대표적인 달(月)을 떠올려주십시오.
            </p>
            <div className='bg_white'>
            <h3>2-1. 아무런 이유없이 기운이 없던 적이 얼마나 됩니까?</h3>
            <ul className='radio checkbox'>
              <li>
              <label>
                <input type="radio" name="question" value="" /><span>한 달 내내</span></label>
              </li>
              <li>
                <label>
                  <input type="radio" name="question" value="" /><span>한 달 중 대부분</span>
                </label>
              </li>
              <li>
                <label><input type="checkbox" name="checkbox" value="email"/>
                <span>이메일</span>
                </label>
              </li>
              <li>
                <label>
                  <input type="radio" name="question" value="" /><span>한 달 중 절반 정도</span>
                </label>
              </li>
              <li>
                <label>
                  <input type="radio" name="question" value="" /><span>한 달 중 며칠</span>
                </label>
              </li>
              <li>
                <label>
                  <input type="radio" name="question" value="" /><span>전혀 없었다</span>
                </label>
              </li>

            </ul>
            </div>

            <div className='bg_white '>
              <h3>3-1. 지난 한 주 동안 자신이 얼마나 우울했는지 표시해주세요. </h3>
              <img src="../../../assets/images/image 28.jpg" alt="" />

              <ul className='score'>
                <li className='tooltip active'>1 
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>3
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>4
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>2
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>5
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>6
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>7
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>8
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>9
                <span class="tooltiptext tooltip-top">매우 아니다</span>
                </li>
                <li className='tooltip'>10
                <span class="tooltiptext tooltip-top">매우 그렇다</span>
                </li>

              </ul>
              <div> 
                1점 : 매우 아니다. <br/>
                10점: 매우 그렇다.
              </div>
              
            </div>

            <div className='bg_white '>
              <h3>4.1. 스스로 우울한 원인이 무엇이라고 생각하시나요? </h3>

              <ul className='radio'>
              <li>
                <label>
                  <input type="radio" name="question" value="" /><span>네</span>
                </label>
                <textarea className="full solo danger" id="textarea_auto" placeholder="사유" rows="1"> </textarea>
                <p className='text_length'>0/300</p>
              </li>
              <li>
                <label>
                  <input type="radio" name="question" value="" /><span>아니요 (설문을 종료합니다.)</span>
                </label>
              </li>
  

            </ul>

              
              
            </div>
            


            <div className='bg_white '>
              <h3>4.1. 스스로 우울한 원인이 무엇이라고 생각하시나요? </h3>

              <textarea className="full solo" id="textarea_auto" placeholder="문항" rows="1"></textarea>

               
              
            </div>



            <div className='bg_white '>
              <h3>5.1. 최근 병원에 방문하셨던 날짜를 알려주세요. </h3>

              <input className='big' type="date" />

              
              
            </div>

            <div className="but_wrap">

            <button type="button" class="secondary gray large ">이전</button>
            <button type="button" class="primary large ">다음</button>

            </div>
          </div> 
        </div>

        {/* END PAGE */}
        {/* <div className="inwrap start">
          <div className='questionnair_body'>
          <div></div>
          <h3>
          👍👏🥰❤ <br/>
          설문을 완료했습니다.
          </h3>
          <h4 className=''>참여해주셔서 진심으로 감사드립니다.</h4>
          </div>
          <div className="but_wrap">
            <button type="button" class="primary large full">종료</button>
          </div>
        </div> */}




         {/* ERROR PAGE */}
         {/* <div className="inwrap start">
          <div className='questionnair_body'>
          <div></div>
          <h3>
          😵 <br/>
          설문지를 표시할 수 없습니다.
          </h3>
          <h4>이전 화면으로 돌아가 다시 시도해보세요. </h4>

          <h4>계속해서 문제가 발생한다면, contact@atflow.kr로 이메일을 보내주시면 신속하게 도움을 드리겠습니다.</h4>

          <h4>불편을 끼쳐드려 죄송합니다.</h4>
          </div>
          <div className="but_wrap">
            <button type="button" class="secondary gray large full">이전</button>
          </div>
        </div> */}






      </div>
    </div>
    );
  }
  
  export default Questionnair;