import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useApi } from '../../context/ApiContext'
import { validatePhoneNumber } from '../../common/stringHelper'

const FindId = () => {

  const navigate = useNavigate()
  const api = useApi();

  const [inputs, setInputs] = useState({
    name: "",
    phoneno: "",        
  })

  const [errors, setErrors] = useState({
    nameError: "",
    phonenoError: "",
    findError: "",
  })
  
  const {name, phoneno} = inputs
  const {nameError, phonenoError, findError} = errors

  const inputName = useRef()
  const inputPhoneno = useRef()
    
  const [isLoading, setIsLoading] = useState(false) 
  const [email, setEmail] = useState("")

  const setError = (name, error) => {
    setErrors({
      ...errors, 
      [`${name}Error`]: error
    })
  }
  
  const onChangeInput = (e) => {

    var { value, name } = e.target
    value = value.replace(/ /g, "") 
    setInputs({
      ...inputs, 
      [name]: value
    })

    if (name == "name") {
      if (value.length > 0) {
        setError("name", "")
      }
    } else if (name == "phoneno") {     
      if (value.length > 0 && !validatePhoneNumber(value)) {
        setError("phoneno", "휴대폰 번호는 숫자만 입력해주세요.")
      } else {
        setError("phoneno", "")
      }     
    } 
  }

  const onKeyEvent = (e) => {

    var { name } = e.target
    if (e.key === 'Enter') {
      if (name == "name") {
        inputPhoneno.current.focus()
      } else if (name == "phoneno") {
        onClickFind()
      }       
    }
  }

  const checkValidation = _ => {

    if (name.length == 0) {
      inputName.current.focus()
      setError("name", "이름을 입력해 주세요.")
      return false
    }
    if (phoneno.length == 0) {
      inputPhoneno.current.focus()
      setError("phoneno", "휴대폰번호를 입력해 주세요.")
      return false
    }
    if (!validatePhoneNumber(phoneno)) {
      setError("phoneno", "휴대폰 번호는 숫자만 입력해주세요.")
      return false
    }
    return true
  }

  const onClickFind = async () => {

    if (isLoading) return
    if (!checkValidation()) return
        
    setIsLoading(true)

    const result = await api.post("findID", {
      name: name,
      phoneno: phoneno
    })
    
    if (result?.data?.result != "ok") {
      if (result?.data?.msg?.length > 0) {
        setError("find", result.data.msg)
      } else {
        setError("find", "error")
      }
      setIsLoading(false)      
    } else {
      setEmail(result.data.data.email)
      setError("signin", "")      
      setIsLoading(false)
    }
  }

  const onClickSignin = async (e) => {

    navigate("/")
  }

  return (
    <div className="body">
      <div  className="login find_pw">
        <div className="inwrap">
          { email.length == 0 ? (
            <div>
              <i className="icon-logo"></i>
              <h4>아이디 찾기</h4>
              <p>
                가입 시 등록한 이름과 휴대폰 번호를 확인해주세요.
              </p>
              <form action="" className="inner_box" onSubmit={(e) => {e.preventDefault()}}>
                <dl>
                  <dt>이름 </dt>
                  <dd>
                    <input 
                          name="name"
                          type="text" 
                          placeholder="이름"
                          ref={inputName}
                          className={nameError.length > 0 ? "error" : ""}
                          value={name}
                          onChange={onChangeInput}
                          onKeyUp={onKeyEvent}
                        />
                        <p className="tip">{nameError}</p>
                  </dd> 
                  <dt>휴대폰 번호 </dt>
                  <dd>
                    <input 
                      name="phoneno"
                      type="text" 
                      placeholder="휴대폰 번호"
                      ref={inputPhoneno}
                      className={phonenoError.length > 0 ? "error" : ""}
                      value={phoneno}
                      onChange={onChangeInput}
                      onKeyUp={onKeyEvent}
                    />
                    <p className="tip">{phonenoError}</p>
                  </dd>
                </dl>
                <div className="but_wrap">
                <button type="button"  className={`primary large ${isLoading && "disabled"}`} onClick={onClickFind} >확인</button>
                <p className="tip">{findError}</p>
                <Link className="primary" to="/">로그인하기</Link>
                </div>
              </form>
            </div>
          ) : ( 
            <div>
              <i className="icon-logo"></i>
              <h4>아이디 찾기</h4>
              <p>
                고객님의 정보와 일치하는 아이디는 다음과 같습니다.
              </p>
              <div  className="inner_box">
                <h5>{email}</h5>
                <div className="but_wrap">
                  <button type="button"  className="primary large" onClick={onClickSignin} >로그인하기</button>
                  <Link className="primary" to="/find-password/1">비밀번호 찾기</Link>
                  </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default FindId;