import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { validatePassword } from '../../../common/stringHelper';

const ChangePasswordPopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const api = useApi()  
  const {alert} = useCommonPopup()

  const [inputs, setInputs] = useState({
    email: props.email,
    password1: "",
    password2: ""
  })

  const [errors, setErrors] = useState({
    password1Error: "",
    password2Error: "",
  })
  
  const {email, password1, password2} = inputs
  const {emailError, password1Error, password2Error, authError} = errors

  const inputPassword1 = useRef()
  const inputPassword2 = useRef()

  useEffect(()=> {
    
    setTimeout(() => { 
      inputPassword1.current?.focus()
    }, 100)

    return () => {
    }
  }, []);

  const setError = (name, error) => {

    const temp = {
      ...errors, 
      [`${name}Error`]: error
    }
    setErrors({
      ...errors, 
      [`${name}Error`]: error
    })    
  }

  const checkPassword1 = (value) => {
    
    var password1Error = ""
    var password2Error = ""
    if (value.length == 0) {
      password1Error = ""
    } else if (value.length < 8) {
      password1Error = "비밀번호는 8자리 이상이어야 합니다."
    } else if (!validatePassword(email, value)) {
      password1Error = "비밀번호에 이메일 주소의 일부, 연속된 숫자 혹은 동일한 문자 3자리 이상을 사용하지 마세요."
    } else {
      password1Error = ""
    }
    if (password2.length > 0 && value.length > 0 && value != password2) {
      password2Error = "비밀번호가 서로 일치하지 않습니다."
    } else {
      password2Error = ""
    }

    setErrors({
      ...errors, 
      [`password1Error`]: password1Error,
      [`password2Error`]: password2Error,
    })  
    // setError("password1", "")
  }

  const checkPassword2 = (value) => {

    if (password1.length == 0) {
      setError("password1", "비밀번호를 입력하세요.")
    }
    if (value.length > 0 && password1 != value) {
      setError("password2", "비밀번호가 서로 일치하지 않습니다.")
    } else {
      setError("password2", "")
    }
  }

  const onChangeInput = (e) => {

    var { value, name } = e.target
    value = value.replace(/ /g, "") 
    setInputs({
      ...inputs, 
      [name]: value
    })

    if (name == "password1") {     
      checkPassword1(value)
    } else if (name == "password2") {
      checkPassword2(value)
    }
  }

  const onKeyEvent = (e) => {
  
    var { key, name } = e.target
    if (e.key === 'Enter') {
      if (name == "password1") {
        inputPassword2.current.focus()
      } else if (name == "password2") {
        onClickConfirm()
      }      
    }
  }

  const checkValidation = _ => {

    if (password1.length == 0) {
      inputPassword1.current.focus()
      setError("password1", "비밀번호를 입력하세요.")
      return false
    }
    if (password1.length < 8) {
      inputPassword1.current.focus()
      setError("password1", "비밀번호는 8자리 이상이어야 합니다.")
      return false
    } 
    if (!validatePassword(email, password1)) {
      inputPassword1.current.focus()
      setError("password1", "비밀번호에 이메일 주소의 일부, 연속된 숫자 혹은 동일한 문자 3자리 이상을 사용하지 마세요.")
      return false
    } 
    if (password2.length == 0) {
      inputPassword2.current.focus()
      setError("password2", "비밀번호를 다시 한번 입력해 주세요.")
      return false
    } 
    if (password1 != password2) {
      inputPassword2.current.focus()
      setError("password2", "비밀번호가 서로 일치하지 않습니다.")
      return false
    } 
    return true
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickConfirm = async () => {

    if (!checkValidation()) return

    if (!!onConfirm) {
      onConfirm(popupData, password1)
    }
  }  

  return (
    // <div className="popup project_home_pop" >
    <div className="popup_wrap">
    {/* 공용 */}
    <div className="popup_inwrap common_pop medium">
      <h5 className="section_tit">비밀번호 <span className="close_btn" onClick={onClickClose}></span></h5>
      <form action="" onSubmit={(e) => {e.preventDefault()}}>
        <div className="pop_content">
          <div>
            <label htmlFor="password">비밀번호</label>      
            {/* <input name="password" type="text" className='full'   placeholder="비밀번호"value=""/> */}
            <input 
              name="password1"
              type="password" 
              ref={inputPassword1}
              className={`full ${password1Error.length > 0 ? "error" : ""}`}
              autoComplete="new-password"
              placeholder="비밀번호"
              value={password1}                        
              onChange={onChangeInput}    
              onKeyUp={onKeyEvent}                         
            />
            <p className="tip">{password1Error}</p>
          </div>          
          <div>
            <label htmlFor="pwCheck">비밀번호 확인</label>      
            {/* <input name="pwCheck" type="text" className='full'   placeholder="비밀번호"value=""/> */}
            <input 
              name="password2"
              type="password" 
              ref={inputPassword2}
              className={`full ${password2Error.length > 0 ? "error" : ""}`}
              autoComplete="new-password"
              placeholder="비밀번호"
              value={password2}                        
              onChange={onChangeInput}    
              onKeyUp={onKeyEvent}                         
            />
            <p className="tip">{password2Error}</p>
          </div>          
        </div>
        <br/>
        <div className="pop_foot">
          <div className="but_wrap">
            <div>
              <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
            </div>
            <div>
              <button type="button" className=" primary large " onClick={onClickConfirm}>확인</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    </div>
    //  </div>
  );  
}
 export default ChangePasswordPopup;