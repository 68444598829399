import $ from 'jquery';
import ReactDOM from 'react-dom/client';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

const ComponentPopup = props => {

  const {popupData, children, onCancel, onConfirm} = props
  var {isShow, immediate, title, container, name, style, confirm, cancel, hasFooter} = props.popupData.option
  const focusRef = useRef()

  useEffect(()=> {

    if (!isShow) {      
      if (immediate) $(`.${name}_pop, .${name}_pop ${name}`).show();
      else $(`.${name}_pop, .${name}_pop ${name}`).fadeIn();

      isShow = true
    }
    document.body.classList.add("pop")

    focusRef.current?.focus()
    
    return () => {
      document.body.classList.remove("pop")
    }
  }, []);

  const onClickClose = async() => {
      
    if (!!onCancel) {
      if (await onCancel(popupData, false) == false) {
        return
      }
    }
    // close()
  }

  const onClickConfirm = async () => {
    
    if (!!onConfirm) {
      if (await onConfirm(popupData, true) == false) {
        return
      }
    }
    // close()
  }  

  const onSubmit = (e) => {
    e.preventDefault()
  }

  return (
    popupData.option.useContainer === false ? (
      <div>
         <form onSubmit={onSubmit}>    
          <input type="check" style={{position:"absolute", width: "0", height:"0", overflow: "hidden"}} ref={focusRef}/>
        </form>
        <div className={`popup ${name}_pop ${name} ${container}`}  >
          {React.cloneElement(children, {...props})}
        </div>
      </div>
    ) : (
      <div>
        <input type="file" style={{position:"absolute", width: "0", height:"0", overflow: "hidden"}} ref={focusRef}/>
        <div className={`popup ${name}_pop ${name} ${container}`}>
          <div className="popup_wrap">
            <div className={`popup_inwrap ${style}`}>            
              <div>
                {title?.length > 0 && <h5 className="section_tit">{title}&nbsp;<span className="close_btn" onClick={onClickClose}></span></h5>}
                <form action="" onSubmit={(e) => {e.preventDefault()}}>          
                  <div className="pop_content height_auto scroll">
                    {React.cloneElement(children, {...props})}
                  </div>
                  { hasFooter !== false && <div className="pop_foot">
                      <div className="but_wrap">
                        <div>
                          {!!cancel && <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>{cancel?? "취소"}</button>}
                        </div>
                        <div>
                          {!!confirm && <button type="button" className=" primary large " onClick={onClickConfirm}>{confirm?? "확인"}</button>}
                        </div>
                      </div>
                    </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );  
}
export default ComponentPopup;