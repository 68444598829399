const DashBoardHeader = () => {
  return (
    <header>
    <div className="dash_head">
        <div className="inwrap">
        <div className="head_profile">
        <h1><a href="../dashboard/"><i className="icon-logo"></i></a></h1>
        <div className="info_wrap popmenu">
            <p className="dash_profile"><span>김</span>김선아</p>
            <ul className="menu_pop">
            <li>한국대학교</li>
            <li><a href="">계정정보 수정</a></li>
            <li><a href="">로그아웃</a></li> 
            </ul>
        </div>
        </div>
        <div className="head_info">
        <h4>김선아님, 반가워요. 👋 </h4>
        <p>리서치플로우에 오신 것을 환영합니다. 프로젝트를 만들어서 연구자들과 공유하고 설문을 손쉽게 관리해보세요.</p>
        </div>
        </div>
    </div>
    <div className="dim_bg"></div>
    </header>
    
);
  }  
  export default DashBoardHeader;