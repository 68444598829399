import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import DashBoardHeader from "./DashBoardHeader";

import { useAuth } from '../../context/AuthContext'
import { useApi } from '../../context/ApiContext'
import { handleSelect, initPopupMenu } from "../../common/javascriptHelper";
import { Navigate, useNavigate } from 'react-router-dom';
import NewProjectPopup from '../project/popup/NewProjectPopup';
import DeleteProjectPopup from '../project/popup/DeleteProjectPopup';
import ProjectNamePopup from '../project/popup/ProjectNamePopup';
import InviteCollaboratorPopup from '../project/popup/InviteCollaboratorPopup';
import { useCommonPopup } from '../../context/CommonPopupContext';
import ProjectList from './ProjectList';
import OpenSurvey from './OpenSurvey';
import RecentVisitSurvey from './RecentVisitSurvey';
import toast from 'react-hot-toast';
import { useLoadingIndicator } from '../../context/LoadingIndicatorContext';

const DashBoard = () => {

  const [hasRecent, setHasRecent] = useState(false)
  const [isRefresh, setIsReresh] = useState(false)
 
  /*=========================================================
   *  useEffect 
   =========================================================*/
  useEffect(_ => {

    return ()=>{ 
    }
  }, [])

  useEffect(_ => {

    if (isRefresh === true) {
      setIsReresh(false)
    }
  }, [isRefresh])

  const refreshData = () => {
    setIsReresh(true)
  }

  /*=========================================================
   *  Render
   =========================================================*/
  return (
    <div className="body">
      <DashBoardHeader/>      
      <script src="../js/progresscircle.js"></script>
      <div className="container">        
        <div className="inwrap">          
          <div className={hasRecent ? "left_wrap" : "full"} style={{gap:"24px"}}>            
            <OpenSurvey isRefresh={isRefresh}/>
            <ProjectList refreshData={refreshData}/>
          </div>
          <div className={hasRecent ? "right_wrap" : ""}>
            <RecentVisitSurvey setHasRecent={setHasRecent} isRefresh={isRefresh}/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DashBoard 