import { Link } from 'react-router-dom';
import ProjectHeader from '../inc/ProjectHeader';
import NewProjectPopup from '../popup/NewProjectPopup';
import ProjectHomePopup from '../popup/ProjectHomePopup';
import SurveySharePopup from '../popup/SurveySharePopup';
import ParticipantPopup from '../popup/participantPopup';
import ParticipantPopup2 from '../popup/participantPopup2';
import ParticipantPopup3 from '../popup/participantPopup3';
import ParticipantPopup4  from '../popup/participantPopup4';
import ParticipantPopup5  from '../popup/participantPopup5';
import ParticipantPopup6  from '../popup/participantPopup6';
import MemoPopup  from '../popup/memoPopup';
const participantView = props => {
  return (      
    <div className="body project participant">
      <ProjectHeader/>
      <NewProjectPopup/>
      <ProjectHomePopup/>
      <SurveySharePopup/>
      <ParticipantPopup/>
      <ParticipantPopup2/>
      <ParticipantPopup3/>
      <ParticipantPopup4/>
      <ParticipantPopup5/>
      <ParticipantPopup6/>
      
      <div className="container">
        <div className="inwrap">
          <div className="full gap">
          <section className="">
            <div className="">
              <table className="line">
                <colgroup>
                  <col className='date' />
                  <col className="date"/>
                  <col className="date"/>
                  <col className="date"/>
                  <col className="date"/>
                </colgroup>
                <thead>
                  <tr>
                    <th>소속기관</th>
                    <th>시험대상자 식별번호</th>
                    <th>이니셜</th>
                    <th>참여자 그룹</th>
                    <th>서면 동의일</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>세브란스병원</td>
                    <td className='c_primary_6'>mY123</td>
                    <td>KSY</td>
                    <td>그룹 A (시험군)</td>
                    <td>
                      <button className="text_only agreeUp-btn" >업로드</button>
                      <button className="text_only agreeView-btn" >2023.03.10.</button>
                    </td>
                  </tr>
                </tbody>
              </table>
           </div>  
          </section>

          <section className="">
            <div className="search_wrap">
              <div>
                <h4>그룹 A (시험군) 스케줄</h4>
              </div>
           
            </div>  
            <div className="section_in height_auto">
            
              <table className="line">
                <colgroup>
                  <col width="*" />
                  <col className="col200"/>
                  <col className="col100"/>
                </colgroup>
                <thead>
                  <tr>
                    <th>설문 목록</th>
                    <th>응답</th>
                    <th className='center'>메모</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1. 과거 병력 조사</td>
                    <td><span className='c_primary_6'>응답 보기(2023.04.35 완료)</span></td>
                    <td className='center'><button className="text_only memo-btn" >3개</button>
                    <MemoPopup/>
                    </td>
                  </tr>
                  <tr>
                    <td>2. 임상 사전 문진</td>
                    <td><span className='c_primary_6'>시작하기</span></td>
                    <td className='center'>-</td>
                  </tr>
                  <tr>
                    <td>3. 2023 프로젝트 치료군 사전 질문</td>
                    <td><span className='c_primary_6'>시작하기</span></td>
                    <td className='center'>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section className="">
            <div className="search_wrap">
              <div>
                <h4>상태 변경 히스토리</h4>
              </div>
           
            </div>  
            <div className="section_in height_auto">
              <table className="line">
                <colgroup>
                  <col width="*" />
                  <col className="col300"/>
                </colgroup>
                <thead>
                  <tr>
                    <th>내용</th>
                    <th>날짜</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2. 상태값 변경 (진행 → 중단)</td>
                    <td>2023.05.18. 18:52:18</td>
                  </tr>
                  <tr>
                    <td>2. 상태값 변경 (진행 → 중단)</td>
                    <td>2023.05.18. 18:52:18</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
         </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default participantView;