import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";

const Preview = props => {

  const navigate = useNavigate()
  const { surveyId } = useParams()
  const [isInit, setIsInit] = useState(false)

  useEffect(_ => {
    
    if (!!surveyId) {
      setIsInit(true)
    }
  }, [])

  return (
    isInit ? (
      <Outlet />
    ) : (
      <div className="body questionnair">
        <div className="container">
          <div className="inwrap start">
            <div className='questionnair_body'>
              <div></div>
              <h3>
              <br/>
              잘못된 접근입니다.
              </h3>
              <h4>링크를 확인하고 다시 시도해 주세요.</h4>
              <h4>계속해서 문제가 발생한다면, contact@atflow.kr로 이메일을 보내주시면 신속하게 도움을 드리겠습니다.</h4>
              <h4>불편을 끼쳐드려 죄송합니다.</h4>
            </div>
            <div className="but_wrap">
              <button type="button" className="secondary gray large full" onClick={() => {window.close()}}>종료</button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
export default Preview