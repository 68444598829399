import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { initPopupMenu } from '../../../common/javascriptHelper';
import { useAuth } from '../../../context/AuthContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { profileImageUrl } from '../../../common/imageHelper';
import { NOTIFICATION_TYPE_AFFILIATION, NOTIFICATION_TYPE_ANSWER_INQUIRY, NOTIFICATION_TYPE_FILE, NOTIFICATION_TYPE_INQUIRY, NOTIFICATION_TYPE_MEMO, NOTIFICATION_TYPE_NOTICE } from '../../../common/codeHelper';
import { useNavigate } from 'react-router-dom';

const NotificationPopup = props => {
  
  const {onClosed} = props
  const navigate = useNavigate()
  const api = useApi()
  const auth = useAuth()
  const {confirm, alert} = useCommonPopup()
  const scrollRef = useRef();
  const [notiList, setNotiList] = useState()
  const [hasMore, setHasMore] = useState(false)
  const [totalCount, setTotalCount] = useState()
  const pageLimit = 10
  const currentPage = useRef(-1)
  const isLoading = useRef(false)

  useEffect(()=> {

    $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').fadeIn();

    initPopupMenu()
    getNotiList(0)


    return ()=>{ 
      $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').hide();
    }

  }, []);
 
  const onClickClose = () => {

    $('.MemoPopup, .MemoPopup .popup_inwrap, .dim_bg').fadeOut().promise().done(function() {
      if (!!onClosed) onClosed()
    });
  }

  const onClickCheckAllRead = async () => {

    const result = await api.post("readAllNoti", {})    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      getNotiList(0)
    }
  }

  const onScroll = (e) => {

    if (!hasMore || isLoading.current === true) return

    var bottom = e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
    if (bottom < 5) {
      getNotiList(currentPage.current + 1)
    }
  };

  /*=========================================================
   *  Data 
   =========================================================*/
   const getNotiList = async (page) => {
    
    if (isLoading.current === true) return 
    if (currentPage.current == page) return
    if (notiList?.length >= totalCount) return
        
    isLoading.current = true
    currentPage.current = page

    const result = await api.post("getNotiList", {
      page: Number(page),
      limit: Number(pageLimit)
    })    
    isLoading.current = false

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
      setHasMore(false)
    } else {
      
      var list = []      
      notiList?.forEach(element => {
        list.push(element)
      });
      result.data.data.notis.forEach(element => {
        list.push(element)
      });
      setHasMore(list.length < result.data.data.totalCount && result.data.data.notis.length > 0)      
      setNotiList(list)
      setTotalCount(result.data.data.totalCount)
    }
  }
 
  const Notification = (props) => {

    const {item, index} = props

    const readNoti = async () => {

      const result = await api.post("readNoti", {
        noti: item.idnotification,
      })    
    }

    const onClickNotice = async () => {
      await readNoti()
      navigate(`/cs/notice?notice=${item.noticeinfo.idnotice}`)
    }

    const onClickFile = async () => {
      await readNoti()
      navigate(`/project/${item.fileinfo.project}/survey/${item.fileinfo.survey}/answer`)
    }

    const onClickMemo = async () => {
      await readNoti()
      
      navigate(`/project/${item.memoinfo.idproject}/survey/${item.memoinfo.survey}/answer/individual?participant=${item.memoinfo.participant}&answer=${item.memoinfo.answer}`)
    }

    const onClickAffiliation = async () => {
      await readNoti()
      
      navigate(`/project/${item.affiliationinfo.idproject}`)
    }

    const onClickInquiry = async () => {
      await readNoti()
      navigate(`/admin/inquiry`)
    }

    const onClickAnswerInquiry = async () => {
      await readNoti()
      navigate(`/cs/inquiry`)
    }

    const FileItem = () => {
      return (
        <>
          <li onClick={onClickFile}>
            <span className='profile'>{!!item.fileinfo.profileimage ? <img src={profileImageUrl(item.fileinfo.profileimage, "small")}/> : `${item.fileinfo.adminname?.[0]?? ""}`}</span>
            <div className="text">
              <p className="tit">
                {item.isnew === 1 && <em></em>}{item.fileinfo.projectname}<span>{item.createddate}</span>
              </p>
              <div>
                <p>{item.fileinfo.adminname}님이 {item.fileinfo.surveyname} 설문 페이지에 컨펌용 엑셀파일을 등록했습니다.(파일명 : {item.fileinfo.filename})
                </p>
            
              </div>
            </div>
          </li>
        </>
      )
    }
    const MemoItem = () => {
      return (
        <>
          <li onClick={onClickMemo}>
            <span className='profile'>{!!item.memoinfo.profileimage ? <img src={profileImageUrl(item.memoinfo.profileimage, "small")}/> : `${item.memoinfo.adminname?.[0]?? ""}`}</span>
            <div className="text">
              <p className="tit">
                {item.isnew === 1 && <em></em>}{item.memoinfo.projectname}<span>{item.createddate}</span>
              </p>
              <div>
                <p>{item.memoinfo.adminname}님이 {item.memoinfo.identifier}님의 응답에 댓글을 등록했습니다. <span style={{color:"#000", fontWeight:"500"}}>"{item.memoinfo.content}"</span>
                </p>
              </div>
            </div>
          </li>
        </>
      )
    }
    const NoticeItem = () => {

      return (
        <>
          <li onClick={onClickNotice}>
            <span className='profile admin'></span>
            <div className="text">
              <p className="tit">
                {item.isnew === 1 && <em></em>}리서치플로우 공지사항<span>{item.createddate}</span>
              </p>
              <div>
                <p>[{item.noticeinfo.title}] {item.noticeinfo.content}
                </p>
              </div>
            </div>
          </li>
        </>
      )
    }
    const InquiryItem = () => {
      return (
        <>
          <li onClick={onClickInquiry}>
            <span className='profile admin'></span>
            <div className="text">
              <p className="tit">
                {item.isnew === 1 && <em></em>}리서치플로우 고객문의 등록<span>{item.createddate}</span>
              </p>
              <div>
                <p>{item.inquiryinfo.name}님이 고객센터에 {item.inquiryinfo.isreinquiry === 1 ? "재문의글을" : "문의글을"} 등록했습니다.</p>
              </div>
            </div>
          </li>
        </>
      )
    }
    const AnswerInquiryItem = () => {
      return (
        <>
          <li onClick={onClickAnswerInquiry}>
            <span className='profile admin'></span>
            <div className="text">
              <p className="tit">
                {item.isnew === 1 && <em></em>}리서치플로우 고객센터<span>{item.createddate}</span>
              </p>
              <div>
                <p>1:1 문의에 답변이 등록되었습니다!</p>
              </div>
            </div>
          </li>
        </>
      )
    }
    const AffiliationItem = () => {
      return (
        <>
          <li onClick={onClickAffiliation}>
            <span className='profile'>{!!item.affiliationinfo.profileimage ? <img src={profileImageUrl(item.affiliationinfo.profileimage, "small")}/> : `${item.affiliationinfo.adminname?.[0]?? ""}`}</span>
            <div className="text">
              <p className="tit">
                {item.isnew === 1 && <em></em>}{item.affiliationinfo.projectname}<span>{item.createddate}</span>
              </p>
              <div>
                <p>{item.affiliationinfo.adminname}님이 소속기관을 {item.affiliationinfo.affiliation}으로 변경했습니다. 참여자 소속 변경 시 프로젝트 접근 권한 확인을 위해 프로젝트 소유자에게 알림을 보내드립니다.
                </p>
              </div>
            </div>
          </li>
        </>
      )
    }
    return (
      <>
        {item.type == NOTIFICATION_TYPE_FILE && FileItem()}
        {item.type == NOTIFICATION_TYPE_MEMO && MemoItem()}
        {item.type == NOTIFICATION_TYPE_NOTICE && NoticeItem()}
        {item.type == NOTIFICATION_TYPE_AFFILIATION && AffiliationItem()}
        {item.type == NOTIFICATION_TYPE_INQUIRY && InquiryItem()}
        {item.type == NOTIFICATION_TYPE_ANSWER_INQUIRY && AnswerInquiryItem()}
      </>
    )
  }
  return (
    <>
      <div className="dim_bg" onClick={() => onClickClose()}></div>
      <div className="popup MemoPopup">
      <div className="tit ">
            <h5>알림</h5>
            { notiList?.length > 0 && <div className="c_primary_6 cursor font_14" onClick={onClickCheckAllRead}>
                모두 읽음으로 표시
              </div>
            }
          </div>
        <ul className="scroll" ref={scrollRef} onScroll={onScroll} style={{maxHeight:"500px"}}>
          { notiList?.length > 0 ? (
              notiList?.map((item, index) => <Notification key={`notification-${index}`} item={item} index={index}/>)
            ) : (
              <li>
                <p className="full center">
                  도착한 알림이 없습니다.
                </p>
              </li>
            )
          }
        </ul>
      </div>
    </>
  );  
}
 export default NotificationPopup;