import { useEffect, useState } from 'react';
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const SurveyScalePreviewPopup = props => {

  const api = useApi()
  const {alert} = useCommonPopup()

  const [scale, setScale] = useState()

  useEffect(_ => {
    
    getPreviewScale()
    return ()=>{ 
    }
  }, [])

  /*=========================================================
   *  Data 
   =========================================================*/

  const getPreviewScale = async () => {

    if (props.scale == null) return

    const result = await api.post("previewScale", {
      scale: props.scale.idscale
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {   
      result.data.data.scale["APA"] = getAPA(result.data.data.scale)
      setScale(result.data.data.scale)   
    }
  }

  const getAPA = (source) => {
    
    var author = source.author
    const array = source.author.split("^")
    if (array.length > 1) author = `${array.slice(0, -1).join(",")}, &${array.slice(-1)}`
    
    var result1 = ""
    var result2 = ""
    if (author.length > 0) result1 += author + ". "
    if (source.year.length > 0) result1 += "(" +source.year + "). "
    if (source.title.length > 0) result1 += source.title + ((source.type !== "website") ? ". " : " ")

    if (source.journal.length > 0) result2 += "<span class='italic'>" + source.journal + "</span>, "
    if (source.volume.length > 0) result2 += source.volume
    if (source.issue?.length > 0) result2 += "(" + source.issue + ")"
    if (source.volume.length > 0 || source.issue?.length > 0) result2 += ", "
    if (source.pages.length > 0) result2 += source.pages
    if (result2.length > 0) result2 += "."
   
    return result1 + result2
  }

  /*=========================================================
   *  Rendering 
   =========================================================*/

  return (
    <>
    { !!scale ? (
      <div>
        <div>
          {scale.name}
          <p className='text_secondaty'>
            <div dangerouslySetInnerHTML={ {__html: scale.APA} }></div>
          </p>
        </div>
        <table className='view_table'>
          <thead>
            { !!scale.questions?.[0] ? (
              <tr>
                <th colSpan="2">{scale.commonquestion}</th>
                { scale.questions[0].items?.map((item, index) =>
                  <td key={`question-header-${index}`}>{item.content}</td>
                )}
              </tr>
            ) : (
              null
            )}
          </thead>
          <tbody>
            { scale.questions?.map((question, index1) =>
              <tr key={`question-${index1}`}>
                <td>{question.orderno}</td>
                <td className="text_left">{question.content}</td>
                { question.items?.map((item, index2) =>
                  <td key={`question-${index1}-item-${index2}`}>{item.score}</td>
                )}
              </tr>
            )}
          </tbody>
        </table>
        <div className='text_secondaty'>
        이 플랫폼을 통해 제공되는 측정 도구와 척도는 원저작자의 소유물이며, 사용자에게 편리한 접근을 제공하기 위한 용도로만 사용하고 있습니다. 척도의 사용 및 실제 배포 전 원저작자에게 문의하여 사용범위를 확인하시기 바랍니다. 리서치플로우는 해당 도구와 척도의 효과성이나 정확성을 보증하지 않으며, 이를 사용함으로써 발생하는 결과에 대한 책임을 지지 않습니다.
        </div>

      </div>
    ) : (null)
    }
    </>
  )
}
 export default SurveyScalePreviewPopup;