export const getFileData = async (file) => {

  try {
    let result = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {

        var img = document.createElement("img");
        img.onload = function(event) {
          let data = resizeImage(img)
          resolve(data)   
        }
        img.src = reader.result;
        // resolve(reader.result)   
      };
      reader.onerror = function (error) {
          resolve(null)
      }
    })
    return result
  } catch(e) {

  }
  return null
}

const resizeImage = (img) => {


  var MAX_WIDTH = 1280;
  var MAX_HEIGHT = 1280;

  var width = img.width;
  var height = img.height;

  // Change the resizing logic
  if (width > height) {
      if (width > MAX_WIDTH) {
          height = height * (MAX_WIDTH / width);
          width = MAX_WIDTH;
      }
  } else {
      if (height > MAX_HEIGHT) {
          width = width * (MAX_HEIGHT / height);
          height = MAX_HEIGHT;
      }
  }

  var canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  return new Promise(resolve => {
    const url = canvas.toDataURL()
    resolve(url);
  });
}

export function profileImageUrl(id, size) {
  const imageUrl = process.env.REACT_APP_IMAGE_URL
  return `${imageUrl}/profile/${id}/${size}.jpg`
}

export function questionImageUrl(id, size) {
  const imageUrl = process.env.REACT_APP_IMAGE_URL
  return `${imageUrl}/question/${id}`
}