import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import AdminHeader from './AdminHeader';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';
import InquiryPopup from './popup/InquiryPopup';
import Pagination from '../../component/Pagination';
import { markKeyword } from '../../common/stringHelper';
import AdminMenu from './AdminMenu';

const AdminInquiry = props => {
  
  const navigate = useNavigate()  
  const api = useApi()
  const {popup, confirm, alert} = useCommonPopup()

  const [inquiryList, setInquiryList] = useState()
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedInquiry, setSelectedInquiry] = useState()

  const [categoryList, setCategoryList] = useState([])
  const [category, setCategory] = useState()

  const [answered, setAnswerd] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const searchPage = useRef()
  const searchKeyword = useRef("")

  const [keyword, setKeyword] = useState("")
  const [sortData, setSortData] = useState({
    sort: 'registerdate',
    order: 'desc'
  })
  /*=========================================================
   *  useEffect 
   =========================================================*/
  
  useEffect(_ => {

    getInquiryCategory()

  }, [])

  useEffect(_ => {
        
    getInquiryList(keyword, category, answered, currentPage, pageLimit)

  }, [pageLimit, category, answered, sortData])

  useEffect(_ => {
        
    if (searchPage.current == currentPage) return
    getInquiryList(keyword, category, answered, currentPage, pageLimit)

  }, [currentPage])

  useEffect(_ => {
    
    if (searchKeyword.current !== keyword) {
      getInquiryList(keyword, category, answered, 0, pageLimit)
    }
  }, [keyword])

  /*=========================================================
   *  Data 
   =========================================================*/
  const getInquiryCategory = async () => {

    const result = await api.post("getInquiryCategory", {})    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setCategoryList(result.data.data.inquirycategory)
    }
  }

  const getInquiryList = async (search, category, answered, page, limit) => {
    
    searchKeyword.current = search

    const result = await api.post("searchInquiryList", {
      page: page,
      limit: limit,
      answered : Number(answered),
      category : category?.idinquirycategory,
      search : search,
      orderby : sortData.sort,
      order : sortData.order
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
            
      setTotalCount(result.data.data.totalCount)
      const list = result.data.data.inquiry.map(element => 
        ({
          ...element,
          ["display_name"] : markKeyword(element.name, search),
          ["display_affiliation"] : markKeyword(element.affiliation, search),
          ["display_category"] : markKeyword(element.category, search),
          ["display_title"] : markKeyword(element.title, search),
          ["display_answer"] : markKeyword(element.answer, search)
        })
      )
      setInquiryList(list)
    }
  }

  /*=========================================================
   *  Handler 
   =========================================================*/
   const onClickNotice= async () => {
    navigate("/admin/notice")
  }

  const onClickInquiry= async () => {
    navigate("/admin/inquiry")
  }

  const onClickMember= async () => {
    navigate("/admin/customer")
  }

  const onClickCategory = (data) => {

    setCategory(data)
  }

  const onClickAnswerd = (answerd) => {

    setAnswerd(answerd)
  }

  const onClickInquiryItem = async (inquiry) => {

    const result = await popup(
      <InquiryPopup inquiry={inquiry}
      />, {
        container: "cs",
        name: "cs_inquiry_popup",
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getInquiryList(keyword, category, answered, currentPage, pageLimit)
    }
  }

  const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onChangePageLimit = (limit) => {
    const index = currentPage * pageLimit
    const page = Math.floor(index / limit)
    setCurrentPage(page)
    setPageLimit(limit)
  }

  const onChangeKeyword = (e) => {

    var value = e.target.value   
    console.log(`onChangeKeyword ${keyword} = ${value} `)
    if (keyword === value) return

    setKeyword(value)
  }
  
  const onKeyEvent = (e) => {

    if (e.key === 'Enter') {
      onClickSearch()
    }
  }

  const onClickSearch = async () => {

    await getInquiryList(keyword, category, answered, currentPage, pageLimit)
  }

  const onClickSort = async (sort) => {

    if (sortData.sort == sort) {
      setSortData({
        sort: sort,
        order: sortData.order == "asc" ? "desc" : "asc"
      })
    } else {
      setSortData({
        sort: sort,
        order: "asc"
      })
    }    
  }
  /*=========================================================
   *  Render 
   =========================================================*/

  return (   
    <div className="body project admin">
      <AdminHeader/>
      <div className="container">
        <div className="inwrap">
          <div className={`full gap`}>
            <div className="csboard_wrap adminboard_wrap">
              <AdminMenu tab='inquiry'/>
              <div className='board_con'>
                <div>
                  <section className="search_box">
                    <input type="text" placeholder="검색" value={keyword?? ''} onChange={onChangeKeyword} onKeyUp={onKeyEvent}/>
                    <button onClick={onClickSearch}><i className="icon-search"></i></button>
                  </section>
                </div>
                <div>
                  <div className='search_wrap'>
                    <div className="radio">
                      <label>
                        <input type="radio" name="checkbox" disabled={isLoading} checked={!category} onChange={(e) => {onClickCategory()}}/>
                        <span>전체</span>
                      </label>  
                      { categoryList?.map((item, itemIndex) => 
                          <label key={`category-${itemIndex}`}>
                            <input type="radio" 
                              name="checkbox" 
                              key={`category-${itemIndex}`}
                              disabled={isLoading} 
                              checked={category === item} 
                              onChange={(e) => {onClickCategory(item)}}/>
                            <span>{item.category}</span>
                          </label>  
                        )
                      }
                    </div>
                    <ul className="tab">
                      <li className={!answered ? 'active' : ''} onClick={()=> {onClickAnswerd()}}>전체</li>
                      <li className={answered === '1' ? 'active' : ''} onClick={()=> {onClickAnswerd('1')}}>답변 완료</li>
                      <li className={answered === '0' ? 'active' : ''} onClick={()=> {onClickAnswerd('0')}}>미완료</li>
                    </ul>
                  </div>
                  <table className='line'>
                    <colgroup>
                      <col className='col80'/>
                      <col className='date'/>
                      <col className='date'/>
                      <col />
                      
                      <col className='col120'/>
                      <col />
                      <col className='col120'/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>이름</th>
                        <th onClick={() => {onClickSort("affiliation")}}>소속 기관<i className={`icon-caretdown ${sortData.sort == "affiliation" && sortData.order == "desc" ? "" : "up"}`}></i></th>
                        <th>문의 구분</th>
                        <th>문의 제목</th>
                        <th onClick={() => {onClickSort("registerdate")}}>접수일<i className={`icon-caretdown ${sortData.sort == "registerdate" && sortData.order == "desc" ? "" : "up"}`}></i></th>
                        <th>답변</th>
                        <th onClick={() => {onClickSort("answerdate")}}>발송일<i className={`icon-caretdown ${sortData.sort == "answerdate" && sortData.order == "desc" ? "" : "up"}`}></i></th>
                      </tr>
                    </thead>
                    <tbody>
                      { inquiryList?.length > 0 ? (
                          inquiryList?.map((item, itemIndex) => 
                            <tr className='cursor hover'  key={`inquiry-${itemIndex}`} onClick={() => {onClickInquiryItem(item)}}>
                              <td><div dangerouslySetInnerHTML={ {__html: item.display_name} }/></td>
                              <td><div dangerouslySetInnerHTML={ {__html: item.display_affiliation} }/></td>
                              <td><div dangerouslySetInnerHTML={ {__html: item.display_category} }/></td>
                              <td className='oneLine'><div dangerouslySetInnerHTML={ {__html: item.display_title} }/></td>
                              <td>{item.registerdate}</td>
                              <td className='oneLine'><div dangerouslySetInnerHTML={ {__html: item.display_answer} }/></td>
                              <td className={item.answered === 1 ? '' : 'c_primary_6'}>{item.answered === 1 ? item.answerdate : '미완료'}</td>
                            </tr>
                          )
                        ) : (
                          <tr><td colSpan={7} className='center'>문의사항이 없습니다.</td></tr>
                        )
                      }                      
                    </tbody>                    
                  </table>                  
                  <Pagination
                      totalCount={totalCount}
                      currentPage={currentPage}
                      pageSize={pageLimit}
                      changePageLimit={onChangePageLimit}
                      changeCurrentPage={onChangePage}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default AdminInquiry;