import React from 'react';

const ProjectTargetGroupsCompletePopup = ({ targetgroups, onCancel, onConfirm, popupData }) => {
  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  };

  const onClickConfirm = async () => {
    if (!!onConfirm) {
      onConfirm(popupData, true)
    }
  };

  return (
    <div className="popup_wrap">
      <div className="popup_inwrap common_pop medium">
        <h5 className="section_tit">참여자 그룹 변경 <span className="close_btn" onClick={onClickClose}></span></h5>
        <div className="pop_content">
          <p>참여자 그룹 정보 변경이 완료되었습니다.</p>
          <div>
            <p style={{ fontWeight: 'bold' }}>[변경 내용]</p>
            <p>그룹 갯수 : {targetgroups.length}개 그룹 사용</p>
            <p>변경 이름 : {targetgroups.map(g => g.name).join(', ')}</p>
          </div>
          <div className="pop_foot" style={{ marginTop: '24px' }}>
            <div className="but_wrap">
              <button className="primary" onClick={onClickConfirm}>확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectTargetGroupsCompletePopup;