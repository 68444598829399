import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const SurveyNamePopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const {surveyData} = props
  const api = useApi()  
  const {alert} = useCommonPopup()

  const [name, setName] = useState(surveyData.name)
  const [error, setError] = useState("")
  const nameInputRef = useRef()

  useEffect(()=> {
    
    setTimeout(() => { 
      nameInputRef.current?.focus()
    }, 100)

    return () => {
    }
  }, []);


  const onChangeName = (e) => {

    const value = e.target.value
    if (value.length > 30) {
      setError("이름은 최대 30자를 넘을 수 없습니다.")
    } else {
      setName(value)
      setError("")
    }
  }

  const onKeyEventName = (e) => {
  
    if (e.key === 'Enter') {
      if (name.length <= 30) {
        onClickOK()
      }
    }
  }

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickOK = async () => {

    const result = await api.request(`/surveys/${surveyData.idsurvey}/`, {
      method: "patch",
      params: { name }
    })

    if (result?.status !== 200) {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }

  return (
    // <div className="popup project_home_pop" >
      <div className="popup_wrap">
        <div className="popup_inwrap edit">
          <h5 className="section_tit">설문 이름 바꾸기  <span className="close_btn" onClick={onClickClose}></span></h5>
          <form action="" onSubmit={(e) => {e.preventDefault()}}>
            <div className="pop_content">    
              <h4>변경할 설문 이름을 입력해주세요. (최대 30자)</h4>
              <div>
                <label htmlFor="project_name">설문 이름</label>
                <input type="text" 
                  className="full big"
                  id="project_name" 
                  ref={nameInputRef}
                  placeholder="예) 당뇨환자의 수면장애" 
                  value={name}
                  onChange={onChangeName}
                  onKeyUp={onKeyEventName}   
                />
                <p className="tip">{error}</p>
              </div>
              <div className="pop_foot">
                  <div className="but_wrap">
                    <div>
                      <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
                    </div>
                    <div>
                      <button type="button" className=" primary large " onClick={onClickOK}>확인</button>
                    </div>
                  </div>
                </div>
              </div>
           </form>
         </div>
       </div>
    //  </div>
  );  
}
 export default SurveyNamePopup;