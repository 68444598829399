const QuestionnairHeader = () => {
  return (

    <header>
      <div  className="question_head">
        <div className="inwrap">
          <div>
            <h4>mY123</h4>
            <span>2. 임상 사전 문진</span>
          </div>
          <h5>
            3/16
          </h5>
          {/* <span class="close_btn"></span> */}

        </div>
        <span className="progress" style={{width:'30%'}}></span>
        <div className="dim_bg"></div>
      </div>
    </header>  
    
    );
}  
export default QuestionnairHeader;