import { useEffect, useState } from "react";
import SurveyLinkPopup from './SurveyLinkPopup';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { useApi } from "../../../context/ApiContext";

const SurveyExportPopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const {popup, alert} = useCommonPopup()
  const api = useApi()

  const {projectData, surveyData} = props
  const [multipleResponse, setMultipleResponse] = useState(false)

  useEffect(()=> {


    return () => {
    }
  }, []);


  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }
  const onChangeMultipleResponse = async (e) => {

    var value = e.target.checked
    setMultipleResponse(value)
  }

  const updateSurveyMultipleResponse = async () => {

    const result = await api.request(`/surveys/${surveyData.idsurvey}/`, {
      method: "patch",
      params: {
        multipleresponse: multipleResponse === true ? 1 : 0,
      }
    })

    if (result?.status !== 200) {
      api.networkError(result, alert)
      return false
    } else {
      return true
    }
  }

  const onClickConfirm = async () => {

    if (projectData.eCRF === 1) {
      await updateSurveyMultipleResponse()
    }
    const result = await api.request(`/surveys/${surveyData.idsurvey}/open/`, { method: "post" })
    if (result?.status !== 200) {
      await api.networkError(result, alert)
      if (result?.data?.detail === '비어 있는 내용이 있습니다.' && !!result?.data?.errors) {
        if (!!onConfirm) {
          onConfirm(popupData, {errors: result.data.errors})
        }
      }
    } else {
      if (projectData?.eCRF !== 1) {
        openLinkPopup(result.data.link)
      } else {
        openLinkPopup("")
      }
    }
  }

  const openLinkPopup = async (link) => {

    if (!!onConfirm) {
      onConfirm(popupData, link)
    }
  }

  return (
    <div className="popup_wrap ">
      <div className="popup_inwrap common_pop medium" >
        <h5 className="section_tit">설문 보내기<span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content">
            { projectData.eCRF === 1 ? (
              <>
               <h5>
                  설문을 응답할 수 있는 상태로 변경합니다.
                  <br/><br/>
                  상태 변경 후에는 주요 설문 편집 기능인 문항 추가, 삭제, 옵션 편집, 순서 변경 등이 제한됩니다.
                  <br/><br/>
                  만약 이러한 변경이 필요한 경우, 해당 설문을 종료하고 새로운 복사본을 생성하여 다시 시작해야 합니다. 기존 참여자들도 필요에 따라 다시 참여해야 한다는 점을 유의해 주세요.
                  {/*<br/><br/>
                  설문을 응답 가능한 상태로 변경하시겠습니까? */}
                </h5>
                <div>
                  <h5 className="vertical_form">중복 응답 허용 <label className="switch">
                    <input type="checkbox"
                      checked={multipleResponse}
                      onChange={onChangeMultipleResponse}
                    />
                    <span className="slider round"></span>
                  </label></h5>
                  <div>
                    <h6>동일한 사용자가 여러 번 반복하여 같은 설문에 응답하는 것을 허용합니다. 이 옵션은 설문 시작 전에 설정해야 하며, 설문을 보낸 후에는 변경할 수 없습니다.</h6>
                  </div>
                </div>
              </>
              ) : (
                <h5>
                  설문 링크를 만들어서 응답자에게 공유하면 링크를 통해 누구나ß  설문에 참여할 수 있습니다.
                  <br/><br/>
                  설문 링크를 만든 후에는 주요 설문 편집 기능인 문항 추가, 삭제,  옵션 편집, 순서 변경 등이 제한됩니다.
                  <br/><br/>
                  만약 이러한 변경이 필요한 경우, 해당 설문을 종료하고 새로운  복사본을 생성하여 다시 시작해야 합니다. 기존 참여자들도 필요에  따라 다시 참여해야 한다는 점을 유의해 주세요.
                  <br/><br/>
                  설문 링크를 만들어서 설문을 보내시겠습니까?
                </h5>
              )
            }
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className=" primary large " onClick={onClickConfirm}>{projectData.eCRF === 1  ? "확인" : "링크 만들기"}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
 export default SurveyExportPopup;