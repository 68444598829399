import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useApi } from '../../context/ApiContext'
import { validateEamil } from '../../common/stringHelper'

const FindPassword1 = () => {

  const navigate = useNavigate()
  const api = useApi();

  const [inputs, setInputs] = useState({
    email: ""
  })

  const [errors, setErrors] = useState({
    emailError: "",
    findError: "",
  })
  
  const {email} = inputs
  const {emailError, findError} = errors

  const inputEmail = useRef()
    
  const [isLoading, setIsLoading] = useState(false) 

  const setError = (name, error) => {
    setErrors({
      ...errors, 
      [`${name}Error`]: error
    })
  }
  
  const onChangeInput = (e) => {

    var { value, name } = e.target
    value = value.replace(/ /g, "") 
    setInputs({
      ...inputs, 
      [name]: value
    })

    if (name == "email") {
      if (value.length > 0) {
        setError("email", "")
      }
    } 
  }

  const onKeyEvent = (e) => {

    var { name } = e.target
    if (e.key === 'Enter') {
      if (name == "email") {
        onClickNext()
      }      
    }
  }

  const checkValidation = _ => {

    if (email.length == 0) {
      inputEmail.current.focus()
      setError("email", "이메일을 입력하세요.")
      return false
    }
    if (!validateEamil(email)) {
      inputEmail.current.focus()
      setError("email", "이메일 형식에 맞지 않습니다. @를 포함하여 정확하게 입력해주세요.")
      return false
    }
    return true
  }

  const onClickNext = async () => {

    if (isLoading) return
    if (!checkValidation()) return
        
    setIsLoading(true)
    setError("find", "")      

    const result = await api.post("checkEmail", {
      email: email
    })
    
    if (result?.data?.result != "ok") {
      if (result?.data?.msg?.length > 0) {
        setError("find", result.data.msg)
      } else {
        setError("find", "error")
      }
      setIsLoading(false)      
    } else {
      setError("find", "")      
      setIsLoading(false)
      navigate("/find-password/2", { state : {
          email: email
        }
      })
    }
  }

  return (
    <div className="body">
    <div  className="login find_pw ">
      <div className="inwrap">
        <div>
          <i className="icon-logo"></i>
          <h4>비밀번호 찾기</h4>
          <p>
            <span>Step 1.</span> 비밀번호를 찾고자 하는 아이디(이메일 주소)를 입력해주세요.  
          </p>
          <form action="" className="inner_box" onSubmit={(e) => {e.preventDefault()}}>
            <dl>
              <dt>이메일 </dt>
              <dd>
                <input 
                  name="email"
                  type="text" 
                  ref={inputEmail}
                  className={emailError.length > 0 ? "error" : ""}
                  placeholder="이메일" 
                  value={email}
                  onChange={onChangeInput}
                  onKeyUp={onKeyEvent}
                />
                <p className="tip">{emailError}</p>
              </dd>
            </dl>
            <div className="but_wrap">
              <button type="button"  className={`primary large ${isLoading && "disabled"}`} onClick={onClickNext}>다음</button>            
              <p className="tip">{findError}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  );
}
export default FindPassword1;