import { Link } from 'react-router-dom';


const JoinFail = props => {
  return (      
    <div className="body project">
    <div className="body">
      <div  className="login email_ok">
        <div className="inwrap">
          <div>
            <i className="icon-logo"></i>
            <h4>😵 리서치플로우 초대 가입 실패</h4>
            <p>
            죄송합니다. <br/>
            회원가입 과정에서 문제가 발생하여 가입에 실패하였습니다.<br/><br/>

            초대장에 포함된 바로 가입 링크를 한 번 더 클릭하여 재시도해주시면 <br/> 감사하겠습니다.<br/><br/>

            계속해서 문제가 발생한다면, <a href='mailto:contact@atflow.kr'>contact@atflow.kr</a>로 이메일을 보내주시면  <br/>신속하게 도움을 드리겠습니다. <br/><br/>

            불편을 끼쳐드려 죄송합니다.
            </p>
          </div>
        </div>
      </div>

    </div>
    </div>
    );
  }
  
  export default JoinFail;