import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import AdminHeader from './AdminHeader';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';
import NoticePopup from './popup/NoticePopup';
import Pagination from '../../component/Pagination';
import AdminMenu from './AdminMenu';

const AdminNotice = props => {
  
  const navigate = useNavigate()  
  const api = useApi()
  const {popup, confirm, alert} = useCommonPopup()

  const [noticeList, setNoticeList] = useState()
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedNotice, setSelectedNotice] = useState()

  /*=========================================================
   *  useEffect 
   =========================================================*/
  
  useEffect(_ => {
  
  }, [])

  useEffect(_ => {
    
    getNoticeList(currentPage, pageLimit)

  }, [currentPage, pageLimit])

  /*=========================================================
   *  Data 
   =========================================================*/
   const getNoticeList = async (page, limit) => {

    const result = await api.post("getNoticeListForManage", {
      page: page,
      limit: limit
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
            
      setTotalCount(result.data.data.totalCount)
      setNoticeList(result.data.data.notices)
      setSelectedNotice(result.data.data.notices.filter(element => element.idnotice == selectedNotice?.idnotice)?.[0])
    }
  }

  /*=========================================================
   *  Handler 
   =========================================================*/
  const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onChangePageLimit = (limit) => {

    const index = currentPage * pageLimit
    const page = Math.floor(index / limit)
    setCurrentPage(page)
    setPageLimit(limit)
  }

  const onClickNewNotice= async () => {
    const result = await popup(
      <NoticePopup 
      />, {
        container: "admin",
        name: "notice_popup",
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getNoticeList(currentPage, pageLimit)
    }
  }

  const onClickNoticeItem = async (notice) => {
    setSelectedNotice(notice)
  }

  const onClickEdit = async () => {

    if (selectedNotice.status !== 'WAIT') {
      if (await confirm(`알림 시작 이후에는 내용을 수정할 수 없으며, 알림 기간만 수정 가능합니다. 
      내용 수정이 필요한 경우 삭제 후 다시 등록해주세요.\n\n
      알림 기간을 수정하시겠습니까?`, {
          title: "공지사항 수정", 
          style: "medium"
        }) === false ) return      
    }
    
    const result = await popup(
      <NoticePopup notice={selectedNotice}
      />, {
        container: "admin",
        name: "notice_popup",
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getNoticeList(currentPage, pageLimit)
    }
  }

  const onClickDelete = async () => {

    if (await confirm(`이 공지사항을 삭제하시겠습니까?\n\n삭제 후 알림창과 고객센터의 공지사항에서 해당 공지가 완전히 제거됩니다.`, {
          title: "공지사항 삭제", 
          style: "medium"
        })
      ) {
        const result = await api.post("deleteNotice", {
          notice: selectedNotice.idnotice,
        })
        if (result?.data?.result != "ok") {
          api.networkError(result, alert)
          return 
        } else {
          getNoticeList(currentPage, pageLimit)
        }
      }     
  }
  /*=========================================================
   *  Render 
   =========================================================*/

  return (   
    <div className="body project admin">
      <AdminHeader/>
      <div className="container">
        <div className="inwrap">
          <div className="full gap">
            <div className="csboard_wrap adminboard_wrap">
              <AdminMenu tab='notice'/>
              <div className='board_con'>
                <div>
                  <h5>
                    <span>등록된 공지사항({!!totalCount? totalCount : '-'}개)</span>
                    <button className="primary new_project_but" onClick={onClickNewNotice}><i>+</i> 새 공지 등록</button>
                  </h5>
                  <table className='line'>
                    <colgroup>
                      <col className='col280'/>
                      <col />
                      <col className='col200'/>
                      <col className='col100'/>
                      <col className='col80'/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>제목</th>
                        <th>내용</th>
                        <th>알림 기간
                          <span className="tooltip">
                            <i className="icon-info"></i>
                            <span className="tooltiptext tooltip-top">시작 날짜에 공지사항이 등록되며, 설정한 기간 동안 알림창에 공지사항이 표시됩니다. 설정된 기간이 종료된 후에도 고객센터 메뉴에서 공지사항을 확인할 수 있습니다.
                            </span>
                          </span>
                          {/* <i className="icon-caretdown"></i> */}
                        </th>
                        <th>상태
                        {/* <i className="icon-caretdown up"></i> */}
                        </th>
                        <th>등록자
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { noticeList?.length > 0 ? (
                          noticeList?.map((item, itemIndex) => 
                            <tr className={`cursor hover ${selectedNotice?.idnotice === item.idnotice ? 'active' : ''}`} 
                              key={`notice-${itemIndex}`} 
                              onClick={() => { onClickNoticeItem(item)}}>
                              <td className='oneLine'>{item.title}</td>
                              <td className='oneLine'>{item.content}</td>
                              <td>{item.startdate} ~ {item.enddate}</td>
                              <td className={item.status == 'OPEN' ? 'c_primary_6' : ''}>{item.status == 'CLOSED' ? '종료' : item.status == 'OPEN' ? '게시중' : '예정'}</td>
                              <td>{item.name}</td>
                            </tr>
                          )
                        ) : (
                          <tr><td colSpan={5} className='center'>공지사항이 없습니다.</td></tr>
                        )
                      }
                    </tbody>
                  </table>
                  <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageSize={pageLimit}
                    changePageLimit={onChangePageLimit}
                    changeCurrentPage={onChangePage}/>
                </div>

                { !!selectedNotice && (
                    <div className='view'>
                      <h5 className='fontw_600'>
                        {selectedNotice.title}
                        <div>
                          {selectedNotice.status === 'WAIT' && <button className="text_only" onClick={onClickEdit}>수정</button>}
                          <button className="text_only" onClick={onClickDelete}>삭제</button>
                        </div>
                      </h5>
                      <div className="view_con">
                        {/* <h6>질문</h6> */}
                        <h5 className='fontw_400'>{selectedNotice.content}</h5>
                      </div>
                      <div className="view_con">
                        <h6>알림 기간</h6> {selectedNotice.startdate} ~ {selectedNotice.enddate}
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default AdminNotice;