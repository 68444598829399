import { useEffect, useRef, useState } from 'react';

const YearContent = ({ answer, updateAnswer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const listRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1900 + 1 }, (_, i) => currentYear - i).reverse();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen && listRef.current && answer) {
      const selectedItem = listRef.current.querySelector('.active');
      if (selectedItem) {
        selectedItem.scrollIntoView({ block: 'center', behavior: 'auto' });
      }
    }
  }, [isOpen, answer]);

  const onClickSelect = () => {
    setIsOpen(!isOpen);
    if (!answer) {
      updateAnswer("1990");
    }
  };

  const onSelectYear = (year) => {
    updateAnswer(year.toString());
    setIsOpen(false);
  };

  return (
    <div className='selectBox_wrap' ref={selectRef}>
      <div className={`selectBox large full ${isOpen ? 'active' : ''}`}>
        <span className="label" onClick={onClickSelect}>
          {answer ? `${answer}년` : "연도"}
        </span>
        {isOpen && (
          <ul className="optionList" ref={listRef}>
            {years.map(year => (
              <li
                key={year}
                className={`optionItem ${answer === year.toString() ? 'active' : ''}`}
                onClick={() => onSelectYear(year)}
              >
                {year}년
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default YearContent;