import React from 'react';

const ScoreContent = ({ currentData, isSelected, onSelectAnswer }) => {
  return (
    <>
      <ul className={`score count_${currentData.question.items.length}`}>
        { currentData.question.items.map((item, index) =>
            <li key={`question-${index}`}
              className={`tooltip ${isSelected(item) ? "active" : ""}`}
              onClick={() => onSelectAnswer(item)}
            >{item.score}
            {item.content?.length > 0 && <span className="tooltiptext tooltip-top">{item.content}</span>}
            </li>
          )
        }
      </ul>
      <div>
        {currentData.question.items?.length > 0 && currentData.question.items[0].score}점 : {currentData.question.items[0].content} &nbsp;-&nbsp;
        {currentData.question.items?.length > 0 && currentData.question.items[currentData.question.items.length - 1].score}점 : {currentData.question.items[currentData.question.items.length - 1].content}
      </div>
    </>
  );
};

export default ScoreContent;
