import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SurveyContextProvider } from '../../context/SurveyContext';
import SurveyAnswerContent from './answer/SurveyAnswerContent';
import SurveyContent from './content/SurveyContent';
import SurveyHeader from './SurveyHeader';
import queryString from 'query-string';

const Survey = props => {

  const { projectId, surveyId, tab } = useParams()
  const navigate = useNavigate()    
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState("survey")

  useEffect(_ => {

    if (projectId === null || projectId === 'undefined') {
      alert('잘못된 접근입니다.')
      navigate("/dashboard")
      return
    }
    if (surveyId === null || surveyId === 'undefined') {
      alert('잘못된 접근입니다.')
      navigate(`/project/${projectId}`)
      return 
    }    
    return ()=>{ 
    }
  }, [])

  useEffect(() => {

    setSelectedTab(tab?? "survey")
    
  }, [location])


  const onChangeTab = (tab) => {
    
    navigate(`/project/${projectId}/survey/${surveyId}/${tab}`, {replace: true})
  }

  return (
    <SurveyContextProvider surveyId={surveyId}>
       <div className="body project survey">
        <SurveyHeader tab={selectedTab} onChangeTab={onChangeTab}/>
        {selectedTab != "answer" ? <SurveyContent/> : <SurveyAnswerContent/>}
      </div>
    </SurveyContextProvider>
  )
}
  
export default Survey;