import $ from 'jquery';
import { useEffect } from "react";

const NewProjectPopup = props => {

  useEffect(()=> {
    $('.new_project_but').click(function() {
      $('.new_project_pop, .popup_inwrap, .step1').fadeIn();
    });
    $('.close_btn, .dim_bg, .pop_close').click(function() {
       $('.new_project_pop, .step1, .step2, .step3').fadeOut();
    });
    $('.go_step1').click(function() {
     $('.step1, .step2, .step3').hide();
     $('.step1').show();
       
    });
    $('.go_step2').click(function() {
       $('.step1, .step2, .step3').hide();
       $('.step2').show();
       
    });
   
    $('.go_step3').click(function() {
       $('.step1, .step2, .step3').hide();
       $('.step3').show();
    });
  }, []);

  return (
    <div class="popup new_project_pop" >
      <div class="popup_wrap">
        <div class="popup_inwrap">
          <h5 class="section_tit">새 프로젝트  <span class="close_btn"></span></h5>
          <form action="">
            <div class="step1" >
              <div class="pop_content">
                
                <ul class="steps">
                  <li class="on"><span>1</span></li>
                  <li><span>2</span></li>
                </ul>
          
                <h4>프로젝트 이름을 입력해주세요.</h4>
                <div>
                  <label for="project_name" >프로젝트 이름 (최대 30자)</label>
                  <input type="text" id="project_name" placeholder="예) 당뇨환자의 수면장애" class="full big"/>
                  <p class="tip">이름은 최대 30자를 넘을 수 없습니다.</p>
                </div>
                <p class="text_secondaty">프로젝트 이름은 추후 언제든지 수정 가능합니다. </p>
                <p className='checkbox'>
                  <label>
                    <input type="checkbox" name="checkbox" value="phone"/><span>eCRF(전자증례기록)으로 진행하는 프로젝트인가요?</span>
                  </label> 
                  <span className='tooltip'>
                    <i className='icon-infocircle '></i> 
                    <span class="tooltiptext tooltip-top">eCTF(전자증례기록)이란? <br/>
                    임상시험 계획서에 따라서 각각의 시험 대상자에 대하여 요구되어지는 정보를 기록하여 임상시험 의뢰자에게 보고 및 전달되어지는 정보의 점검 가능한 전자 기록을 말합니다.</span>
                  </span>
                </p>
              
              </div>
              <div class="pop_foot">
                <div class="but_wrap">
                  <div>
                    <button type="button" class="secondary gray large pop_close">이전</button>
                  </div>
                  <div>
                    
                    <button type="button" class=" disabled large go_step2" >다음</button>
                    {/* <!-- <button type="button" class="primary  large" onclick="location.href=''">다음</button> --> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- step 2 -->   */}
            <div class="step2">
              <div class="pop_content">
                  
                <ul class="steps">
                  <li class="ok"><span></span></li>
                  <li  class="on"><span>2</span></li>
                </ul>
          
                <h4>공유할 작업자가 있다면 이메일을 입력해주세요.  <br/>
                  없으면 다음 단계로 진행해주세요.</h4>
                <div>
                  <label for="join_mail" >이메일</label>
                  <div class="vertical_form">
                    <div>
                      {/* <!-- <div>
                        참여자
                        <ul>
                          <li>참여자</li>
                          <li>쇼유자</li>
                        </ul>
                      </div> --> */}
                    
                        <div class="selectBox select_inline">
                          <span class="label">참여자</span>
                          <ul class="optionList">
                              <li class="optionItem">참여자</li>
                              <li class="optionItem">소유자</li>
                          </ul>
                        </div>
                      <input type="text" id="join_mail" placeholder="예) af@atflow.co.kr" class="full big"/>
                    </div>
                    <div >
                      <button class="full">추가</button>
                    </div>
                  </div>
                  
                </div>
                <div>
                  <div class="vertical_form">
                    <div>
                    <i class="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div className='text_right'>
                      소유자
                    </div>
                  </div>
                  <div class="vertical_form">
                    <div>
                    <i class="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div>
                      <div class="selectBox select_inline">
                        <span class="label">참여자</span>
                        <ul class="optionList">
                            <li class="optionItem">참여자</li>
                        
                            <li class="optionItem">삭제</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="vertical_form">
                    <div>
                    <i class="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div>
                      <div class="selectBox select_inline">
                        <span class="label">참여자</span>
                        <ul class="optionList">
                            <li class="optionItem">참여자</li>
                     
                            <li class="optionItem">삭제</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="vertical_form">
                    <div>
                    <i class="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div>
                      <div class="selectBox select_inline">
                        <span class="label">참여자</span>
                        <ul class="optionList">
                            <li class="optionItem">참여자</li>
                     
                            <li class="optionItem">삭제</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="vertical_form">
                    <div>
                    <i class="icon-mail"></i> <a href="abcd@atflow.co.kr">abcd@atflow.co.kr (나) </a>
                    </div>
                    <div  className='text_right'>
                    초대장 발송됨
                    </div>
                  </div>
                </div>


                <p class="text_secondaty">프로젝트 공동작업자로 지정되면 프로젝트 내 모든 설문의 접근이 가능합니다. </p>
                
              </div>
              <div class="pop_foot">
                <div class="but_wrap">
                  <div>
                    <button type="button" class="secondary gray large go_step1" >이전</button>
                  </div>
                  <div>
                    <button type="button" class="primary  large go_step3" >다음</button>
                  
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- step 3 -->   */}
            <div class="step3">
              <div class="pop_content">
                  
                <ul class="steps">
                  <li class="ok"><span></span></li>
                  <li  class="ok"><span></span></li>
                  <li  class="on"><span>3</span></li>
                </ul>

                <h4>🎉  <br/>
                  프로젝트가 추가되었습니다!</h4>

                <p class="text_secondaty">이제 본격적인 설문 컨텐츠를 채워보세요.  </p>
                
              </div>
              <div class="pop_foot">
                <div class="but_wrap">
                  <div>
                    <button type="button" class="primary  large pop_close" >프로젝트 들어가기</button>
                  </div>
                </div>
              </div>
            </div>
            {/* 초대장 발송 */}
            <div className="popup  popPlus">
              <div className="popup_wrap">
                <div className="popup_inwrap">
                  <h5 className="section_tit">초대장 발송<span className="close_btn"></span></h5>

                  <div className="pop_content height_auto">
                    <div>
                      ok@naver.com님은 아직 Research Flow에 가입되어 있지 않습니다.< br/>
                      공동작업자로 추가하기 위해 초대장을 발송하시겠습니까?
                    </div>
                    
                  </div>
                  <div className="pop_foot">
                    <div className="but_wrap">
                      <div>
                        <button type="button" className="secondary gray large pop_close">취소</button>
                      </div>
                      <div>

                        <button type="button" className=" primary large ">확인</button>
                        {/* <!-- <button type="button" className="primary  large" onclick="location.href=''">다음</button> --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </form>
        </div>
        {/* <div class="dim_bg"></div> */}
      </div>
    </div>
  );  
}
 export default NewProjectPopup;