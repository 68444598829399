import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { getYYYYMMDD } from '../../../common/stringHelper';
import { dateToString, stringToDate } from '../../../common/dateHelper';

const NoticePopup = props => {

  const {popupData, onCancel, onConfirm, notice} = props
  
  const api = useApi()  
  const {alert} = useCommonPopup()

  const [title, setTitle] = useState(notice?.title)
  const [content, setContent] = useState(notice?.content)

  const [startDate, setStartDate] = useState(notice?.startdate)
  const [endDate, setEndDate] = useState(notice?.enddate)
  const [dateError, setDateError] = useState('')
  const [enableOK, setEnableOK] = useState(false)
  
  useEffect(()=> {
    
      return () => {
    }
  }, []);

  useEffect(()=> {
    
    console.log(`startDate ${startDate}`)
    setEnableOK(checkValidate())

  }, [title, content, startDate, endDate]);

  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickOK = async () => {

    var result = null

    if (!!notice) {
      result = await api.post("updateNotice", {
        notice: notice.idnotice,
        title: title,
        content: content,
        startdate: startDate,
        enddate: endDate
      })
      
    } else {
      result = await api.post("registerNotice", {
        title: title,
        content: content,
        startdate: startDate,
        enddate: endDate
      })
      
    }
    
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    }
  }  

  const onChangeTitle = (e) => {

    setTitle(e.target.value);
  }

  const onChangeContent = (e) => {

    setContent(e.target.value);
  }

  const onChangeStartDate = (e) => {

    
    var sdate = dateToString(e.target.value)  
    var edate = dateToString(endDate)    
    var today = dateToString(new Date())

    if (new Date(sdate) < new Date(today)) {
      setDateError('노출 기간이 현 시점보다 과거입니다.')
    } else if (new Date(edate) < new Date(sdate)) {
      setDateError('종료 시간이 시작 시간보다 과거입니다.')
    } else {
      setDateError('')
    }
    setStartDate(e.target.value);
  }

  const onChangeEndDate = (e) => {

    var sdate = dateToString(startDate)  
    var edate = dateToString(e.target.value)    
    var today = dateToString(new Date())

    if (!!notice && notice?.status !== 'WAIT') {
      setDateError('')
    } else if (new Date(sdate) < new Date(today)) {
      setDateError('노출 기간이 현 시점보다 과거입니다.')
    } /*else if (new Date(edate) < new Date(sdate)) {
      setDateError('종료 시간이 시작 시간보다 과거입니다.')
    } */else {
      setDateError('')
    }
    setEndDate(e.target.value);
  }

  const checkValidate = () => {
    
    if (!title) return false
    if (!content) return false
    if (!startDate) return false
    if (!endDate) return false
    
    var sdate = dateToString(startDate)  
    var edate = dateToString(endDate)    
    var today = dateToString(new Date())

    if (!!notice && notice?.status !== 'WAIT') {
    }  else if (new Date(sdate) < new Date(today)) {
      return false
    }
    // else if (new Date(edate) < new Date(sdate)) return false
    
    return true
  }

  return (    
    <div className="popup_wrap ">
      <div className="popup_inwrap common_pop large">
        <h5 className="section_tit">{!!notice ? '공지사항 수정' : '새 공지 등록'}<span className="close_btn" onClick={onClickClose}></span></h5>
        <form action="" onSubmit={(e) => {e.preventDefault()}}>
          <div className="pop_content">    
           <div>
              <label htmlFor="">제목</label>
              <input type="text" 
                className="full big" 
                id="project_name" 
                placeholder="제목을 입력해주세요." 
                value={title}
                disabled={!!notice && notice?.status !== 'WAIT'}
                onChange={onChangeTitle}/>
            </div>
            <div>
              <label htmlFor="">내용</label>
              <textarea 
                className='full big ' 
                cols="30" 
                rows="5" 
                placeholder="내용을 입력해주세요." 
                value={content}
                disabled={!!notice && notice?.status !== 'WAIT'}
                onChange={onChangeContent}/>
            </div> 
            <div >
              <label htmlFor="">노출 기간</label>
              <div className='flex_between'>
                <input className={`big ${startDate?.length > 0 ? "active" : ""}`} 
                  type="date" 
                  placeholder="YYYY.MM.DD" 
                  value={startDate} 
                  disabled={!!notice && notice?.status !== 'WAIT'}
                  onChange={onChangeStartDate}/>
                <span>~</span>
                <input className={`big ${endDate?.length > 0 ? "active" : ""}`} 
                  type="date" 
                  placeholder="YYYY.MM.DD" 
                  value={endDate} 
                  onChange={onChangeEndDate}/>
              </div>
              <p className="tip">{dateError}</p>
              <p className='c_primary_6'>공지 내용과 노출 기간은 공지 시작 후에는 수정할 수 없습니다. <br/>
                노출 기간 시작일부터는 삭제만 가능합니다.  <br/>
                미리 작업을 완료하시고 등록 전에 내용을 한 번 더 점검해 주시기 바랍니다.</p>
            </div> 
            
            
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className={`large primary ${enableOK === true ? '' : 'disabled'}`} disabled={!enableOK} onClick={onClickOK}>확인</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );  
}
 export default NoticePopup;