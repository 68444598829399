import { useEffect, useRef, useState } from 'react';
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { useDropzone } from 'react-dropzone';
import axios from 'axios'
import toast from 'react-hot-toast';

const FileUploadPopup = props => {

  const {surveyId} = props
  const {popupData, onCancel, onConfirm} = props

  const api = useApi()
  const { popup, alert, confirm } = useCommonPopup()
  const [fileList, setFileList] = useState([])
  const [urlList, setUrlList] = useState({})
  const [error, setError] = useState()
  
  const [selectedData, setSelectedData] = useState()
  const [loadingMessage, setLoadingMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(_ => {
    
    getExcelForConfirmList()

  }, [])


  /*=========================================================
  *  Data 
  =========================================================*/
  
  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickDelete = async (data) => {
    if (await confirm(`이 파일을 삭제하시겠습니까?`, {title: "파일 삭제", confirm: "삭제"})) {
      deleteExcelForConfirm(data.filename, data.filepath)
    } 
  }

  const onClickDownload = (data) => {
    getPresignedUrlForDownload(data.filename, data.filepath)
  }

  const getPresignedUrlForUpload = async (filename) => {
    
    const result = await api.post("getPresignedUrlForUpload", {
      survey: surveyId,
      filename : filename
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {      
      var url = result.data.data.url
      var filepath = result.data.data.filepath
      console.log(`getPresignedUrlForUpload : url = ${url}\nfilepath = ${filepath}`)
      return result.data.data
    }
    return null
  }

  const getExcelForConfirmList = async () => {
    
    const result = await api.post("getExcelForConfirmList", {
      survey: surveyId,
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {      
      console.log(`getExcelForConfirmList : ${JSON.stringify(result.data.data.excelfiles)}`)
      setFileList(result.data.data.excelfiles)
      if (result.data.data.excelfiles.length >= 5) {
        setError('파일은 5개까지만 등록할 수 있습니다. 기존 파일을 삭제 후 등록해주세요.')
      } else {
        setError('')
      }
    }
  }

  const registerExcelForConfirm = async (filename, filepath) => {
    
    const result = await api.post("registerExcelForConfirm", {
      survey: surveyId,
      filename: filename,
      filepath: filepath
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {      
      console.log(`registerExcelForConfirm : ${JSON.stringify(result.data.data)}`)
      getExcelForConfirmList()
    }
  }

  const deleteExcelForConfirm = async (filename, filepath) => {
    
    setIsLoading(true)
    setLoadingMessage("삭제 중입니다...")
    const result = await api.post("deleteExcelForConfirm", {
      survey: surveyId,
      filename: filename,
      filepath: filepath
    })

    if (result?.data?.result !== "ok") {
      setIsLoading(false)
      api.networkError(result, alert)
    } else {    
      setIsLoading(false)  
      toast.success(`파일이 삭제되었습니다.`); 
      getExcelForConfirmList()
    }
  }

  const getPresignedUrlForDownload = async (filename, filepath) => {
    
    const result = await api.post("getPresignedUrlForDownload", {
      survey: surveyId,
      filename: filename,
      filepath: filepath
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {      
      console.log(`getPresignedUrlForDownload ${result.data.data.url}`)
      const link = document.createElement('a');
      link.href = result.data.data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    disabled: isLoading,
    onDrop: async acceptedFiles => {
      
      if (isLoading) return

      if (acceptedFiles && acceptedFiles.length > 0) {
        
        setIsLoading(true)
        setLoadingMessage("업로드 중입니다...")
        var file = acceptedFiles[0]
        console.log(`file.size ${file.size}`)
        if (file.size >= 10485760) {
          setIsLoading(false)          
          setError('10MB를 초과하는 파일은 등록할 수 없습니다.')
          return
        } else {
          setError('')
        }
        var filename = file.name.normalize('NFC')

        var data = await getPresignedUrlForUpload(filename)
        if (data === null) return

        var url = data.url
        var filepath = data.filepath

        try {
          await axios.put(url, file, {
              headers: {'Content-Type': file.type, charset: 'utf-8'},
          }).then(async ({ status, data }) => {
            console.log(`result : ${status} ${JSON.stringify(data)}`)
            await registerExcelForConfirm(filename, filepath)
            setIsLoading(false)
          })
          .catch(error => {
            console.log(`error : ${JSON.stringify(error)}`)
            setIsLoading(false)
            api.networkError(error, alert)
          });
        } catch (err) { 
          setIsLoading(false)
          api.networkError(err, alert)
        }
      }
    }
  });
  
  const onMouseOver = (data) => {

    if (isLoading) return
    setSelectedData(data)
  }

  const onMouseOut = (data) => {

    if (data === selectedData) {
      setSelectedData(null)
    }
  }

  return (
    <div>
      <div className="popup_wrap">
        <div className={`popup_inwrap`}>            
          <div>
            <h5 className="section_tit">컨펌용 엑셀 {fileList?.length > 0 ? `(${fileList?.length})` : ``}<span className="close_btn" onClick={onClickClose}></span></h5>
            <form action="" onSubmit={(e) => {e.preventDefault()}}>          
              <div className='pop_content' style={{minHeight:"unset"}}>
                <div {...getRootProps({className: `gray_box ${isLoading ? "c_disabled" : ""}`})}>
                  <input {...getInputProps()} />
                  <i className='icon-inbox'></i>
                  <h5>
                    이 영역을 클릭하거나 원하는 파일을 여기로 끌어놓아 <br/>
                    업로드하세요. (10MB 이하 최대 5개)
                  </h5>
                  <p>연구용 임상 데이터 최종본을 여기에 저장하세요 .</p>
                </div>  
                { fileList?.map((file, index) =>
                  <div className="flex " key={`file-${index}`} 
                    onMouseOver={() => {onMouseOver(file)}} onMouseOut={() => {onMouseOut(file)}}>
                    <div>
                      <i className="icon-attachment"></i>
                      <span className='c_primary_6' onClick={() => onClickDownload(file)}>{file.filename}</span>
                    </div>
                    <div>
                      <span>{file.createdate}</span> 
                      {isLoading ===false && file === selectedData && <i className='icon-delete' style={{paddingLeft:"5px"}} onClick={() => {onClickDelete(file)}}></i>}
                    </div>
                  </div>
                )}                  
                {isLoading ? <div className='upload'>{loadingMessage}</div> : <div className='c_dust_red_5'>{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>      
  )
}
export default FileUploadPopup;