import $ from 'jquery';
import { useEffect } from "react";

const ParticipantPopup2 = props => {

  useEffect(()=> {

    $('.Participant-btn').click(function() {
      $('.Participant_pop, .Participant_pop .popup_inwrap ').fadeIn();
    });

  }, []);

  return (
 
    <div className="popup Participant_pop" >
        <div className="popup_wrap">

        {/* 참여자 그룹 변경 */}
        <div className="popup_inwrap">
          <h5 className="section_tit">참여자 그룹 변경  <span className="close_btn"></span></h5>
          <form action="">
            <div className="pop_content height_auto ">
                <h4>
                my123, 49DsD님의 그룹을 선택해주세요.
                </h4>
                <div class="selectBox large">
                  <span class="label">전체</span>
                  <ul class="optionList">
                  <li class="optionItem">전체</li>
                    <li class="optionItem">그룹 A (시험군)</li>
                    <li class="optionItem">그룹 B (대조군)</li>
                  </ul>
                </div>
                  
              </div>
              <div className="pop_foot">
                <div className="but_wrap">
                  <div>
                    <button type="button" className="secondary gray large pop_close">취소</button>
                  </div>
                  <div>
                    <button type="button" className=" primary large ">확인</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
        </div>
      </div>

  );  
}
 export default ParticipantPopup2;