import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { handleSelect } from '../../../common/javascriptHelper';
import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { useSurvey } from '../../../context/SurveyContext';
import { SURVEY_STATUS_CLOSED,
  SURVEY_STATUS_WRITING } from '../../../common/codeHelper';
import { useProject } from '../../../context/ProjectContext';
import SaveMyScalePopup from '../popup/SaveMyScalePopup';
import { dateToString } from '../../../common/dateHelper';


const SectionContent = props => {

  const api = useApi()
  const {popup, alert, confirm} = useCommonPopup()

  const project = useProject()
  const survey = useSurvey()

  // const [section, setSection] = useState(props.section)
  const {section} = props
  const oldData = useRef(props.section)

  const nameRef = useRef()
  const timer = useRef({
    name: null,
    description: null,
    commonQuestion: null
  })

  /*=========================================================
   *  useEffect
   =========================================================*/

  useEffect(_ => {

    getSection(section.idsection)

    return ()=>{
      clearTimeout(timer.current.name)
      clearTimeout(timer.current.description)
      clearTimeout(timer.current.commonQuestion)
    }
  }, [])

  useEffect(_ => {

    if (props.section?.idsection !== oldData?.current?.idsection) {
      oldData.current = {
        ...section
      }
      getSection(props.section?.idsection)
    }
    // getSection(section.idsection)
    // survey.updateSection(section)

    // if (!!section && section.idsection != props.section.idsection) {
    //   setSection(props.section)
    // }
  }, [props])

  useEffect(_ => {

    if (!!survey.showError) {
     if (survey.showError.type === "section" && survey.showError.id == section.idsection) {
        setTimeout(() => {
          nameRef.current?.focus()
        }, 200)
      }
    }

  }, [survey.showError])

  /*=========================================================
   *  Data
   =========================================================*/
  const getSection = async (idsection) => {

    const result = await api.request(`/sections/${idsection}/`)

    if (result?.status !== 200) {
      return null
    } else {
      var data = result.data

      survey.updateSection({
        ...section,
        ...data,
      })
    }
  }

  const updateSection = async (name, value, action, extra) => {

    var params = {
      ...extra,
      section: section.idsection,
      [name]: value
    }

    const result = await api.post(action, params)
    return result?.data?.result == "ok"
  }

  /*=========================================================
   *  Handler
   =========================================================*/

  const onChangeData = async (name, value, action, immediate, extra) => {

    // if (oldData.current[name] == value) return
    if (immediate) {
      var result = await updateSection(name, value, action, extra)
      if (result) {
        // await getSurvey()
      }
    } else {
      // setSection({
      //   ...section,
      //   [name]: value
      // })
      survey.updateSection({
        ...section,
        [name]: value
      })
      if (!!timer.current[name]) clearTimeout(timer.current[name])
      timer.current[name] = setTimeout(() => {
        updateSection(name, value, action, extra)
      }, survey.saveTimeout)
    }
  }

  const onChangeName = (e) => {

    var value = e.target.value
    onChangeData("name", value, "updateSectionName")
  }


  const onChangeDescription = (e) => {

    var value = e.target.value
    if (value.length > 1024) return

    onChangeData("description", value, "updateSectionDescription")
  }

  const onChangeCommonQuestion = (e) => {

    var value = e.target.value
    if (value.length > 1000) return

    onChangeData("commonquestion", value, "updateSectionCommonQuestion")
  }

  const onChangeAgreement = (e) => {

    var value = e.target.value

    onChangeData("agreement", value, "updateSectionPrivacyAgreement")
  }

  const onClickSelect = (e) => {

    if (survey.surveyData.status !== SURVEY_STATUS_WRITING) return

    handleSelect(e)
  }

  const onClickCopy = async () => {
    const result = await api.request(`/sections/${section.idsection}/copy/`, { method: "post" })
    if (result?.status !== 201) {
      api.networkError(result, alert)
    } else {
      await survey.getSurvey(result.data.section, null)
    }
  }

  const onClickDelete = async() => {

    const result = await api.request(`/sections/${section.idsection}/`, { method: "delete" })

    if (result?.status !== 204) {
      api.networkError(result, alert)
    } else {
      await survey.getSurvey()
      toast.success(`섹션이 삭제되었습니다.`);
    }
  }

  const onClickSaveMyScale = async () => {

    const result = await popup(
      <SaveMyScalePopup
        projectData={project.projectData}
        section={section}
        scaleName={`${section.name}_${dateToString(new Date(), 'YYYYMMDDHHmm')}`}
      />, {
        container: "survey",
        name: "save_my_scale_pop",
        hasFooter: false,
        useContainer: false,
      },
    )
    if (result === true) {
      // getProjectList(keyword, currentPage)
    }
  }

  const onChangeAutoComplete = async (e) => {

    if (e.target.checked) {

      if (await confirm(`사용자 편집 모드에서 자동 완성 모드로 전환하시겠습니까?\n
        지금까지 편집한 문구가 초기화되며 자동으로 제공되는 문구로 대체됩니다.`
        , {title : '자동 완성 켜기', confirm: '켜기'}) === false) return

      const result = await api.post("updatePrivacyAutoComplete", {
        section: section.idsection,
        privacyautocomplete: 1
      })

      if (result?.data?.result !== "ok") {
        api.networkError(result, alert)
      } else {
        await getSection(section.idsection)
      }

    } else {

      if (await confirm(`자동 완성 모드에서 사용자 편집 모드로 전환하시겠습니까?\n
        사용자 편집 모드로 전환하면 조건 수정에 따라 문구가 자동으로 업데이트되지 않습니다.`
        , {title : '자동 완성 끄기', confirm: '끄기'}) === false) return

      const result = await api.post("updatePrivacyAutoComplete", {
        section: section.idsection,
        privacyautocomplete: 0
      })
      if (result?.data?.result !== "ok") {
        api.networkError(result, alert)
      } else {
        await getSection(section.idsection)
      }
    }
  }

  return (
    <section>
      <div className='in_padding flex_c question_body'>
        <div>
          <label htmlFor="">섹션명</label>
          <input
            type="text"
            ref={nameRef}
            className={`full big ${survey.hasError(`section-${section.idsection}`) ? "error" : ""}`}
            placeholder="섹션명을 입력해주세요."
            value={section.name}
            onChange={onChangeName}
            disabled={survey.surveyData?.status === SURVEY_STATUS_CLOSED}
          />
        </div>
        <div>
          <label htmlFor="">설명</label>
          <textarea name="" id="" cols="30" rows="5"
            className='full'
            placeholder='섹션명과 설명은 설문하는데 필요한 개념, 유의사항 등을 전달하는 수단으로 활용하세요.
              개별 설문에는 최소 1개의 섹션이 필요하며, 필요에 따라서 추가하시면 됩니다.
              섹션 화면은 사용자에게 설문 표지처럼 보여진다는 점을 명심하세요.'
            value={section.description}
            onChange={onChangeDescription}
            disabled={survey.surveyData?.status === SURVEY_STATUS_CLOSED}>
          </textarea>
          {/* <p className='text_right c_disabled'> {section.description.length} / 100</p> */}
        </div>
        <div>
          <label htmlFor="">섹션 공통 질문
            <span className='tooltip'><i className='icon-infocircle '></i> <span className="tooltiptext tooltip-top">문항마다 섹션 공통 질문이 함께 노출되어 보다 쉽고 정확한 설문조사 응답이 가능합니다.</span></span>
          </label>
          <textarea name="" id="" cols="30" rows="10"
            className='full '
            placeholder='응답자가 문항에 답변할 때, 해당 문항이 속한 섹션의 공통 질문도 함께 제공됩니다.
              연속된 질문의 경우 공통 질문을 활용하면 응답자가 설문 조사를 더욱 이해하기 쉽게 만들어 줄 수 있습니다.
              입력하지 않으면 섹션 공통 질문은 응답자에게 노출되지 않습니다.'
            value={section.commonquestion ?? ''}
            onChange={onChangeCommonQuestion}
            disabled={survey.surveyData?.status === SURVEY_STATUS_CLOSED}
          >
          </textarea>
          <p className='text_right c_disabled'> {section.commonquestion?.length ?? 0} / 1000</p>
        </div>
        {/* { section.hasconditionquestion === 1 &&  */}
        <div>
            <label htmlFor="">개인정보 수집 동의
              <span className='tooltip'><i className='icon-infocircle '></i> <span className="tooltiptext tooltip-top">문항 중 “조건“으로 사용하는 항목을 사용하면 자동으로 이 내용이 작성되며, 응답자에게 개인정보 수집 동의를 받을 수 있습니다. 입력 내용이 없으면 개인정보 수집 동의는 응답자에게 노출되지 않습니다.</span></span>
              <span className='switch_wrap'> 자동 완성
                <label className="switch">
                  <input type="checkbox" checked={section.privacyautocomplete === 1} disabled={survey.surveyData.status !== SURVEY_STATUS_WRITING} onChange={onChangeAutoComplete}/>
                    <span className="slider round"></span>
                </label>
              </span>
            </label>
            <textarea name="" id="" cols="30" rows={section.hasconditionquestion === 1 ? "10" : "2"}
              className={`full ${section.privacyautocomplete === 1 ? 'disabled' : ''}`}
              placeholder={section.hasconditionquestion !== 1 ? `문항 중 “조건“으로 사용하는 항목을 사용하면 자동으로 이 내용이 작성되며, 응답자에게 개인정보 수집 동의를 받을 수 있습니다. 입력 내용이 없으면 개인정보 수집 동의는 응답자에게 노출되지 않습니다.` : ''}
              value={section.agreement}
              onChange={onChangeAgreement}
              disabled={section.privacyautocomplete === 1 || section.hasconditionquestion !== 1}
            >
            </textarea>
            {/* <p className='text_right c_disabled'> {section.commonquestion.length} / 100</p> */}
          </div>
        {/* } */}
      </div>
      <div className='question_foot'>
        <div className="in_padding">
          <button className={`text_only ${section?.name?.length > 0 ? '' : 'disabled'}`} disabled={section?.name?.length === 0} onClick={onClickSaveMyScale}>내 척도에 저장</button>
          { survey.surveyData.status === SURVEY_STATUS_WRITING ? (
            <>
              <span className='tooltip'>
                <i className="icon-copy" onClick={onClickCopy}></i>
                <span className="tooltiptext tooltip-top">섹션 복사</span>
              </span>
              <i className={`icon-delete survey-del-btn ${section.orderno === 1 ? "c_disabled" : ""}`}
                onClick={onClickDelete}
              />
            </>
          ) : (<br/>)}

        </div>
      </div>
    </section>
  )
}

export default SectionContent;