import moment from 'moment'

const setKrDayInfo = _ => {
  moment.lang('ko', {
    weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
  })
}

export const getMoment = data => moment(data)

export const dateToString = (data, format = 'YYYY-MM-DD') => {
  return moment(data).format(format)
}

export const getToday = (data) => {
  return moment(data).format('YYYY-MM-DD')
}


export const stringToDate = (str, format = 'YYYY-MM-DD') => {
  return moment(str, format)
}


export const convertDateString = (str, inputFormat, outputFormat) => {

  let date = stringToDate(str, inputFormat)
  return dateToString(date, outputFormat)
}

export const diff = (date1, date2, format) => {

  let t1 = moment(date1, format)
  let t2 = moment(date2, format)
  return moment.duration(t2.diff(t1)).asSeconds();
}

export const diffNow = (date, format) => {

  let t1 = moment()
  let t2 = moment(date, format)
  return moment.duration(t2.diff(t1)).asSeconds();
}

export const diffDayNow = (date, format) => {

  let t1 = moment(moment().format('YYYY-MM-DD'))
  let t2 = moment(moment(date, format).format('YYYY-MM-DD'))

  return moment.duration(t2.diff(t1)).asDays();
}

// export const convertDateTimeToUnixTimeStamp = (data, format = 'YYYY-MM-DD HH:mm') => {
//   if (format === 'HH:mm') data = getCurrentTimeFormatData(`YYYY-MM-DD ${data}`)

  // const _i = moment(data).format('YYYY-MM-DD HH:mm:ss')
  // // new Date(data).getTime() / 1000
  // return moment(_i).unix()
// }

// export const getCurrentTimeFormatData = (format = 'YYYY-MM-DD') => {
//   return moment().format(format)
// }

// export const getTimeFormatDataFromTimeStamp = (timeStamp, format = 'YYYY-MM-DD HH:mm:ss') => {
//   let _timeStamp = timeStamp
//   if (`${_timeStamp}`.length === 10) {
//     _timeStamp = _timeStamp * 1000
//   }
//   return moment(_timeStamp).format(format)
// }

// // prev, next - years | months | days
// export const getNextTimeFormatData = (unit = 'years', count = 1, format = 'YYYY-MM-DD') => {
//   return moment().add(count, unit).format(format)
// }

// export const getPrevTimeFormatData = (unit = 'years', count = 1, format = 'YYYY-MM-DD') => {
//   return moment().subtract(count, unit).format(format)
// }

// export const isBeforeDate = timeStamp => {
//   let _timeStamp = timeStamp
//   if (`${_timeStamp}`.length === 10) {
//     _timeStamp = _timeStamp * 1000
//   }
//   return moment(_timeStamp).isBefore()
// }

// export const getTimeFormatValue = seconds => {
//   let min = parseInt(seconds / 60)
//   let sec = seconds % 60
//   if (min.toString().length === 1) min = '0' + min
//   if (sec.toString().length === 1) sec = '0' + sec

//   return `${min}분 ${sec}초`
// }

// export const getToday = seconds => {
//   var today = new Date()
//   var dd = String(today.getDate()).padStart(2, '0')
//   var mm = String(today.getMonth() + 1).padStart(2, '0')
//   var yyyy = today.getFullYear();        
//   return yyyy + mm + dd
// }

// export const getTimeSimpleFormatValue = seconds => {

//   let min = parseInt(seconds / 60)
//   let sec = seconds % 60

//   if (sec == 0) {
//     return min + '분'          
//   }else {
//     return min + '분' + sec + '초'
//   }
// }

// export const getMonthList = () => {
//   const result = []
//   for (let i = 1; i <= 12; i++) {
//     let obj = {}
//     let v = i < 10 ? `0${i}` : `${i}`
//     obj.key = v
//     obj.value = v
//     result.push(obj)
//   }

//   return result
// }

// export const getYearList = () => {
//   const startYear = Number(moment().format('YYYY'))
//   const result = []

//   for (let i = startYear; i > startYear - 12; i--) {
//     let obj = {}
//     let v = i < 10 ? `0${i}` : `${i}`
//     obj.key = v
//     obj.value = v
//     result.push(obj)
//   }

//   return result
// }

// export const currentDate = () => {
//   const currentDate = moment().format('YYYYMMDD')

//   return {
//     year: currentDate.substring(0, 4),
//     month: currentDate.substring(4, 6),
//     date: currentDate.substring(6, 8),
//   }
// }

// export const getDateFormat = yyyyMMdd => {
//   if (!yyyyMMdd || yyyyMMdd.length !== 8) {
//     return null
//   }
//   return {
//     year: yyyyMMdd.substring(0, 4),
//     month: yyyyMMdd.substring(4, 6),
//     date: yyyyMMdd.substring(6, 8),
//   }
// }

// export const nextDate = (year, month, date) => {
//   setKrDayInfo()
//   const dateInfo = year && month && date ? moment(`${year}-${month}-${date}`) : moment()
//   const nextDate = dateInfo.add(1, 'days')
//   const nextDateFormatData = nextDate.format('YYYYMMDD')
//   const nextDateDay = nextDate.format('ddd')

//   return {
//     year: nextDateFormatData.substring(0, 4),
//     month: nextDateFormatData.substring(4, 6),
//     date: nextDateFormatData.substring(6, 8),
//     day: nextDateDay,
//   }
// }

// export const prevDate = (year, month, date) => {
//   setKrDayInfo()
//   const dateInfo = year && month && date ? moment(`${year}-${month}-${date}`) : moment()
//   const prevtDate = dateInfo.subtract(1, 'days')
//   const prevtDateFormatData = prevtDate.format('YYYYMMDD')
//   const prevtDateDay = prevtDate.format('ddd')
//   return {
//     year: prevtDateFormatData.substring(0, 4),
//     month: prevtDateFormatData.substring(4, 6),
//     date: prevtDateFormatData.substring(6, 8),
//     day: prevtDateDay,
//   }
// }

// export const nextMonth = (year, month) => {
//   const yearMonth = year && month ? moment(`${year}-${month}`) : moment()
//   const nextMonth = yearMonth.add(1, 'months').format('YYYYMM')

//   return { year: nextMonth.substring(0, 4), month: nextMonth.substring(4, 6) }
// }

// export const prevMonth = (year, month) => {
//   const yearMonth = year && month ? moment(`${year}-${month}`) : moment()
//   const prevMonth = yearMonth.subtract(1, 'months').format('YYYYMM')

//   return { year: prevMonth.substring(0, 4), month: prevMonth.substring(4, 6) }
// }

// export const getMonthDateRange = (year, month) => {
//   const yearMonth = year && month ? moment(`${year}-${month}`) : moment()

//   const startOfMonth = yearMonth.clone().startOf('month').format('YYYYMMDD')
//   const endOfMonth = yearMonth.clone().endOf('month').format('YYYYMMDD')

//   return { startDate: startOfMonth, endDate: endOfMonth }
// }
