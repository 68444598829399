import { useEffect, useRef, useState } from 'react';
import { useApi } from '../../../context/ApiContext';
import SurveyScalePreviewPopup from './SurveyScalePreviewPopup';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { markKeyword } from '../../../common/stringHelper';
import MyScalePopup from '../../user/popup/MyScalePopup';

const SurveyScalePopup = props => {

  const {popupData, onCancel, onConfirm} = props

  const api = useApi()
  const { popup, alert } = useCommonPopup()

  const [customScaleList, setCustomScaleList] = useState(null)
  const [recommendList, setRecommendList] = useState(null)

  const [resultList, setResultList] = useState(null)
  
  const [keyword, setKeyword] = useState("")
  const searchKeyword = useRef("")

  // var timer = useRef()

  useEffect(_ => {
    
    getCustomScaleList()
    
    // return ()=>{ 
    //   clearTimeout(timer)
    // }

  }, [])

  useEffect(_ => {
    
    if (searchKeyword.current !== keyword) {
      searchScaleList(keyword)
    }

  }, [keyword])

  /*=========================================================
  *  Data 
  =========================================================*/
  
  const getCustomScaleList = async () => {

    const result = await api.post("customScaleList", {})
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else { 
      var list = result.data.data.scales 
      list.forEach((data, index) => {
        data["custom"] = 1
      }); 
      setCustomScaleList(list)   
      getRecommendScaleList()
    }
  }

  const getRecommendScaleList = async () => {

    const result = await api.post("recommendScaleList", {})
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else { 
      var list = result.data.data.scales 
      list.forEach((data, index) => {
        data["custom"] = 0
        data["display_name"] = data.name
        data["APA"] = getAPA(data)
      }); 
      setRecommendList(list)   
    }
  }


  const searchScaleList = async (search) => {

    if (search?.length == 0) {
      setResultList(null)
      return
    }
  
    searchKeyword.current = search

    const result = await api.post("searchScale", {
      search: search
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {   
      var list = []
      result.data.data.customscales?.forEach((data, index) => {
        data["custom"] = 1
        data["display_name"] = markKeyword(data.name, search)   
        list.push(data)
      }); 
      result.data.data.scales.forEach((data, index) => {
        data["custom"] = 0
        data["display_name"] = markKeyword(data.name, search)//data.name.replace(search, `<span class='c_primary_6'>${search}</span>`)
        data["APA"] = getAPA(data)
        list.push(data)
      });     
      setResultList(list)   
    }
  }

  const getAPA = (source) => {
    
    var author = source.author
    const array = source.author.split("^")
    if (array.length > 1) author = `${array.slice(0, -1).join(",")}, &${array.slice(-1)}`
    
    var result1 = ""
    var result2 = ""
    if (author.length > 0) result1 += author + ". "
    if (source.year.length > 0) result1 += "(" +source.year + "), "
    if (source.title.length > 0) result1 += source.title + ((source.type !== "website") ? ". " : " ")

    if (source.journal.length > 0) result2 += "<span class='italic'>" + source.journal + "</span>, "
    if (source.volume.length > 0) result2 += source.volume
    if (source.issue?.length > 0) result2 += "(" + source.issue + ")"
    if (source.volume.length > 0 || source.issue?.length > 0) result2 += ", "
    if (source.pages.length > 0) result2 += source.pages
    if (result2.length > 0) result2 += "."
   
    return result1 + result2
  }

  /*=========================================================
   *  Handler 
   =========================================================*/

  const onChangeInput = (e) => {

    var value = e.target.value   
    if (keyword === value) return

    setKeyword(value)

    // if (!!timer) clearTimeout(timer)
    // timer = setTimeout(() => {
    //   searchScaleList(value)
    // }, 200)
  }

  const onKeyEvent = (e) => {

    if (e.key === 'Enter') {
      onClickSearch()
    }
  }

  const onClickSearch = async () => {

    await searchScaleList(keyword)
  }

  const onClickScale = async (scale) => {

    if ( await popup(
      <SurveyScalePreviewPopup scale={scale}/>,
      {
        container: "survey",
        name: "popup-survey-scale-preview",
        style: "survey_scaleStep2",
        title: "척도 미리보기",
        confirm: "이 척도 사용하기"
      }, 
    )) {      
      if (!!onConfirm) {        
        onConfirm(popupData, scale)
      }
    } 
  }

  const onClickCustomScale = async (scale) => {

    onConfirm(popupData, scale)
  }
  
  const onClickCustomScaleEdit = async () => {

    await popup(
      <MyScalePopup/>,
      {
        container: "",
        name: "MyScalePopup",
        style: "survey_scale",
        title: "내 척도 보기",    
        useContainer: false
      }, 
    )
    getCustomScaleList()
  }

  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }
  
  return (
    <div>
      {/* <input type="file" style={{position:"absolute", width: "0", height:"0", overflow: "hidden"}} ref={focusRef}/> */}
      <div className={`popup SurveyScalePopup_pop SurveyScalePopup survey`}>
        <div className="popup_wrap">
          <div className={`popup_inwrap survey_scale`}>            
            <div>
              <h5 className="section_tit">척도불러오기&nbsp;<span className="close_btn" onClick={onClickClose}></span></h5>
              <form action="" onSubmit={(e) => {e.preventDefault()}}>          
                <div className="pop_content scroll" style={{height: "700px"}}>
                  <p className="text_secondaty">자주 사용되는 표준화된 척도들을 불러와  설문조사를 쉽고 빠르게 만들어보세요.</p>
                  <div>
                    <label htmlFor="">척도 검색</label>
                    <div className="vertical_form">
                      <div>
                        <input type="text" id="" placeholder="척도 이름" className="full" value={keyword} onChange={onChangeInput} onKeyUp={onKeyEvent}/>
                      </div>
                      <div>
                        <button className="full" onClick={onClickSearch}><i className="icon-search"></i></button>
                      </div>
                    </div>
                  </div>
                  <div >
                    { !!resultList ? (
                        <>
                          <h6>검색 결과({resultList.length})</h6>
                          { resultList.length > 0 ? (
                              resultList?.map((item, index) =>
                                item.custom === 1 ? (
                                  <div className="divder_up flex_between" onClick={() => { onClickCustomScale(item) }}>
                                    <div>
                                      <div dangerouslySetInnerHTML={ {__html: item.display_name} }></div>
                                      <p className="text_secondaty">
                                        <div>
                                          프로젝트 : {item.projectname} <br/>
                                          {item.savedate}
                                        </div>
                                      </p>
                                    </div>
                                    <label className="c_primary_6 small bg" disabled={true}>내 척도</label>
                                  </div>
                                ) : (
                                  <div className='divder_up' key={`search-${index}`} onClick={() => { onClickScale(item) }}>
                                    <div dangerouslySetInnerHTML={ {__html: item.display_name} }></div>
                                    <p className='text_secondaty'>
                                      <div dangerouslySetInnerHTML={ {__html: item.APA} }></div>
                                    </p>
                                  </div>
                                )
                              )
                            ) : (
                              <div className='divder_up'>
                                <p className='center'>
                                  <br/><br/><br/>
                                  검색 결과가 없습니다.
                                  <br/><br/><br/><br/><br/><br/>
                                </p>
                              </div>
                            )
                          }
                        </>
                      ) : (
                        <>
                          {
                            !!customScaleList & customScaleList?.length > 0 ? (
                              <>
                                <h6 className='flex_between'>
                                  <span>내 척도</span>
                                  <button className="text_only" onClick={onClickCustomScaleEdit}>편집</button>
                                </h6>
                                { customScaleList?.map((item, index) =>
                                    <div className="divder_up flex_between" onClick={() => { onClickCustomScale(item) }}>
                                      <div>
                                        {item.name}
                                        <p className="text_secondaty">
                                          <div>
                                            프로젝트 : {item.projectname} <br/>
                                            {item.savedate}
                                          </div>
                                        </p>
                                      </div>
                                      <label className="c_primary_6 small bg" disabled={true}>내 척도</label>
                                    </div>
                                  )
                                }
                              </>
                            ) : (null)                     
                          }
                          <h6>추천</h6>
                          {
                            recommendList?.map((item, index) =>
                            <div className='divder_up' key={`recommend-${index}`} onClick={() => { onClickScale(item) }}>
                              {item.name}
                              <p className='text_secondaty'>
                                <div dangerouslySetInnerHTML={ {__html: item.APA} }></div>
                              </p>
                            </div>
                          )
                          }
                        </>
                      )
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>      
  )
}

export default SurveyScalePopup;