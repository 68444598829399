import React, { createContext, useEffect, useState, useMemo, useContext } from 'react'
import { useApi } from './ApiContext'
import { useCommonPopup } from './CommonPopupContext';

var ProjectContext = createContext();

export function ProjectContextProvider({ projectId, children }) {
   
  const api = useApi()
  const {alert} = useCommonPopup()
       
  const [projectData, setProjectData] = useState(null)
  // const [surveyList, setSurveyList] = useState()
  // const [totalCount, setTotalCount] = useState(0)

  useEffect(_ => {

    getProject()
   
  }, [])

  const getProjectId = () => {
    return projectId
  }

  const getProject = async () => {

    const result = await api.request(`/projects/${projectId}/`)
    if (result?.status !== 200) {
      api.networkError(result, alert)
      return null
    } else {   

      var project = result.data
      setProjectData(project)      
      
      return project
    }
  }

  const updateProjectName = async (name) => {

    setProjectData({
      ...projectData,
      name:name
    })       
  }

  return (
    <ProjectContext.Provider
      value={{ projectId, projectData, setProjectData, 
        // surveyList, totalCount,
        getProject, 
        // getSurveyList, 
        updateProjectName}}
    >
      {children}
    </ProjectContext.Provider>
  );
}

export function useProject() {
  return useContext(ProjectContext)
}