import { useEffect, useRef, useState } from "react";
import { useAuth } from '../../../context/AuthContext';
import { useApi } from '../../../context/ApiContext';

import { handleSelect } from '../../../common/javascriptHelper';
import { validateEamil as validateEmail } from '../../../common/stringHelper';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const InviteCollaboratorPopup = props => {

  const {popupData, onCancel, onConfirm} = props
  const auth = useAuth()
  const api = useApi()
  const { confirm, alert } = useCommonPopup()

  const { projectData } = props
  const [collaborators, setCollaborators] = useState([])
  const [invitees, setInvitees] = useState([])
  const [emails, setEmails] = useState([])
  const [isOwner, setIsOwner] = useState(false)
  const [emailData, setEmailData] = useState({
    email: "",
    error: "",
  })
  const emailInputRef = useRef()
  
  useEffect(()=> {

    document.body.classList.add("pop")

    setTimeout(() => { 
      emailInputRef.current?.focus()
    }, 100)

    getCollaborators()
    
    return () => {
      document.body.classList.remove("pop")
    }
  }, []);

  useEffect(()=> {

  }, [collaborators, invitees, emails]);

  const getCollaborators = async () => {

    const result = await api.request(`/projects/${projectData.idproject}/collaborators/`)
    if (result?.status !== 200) {
      api.networkError(result, alert)
    } else {

      var list = result.data.collaborators
      list = list.sort((a, b) => {
        return b.isowner - a.isowner
      })
      list.forEach((element) => {
        if (element.email === auth.adminInfo.email && element.isowner ===1) {
          setIsOwner(true)
        }
      })
      setCollaborators(list)
      setInvitees(result.data.invitees)
    }
  }

  const checkEmail = () => {
    if (emailData.email.length === 0) {
      setEmailData({
        ...emailData,
        error: "이메일을 입력해 주세요."
      })
      return false       
    } 
    if (!validateEmail(emailData.email)) {
      setEmailData({
        ...emailData,
        error: "이메일 형식에 맞지 않습니다. 다시 확인해 주세요."
      })        
      return false
    } 

    const exist = collaborators.some(item => item.email === emailData.email) ||
      emails.some(item => item.email === emailData.email);

    if (exist) {
      setEmailData({
        ...emailData,
        error: "이 이메일은 이미 등록된 작업자입니다."
      })
      return false
    }   
    return true
  }
  
  const onChangeEmail = (e) => {

    var {value} = e.target
    var email = value.replace(/ /g, "") 
          
    var error = ""    

    setEmailData({
      ...emailData,
      email,
      error
    })
  }
  
  const onKeyEventEmail = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }
  
  const onClickAdd = async () => {

    if (!checkEmail()) return

    const result = await api.post("inviteCollaborator", {
      email: emailData.email,
    })
    
    if (result?.data?.result === "ok") {
      addCollaborator(emailData.email)
    } else{
      // NOT_REGISTERED_EMAIL
      if (result.data.code === 240) {
        // TODO : 팝업 디자인 
        if (await confirm(`${emailData.email}님은 아직 Research Flow에 가입되어 있지 않으며,
        공동작업자로 추가하기 위해 초대장을 발송할 예정입니다.<br/> 
        계속 진행을 원하시면 확인 버튼을 눌러주세요.`, {title: "초대장 발송"})) {
          addEmails(emailData.email)
        } else {
          setEmailData({
            ...emailData,
            email: "",
            error: ""
          })  
        }
      } else if (!!result.data?.msg) {
        setEmailData({
          ...emailData,
          error: result.data?.msg
        })  
      } else {
        api.networkError(result, alert)
      }
    }
  }

  const checkInvitees = (item) => {

    return invitees.some(element => element.email === item.email)
  }

  const addCollaborator = (email) => {   

    setCollaborators([
      ...collaborators, 
      {
        email: email,
        isowner: 0,
      }
    ]) 
    setEmailData({
      ...emailData,
      email: "",
      error: ""
    }) 
  }

  const addEmails = (email) => {        
    setEmails([
      ...emails, 
      {
        email: email,
        isowner: 0,
      }
    ]) 
    setEmailData({
      ...emailData,
      email: "",
      error: ""
    }) 
  }

  const onClickCollaborator = (item) => {
    if (!isOwner) return

    var list = collaborators.map(element =>
      (element.email === item.email ?  {
        ...element,
        isowner: 0
      } : element)
    )

    if (list.every(element => element.isowner === 0)) {
      alert("최소 1명의 소유자가 필요합니다.");
    }
    else {
      setCollaborators(list);
    }
  }

  const onClickOwner = (item) => {

    if (!isOwner) return

    var list = collaborators.map(element =>
      (element.email === item.email ?  {
        ...element,
        isowner: 1
      } : element)
    )
    setCollaborators(list);
  }

  const onClickDeleteCollaborators = (item) => {

    setCollaborators(collaborators.filter(element => element !== item));
  }

  const onClickDeleteInvitees = (item) => {

    setInvitees(invitees.filter(element => element !== item));
  }

  const onClickDeleteEmails = (item) => {

    setEmails(emails.filter(element => element !== item));
  }

  const onClickAuthority = (e) => {

    handleSelect(e)
  }
   
  const onClickClose = () => {

    if (!!onCancel) {
      onCancel(popupData, false)
    }
  }

  const onClickOK = async () => {

    // collaborators + emails
    const list = collaborators.concat(emails).map(element => ({
      email: element.email,
      owner: element.isowner
    }));

    const result = await api.post("updateCollaborator", {
      project: projectData.idproject,
      collaborators: list,
    })

    if (result?.data?.result === "ok") {
      if (!!onConfirm) {
        onConfirm(popupData, true)
      }
    } else {
      api.networkError(result, alert)
    }
  }

  return (
    // <div className="popup project_home_pop" >
      <div className="popup_wrap">
        <div className="popup_inwrap survey_share">
          <h5 className="section_tit">
            ‘{projectData.name}’ 공동 작업자 
            <span className="close_btn" onClick={onClickClose}></span>
          </h5>
          <form action="" onSubmit={(e) => {e.preventDefault()}}>
            <div className="pop_content">                      
            <div>
              <label htmlFor="join_mail" >이메일</label>
              <div className="vertical_form">
                <div>
                  <div className="select_inline">
                    <span className="">참여자</span>
                  </div>
                  <input 
                    id="join_mail" 
                    type="text" 
                    ref={emailInputRef}
                    className={`full big ${emailData.error.length > 0 ? "error" : ""}`}
                    placeholder="예) af@atflow.co.kr" 
                    value={emailData.email}
                    onChange={onChangeEmail}
                    onKeyUp={onKeyEventEmail}          
                  />
                </div>
                <div >
                  <button className="full" onClick={onClickAdd}>추가</button>
                </div>
              </div>
              <p className="tip">{emailData.error}</p>      
            </div>
            <div>           
            {
              collaborators.map((item, index) =>
                <div className="vertical_form people" key={`collaborator-${index}`}>
                  <div>
                  <i className="icon-mail"></i>{item.email} {auth.adminInfo.email === item.email && "(나)"}
                  </div>
                  <div>
                  { projectData.isowner === 1 ? ( 
                      auth.adminInfo.email === item.email ? (
                        item.isowner === 1 ? (
                          <div className="selectBox select_inline">
                            <span className="label" onClick={onClickAuthority}>소유자</span>
                            <ul className="optionList">
                              <li className="optionItem disabled skipLabelModify" onClick={() => {onClickCollaborator(item)}}>참여자</li>
                            </ul>
                          </div>
                        ) : (
                          <div className="selectBox select_inline">
                            <span className="label" onClick={onClickAuthority}>참여자</span>
                            <ul className="optionList">
                              <li className="optionItem disabled skipLabelModify" onClick={() => {onClickOwner(item)}}>소유자</li>
                            </ul>
                          </div>
                        )
                      ) : (
                        item.isowner === 1 ? (
                          <div className="selectBox select_inline">
                            <span className="label" onClick={onClickAuthority}>소유자</span>
                            <ul className="optionList">
                              <li className="optionItem disabled skipLabelModify" onClick={() => {onClickCollaborator(item)}}>참여자</li>
                            </ul>
                          </div>
                        ) : (
                          <div className="selectBox select_inline">
                            <span className="label" onClick={onClickAuthority}>참여자</span>
                            <ul className="optionList">
                              <li className="optionItem disabled skipLabelModify" onClick={() => {onClickOwner(item)}}>소유자</li>
                              <li className="optionItem" onClick={() => {onClickDeleteCollaborators(item)}}>삭제</li>
                            </ul>
                          </div>
                        )
                      )
                    ) : (
                      <div className="select_inline">
                        <span className="dash_profile" onClick={onClickAuthority}>{item.isowner === 1 ? "소유자" : "참여자"}</span>
                      </div>
                    )
                  }
                  </div>
                </div>
              )
            }
            {
              invitees.map((item, index) =>
                <div className="vertical_form people" key={`collaborator-${index}`}>
                  <div>
                  <i className="icon-mail"></i>{item.email}
                  </div>
                  <div>
                    <div className="selectBox select_inline">
                      <span className="dash_profile">초대장 발송됨</span>
                    </div>
                  </div>
                </div>
              )
            }
            {
              emails.map((item, index) =>
                <div className="vertical_form people" key={`collaborator-${index}`}>
                  <div>
                  <i className="icon-mail"></i>{item.email}
                  </div>
                  <div>
                    <div className="selectBox select_inline">
                      <span className="label" onClick={onClickAuthority}>{checkInvitees(item) === true ? "초대장 재발송 예정" : "초대장 발송예정"}</span>
                      <ul className="optionList">
                        <li className="optionItem" onClick={() => {onClickDeleteEmails(item)}}>삭제</li> 
                      </ul>
                    </div>
                  </div>
                </div>
              )
            }
            </div>
            <p className="text_secondaty">프로젝트 공동작업자로 지정되면 프로젝트 내 모든 설문의 접근이 가능합니다. </p>
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button type="button" className="secondary gray large pop_close" onClick={onClickClose}>취소</button>
              </div>
              <div>
                <button type="button" className=" primary large " onClick={onClickOK}>확인</button>
              </div>
            </div>
          </div>
         </form>
        </div>
      </div>
    // </div>
  );  
}
 export default InviteCollaboratorPopup;