import { Link } from 'react-router-dom';
import ProjectHeader from '../inc/ProjectHeader';

const Project02 = props => {
  return (      
    <div className="body project">
      <ProjectHeader/>
      <div className="container">
        <div className="inwrap">
          <div className="full gap no_item">
          <section className="">
            <div className="search_wrap">
              <div>
                <div className="search_box">
                  <input type="text" placeholder="설문이름으로 검색"/>
                  <button><i className="icon-search"></i></button>
                </div>

                <div className="selectBox">
                  <span className="label">설문 상태 : 전체</span>
                  <ul className="optionList">
                    <li className="optionItem">전체</li>
                    <li className="optionItem">설문 진행 중</li>
                  </ul>
                  </div>

              </div>
              {/* <button className="primary large new_project_but"> <i>+</i> 새 프로젝트</button> */}
            </div>  
          </section>
          <section className="project">
            <div>
              <img src="../../../assets/images/no_items.png" alt=""/>
            </div>
            <div>
              <p>
                아직 만들어진 설문이 없습니다. <br/>
                설문 만들기 버튼을 눌러서 시작해보세요.
              </p>
            </div>
            <div>

              <button className="primary large new_project_but"> <i>+</i> 설문 만들기</button>
            </div>
          </section>


         </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default Project02;