import { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';

const CS = props => {
  
  const navigate = useNavigate()  
  const api = useApi()
  const {popup, confirm, alert} = useCommonPopup()

  /*=========================================================
   *  useEffect 
   =========================================================*/
  
  useEffect(_ => {
    navigate(`notice`, {replace: true})
  }, [])

  return (   
    <Outlet/>
  );
}
  
export default CS;